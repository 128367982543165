import React, { useState } from 'react';
import '../../Campaign.css';
import moment from 'moment';
import { WEEK_DAYS, ENTITY, getTimeToPushData } from '../../CampaignData';
import './ContentPlanner.css';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import ContentItem from './ContentItem';

const COLUMNS = 5;

const ContentPlanner = (props) => {
  const contentElementArr = [];
  let position = 0;
  let weekDayIndex = 0;
  let rowContent;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [contentId, setContentId] = useState('');

  const timeToPushData = getTimeToPushData();

  const { campaign, onChangeTimeToPush, deleteContent, content = [] } = props;
  const { no_of_days, start_date, status } = campaign;
  const lastRowColumn = no_of_days % COLUMNS;
  let noOfRows = parseInt(no_of_days / COLUMNS) + (lastRowColumn ? 1 : 0);

  const onClickDeleteContentItem = (id) => {
    setShowDeleteDialog(true);
    setContentId(id);
  };

  for (let row = 0; row < noOfRows; row++) {
    let columnArr = [];
    const columns = row === noOfRows - 1 ? lastRowColumn : COLUMNS;
    for (let column = 0; column < columns; column++) {
      let pushDate;
      if (start_date) {
        pushDate = new Date(start_date);
        pushDate.setDate(pushDate.getDate() + position);
      }
      const [contentItem = {}] = content.filter((item) => item.sequence_no === position + 1);
      const { title, type, id } = contentItem.entity || {};
      columnArr.push({
        ...contentItem,
        row,
        column,
        position,
        entity_id: id,
        entityTitle: title,
        entityType: type,
        canAddOrEdit:
                    (pushDate ? pushDate > moment() : status === 'draft' || status === 'upcoming') &&
                    status !== 'ended',
        date: pushDate ? moment(pushDate).format('DD/MM/YYYY') : '',
        typeDesc: ENTITY[contentItem.type],
        weekName: start_date ? moment(pushDate).format('dddd') : WEEK_DAYS[position - weekDayIndex * 7]
      });
      if (column === columns - 1) {
        if (row === noOfRows - 1 && status !== 'ended') {
          columnArr.push({
            emptyCol: true
          });
        }
        rowContent = (
          <div className="d-flex">
            {columnArr.map((item) => (
              <ContentItem
                {...item}
                {...props}
                onChangeTimeToPush={onChangeTimeToPush}
                timeToPushData={timeToPushData}
                onClickDeleteContentItem={onClickDeleteContentItem}
              />
            ))}
          </div>
        );
        contentElementArr.push(rowContent);
      }
      position++;
      if (position % 7 === 0) {
        weekDayIndex++;
      }
    }
  }
  return (
    <div className="vertical-align mt-3">
      {contentElementArr}
      {showDeleteDialog ? (
        <DeleteDialog
          item="Content"
          onSuccess={() => {
            setShowDeleteDialog(false);
            deleteContent(contentId);
          }}
          onClose={() => setShowDeleteDialog(false)}
        />
      ) : null}
      {showMessage ? (
        <MessageDialog
          message={showMessage.message}
          title={showMessage.title}
          onClose={() => setShowMessage(null)}
        />
      ) : null}
    </div>
  );
};

export default ContentPlanner;
