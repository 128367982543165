import React from 'react';
import { get, post, dodelete, put } from 'lib/comms';
import { MessageDialog } from 'components/Dialog/Dialog';
import OnboardingTasks from './components/Tasks/TaskManage/TaskManage';
import OnboardingUsers from './components/Users/OnboardingUsers';
import UserTaskStatusPage from './components/Tasks/TaskStatus/UserTaskStatus';
import OnboardingManagement from './components/Onboarding';
import { Redirect, Router } from 'react-router-dom';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import ToolTip from 'components/ToolTip/TooltTip';

const ONBOARDING_TOOLTIPS = [
  {
    target: '.onboarding-create',
    title: 'Onboarding',
    content:
            'Onboard new hires into your organisation in an engaging, productive and inclusive way.  Share a welcome video from your CEO, make access to company information quick and easy and let Engage4 guide your new hire through a series of customisable tasks to welcome them into your organisation.',
    placement: 'left'
  },
  {
    target: '.onboard-select',
    title: 'Multiple Onboarding Flows',
    content: 'You can create multiple onboarding process flows to meet the needs of your organisation.',
    placement: 'bottom'
  },
  {
    target: '.add-task-button',
    title: 'Build your onboarding process by adding tasks',
    content:
            'You can start to customise your onboarding process by creating tasks using content that you have already set up in Engage4 for example using information from your knowledgebase, a challenge or create new content.',
    placement: 'right'
  },
  {
    target: '.card',
    title: 'Creating your task',
    content:
            'In the task intro field give a description of the task that is to be completed for example "Watch the welcome video from our CEO. Then select the relevant content from the knowledgebase or challenges (Micro learning for our premium customers).',
    placement: 'bottom'
  },
  {
    target: '.add-item-container',
    title: 'Add new content',
    content:
            "If the content you are looking to link to a task doesn't exist you can create it from here without losing your progress.",
    placement: 'left'
  },
  {
    target: '.searchrow-outer',
    title: 'Assigning participants',
    content:
            'Once you have created your onboarding process you can assign the onboarding to users. Each user can only have one onboarding process assigned to them at a time and they will be notified in app when you have assigned an onboarding process to them. You will be able to track the progress for each user from this screen, simply select the relevant user to see which tasks they have completed and which are still to be done.',
    placement: 'bottom'
  }
];

export default class OnboardingPage extends React.Component {
  constructor (props) {
    super(props);
    this.navigationState = this.props.location.state;
    this.state = {
      showUserProgress: false,
      addingTask: this.navigationState?.from === 'challenges',
      editingTaskItem: this.navigationState?.params,
      onboardingId: this.navigationState?.params?.onboardingId || '',
      showTaskStatus: false,
      slctdMember: null,
      addContentItem: null,
      stepIndex: 0,
      featureFlags: [],
      showMessage: this.navigationState?.createdNew
        ? {
          title: 'Message',
          message: 'Newly created item available for selection'
        }
        : null
    };
  }

  componentDidMount () {
    this.fetchFeatureFlags();
  }

    fetchFeatureFlags = async (orgId) => {
      const featureFlagResp = await get(`cms/feature_flags/${orgId}`, null, true);
      if (featureFlagResp && featureFlagResp.feature_flags) {
        this.setState({ featureFlags: featureFlagResp.feature_flags });
      }
    };

    showErrorDialog = (message) => {
      setTimeout(
        () =>
          this.setState({
            showMessage: {
              message: message,
              title: 'Save error',
              close: () => this.setState({ showMessage: null })
            }
          }),
        500
      );
    };

    onClickViewTaskProgress = (member) => {
      this.setState({
        showUserProgress: true,
        slctdMember: member
      });
    };
    onClickCloseTaskProgress = (member) => {
      this.setState({
        showUserProgress: null,
        slctdMember: null
      });
    };

    openAddingTask = () => {
      this.setState({
        addingTask: true,
        editingTaskItem: null
      });
    };

    onCloseContent = () => {
      this.setState({
        addingTask: false,
        editingTaskItem: null
      });
    };

    openEditingTask = (taskItem) => {
      this.setState({
        editingTaskItem: taskItem
      });
    };

    onSelectOnboarding = (onboardingId) => {
      this.setState({ onboardingId });
    };

    onClickAddItem = (item) =>
      this.setState({
        addContentItem: item
      });

    render () {
      const {
        addingTask,
        editingTaskItem,
        showUserProgress,
        onboardingId,
        slctdMember,
        addContentItem,
        showMessage,
        stepIndex,
        featureFlags
      } = this.state;
      if (addContentItem) {
        let pathname = 'knowledgebase';
        if (addContentItem.type === 'challenges') {
          pathname = 'challenges';
        } else if (addContentItem.type === 'learning') {
          pathname = 'micro';
        }
        return (
          <Redirect
            to={{
              pathname: `/${pathname}`,
              state: {
                from: 'onboarding',
                params: {
                  ...addContentItem,
                  onboardingId
                }
              }
            }}
          />
        );
      }
      return (
        <div className="maincontainer">
          <SubscriptionBanner />
          {showUserProgress || addingTask || editingTaskItem ? null : (
            <OnboardingManagement onSelectOnboarding={this.onSelectOnboarding} onboardingId={onboardingId} />
          )}
          {onboardingId ? (
            <>
              {!showUserProgress ? (
                <OnboardingTasks
                  openAddingTask={this.openAddingTask}
                  openEditingTask={this.openEditingTask}
                  addingTask={addingTask}
                  editingTaskItem={editingTaskItem}
                  onCloseContent={this.onCloseContent}
                  onboardingId={onboardingId}
                  addContentItem={this.onClickAddItem}
                  featureFlags={featureFlags}
                />
              ) : (
                <UserTaskStatusPage
                  onboardingId={onboardingId}
                  userId={slctdMember && slctdMember.user_id}
                  onBack={this.onClickCloseTaskProgress}
                />
              )}
              {addingTask || editingTaskItem || showUserProgress ? null : (
                <OnboardingUsers
                  onboardingId={onboardingId}
                  onClickViewProgress={this.onClickViewTaskProgress}
                />
              )}
            </>
          ) : null}
          <ToolTip
            screenId="onboarding"
            stepIndex={this.state.stepIndex}
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  editingLayout:
                                    ((stepIndex === 2 || stepIndex === 3) && tooltipData.action === 'next') ||
                                    ((stepIndex === 4 || stepIndex === 5) && tooltipData.action === 'prev')
                                      ? this.openAddingTask()
                                      : this.onCloseContent()
                });
              }
            }}
            style={{ width: 480 }}
            steps={ONBOARDING_TOOLTIPS}
          />
          {showMessage ? (
            <MessageDialog
              message={showMessage.message}
              title={showMessage.title}
              onClose={() =>
                this.setState({
                  showMessage: null
                })
              }
            />
          ) : null}
        </div>
      );
    }
}
