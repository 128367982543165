import React, { useRef } from 'react';
import { EditText } from 'components/EditText/EditText';
import EditBrowse from 'components/EditBrowse/EditBrowse';
import ReactQuill, { Quill } from 'react-quill';
import { upload } from 'lib/comms';
import { Stream } from '@cloudflare/stream-react';
import AuthImage from 'components/AuthImage/AuthImage';
import CustomColor from 'components/InlineBlotQuill/InlineBlotQuill';
import { MessageDialog } from 'components/Dialog/Dialog';

import * as S from './KnowledgebaseItem.styled';

// Allows spellcheck to function without deleting the word or surrounding text.
Quill.register(CustomColor, true);
export default class KnowledgebaseInfoItem extends React.Component {
  constructor (props) {
    super(props);
    this.state = { showMessage: null };
  }

    onChangeFileName = (index, fileName) => {
      if (fileName.length > 254) return;
      let files = [...this.props.files];
      files[index] = {
        ...files[index],
        title: fileName
      };
      this.props.updateKnowledgebaseItem({
        files
      });
    };

    onFileChange = (file, index) => {
      let files = [...this.props.files];
      files[index].file = file;
      files[index].original_name = file.name;
      this.props.updateKnowledgebaseItem({
        files
      });
    };

    removeFile = (index) => {
      const files = [...this.props.files];
      files.splice(index, 1);
      this.props.updateKnowledgebaseItem({ files });
    };

    onChangeInformationText = (description) => {
      this.props.updateKnowledgebaseItem({ description });
    };

    onClickAddFiles = () => {
      let files = [
        ...this.props.files,
        {
          title: ''
        }
      ];
      if (files.length > 5) {
        this.setState({
          showMessage: {
            message: 'You can add maximum of 5 files',
            title: 'Message'
          }
        });
        return;
      }
      this.props.updateKnowledgebaseItem({
        files
      });
    };

    render () {
      let { isEditing, files, description, isNew } = this.props;
      return (
        <div>
          <div style={{ width: '100%' }}>
            <ReactQuill
              style={{ minHeight: 300, backgroundColor: '#D8D8D8' }}
              readOnly={!isEditing}
              theme="snow"
              value={description}
              onChange={this.onChangeInformationText}
            />
          </div>

          <S.VerticalSpacer size={35} />

          {files.map((file, index) => (
            <S.FileContainer>
              <EditText
                outerClassName="mt-3 mb-3 w-50"
                label="File Name"
                editing={isEditing && !file.id}
                onChange={(title) => this.onChangeFileName(index, title)}
                value={file.title}
              />
              <S.HorizontalSpacer size={20} />
              <EditBrowse
                editing={isEditing && !file.id}
                onFileChange={(slctdFile) => this.onFileChange(slctdFile, index)}
                fileName={file['original_name']}
              />
              <S.HorizontalSpacer size={20} />
              <button
                className="btn btn-remove"
                onClick={() => this.removeFile(index)}
                disabled={!isEditing && file.id}
                isEditing={isEditing && !file.id}
              >
                            Remove
              </button>
            </S.FileContainer>
          ))}

          <S.BottomFileContainer>
            <button
              className="btn btn-primary knowledgebase-btn"
              disabled={!isEditing}
              onClick={this.onClickAddFiles}
            >
                        ADD FILES
            </button>
          </S.BottomFileContainer>
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
export class VideoOrImage extends React.Component {
    onAddImage = async (event) => {
      const acceptedFiles = event.target.files;
      if (acceptedFiles.length > 0) {
        const [{ name, size }] = acceptedFiles;
        if (size > 4000000) return alert('Please upload a smaller image!');
        const resp = await upload('cms/upload', 'image', acceptedFiles[0], true);
        if (resp && resp.image_id) this.props.updateKnowledgebaseItem({ image_id: resp.image_id });
      }
    };

    onMediaSelect = (event) => {
      try {
        const mediaFile = event.target.files[0];
        const fileBlobUrl = URL.createObjectURL(mediaFile);
        const mediaType = mediaFile.type.indexOf('video') !== -1 ? 'video' : 'image';

        if (mediaType === 'video') {
          if (mediaFile.size > 200 * 1000 * 1000) {
            // Cloudflare limit is 200 MB for direct user uploads
            // TO-DO ERROR DIALOG FOR VIDEO FILE THAT IS TOO LARGE
            return alert('Video File is too large, please upload a video file that is less than 200MB');
          }
          this.setState({
            mediaFile,
            mediaType,
            videoBlobUrl: fileBlobUrl,
            videoName: mediaFile.name
          });

          this.props.updateKnowledgebaseItem({
            videoBlobUrl: fileBlobUrl,
            mediaFile: mediaFile
          });
        } else {
          this.onAddImage(event);
        }
      } catch (err) {
        console.log(err);
      }
    };

    removeImage = () => this.props.updateKnowledgebaseItem({ image_id: null });
    removeVideo = () =>
      this.props.updateKnowledgebaseItem({
        video_id: null,
        isVideoSet: false,
        videoBlobUrl: '',
        video_urls: {},
        videoUrls: {}
      });

    checkForVideoFileDuration = (videoEvent) => {
      if (videoEvent.currentTarget.duration > 1800) {
        this.setState({
          mediaType: '',
          mediaFile: null,
          imageBlobUrl: '',
          videoName: '',
          videoBlobUrl: ''
        });
        return alert('Video duration is too long, please upload a video that is under 30 minutes long');
      }
    };

    render () {
      const { isEditing, imageId, isVideoSet, videoUrls, videoBlobUrl } = this.props;
      const videoThumbnail = videoUrls.thumbnail_animated;
      const videoToken = videoUrls.token;

      return (
        <div style={{ width: '100%' }}>
          {imageId && (
            <div>
              <AuthImage url={`content/image/${imageId}`} className="editimage-img" alt="" />
              <S.VerticalSpacer size={20} />
              {isEditing ? (
                <RemoveImageButtonEnabled onClick={this.removeImage} />
              ) : (
                <RemoveImageButtonDisabled />
              )}
            </div>
          )}

          {videoBlobUrl ? (
            <div>
              <video
                key={videoBlobUrl}
                width="100%"
                controls
                onDurationChange={this.checkForVideoFileDuration}
                src={videoBlobUrl}
              >
                <source src={videoBlobUrl} />
                            Your browser does not support HTML video.
              </video>
              <S.VerticalSpacer size={20} />
              {isEditing ? (
                <RemoveVideoButtonEnabled onClick={this.removeVideo} />
              ) : (
                <RemoveVideoButtonDisabled />
              )}
            </div>
          ) : videoThumbnail ? (
            <div>
              <Stream src={videoToken} controls autoplay={false} preload height="300px" width="100%"></Stream>

              {/* <img
                src={videoThumbnail}
                className='editimage-img'
                /> */}
              <S.VerticalSpacer size={20} />
              {isEditing ? (
                <RemoveVideoButtonEnabled onClick={this.removeVideo} />
              ) : (
                <RemoveVideoButtonDisabled />
              )}
            </div>
          ) : null}

          {!imageId &&
                    !isVideoSet &&
                    !videoBlobUrl &&
                    (isEditing ? (
                      <>
                        <AddMediaButtonEnabled onClick={this.onMediaSelect} />
                      </>
                    ) : (
                      <>
                        <AddMediaButtonDisabled />
                      </>
                    ))}
        </div>
      );
    }
}

export const AddMediaButtonEnabled = ({ onClick }) => (
  <label className={'knowledgebaseItem-add-image'}>
        ADD MEDIA
    <input type="file" onChange={onClick} accept="video/*, image/*" />
  </label>
);

export const AddMediaButtonDisabled = () => (
  <button disabled className={'btn btn-secondary w-100'}>
        ADD MEDIA
  </button>
);

export const RemoveImageButtonEnabled = ({ onClick }) => (
  <button onClick={onClick} className={'btn btn-primary w-100'} onClick={onClick}>
        REMOVE IMAGE
  </button>
);

export const RemoveImageButtonDisabled = () => (
  <button disabled className={'btn btn-secondary w-100'}>
        REMOVE IMAGE
  </button>
);

export const RemoveVideoButtonEnabled = ({ onClick }) => (
  <button onClick={onClick} className={'btn btn-primary w-100'}>
        REMOVE VIDEO
  </button>
);

export const RemoveVideoButtonDisabled = () => (
  <button disabled className={'btn btn-secondary w-100'}>
        REMOVE VIDEO
  </button>
);
