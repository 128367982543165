export const DASHBOARD_TOOLTIPS = [
  {
    target: '.dashboard-name',
    title: 'Your dashboard',
    content:
            'Your dashboard is where you will find information and analytics on how your organisation is using engage4, the dashboard has a number of widgets that allow for filtering and settings to help you get the information you need. You can have multiple dashboards set up with different views. To help get you started we have created a default dashboard for you which you can edit.',
    placement: 'left'
  },
  {
    target: '.dashboard-change-layout',
    title: 'Edit dashboard layout',
    content: 'To edit an existing dashboard layout select "Change Layout."',
    placement: 'right'
  },
  {
    target: '.card-title-outer',
    title: 'Changing the size of a widget',
    content:
            'You can change the size of the widget by selecting the S M or L and this will give you a preview of the widget size.',
    placement: 'bottom'
  },
  {
    target: '.dashboard-widget-drag',
    title: 'Ordering your widgets',
    content: 'To reorder your widgets simply drag and drop them in the order you would like to see them displayed.',
    placement: 'bottom'
  },
  {
    target: '.dashboard-add-widget',
    title: 'Adding a new widget',
    content:
            'Your dashboard is made up of multiple widgets, you can add as many of the same widgets as required. Lets take a look at each of the widgets in more detail.',
    placement: 'bottom'
  },
  {
    target: '.dashboard-stats-widget',
    title: 'Statistics',
    content:
            'This widget will give you an indication of the overall use of each of the features within Engage4. You can also customise targets for each of this statistics by selecting thresholds from the top right of the widget.',
    placement: 'right'
  },
  {
    target: '.dasboard-threshold',
    title: 'Setting your thresholds',
    content:
            'For each feature listed set a medium and high target. If you do not want to track against a specific feature you can untick that feature. Once you have set your thresholds when you have achieved the medium target the statistic will be highlighted in amber when you have reached your high target the statistic will be highlighted in green and if you are behind on target it will be in red.',
    placement: 'right'
  },
  {
    target: '.dashboard-sessions-widget',
    title: 'App Sessions',
    content:
            'This widget allows you to view the number of app sessions over the selected period of time, giving you an indication of how many times the app is accessed by your organisation.',
    placement: 'left'
  },
  {
    target: '.dashboard-pulse-widget',
    title: 'Check in Pulse',
    content:
            'Track and monitor the overall temperature of your organisation over the selected period, as an example this information can be helpful during periods of significant change  helping you understand the impact that change is having on your employees and allowing you to offer additional support.',
    placement: 'bottom'
  },
  {
    target: '.dashboard-top-widget',
    title: 'Your top users',
    content:
            'Track who your top contributors are within Engage4 based on the points users are earning for using Engage4. See up to the top 12 users.',
    placement: 'left'
  },
  {
    target: '.dashboard-uniquedailysessions-widget',
    title: 'Unique daily sessions',
    content:
            'This information is similar to the app sessions widget however the major difference is that it shows the number of unique logins to Engage4 for the day. Based on this information you will have an understanding of how many of your users are using Engage4 on a regular basis.',
    placement: 'bottom'
  },
  {
    target: '.dashboard-group-widget',
    title: 'Groups analytics',
    content:
            'This widget will highlight the top five polls and notice posts across all your groups that received the highest level of engagement and also highlight the average response rate for polls and the average engagement rate for notice posts. This is a great way to monitor which groups and topics are most popular and therefore achieve higher levels of engagement across your organisation.',
    placement: 'left'
  },
  {
    target: '.dashboard-settings',
    title: 'Setting for each widget',
    content:
            'Each widget has the settings options at the top right. This allows you to filter the information using any departments or user tags you may have set up. You can also select the time period for the information so if you want to created two check in pulse widgets one could display information based on last month and the other based on this month for a great visual comparison.',
    placement: 'right'
  },
  {
    target: '.dashboard-export',
    title: 'Exporting your dashboard',
    content:
            'Select export to download an excel document with all the data from your dashboard to use in reporting and presentations.',
    placement: 'bottom'
  },
  {
    target: '.dashboard-dropdown',
    title: 'Managing your dashboards',
    content:
            'All dashboard are visible to any CMS users with administrator or management permissions. You can edit a dashboard at any time by selecting settings on the widget or the change layout option. Any changes you make to a dashboard will be applied for all users. You can have multiple dashboards by selecting create new dashboard and then selecting the appropriate dashboard from the select dashboard drop down. You can delete a dashboard by selecting delete, this will delete the dashboard for all CMS users.',
    placement: 'bottom'
  }
];
