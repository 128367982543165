import React, { useState, useCallback, useEffect } from 'react';
import './WelcomeDialog.css';
import welcomeImage from 'assets/welcome_image.png';
import { Link } from 'react-router-dom';

import { get, post } from 'lib/comms';

// useEffect call API, useState setShowWelcome, function that returns welcome status. ComponentDidMount GET request to get status of welcomeStatus, DidUnmount to POST welcomestatus
// CMS Welcome status. key value pair showWelcome.
// strip this out to its own component (look at radhas pushed up code for an idea on how to use useEffect to get api data and set)

export const WelcomeDialog = (props) => {
  const [showWelcome, setShowWelcome] = useState();

  const { title, btnText } = props;
  const message =
        'Welcome to Engage4! We are excited to be a part of helping to connect, onboard, unite and retain your workforce. To help you get started we have created a brief tutorial for each of the screens within this content management system (CMS). Each time you visit a screen for the first time the tutorial will guide you through how to set up and use that feature. If you forget what to do simply reset the tutorial from the settings screen. Please refer to the full user guide linked in the welcome email that was sent when you signed up. If you have any questions or require any assistance please email support@engage4.com';

  useEffect(() => {
    const fetchData = async () => {
      const welcomeStatusResp = await get('cms/welcome_status', null, true);

      if (welcomeStatusResp && welcomeStatusResp.show_welcome) {
        setShowWelcome(welcomeStatusResp.show_welcome);
      }
    };
    fetchData();

    return () => {
      post('cms/welcome_status', { show_welcome: 0 }, true);
    };
  }, []);

  if (showWelcome === true) {
    return (
      <div className="welcome-dialog-overlay-outer">
        <div className="welcome-dialog-inner" style={{ width: '800px' }}>
          <img src={welcomeImage} alt="welcome image" className="welcome-image" />
          <h4 style={{ marginTop: '20px', marginBottom: '10px' }}>{title}</h4>
          <p className="welcome-dialog-link-text">{message}</p>
          <div className="welcome-button-container">
            {/* <Link className="welcome-dialog-button" to="/users"> */}
            <button className="btn btn-primary welcome-dialog-button" onClick={() => setShowWelcome(false)}>
              {btnText || 'OK'}
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
