import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import { get, post } from 'lib/comms';
import Table from 'components/Table/Table';
import DepartmentPage from './DepartmentPage';
import NewDepartmentPage from './NewDepartmentPage';
import './DepartmentsPage.css';
import { Redirect } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { USER_ROLES } from '../../../../App';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import ToolTip from 'components/ToolTip/TooltTip';

const columns = [
  { name: 'Id', selector: 'id', grow: 0, sortable: false, hide: 4096 },
  { name: 'Department', selector: 'department', sortable: true, grow: 3, wrap: true },
  { name: 'Users', selector: 'user_count', sortable: true, grow: 3, wrap: true }
];
export default class DepartmentsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: [],
      search: '',
      editing: null,
      addingDepartment: false,
      newDepartment: null,
      showMessage: null,
      stepIndex: 0
    };
  }
    static contextType = UserContext;

    componentDidMount = () => this.reload();

    doneEditing = () => {
      this.setState({ editing: null });
      this.reload();
    };

    newDepartmentSave = async () => {
      let { newDepartment } = this.state;
      if (newDepartment) {
        const resp = await post('cms/department/', { new_department_name: newDepartment }, true);
        if (resp && resp.success) {
          this.setState({ newDepartment: null, addingDepartment: null });
          this.reload();
        }
        if (resp && resp.message) {
          console.log(resp.message);
          setTimeout(
            () =>
              this.setState({
                showMessage: {
                  message: resp.message,
                  title: 'Save error',
                  close: () => this.setState({ showMessage: null })
                }
              }),
            500
          );
        }
      }
    };

    handleNewDepartmentChange = (value) => {
      this.setState({ newDepartment: value });
    };

    reload = async () => {
      let resp = await get('cms/department', null, true);

      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.departments) this.setState({ data: resp.departments });
      else console.log(resp.departments);
    };

    render () {
      const { user, userRoleIds } = this.context;
      const isAdmin = userRoleIds.indexOf(USER_ROLES.ADMIN) !== -1;
      const isManagement = userRoleIds.indexOf(USER_ROLES.MANAGEMENT) !== -1;
      const errorMessage = this.state.showMessage;
      const departmentsData = this.state.data;
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      let { data, search, editing, addingDepartment } = this.state;
      if (addingDepartment) {
        return (
          <NewDepartmentPage
            value={this.state.newDepartment}
            onEdit={this.handleNewDepartmentChange}
            submit={this.newDepartmentSave}
            errorMessage={errorMessage}
            back={() => this.setState({ addingDepartment: null })}
          />
        );
      }
      if (search) data = data.filter((item) => item.department.toLowerCase().indexOf(search.toLowerCase()) >= 0);

      return (
        <>
          {this.state.editing ? (
            <DepartmentPage department={editing} departmentsData={departmentsData} onBack={this.doneEditing} />
          ) : (
            <div className="maincontainer">
              <SubscriptionBanner />
              <h4>Department</h4>
              <SearchRow
                value={search}
                searchTitle="Search by department name"
                onSearch={(search) => this.setState({ search })}
                className={addingDepartment ? 'success' : 'primary'}
                onBtnClick={() => this.setState({ addingDepartment: !addingDepartment, search: '' })}
                buttonTitle={isAdmin || isManagement ? 'ADD NEW DEPARTMENT' : ''}
              />
              <Table
                columns={columns}
                data={data}
                onRowClicked={(item) => this.setState({ editing: item })}
                tableID="departments"
                defaultSortField={columns[1].selector}
              />
            </div>
          )}
          <ToolTip
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  editing:
                                    (this.state.stepIndex === 1 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 2 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 3 && tooltipData.action === 'prev')
                                      ? data[0]
                                      : null
                });
              }
            }}
            screenId={'departments'}
            steps={[
              {
                target: '.table-outer',
                title: 'Departments',
                content:
                                'Departments allow you to filter down information on your dashboard, you can create and manage departments from this screen.'
              },
              {
                target: '.searchrow-btn',
                title: 'Adding a Department',
                content:
                                'To add a new department select add new and create a department, this will then be added to the profile screen of all users to select from a dropdown list or you can manage this from the CMS.'
              },
              {
                target: '.members-table',
                title: 'Managing a Department',
                content:
                                'from this screen you can view and manage which users are part of that department, you can edit the department name and you can delete the department.'
              },
              {
                target: '.btn-danger',
                title: 'Delete Department',
                content:
                                'If you delete a department that still has users assigned to it you will be asked to select which department those users must be moved to before you can delete this one.'
              }
            ]}
            stepIndex={this.state.stepIndex}
          />
        </>
      );
    }
}
