import React from 'react';
import moment from 'moment';

const CampaignInfo = ({ campaign, onClickExportReport }) => {
  const { name, auto_start, description, color, start_date, no_of_days } =
    campaign;
  const startDate = moment(start_date).format('DD/MM/YYYY');
  const dateLabel =
    startDate < moment().format('DD/MM/YYYY') ? 'Starts on' : 'Started on';
  const length = no_of_days / 7 === 1 ? '1 Week' : `${no_of_days / 7} Weeks`;
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="campign-color" style={{ backgroundColor: color }}></div>
        <h4 className="p-0 m-0 ml-2">{`${name} - ${
          auto_start ? 'Auto' : 'Manual'
        }`}</h4>
        {onClickExportReport && typeof onClickExportReport === 'function' ? (
          <button
            className="btn btn-primary"
            style={{ height: 40 }}
            onClick={onClickExportReport}
          >
            Export
          </button>
        ) : null}
      </div>
      {auto_start ? (
        <p className="m-0 p-0 mt-3">{`${dateLabel} ${startDate} for ${length}`}</p>
      ) : null}
      {description ? <p className="p-0 m-0 mt-1">{description}</p> : null}
    </div>
  );
};

export default CampaignInfo;
