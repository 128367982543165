export default {
  selected: {
    backgroundColor: '#0274a7'
  },
  title: {
    style: {
      fontSize: '22px',
      fontColor: '#FFFFFF',
      backgroundColor: '#363640',
      color: '#FFFFFF'
    }
  },
  contextMenu: {
    style: {
      backgroundColor: '#E91E63',
      fontColor: '#FFFFFF'
    }
  },
  headRow: {
    style: {
      fontSize: '13px',
      fontName: 'Poppins',
      fontWeight: 600,
      fontColorActive: '#FFFFFF',
      backgroundColor: '#0274a7',
      minHeight: '56px',
      fontColor: '#FFFFFF',
      color: '#FFFFFF'
    }
  },
  headCells: {
    style: {
      fontSize: '13px',
      fontWeight: 600,
      fontColor: '#FFFFFF',
      color: '#FFFFFF',
      paddingLeft: '16px',
      paddingRight: '16px'
    },
    activeSortStyle: {
      color: '#FFFFFF',
      '&:focus': {
        outline: 'none'
      },
      '&:hover:not(:focus)': {
        color: '#FFFFFF'
      }
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#FFFFFF'
      },
      '&:hover': {
        color: '#FFFFFF'
      }
    }
  },
  rows: {
    style: {
      fontSize: '15px',
      fontName: 'Poppins',
      fontColor: '#0F2B41',
      backgroundColor: '#FFFFFF',
      borderColor: '#EAEAEA',
      hoverFontColor: 'black',
      hoverBackgroundColor: 'rgba(0, 0, 0, .24)'
    }
  },
  cells: {
    style: {
      cellPadding: '48px'
    }
  },
  pagination: {
    style: {
      fontSize: '12px',
      fontName: 'Poppins',
      fontColor: '#505A62',
      backgroundColor: '#FFFFFF',
      buttonFontColor: '#505A62',
      buttonHoverBackground: 'rgba(255, 255, 255, .12)'
    }
  },
  expander: {
    style: {
      fontColor: '#FFFFFF',
      backgroundColor: '#363640',
      expanderColor: '#FFFFFF'
    }
  }
};
