import React from 'react';
import moment from 'moment';
import BackRow from 'components/BackRow/BackRow';
import './Campaign.css';
import EditCampaignDialog from './components/AddOrEditCampaign';
import ContentPlanner from './components/ContentPlanner/ContentPlanner';
import CampaignsService from 'services/CampaignsService';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import RunOrStopCampaign from './components/RunOrStopCampaign';
import CreateOrEditContent from 'components/CreateContent/CreateContent';
import { Redirect } from 'react-router-dom';
import CampaignInfo from './components/CampaignInfo';

const DELETE_CONFIRMATION_MSG =
    'Are you sure you want to delete this campaign? This action canot be undone.Any future posts will not be published and any posted content will  be removed. The associated reporting will also be deleted. Any Micro Learning, challenges or knowledgebase items will still be accessible within those areas of the app and will need to be deleted separately';

export default class CampaignPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      editCampaign: false,
      campaign: this.props.campaign,
      content: null,
      showMessage: false,
      showDeleteDialog: false,
      runOrStopCampaign: false,
      addingContent: this.props.addingContent ? this.props.addingContent.sequence_no : false,
      editingContent: this.props.addingContent && this.props.addingContent.id ? this.props.addingContent : null,
      addContentItem: false
    };
  }

  componentDidMount () {
    this.reload();
  }

    reload = async () => {
      const content = await CampaignsService.getContent(this.state.campaign.id);
      this.setState({ content });
    };

    updateState = (key, val) => {
      this.setState({
        [key]: val
      });
    };

    updateCampaign = async(params, navigateBack) => {
      const campaign = this.state.campaign;
      const start_date = campaign.start_date || params.start_date;
      const updatedParams = {
        ...campaign,
        ...params,
        start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : start_date
      };
      this.setState({
        campaign: { ...campaign, ...params },
        runOrStopCampaign: false
      });

      const resp = await CampaignsService.createOrEditCampaign(updatedParams);
      if (resp && resp.success) {
        this.state.content.map((item) => {
          CampaignsService.addOrEditContent(this.state.campaign, item);
        });
      }


      if (navigateBack) {
        this.props.onBack();
      }
    };

    deleteCampaign = async () => {
      const message = await CampaignsService.deleteCampaign(this.state.campaign.id);
      if (message) {
        this.setState({
          showMessage: { title: 'Delete Error', message }
        });
      } else this.props.onBack();
    };

    onClickAddWeek = () => {
      this.updateCampaign({ no_of_days: this.state.campaign.no_of_days + 7 });
    };

    OnClickRunOrStopCampaign = (dialogStatus) => {
      this.setState({
        runOrStopCampaign: dialogStatus
      });
    };

    onClickAddContent = (slctdSeqNo) => {
      this.setState({
        addingContent: slctdSeqNo
      });
    };

    onClickEditContent = (contentItem) => {
      this.setState({
        editingContent: contentItem
      });
    };

    onSaveContent = async (item) => {
      const { intro, id, type, time_to_push, entity } = item;
      let campaignParams = {
        title: intro,
        type,
        entity_id: id,
        time_to_push: time_to_push || '09:00',
        entity
      };
      if (this.state.addingContent) {
        campaignParams.sequence_no = this.state.addingContent;
      } else if (this.state.editingContent) {
        campaignParams.id = this.state.editingContent.id;
        campaignParams.sequence_no = this.state.editingContent.sequence_no;
      }
      const resp = await CampaignsService.addOrEditContent(this.state.campaign, campaignParams);
      if (resp && resp.success) {
        this.reload();
      }
    };

    onChangeTimeToPush = async (timeToPushParams) => {
      const { id, time_to_push } = timeToPushParams;
      this.setState({
        content: this.state.content.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              time_to_push
            };
          }
          return item;
        })
      });
      CampaignsService.addOrEditContent(this.state.campaign, timeToPushParams);
    };

    deleteContent = async (contentId) => {
      const message = await CampaignsService.deleteContent(contentId);
      if (message) {
        this.setState({
          showMessage: {
            title: 'Delete Error',
            message
          }
        });
      } else {
        this.reload();
      }
    };

    onClickAddContentItem = (item) =>
      this.setState({
        addContentItem: item
      });

    renderDetails = () => {
      const { status } = this.state.campaign;
      const canEditCampaign = status === 'draft' || status === 'upcoming';
      return (
        <div className="d-flex  mt-3" style={{ justifyContent: 'space-between' }}>
          <div className="horizontal-align">
            <button
              className="edit-btn"
              style={canEditCampaign ? {} : { color: '#0F2B41', opacity: 0.5 }}
              disabled={!canEditCampaign}
              onClick={() =>
                this.setState({
                  editCampaign: true
                })
              }
            >
                        Edit
            </button>
            <span className={'vertical-bar'}>|</span>
            <button
              className="edit-btn"
              disabled={!(canEditCampaign || status === 'ended')}
              style={{
                color: '#AF365F',
                opacity: canEditCampaign || status === 'ended' ? 1 : 0.5
              }}
              onClick={() => {
                this.setState({ showDeleteDialog: true });
              }}
            >
                        Delete
            </button>
          </div>
          {status === 'active' || status === 'draft' ? (
            <btn
              style={{ height: 40, cursor: 'pointer' }}
              className="btn btn-primary"
              onClick={() => this.OnClickRunOrStopCampaign(true)}
            >
              {status === 'active' ? 'STOP CAMPAIGN' : 'RUN CAMPAIGN'}
            </btn>
          ) : null}
        </div>
      );
    };

    render () {
      const { editCampaign, addingContent, editingContent, addContentItem } = this.state;

      if (addContentItem) {
        let pathname = 'knowledgebase';
        if (addContentItem.type === 'challenges') {
          pathname = 'challenges';
        } else if (addContentItem.type === 'learning') {
          pathname = 'micro';
        }
        return (
          <Redirect
            to={{
              pathname: `/${pathname}`,
              state: {
                from: 'campaigns',
                params: {
                  ...addContentItem,
                  campaign: this.state.campaign,
                  content: {
                    ...this.state.editingContent,
                    sequence_no: this.state.addingContent
                  }
                }
              }
            }}
          />
        );
      }

      if (addingContent || editingContent) {
        return (
          <CreateOrEditContent
            addContentItem={this.onClickAddContentItem}
            onSave={this.onSaveContent}
            editingItem={editingContent}
            isCampaignContent={true}
            onClose={() => this.setState({ addingContent: false, editingContent: null })}
          />
        );
      }

      return (
        <div className="maincontainer">
          <BackRow backTitle="Back to Campaign Dashboard" onBack={this.props.onBack} />
          <CampaignInfo campaign={this.state.campaign} />
          {this.renderDetails()}
          {this.state.content ? (
            <ContentPlanner
              campaign={this.state.campaign}
              content={this.state.content}
              onClickAddWeek={this.onClickAddWeek}
              onClickAddContent={this.onClickAddContent}
              deleteContent={this.deleteContent}
              onClickEditContent={this.onClickEditContent}
              onChangeTimeToPush={this.onChangeTimeToPush}
            />
          ) : null}

          {editCampaign ? (
            <EditCampaignDialog isEdit={true} updateState={this.updateState} campaign={this.state.campaign} />
          ) : null}
          {this.state.runOrStopCampaign ? (
            <RunOrStopCampaign
              {...this.state.campaign}
              OnClickRunOrStopCampaign={this.OnClickRunOrStopCampaign}
              updateCampaign={this.updateCampaign}
            />
          ) : null}
          {this.state.showDeleteDialog ? (
            <DeleteDialog
              item="Campaign"
              confirmMsg={DELETE_CONFIRMATION_MSG}
              onSuccess={this.deleteCampaign}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
