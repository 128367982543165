import React from 'react';
import { get } from 'lib/comms';
import { FlexibleXYPlot, VerticalBarSeries, HorizontalGridLines, XAxis, YAxis, Hint } from 'react-vis'; // https://uber.github.io/react-vis/documentation/api-reference/xy-plot
import 'react-vis/dist/style.css';
import moment from 'moment';
import { createDashboardWidgetSettings } from '../../../../../lib/dashboard';
import SmallLoader from '../../../../../components/SmallLoader/SmallLoader';

export default class extends React.Component {
    state = { data: [], status: 'LOADING' };

    componentDidMount = async () => this.reload();

    reload = async () => {
      const { settings } = this.props;

      this.setState({
        status: 'LOADING'
      });

      const response = await get(
        'cms/dashboard_data/uniquedailyappsessions',
        createDashboardWidgetSettings(settings),
        true
      );
      const data = response.data;

      if (response.success === false && response.message.length > 0) {
        this.setState({
          status: 'READY_WITH_ERROR',
          error: response.message
        });
      }

      if (Array.isArray(data)) {
        this.setState({
          data,
          status: 'READY'
        });
      }
    };

    render () {
      const { data, hintItem, status, error } = this.state;
      const { width, settings = {} } = this.props;
      const { time = 'thismonth' } = settings;
      const graphData = [];
      let xlsData = [];

      if (status === 'LOADING') {
        return (
          <div style={localStyle.outer}>
            <SmallLoader />
          </div>
        );
      }

      if (status === 'READY_WITH_ERROR') {
        return <div style={localStyle.outer}>{error}</div>;
      }

      let dataObj = {};
      for (const record of data) dataObj[record.date] = record.sessions; // eslint-disable-line no-unused-vars
      if (time === '6months') {
        xlsData.push(['Month', 'Sessions']);
        for (let i = 0; i < 6; i++) {
          const month = i === 5 ? moment() : moment().add(i - 5, 'months');
          const monthMatch = month.format('YYYY-MM');
          let monthTotal = 0;
          for (var d in dataObj) monthTotal += d.substr(0, 7) === monthMatch ? dataObj[d] : 0;
          graphData.push({ x: month.format('MMM'), y: monthTotal });
          xlsData.push([month.format('MMMM YYYY'), monthTotal]);
        }
      } else {
        xlsData.push(['Date', 'Sessions']);
        let [startDate, endDate, format] = [moment().startOf('month'), moment().endOf('day'), 'D'];
        if (time === '7days') [startDate, format] = [moment().subtract(6, 'days'), width === 1 ? 'ddd' : 'ddd Do'];
        if (time === 'prevmonth') {
          [startDate, endDate] = [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
          ];
        }

        let day = moment(startDate);
        while (day.isBefore(endDate)) {
          graphData.push({ x: day.format(format), y: dataObj[day.format('YYYY-MM-DD')] || 0 });
          xlsData.push([day.format('DD/MM/YYYY'), dataObj[day.format('YYYY-MM-DD')] || 0]);
          day.add(1, 'day');
        }
      }

      if (this.props.onXLSData) this.props.onXLSData(xlsData);

      const maxY = Math.max(
        graphData.reduce((biggest, item) => Math.max(biggest, item.y), 0),
        10
      );

      return (
        <div style={localStyle.outer}>
          <div style={{ flex: 1 }}>
            <FlexibleXYPlot
              xType="ordinal"
              yDomain={[0, maxY * 1.2]}
              margin={{ left: 50, right: 10, top: 10, bottom: 40 }}
            >
              <HorizontalGridLines />
              <XAxis />
              <YAxis />
              <VerticalBarSeries
                data={graphData}
                color="#1774A7"
                barWidth={0.4}
                style={{ borderRadius: '4px' }}
                onValueMouseOver={(item) => this.setState({ hintItem: item })}
                onValueMouseOut={() => this.setState({ hintItem: null })}
              />
              {hintItem ? (
                <Hint value={hintItem} style={{ fontSize: 14 }}>
                  <div
                    style={{
                      border: '1px solid #1774A7',
                      borderWidth: 2,
                      borderRadius: '4px',
                      backgroundColor: '#FFF',
                      padding: '2px 6px',
                      color: '#000',
                      margin: '1px'
                    }}
                  >
                    {hintItem.y.toFixed(0)}
                  </div>
                </Hint>
              ) : null}
            </FlexibleXYPlot>
          </div>
        </div>
      );
    }
}

const localStyle = {
  outer: { display: 'flex', flexDirection: 'row', padding: 4, flex: 1 }
};
