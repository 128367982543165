import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddCampaignView = (props) => {
  let { updateState } = props;
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="white">
        <button className={'btn btn-primary searchrow-btn'}>
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
                    ADD NEW CAMPAIGN
        </button>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            updateState('addCampaignFromTemplate', true);
          }}
        >
                    From Template
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            updateState('addNewCampaign', true);
          }}
        >
                    Create New
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AddCampaignView;
