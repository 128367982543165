import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { EditText, EditTextArea, EditRichTextArea } from 'components/EditText/EditText';
import { EditImage } from 'components/EditImage/EditImage';
import { post } from 'lib/comms';
import './ContentPage.css';

export default class UserPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = { content: props.item, editing: [false] };
  }

    setEditing = async (index) => {
      let { editing, content } = this.state;
      editing[index] = !editing[index];
      if (!editing[index]) {
        // save to db
        const resp = await post('cms/appcontent', { content }, true);
        if (!resp || !resp.success) console.log(resp);
      }
      this.setState({ editing });
    };

    render () {
      let { content, editing } = this.state;
      return (
        <div className="maincontainer">
          <BackRow onBack={this.props.onBack} />
          <div className="row">
            <div className="col">
              <Card
                title={content.content_type}
                editing={editing[0] ? '1' : undefined}
                onToggle={() => this.setEditing(0)}
              >
                <div className="content-edit-outer">
                  <div className="content-edit-image-outer mt-2">
                    <EditImage
                      editing={editing[0] ? '1' : undefined}
                      imageid={content.image_id}
                      onChange={(imageId) =>
                        this.setState({ content: { ...content, image_id: imageId } })
                      }
                    />
                  </div>
                  <div className="content-edit-text-outer">
                    <EditText
                      className="mb-0"
                      label="Header"
                      editing={editing[0] ? '1' : undefined}
                      onChange={(text) => this.setState({ content: { ...content, header: text } })}
                      value={content.header}
                      maxLength={26}
                    />
                    {content.type === 'HTML' ? (
                      <EditRichTextArea
                        label="Body"
                        editing={editing[0] ? '1' : undefined}
                        onChange={(text) => this.setState({ content: { ...content, body: text } })}
                        value={content.body}
                        maxLength={2000}
                      />
                    ) : (
                      <EditTextArea
                        label="Body"
                        editing={editing[0] ? '1' : undefined}
                        onChange={(text) => this.setState({ content: { ...content, body: text } })}
                        value={content.body}
                        maxLength={300}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      );
    }
}
