import React, { useCallback, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import './SettingsPage.css';
import Card from '../../../../components/Card/Card';
import { get, post } from '../../../../lib/comms';
import Loader from '../../../../components/Loader/Loader';
import SmallLoader from '../../../../components/SmallLoader/SmallLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SettingsRow } from './SettingsRow';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import { USER_ROLES } from '../../../../App';
import { UserContext } from '../../../../contexts/UserContext';
import ToolTip from 'components/ToolTip/TooltTip';

const SettingsPage = () => {
  const userContextVal = useContext(UserContext);
  const userRoleIds = userContextVal.userRoleIds || [];
  const isAdmin = userRoleIds.indexOf(USER_ROLES.ADMIN) !== -1;

  const [status, setStatus] = useState('LOADING'); // LOADING, READY, ERROR
  const [tooltipCardStatus, setTooltipCardStatus] = useState('LOADING'); // LOADING, READY ERROR
  const [supportExpiry, setSupportExpiry] = useState(null);
  const [supportEnabled, setSupportEnabled] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [settings, setSettings] = useState(null);
  const [tooltipStatus, setTooltipStatus] = useState();

  const [stepIndex, setStepIndex] = useState(0);

  const [updatingSettings, setUpdatingSettings] = useState([]);

  const onResetTooltip = () => setShowConfirmModal(true);

  useEffect(() => {
    const go = async () => {
      setStatus('LOADING');
      const supportActiveRequest = await get('cms/support', {}, true);

      if (supportActiveRequest && supportActiveRequest.success) {
        setSupportExpiry(supportActiveRequest.support_window_expires_at);
        setSupportEnabled(
          supportActiveRequest.support_window_expires_at &&
                        new Date(supportActiveRequest.support_window_expires_at) > new Date()
        );
        setStatus('READY');
      } else {
        setStatus('ERROR');
      }
    };

    go();
  }, []);

  useEffect(() => {
    const go = async () => {
      const settingsRequest = await get('cms/settings', {}, true);

      if (settingsRequest && settingsRequest.success) {
        setSettings(settingsRequest.settings);
      } else {
        setStatus('ERROR');
      }
    };

    go();
  }, []);

  // get tooltip status
  useEffect(() => {
    const go = async () => {
      setTooltipCardStatus('LOADING');
      const tooltipRequest = await get('cms/tooltip_status', {}, true);

      if (tooltipRequest && tooltipRequest.success) {
        setTooltipStatus(tooltipRequest.tooltip_seen);
        setTooltipCardStatus('READY');
      } else {
        setTooltipCardStatus('ERROR');
      }
    };

    go();
  }, []);

  const requestSupportEnabled = useCallback(
    (enabled) => () => {
      const go = async () => {
        setStatus('LOADING');
        const supportEnabledRequest = await post('cms/support', { enabled }, true);

        if (supportEnabledRequest && supportEnabledRequest.success) {
          setSupportExpiry(supportEnabledRequest.support_window_expires_at);
          setSupportEnabled(
            supportEnabledRequest.support_window_expires_at &&
                            new Date(supportEnabledRequest.support_window_expires_at) > new Date()
          );
          setStatus('READY');
        } else {
          setStatus('ERROR');
        }
      };

      go();
    },
    []
  );

  // re-enable CMS tooltips
  const requestTooltipEnabled = useCallback(() => {
    const go = async () => {
      setTooltipCardStatus('LOADING');
      const tooltipEnabledRequest = await post('cms/tooltip_status', {}, true);

      if (tooltipEnabledRequest && tooltipEnabledRequest.success) {
        setTooltipStatus(false);
        setTooltipCardStatus('READY');
        setShowConfirmModal(false);
      } else {
        setTooltipCardStatus('ERROR');
        setShowConfirmModal(false);
      }
    };

    go();
  }, []);

  const updateSetting = useCallback((type, value) => {
    const go = async () => {
      setUpdatingSettings([...updatingSettings, type]);

      // make the call
      const settingRequest = await post(`cms/settings/${type}`, { value }, true);

      if (settingRequest && settingRequest.success) {
        const newSettings = { ...settings };

        newSettings[type] = value;

        setSettings(newSettings);
      }

      setUpdatingSettings([]);
    };

    go();
  }, []);

  return (
    <div className="maincontainer">
      <ToolTip
        callback={(tooltipData) => {
          if (tooltipData.lifecycle === 'complete') {
            setStepIndex(tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1);
          }
        }}
        screenId={'settings'}
        steps={
          // if user is admin all three cards will be shown, if not then only reset tutorial will be shown.
          isAdmin
            ? [
              {
                target: '.settings',
                title: 'Email Address Visibility',
                content:
                                      'When adding new users to Engage4, if you use an email address as the username this information is visible to all users when they visit a users profile. If you would like to turn email visibility off you can do so from here. This will apply to all your users in Engage4 and you can update this at anytime.'
              },
              {
                target: '.remote',
                title: 'Remote Support',
                content:
                                      'If you require support from us in resolving an issue you are experiencing we may ask you to activate remote support. This is so we can log into your system and investigate the issue further. This access will automatically expire after 14 days or you can deactivate it at any time before then'
              },
              {
                target: '.user-settings',
                title: 'Reset Tutorial',
                content:
                                      'If you would like to reset this tutorial so you can view it again for each of the screens simple select RESET and the next time you access a screen from the menu you will be taken through the tutorial for that screen'
              }
            ]
            : [
              {
                target: '.user-settings',
                title: 'Reset Tutorial',
                content:
                                      'If you would like to reset this tutorial so you can view it again for each of the screens simple select RESET and the next time you access a screen from the menu you will be taken through the tutorial for that screen'
              }
            ]
        }
        stepIndex={stepIndex}
      />
      <SubscriptionBanner />
      <h4>Settings</h4>
      {isAdmin ? (
        <>
          {' '}
          <Card title="Organisation Settings" outerClass="settings">
            {settings === null ? (
              <SmallLoader color="#0F2B41" />
            ) : (
              <SettingsRow
                title="Show Email Addresses In App"
                subtitle="Turning this off will ensure user emails never appear in the App."
                options={['Show Emails', 'Hide Emails']}
                values={[true, false]}
                value={settings.SHOW_EMAILS}
                onChange={(val) => {
                  updateSetting('SHOW_EMAILS', val);
                }}
                updating={updatingSettings.indexOf('SHOW_EMAILS') !== -1}
              />
            )}
          </Card>
          <Card title="Remote Support" outerClass="remote">
            {status === 'LOADING' && <SmallLoader color="#0F2B41" />}
            {status === 'READY' && (
              <div>
                <div className="mb-2">
                                    Activating remote support will allow the Engage4 team to log into your organisation
                                    and assist you with administration.
                </div>
                {supportEnabled ? (
                  <div>
                    <button className="btn btn-primary" onClick={requestSupportEnabled(false)}>
                                            DEACTIVATE REMOTE SUPPORT
                    </button>

                    <div className="mt-2">
                                            Remote support expires <b>{moment(supportExpiry).fromNow()}</b> (
                      {moment(supportExpiry).format('dddd, MMMM Do YYYY, h:mm:ssa')})
                    </div>
                  </div>
                ) : (
                  <div>
                    <button className="btn btn-primary" onClick={requestSupportEnabled(true)}>
                                            ACTIVATE REMOTE SUPPORT
                    </button>
                  </div>
                )}
              </div>
            )}
            {status === 'ERROR' && <div>Error getting remote support settings.</div>}
          </Card>
        </>
      ) : null}
      {tooltipStatus === true ? (
        <Card title="User Settings" outerClass="user-settings">
          {tooltipCardStatus === 'LOADING' && <SmallLoader color="#0F2B41" />}
          {tooltipCardStatus === 'READY' && (
            <div className="d-flex flex-row">
              <div className="settings-text-container">
                <div className="onboarding-card__title">Reset Tutorial</div>
                <div className="mb-2">
                                    Resetting your tutorial allows you to revisit the tutorial you received when you
                                    first logged into the Engage4 dashboard. This will not affect other CMS users.
                </div>
              </div>
              <div className="card-container">
                <div>
                  <button
                    className="btn reset-button btn-danger"
                    onClick={() => {
                      onResetTooltip();
                    }}
                  >
                                        RESET
                  </button>
                </div>
              </div>
            </div>
          )}
          {tooltipCardStatus === 'ERROR' && <div>Error getting tooltip status</div>}
        </Card>
      ) : null}
      {showConfirmModal && (
        <div className="dialog-overlay-outer">
          <div className="dialog-inner" style={{ width: 450, marginLeft: '270px' }}>
            <p className="dialog-title">Reset Tutorial</p>
            <p className="dialog-text">Are you sure you want to reset the tutorial?</p>
            <button
              className="btn btn-primary dialog-button"
              onClick={() => {
                requestTooltipEnabled();
              }}
            >
                            YES, RESET TUTORIAL
            </button>
            <button
              className="btn btn-outline-primary dialog-button"
              onClick={() => {
                setShowConfirmModal(false);
              }}
            >
                            NO, DON'T RESET TUTORIAL
            </button>
            <button
              className="dialog-close text-button"
              onClick={() => {
                setShowConfirmModal(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
