import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import Table from 'components/Table/Table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lib/comms';
import { Redirect } from 'react-router-dom';
import KnowledgebaseItem from './KnowledgebaseItemPage';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import ToolTip from 'components/ToolTip/TooltTip';

const columns = [
  {
    name: 'Knowledgebase Item Name',
    grow: 7,
    selector: 'title',
    sortable: true,
    wrap: true
  },
  { name: 'Source', grow: 2, selector: 'source' },
  { name: 'Type', selector: 'typeLabel' },
  { name: 'Active', grow: 2, selector: 'activeLabel' },
  {
    name: '',
    selector: 'id',
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
  }
];

export default class KnowledgebaseListPage extends React.Component {
  constructor (props) {
    super(props);
    this.navigationState = this.props.location.state;
    this.previousPage = this.navigationState?.from;
    this.fromAnotherPage =
            this.navigationState?.from === 'onboarding' || this.navigationState?.from === 'campaigns';
    this.state = {
      data: [],
      editing: this.fromAnotherPage ? { id: 'new' } : null,
      stepIndex: 0
    };
  }

    updateItemCreated = () => {
      this.setState({ createdNew: true });
    };

    componentDidMount = () => this.reload();

    reload = async () => {
      let resp = await get('cms/knowledgebase_items', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.items) {
        const items = resp.items.map((item) => {
          return {
            ...item,
            activeLabel: item.active ? 'Active' : 'Archived',
            typeLabel: item.type === 'INFO' ? 'Information' : item.type === 'DETAILS' ? 'Details' : 'File'
          };
        });
        this.setState({ data: items });
      } else console.log(resp);
    };

    doneEditing = () => {
      if (this.fromAnotherPage) {
        this.setState({ fromAnotherPage: true });
      } else {
        this.setState({ editing: null });
        this.reload();
      }
    };

    render () {
      if (this.state.fromAnotherPage) {
        return (
          <Redirect
            to={{
              pathname: `/${this.previousPage}`,
              state: {
                ...this.navigationState,
                from: 'learning',
                createdNew: this.state.createdNew
              }
            }}
          />
        );
      }

      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      let { data, search, editing } = this.state;

      if (search) {
        data = data.filter(
          (item) => `${item.title} ${item.category}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      }

      return (
        <>
          {this.state.editing ? (
            <KnowledgebaseItem
              knowledgebaseItem={editing}
              onBack={this.doneEditing}
              updateItemCreated={this.updateItemCreated}
            />
          ) : (
            <div className="maincontainer">
              <SubscriptionBanner />
              <h4>Knowledgebase</h4>
              <SearchRow
                value={search}
                searchTitle="Search by Knowledgebase name"
                buttonTitle="ADD NEW ITEM"
                onBtnClick={() => this.setState({ editing: { id: 'new' } })}
                onSearch={(search) => this.setState({ search })}
              />
              <Table
                columns={columns}
                data={data}
                onRowClicked={(item) => this.setState({ editing: item })}
                tableID="knowledgebase"
                defaultSortField={columns[0].selector}
              />
            </div>
          )}
          <ToolTip
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  editing:
                                    (this.state.stepIndex === 1 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 2 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 3 && tooltipData.action === 'prev') ||
                                    (this.state.stepIndex === 3 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 4 && tooltipData.action === 'prev') ||
                                    (this.state.stepIndex === 4 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 5 && tooltipData.action === 'prev') ||
                                    (this.state.stepIndex == 6 && tooltipData.action === 'prev')
                                      ? { id: 'new' }
                                      : null
                });
              }
            }}
            screenId={'knowledgebase'}
            steps={[
              {
                target: '.maincontainer',
                title: 'Knowledgebase',
                content:
                                'The knowledgebase is a centralised place for all organisation information, easily managed and updated containing files, long-form content and contact details that can be filtered by customised categories.'
              },
              {
                target: '.searchrow-btn',
                title: 'Getting Started',
                content:
                                'to create a knowledgebase item select "Add New Item" you will then have three different types of content to choose from.'
              },
              {
                target: '.Information',
                title: 'Information Item',
                content:
                                'This is best used for when you have long form content you want to share with users that may include an image, video or multiple smaller files.'
              },
              {
                target: '.File',
                title: 'File Item',
                content:
                                'This content type is best used to highlight a single file for example a company handbook.'
              },
              {
                target: '.Details',
                title: 'Detail Item',
                content:
                                'This content type is best used to share key contact information including phone number, web address and email address.'
              },
              {
                target: '.categories-section',
                title: 'Selecting a Category',
                content:
                                'You can then add a category to your knowledgebase item making it easier to search for specific information. These categories are customisable and you can create a new one from selecting "add category" or select an existing category by checking the box from the list.'
              },
              {
                target: '.maincontainer',
                title: 'View and manage knowledgebase',
                content:
                                'Once you have created knowledgebase items you can view the list of all items from here. If you select one from the list you can then edit the information or choose to delete this item.'
              }
            ]}
            stepIndex={this.state.stepIndex}
          />
        </>
      );
    }
}
