import React, { useState, useContext, useEffect } from 'react';
import { getData, setData } from 'lib/data';
import { Redirect } from 'react-router-dom';
import Chance from 'chance';
import Loader from 'components/Loader/Loader';
import TextField, { Input } from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import { post } from 'lib/comms';
import logoImage from 'assets/logo_blue.svg';
import welcomeImage from 'assets/welcome.png';
import { MessageDialog, MagicLinkDialog } from 'components/Dialog/Dialog';
import './LoginPage.css';
import '@material/react-text-field/dist/text-field.css';
import { UserContext } from '../../contexts/UserContext';

const LoginPage = (props) => {
  const [token, setToken] = useState(getData('token', '', true));
  const [status, setStatus] = useState('EMAIL'); // EMAIL, EMAIL_LOADING, PASSWORD, PASSWORD_LOADING, MAGIC_LINK, LOGGING_IN
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginToken, setLoginToken] = useState(null);

  const user = useContext(UserContext);

  useEffect(() => {
    // create a unique device key
    if (!getData('deviceKey', null)) {
      setData('deviceKey', Chance().hash(), true);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (status === 'EMAIL') {
      setStatus('EMAIL_LOADING');
      setError(null);

      try {
        const loginRequest = await post('authentication/request_authentication', {
          email_address: email,
          device_key: getData('deviceKey', ''),
          platform: 'cms'
        });

        if (loginRequest && loginRequest.success && loginRequest.auth_type) {
          const authType = loginRequest.auth_type.toUpperCase();

          if (authType === 'PASSWORD') {
            setStatus('PASSWORD');
            setLoginToken(loginRequest.token);
          } else if (authType === 'MAGIC_LINK') {
            setStatus('MAGIC_LINK');
          } else {
            setStatus('EMAIL');
            setError('Unknown account auth scheme. Please contact Engage4 support.');
          }
        } else {
          setStatus('EMAIL');
          setError('Incorrect credentials.');
        }
      } catch (error) {
        setStatus('EMAIL');
        setError('An unknown error occured.');
      }
    } else if (status === 'PASSWORD') {
      setStatus('PASSWORD_LOADING');
      setError(null);

      try {
        const loginRequest = await post('authentication/password_authentication', {
          email_address: email,
          device_key: getData('deviceKey', ''),
          password: password,
          token: loginToken
        });

        if (loginRequest && loginRequest.success && loginRequest.key) {
          // set token in application
          user.setToken(loginRequest.key);
          setStatus('LOGGING_IN');
        } else {
          setStatus('PASSWORD');
          setError('Incorrect credentials.');
        }
      } catch (error) {
        setStatus('PASSWORD');
        setError('An unknown error occured.');
      }
    }
  };

  // if user exists, redirect to home
  if (user.user) return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;

  let introText = '';
  let loadingText = '';

  switch (status) {
    case 'EMAIL':
    case 'EMAIL_LOADING':
      introText = 'Welcome back. Please sign in to your account';
      loadingText = 'Checking username...';
      break;
    case 'PASSWORD':
    case 'PASSWORD_LOADING':
      introText = 'Please enter your password';
      loadingText = 'Checking password...';
      break;
    case 'MAGIC_LINK':
      introText = 'Please check your inbox.';
      break;
    case 'LOGGING_IN':
      loadingText = 'Logging you in...';
      break;
  }

  return (
    <div className="login-outer">
      <div className="login-innerleft">
        <div className="login-content">
          <img src={logoImage} alt="Logo" className="login-logo" />
          <h3 className="login-title mt-5">Administration Console</h3>
          <h5 className="grey" style={{ marginTop: '-10px' }}>
            {introText}
          </h5>
          <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
            {status === 'MAGIC_LINK' ? (
              <p>You can close this window if you want.</p>
            ) : (
              <TextField className="mt-4" label="Username" outlined={true}>
                <Input
                  id="email"
                  className="poppins"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  readOnly={status === 'PASSWORD' ? 'On' : null}
                />
              </TextField>
            )}

            {['PASSWORD', 'PASSWORD_LOADING'].indexOf(status) > -1 ? (
              <TextField itemRef={'password'} className="mt-4" label="Password" outlined={true}>
                <Input
                  id="pass"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </TextField>
            ) : null}

            {error ? <p className="login-error">{error}</p> : null}

            {status !== 'MAGIC_LINK' ? (
              <input type="submit" value="SIGN IN" className="mt-5 btn btn-primary" />
            ) : null}
          </form>
        </div>
      </div>

      <div className="login-innerright">
        <img src={welcomeImage} alt="Welcome" className="login-imageright" />
      </div>

      {['EMAIL_LOADING', 'PASSWORD_LOADING', 'LOGGING_IN'].indexOf(status) > -1 ? (
        <Loader text={loadingText} />
      ) : null}
      {status === 'MAGIC_LINK' ? <MagicLinkDialog email={email} onClose={() => {}} /> : null}
    </div>
  );
};

export default LoginPage;

// export default class LoginPage extends React.Component {
//   constructor (props) {
//     super(props);
//     this.state = {token: getData('token', '', true), status: 'email', email: '', password: '', loadingText: ''};

//     this.password = null;
//   }

//   onSubmit = async (event) => {
//     event.preventDefault();
//     if (this.state.status === 'email') {
//       this.setState({loadingText: 'Checking email address...'});
//       const resp = await post('authentication/request_authentication', {email_address: this.state.email, device_key: 'cms'});
//       if (resp && resp.success && resp.auth_type) {
//         const authType = resp.auth_type.toUpperCase();
//         if (authType === 'PASSWORD') {
//           this.setState({status: 'password', loadingText: '', logintoken: resp.token});
//         } else if (authType === 'MAGIC_LINK') this.setState({status: 'magiclink', loadingText: '', logintoken: resp.token, magicLinkEmail: this.state.email});
//         else this.setState({errorText: 'Unknown auth type: ' + resp.auth_type, loadingText: ''});
//       } else this.setState({errorText: 'Sorry, that email address is not in the system', loadingText: ''});
//     } else if (this.state.status === 'password') {
//       this.setState({loadingText: 'Checking password...'});
//       const resp = await post('authentication/password_authentication', {email_address: this.state.email, device_key: 'cms', password: this.state.password, token: this.state.logintoken});
//       if (resp.success && resp.key) {
//         setData('token', resp.key, true);
//         this.setState({status: 'success', token: resp.key});
//       } else this.setState({errorText: 'Invalid password', loadingText: ''});
//     }
//   }

//   render () {
//     const {token, status, email, password, loadingText, errorText, showMessage, magicLinkEmail} = this.state;
//     if (token) return <Redirect to={{pathname: '/', state: {from: this.props.location}}}/>;

//     const introText = {email: 'Welcome back. Please sign in to your account', password: 'Please enter your password', magiclink: 'Please check your inbox...'};
//     return (
//       <div className="login-outer">

//         <div className="login-innerleft">
//           <div className="login-content">
//             <img src={logoImage} alt="Logo" className="login-logo"/>
//             <h3 className="login-title mt-5">Administration Console</h3>
//             <h5 className="grey" style={{marginTop: '-10px'}}>{introText[status]}</h5>
//             <form onSubmit={this.onSubmit} style={{display: 'flex', flexDirection: 'column'}}>

//               {status === 'magiclink' ? <p>You can close this window if you want.</p> : (
//                 <TextField className="mt-4" label='Email address' outlined={true}>
//                   <Input id="email" className="poppins" name="email" value={email} onChange={e => this.setState({email: e.currentTarget.value, errorText: ''})} readOnly={status === 'password' ? 'On' : null}/>
//                 </TextField>

//               )}

//               {status === 'password' ? (
//                 <TextField itemRef={'password'} className="mt-4" label='Password' outlined={true} >
//                   <Input id="pass"value={password} type="password" onChange={e => this.setState({password: e.currentTarget.value, errorText: ''})} />
//                 </TextField>
//               ) : null}

//               {errorText ? <p className="login-error">{errorText}</p> : null}

//               {status !== 'magiclink' ? <input type="submit" value="SIGN IN" className="mt-5 btn btn-primary"/> : null}

//             </form>
//           </div>
//         </div>

//         <div className="login-innerright">
//           <img src={welcomeImage} alt="Welcome" className="login-imageright"/>;
//         </div>

//         {loadingText ? <Loader text={loadingText}/> : null}
//         {showMessage ? <MessageDialog message={showMessage.message} title={showMessage.title} onClose={() => this.setState({showMessage: null})}/> : null}
//         {magicLinkEmail ? <MagicLinkDialog email={magicLinkEmail} onClose={() => this.setState({magicLinkEmail: null})}/> : null}
//       </div>
//     );
//   }
// }
