import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import { get } from 'lib/comms';
import Table from 'components/Table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import GroupPage from './GroupPage';
import './GroupsPage.css';
import AuthImage from 'components/AuthImage/AuthImage';
import { Redirect } from 'react-router-dom';
import placeholder from 'assets/placeholder.png';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import ToolTip from 'components/ToolTip/TooltTip';

const columns = [
  {
    name: '',
    selector: 'image_id',
    grow: 0,
    sortable: false,
    cell: (row) =>
      row.image_id ? (
        <AuthImage url={`content/image/${row.image_id}`} className="group-image" alt="" />
      ) : (
        <img src={placeholder} className="group-image" alt="" />
      )
  },
  { name: 'Group Name', selector: 'name', sortable: true, grow: 3, wrap: true },
  { name: 'Category', selector: 'category_name', sortable: true },
  { name: 'No. of Members', selector: 'member_count', center: true, sortable: true },
  {
    name: 'Org group',
    selector: 'is_org_level',
    center: true,
    sortable: true,
    format: (row) => (row.is_org_level ? 'Yes' : '')
  },
  { name: 'Privacy', selector: 'group_privacy_type_id', center: true, sortable: true },
  {
    name: '',
    selector: 'id',
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
  }
];
export default class GroupsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = { data: [], search: '', editing: null, stepIndex: 0 };
  }

    componentDidMount = () => this.reload();

    doneEditing = () => {
      this.setState({ editing: null });
      this.reload();
    };

    reload = async () => {
      let resp = await get('cms/groups', null, true);

      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.groups) this.setState({ data: resp.groups });
      else console.log(resp);
    };

    render () {
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      let { data, search, editing } = this.state;
      // if (editing) return <GroupPage group={editing} onBack={this.doneEditing}/>;
      if (search) {
        data = data.filter(
          (item) => `${item.name} ${item.category_name}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      }

      return (
        <>
          {this.state.editing ? (
            <GroupPage group={editing} onBack={this.doneEditing} />
          ) : (
            <div className="maincontainer">
              <SubscriptionBanner />
              <h4>Group Management</h4>
              <SearchRow
                value={search}
                searchTitle="Search by group name or category"
                onSearch={(search) => this.setState({ search })}
              />
              <Table
                columns={columns}
                data={data}
                onRowClicked={(item) => this.setState({ editing: item })}
                tableID="groups"
                defaultSortField={columns[1].selector}
              />
            </div>
          )}

          <ToolTip
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  editing:
                                    (this.state.stepIndex === 0 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 1 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 2 && tooltipData.action === 'prev') ||
                                    (this.state.stepIndex === 2 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 3 && tooltipData.action === 'prev')
                                      ? data[0]
                                      : null
                });
              }
            }}
            screenId={'groups'}
            steps={[
              {
                target: '.table-outer',
                title: 'Manage Groups',
                content:
                                'Groups are created in the app by your users. As an administrator  you can view and manage all the groups that have been created across your organisation.'
              },
              {
                target: '.card',
                title: 'Group Information',
                content:
                                'From here you can edit the group information such as update the groups image, name, description category and privacy by selecting edit at the top right.'
              },
              {
                target: '.members-table',
                title: 'Group Members',
                content: 'You can view a list of all members in this group and add or remove members.'
              },
              {
                target: '.btn-danger',
                title: 'Deleting a Group',
                content:
                                'You can delete a group at any time, this will delete the group and any content that has been posted for all users. Once a group is deleted it can not be restored'
              }
            ]}
            stepIndex={this.state.stepIndex}
          />
        </>
      );
    }
}
