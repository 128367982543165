import React from 'react';

const StatisticsItem = ({ name, value }) => {
  return (
    <div className="d-flex flex-column" style={{ alignItems: 'center' }}>
      <div className="report-item-cont">{value}</div>
      <span className="report-item-label">{name}</span>
    </div>
  );
};

export default StatisticsItem;
