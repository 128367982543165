import React from 'react';

const TermsAndConditions = () => (
  <>
    <p>AGREEMENT</p>

    <p>BACKGROUND</p>

    <p>
            (A) The Supplier has developed a software application (for both mobile and desktop users) under the name of
            ‘engage4’ (the “Application”) which it makes available on a pay-per-use basis.
    </p>

    <p>
            (B) The Client wishes to use the Application and the Supplier's services in its business, and the Supplier
            has agreed to provide the same, subject to the terms and conditions of this agreement.
    </p>

    <p>Agreed terms</p>

    <p>1. Interpretation</p>

    <p>1.1 The definitions and rules of interpretation in this clause apply in thisagreement: -</p>

    <p>
            Authorised Users: those individual users who are authorised by the Client (pursuant to the purchase by (or
            provision to) the Client of User Subscriptions) to use the Subscribed Services and the Documentation;
    </p>

    <p>
            Business Day: a day, other than a Saturday or Sunday, when banks in either Edinburgh or London are open for
            business;
    </p>

    <p>
            Confidential Information: information that is proprietary or confidential and is either clearly labelled as
            such or identified as Confidential Information by either party;
    </p>

    <p>
            Controller, processor, data subject, personal data, personal data breach, processing, special category data
            and appropriate technical and organisational measures: shall be as defined in the Data Protection
            Legislation;
    </p>

    <p>
            Client Data: the data inputted or uploaded by the Client, Authorised Users, or provided by the Client and
            inputted or uploaded by the Supplier on the Client's behalf, in each case for the purpose of using the
            Services or facilitating the Client's use of the Services;
    </p>

    <p>
            Continuation Letter: means (if applicable) any letter or agreement entered into between the Client and the
            Supplier pursuant to clause 13.1, in terms of which they agree to continue this agreement on a chargeable
            basis, upon the expiry of any applicable Trial Period;
    </p>

    <p>
            Data Protection Legislation: means the Data Protection Act 2018, Directive 2002/58/EC (ePrivacy Directive),
            the Privacy and Electronic Communications (EC Directive) Regulations 2003, and/or the General Data
            Protection Regulation (EU) Regulation 2016/679 and any laws which replace, amend or implement the foregoing;
    </p>

    <p>
            Documentation: those documents (if any) made available to the Client by the Supplier from time to time,
            which set out a description of the Services and/or the user instructions for the Services;
    </p>

    <p>Effective Date: the date of this agreement;</p>

    <p>
            Initial Subscription Term: the initial term of this agreement, following upon the expiry of any applicable
            Trial Period, as set out in Part 2 of the Schedule (or as otherwise specified in any Continuation Letter);
    </p>

    <p>
            Renewal Period: the period described as such in Part 2 of the Schedule (or as otherwise specified in any
            Continuation Letter);
    </p>

    <p>Schedule: means the schedule, in 3 Parts, annexed to and forming part of this agreement;</p>

    <p>
            Services: means the Subscribed Services,together with any additional services provided to the Client by the
            Supplier under or pursuant to this agreement;
    </p>

    <p>Software: means the software applications underlying the Application;</p>

    <p>
            Subscribed Services:  means the use of, and access to, the Application and certain features and
            functionality provided therein;
    </p>

    <p>
            Subscription Fees: the subscription fees payable by the Client to the Supplier for the purchase of the User
            Subscriptions, as set out inPart 1 of the Schedule (or as otherwise specified in any Continuation Letter);
    </p>

    <p>
            Subscription Term: has the meaning given in clause 13.1 (being (to the extent applicable) the Trial Period,
            the Initial Subscription Term, and any Renewal Periods);
    </p>

    <p>
            Support Services Policy: The Supplier's policy for providing support in relation to the Subscribed Services,
            as the same shall be made available by the Supplier to the Client from time to time;
    </p>

    <p>
            Trial Period: means the trial period (if any, to the extent specified in Part 2 of the Schedule) during
            which the Supplier has agreed to make the Subscribed Services available to the Client, upon the terms of
            this agreement, on a free of charge basis;
    </p>

    <p>
            User Subscriptions: the user subscriptions purchased by (or granted to) the Client pursuant to clause 8.1
            (and as detailed in Part 1A of the Schedule and/or any Continuation Letter), which entitle Authorised Users
            to access and use the Subscribed Services and the Documentation in accordance with thisagreement.
    </p>

    <p>
            Virus: anything or device which may: prevent, impair or otherwise adversely affect the operation of any
            computer software, hardware or network, any telecommunications service, equipment or network or any other
            service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme
            or data; or adversely affect the user experience, including worms, trojan horses, viruses and other similar
            things or devices.
    </p>

    <p>1.2 In this agreement, unless the context otherwise requires: -</p>

    <p>(a) the singular includes the plural and vice versa;</p>

    <p>
            (b) references to sub-clauses, clauses or Parts of a Schedule are to sub-clauses, clauses, or Parts of the
            Schedule of this agreement;{' '}
    </p>

    <p>(c) ‘writing’ shall (unless otherwise specified) include email; </p>

    <p>
            (d) a person includes an individual, corporate or unincorporated body (whether or not having separate legal
            personality) and that person's legal and personal representatives, successors or permitted assignees;
    </p>

    <p>(e) 'including’ (or similar words) means including, without limitation;</p>

    <p>
            (f) any reference to a website address of the Supplier shall be deemed to include such other web address as
            may be notified by the Supplier to the Client from time to time.
    </p>

    <p>2. User subscriptions</p>

    <p>
            2.1 Subject to the Client purchasing the User Subscriptions (or, during any Trial Period, subject to the
            Supplier agreeing to make such User Subscriptions available to the Client) and subject to the other terms
            and conditions of this agreement, the Supplier hereby grants to the Client a non-exclusive, non-transferable
            right (without the right to grant sublicences) to permit the Authorised Users to use the Subscribed Services
            and the Documentation during the Subscription Term. It is acknowledged that the Client shall be entitled to
            add Authorised Users from out with its own organisation, provided (for the avoidance of doubt) that every
            Authorised User shall be an individual person and not a firm, company or other entity.
    </p>

    <p>2.2 In relation to the Authorised Users, the Client undertakes that:</p>

    <p>
            (a) it will purchase (or, during any Trial Period, will agree to take) sufficient User Subscriptions, in
            terms of this agreement, to allow for not less than one User Subscription per Authorised User;
    </p>

    <p>(b) it will not allow any User Subscription to be used by more than one individual Authorised User;</p>

    <p>
            (c) each Authorised User shall utilise the ‘Magic Link’ authentication method or, if available, maintain a
            secure password for his use of the Subscribed Services, and that all user authentications and passwords
            shall be kept confidential and changed or updated from time to time in accordance with the Supplier’s
            recommendations;
    </p>

    <p>
            (d) it shall maintain a written, up to date list of current Authorised Users and provide such list to the
            Supplier (together with any other information regarding the Authorised Users as the Supplier may reasonably
            request) within 5 Business Days of the Supplier's written request at any time;
    </p>

    <p>
            (e) it shall remove Authorised Users only when they are no longer to be given access to the Subscribed
            Services (and shall not, without limitation, remove Authorised Users with a view to impacting the
            Subscription Fees payable under this agreement);
    </p>

    <p>
            (f) it shall permit the Supplier (or the Supplier's representative(s)) to audit its use of the Services
            (including to establish the name and user authentication/password of each Authorised User) to the extent
            necessary to verify the Client’s compliance with this agreement (including, without limitation, its process
            and timing of adding and removing Authorised Users). Each such audit may be conducted no more than once per
            quarter, at the Supplier's expense, shall be subject to the provision of reasonable prior notice, and shall
            be exercised in such a manner so as not to substantially interfere with the Client's normal conduct of
            business;
    </p>

    <p>
            (g) if any audit referred to in clause 2.2(f) reveals that any user authentication, password or other access
            to the Subscribed Services has been provided to any individual who is not an Authorised User, then without
            prejudice to the Supplier's other rights, the Client shall promptly disable such user
            authentications/password(s), the Supplier shall not issue any new user authentications/passwords to any such
            individual and the Supplier shall be entitled to charge the Client a fee (if such fee should have been
            payable pursuant to the terms of this agreement) for any unauthorised usage made; and
    </p>

    <p>
            (h) if any audit referred to in clause 2.2(f) reveals that the Client has underpaid its Subscription Fees,
            then without prejudice to the Supplier's other rights, the Client shall pay to the Supplier an amount equal
            to such underpayment (calculated in accordance with Part 1 of the Schedule), within 10 Business Days of the
            date of the relevant audit.
    </p>

    <p>
            2.3 The Supplier may monitor, collect, store and use information on the use and performance of the Services
            (including Client Data) to detect threats or errors to the Services and/or the Supplier’s operations and for
            the purposes of the further development and improvement of the Supplier’s services provided that such
            activities at all times comply with the terms of clause 5 and the Data Protection Legislation.  {' '}
    </p>

    <p>
            2.4 The Client shall not (and shall procure that its Authorised Users shall not) access, store, distribute,
            upload or transmit any Virus, or any material, content or image, during the course of its use of the
            Services that:
    </p>

    <p>
            (a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically
            offensive;
    </p>

    <p>(b) facilitates illegal activity;</p>

    <p>(c) depicts sexually explicit images;</p>

    <p>(d) promotes unlawful violence;</p>

    <p>(e) is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; </p>

    <p>
            (f) involves the transmission of unsolicited mass mailings or other forms of spam, junk mail, chain letters
            or similar; or
    </p>

    <p>(g) is otherwise illegal or causes damage or injury to any person or property; or</p>

    <p>(h) infringes any intellectual property rights (or other proprietary rights) of any person;</p>

    <p>
            and the Supplier reserves the right, without liability to the Client and without prejudice to its other
            rights, to suspend the provision of the Services, to remove any such material or content and/or to restrict
            or disable the Client's access to the Services in the event of there being any breach of this clause.
    </p>

    <p>2.5 The Client shall not (and shall procure that its Authorised Users shall not):</p>

    <p>
            (a) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download,
            display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in
            any form or media or by any means; or
    </p>

    <p>
            (b) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to
            human-perceivable form all or any part of the Software; or
    </p>

    <p>
            (c) access all or any part of the Services and Documentation in order to build a product or service which
            competes with the Services and/or the Documentation; or
    </p>

    <p>(d) use the Services and/or Documentation to provide services to third parties; or</p>

    <p>
            (e) license, sell, rent, lease, transfer, assign, distribute, display, or otherwise commercially exploit, or
            otherwise make the Services and/or Documentation available to any third party except the Authorised Users;
            or
    </p>

    <p>
            (f) attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation,
            other than as provided under this clause2; or
    </p>

    <p>
            (g) introduce or permit the introduction of, any Virus into the Supplier's network and information systems.
    </p>

    <p>
            2.6 The Client shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the
            Services and/or the Documentation and, in the event of any such unauthorised access or use, shall promptly
            notify the Supplier thereof.
    </p>

    <p>3. Third Party Providers</p>

    <p>
            3.1 The Client acknowledges that the Services may enable or assist it to access, incorporate or link to the
            website content of, and/or to correspond with, third parties via third party websites and that it does so at
            its own risk.   The Supplier makes no representation, warranty or commitment and shall have no liability or
            obligation whatsoever in relation to the content or use of, or correspondence with, any third-party website.
             The Supplier recommends that the Client refers to the third party’s website terms and conditions and
            privacy policy prior to using the relevant third-party website.  Whilst the Supplier may suggest websites
            which may be of interest to the Client, the Supplier does not endorse or approve any third-party website,
            nor the content of any third-party website made available via the Services.
    </p>

    <p>4. Services</p>

    <p>
            4.1 The Supplier shall, during the Subscription Term, provide the Services to the Client on and subject to
            the terms of this agreement.
    </p>

    <p>
            4.2 The Supplier shall use commercially reasonable endeavours to make the Subscribed Services available 24
            hours a day, seven days a week, except for:
    </p>

    <p>(a) planned maintenance carried out upon not less than two weeks’ notice; and</p>

    <p>
            (b) unscheduled maintenance, provided that the Supplier has used reasonable endeavours to give the Client at
            least 2 hours’ notice in advance;
    </p>

    <p>
            provided always that, during any Trial Period, the Supplier have no liability to the Client if it is unable
            to comply with the provisions of this clause 4.2.
    </p>

    <p>
            4.3 The Supplier will, as part of the Subscribed Services (and on the basis set out in Part 1B of the
            Schedule) provide the Client with the Supplier's standard client support services in accordance with the
            Supplier's Support Services Policy in effect at the time that such support services are provided. The
            Supplier may amend the Support Services Policy in its sole and absolute discretion from time to time.
    </p>

    <p>
            4.4 The Client may from time to time purchase enhanced support services separately at the Supplier's then
            current rates, which shall be provided subject to the terms of this agreement. Details of any additional
            support services purchased by the Client on or prior to its entry into this agreement are detailed in Part
            1C of the Schedule. For the avoidance of doubt, the services referred to in this clause 4.4 (and in clause
            4.5 below) shall not be available to the Client during any Trial Period, unless the Client agrees to
            purchase such services from the Supplier on a chargeable basis.
    </p>

    <p>
            4.5 The Client may also purchase additional services (including, without limitation, training and
            consultancy services) at the rates agreed between the Client and the Supplier from time to time, which shall
            be provided subject to the terms of this agreement.  Details of any additional services purchased by the
            Client on or prior to its entry into this agreement are detailed in Part 1D of the Schedule. {' '}
    </p>

    <p>
            4.6 The Client acknowledges that the Supplier shall be entitled to modify the features and functionality of
            the Services and to make updates, enhancements and fixes thereto from time to time or to address security
            issues (together “Modifications”). The Supplier shall use reasonable endeavours to ensure that any such
            Modification does not materially adversely affect the use of the relevant Service(s) by the Supplier’s
            clients generally.  Without prejudice to the foregoing, the Supplier shall have the right to make any
            changes to the Services which are necessary to comply with any legal or regulatory requirement.
    </p>

    <p>5. Client data</p>

    <p>
            5.1 The Client shall own all right, title and interest in and to all Client Data that is not personal data
            and shall be solely responsible for the legality, reliability, accuracy and quality of such data.   The
            Client hereby licences and permits the Supplier to use, store and display the Client Data in the course of
            providing the Services.   The Client warrants that it has all necessary consents and authorisations to
            enable it to grant the foregoing and shall be responsible, on an ongoing basis, for ensuring that all
            necessary authorisations, permissions and consents are in place in respect of any Client Data or other
            content provided or uploaded by the Client and/or the Authorised Users in their use of the Subscribed
            Services.{' '}
    </p>

    <p>
            5.2 The Supplier shall follow its archiving procedures for Client Data as set out in the back up policies
            put in place by or on behalf of the Supplier from time to time. In the event of any loss or damage to Client
            Data, the Client's sole remedy against the Supplier shall be for the Supplier to use reasonable commercial
            endeavours to restore the lost or damaged Client Data from the latest back-up of such Client Data maintained
            by the Supplier. The Supplier shall not be responsible for any loss, destruction, alteration or disclosure
            of Client Data caused by any third party (except those sub-contracted by the Supplier).
    </p>

    <p>
            5.3 The Supplier shall, in providing the Services, comply with its privacy policy relating to the privacy
            and security of the Client Data available at{' '}
      <a target="_blank" href="https://engage4.vidatec.com">
                https://engage4.vidatec.com
      </a>
            , as amended from time to time by the Supplier in its discretion. The Client acknowledges the terms thereof
            and undertakes that it shall not, pursuant to this agreement, give any instructions to the Supplier which
            (if followed) would cause the Supplier to breach the terms of any such privacy policy.
    </p>

    <p>
            5.4 Both parties will comply with all applicable requirements of the Data Protection Legislation. This
            clause 5 is in addition to, and does not relieve, remove or replace, a party's obligations or rights under
            the Data Protection Legislation. The Client further acknowledges that the existence of the Supplier’s
            privacy policy does not alter or lessen its obligations in respect of the Client Data (including, without
            limitation, its obligations to obtain any necessary consents from its Authorised Users).
    </p>

    <p>5.5 The parties acknowledge that:</p>

    <p>
            (a) if the Supplier processes any personal data on the Client's behalf when performing its obligations under
            this agreement, the Client is the controller and the Supplier is the processor for the purposes of the Data
            Protection Legislation;
    </p>

    <p>
            (b) Part 3 of the Schedule sets out the scope, nature and purpose of processing by the Supplier, the
            duration of the processing and the types of personal data and categories of data subject;
    </p>

    <p>
            (c) the personal data may be transferred or stored outside the EEA in order to carry out the Services and
            the Supplier's other obligations under this agreement; and
    </p>

    <p>(d) personal data may be passed by the Supplier to its third-party hosting and storage provider(s).</p>

    <p>
            5.6 Without prejudice to the generality of clause 5.4, the Client will ensure that it has all necessary
            consents and notices in place to enable lawful transfer of any personal data to the Supplier for the
            duration and purposes of this agreement so that the Supplier may lawfully use, process and transfer such
            personal data on the Client's behalf, in accordance with this agreement.
    </p>

    <p>
            5.7 Without prejudice to the generality of clause5.4, the Supplier shall, in relation to any personal data
            processed in the performance by the Supplier of its obligations under this agreement:
    </p>

    <p>
            (a) process that personal data only on the documented written instructions of the Client (which shall
            include the terms of this agreement) unless the Supplier is required by any applicable laws of the UK or
            European Union (or of any member state thereof) to process personal data (Applicable Laws). Where the
            Supplier is relying on Applicable Laws as the basis for processing personal data, the Supplier shall
            promptly notify the Client of this before performing the processing required by the Applicable Laws unless
            such laws prohibit the Supplier from doing so;
    </p>

    <p>
            (b) not transfer any personal data outside of the European Economic Area and the UK unless the following
            conditions are fulfilled:
    </p>

    <p>(i) the Client or Supplier has provided appropriate safeguards in relation to the transfer;</p>

    <p>(ii) the data subject has enforceable rights and effective legal remedies;</p>

    <p>
            (iii) the Supplier complies with its obligations under the Data Protection Legislation by providing an
            adequate level of protection to any personal data that is transferred; and
    </p>

    <p>
            (iv) the Supplier complies with reasonable instructions notified to it in advance by the Client with respect
            to the processing of the personal data;
    </p>

    <p>
            (c) assist the Client, at the Client's cost, in responding to any request from a data subject and in
            ensuring compliance with its obligations under the Data Protection Legislation with respect to security,
            breach notifications, impact assessments and consultations with supervisory authorities or regulators;
    </p>

    <p>(d) notify the Client without undue delay on becoming aware of a personal data breach;</p>

    <p>
            (e) at the written direction of the Client, delete or return personal data and copies thereof to the Client
            on termination of this agreement unless required by Applicable Laws to store the personal data (and
            &quot;delete&quot; shall mean to put such data beyond use); and
    </p>

    <p>
            (f) maintain accurate records and information to demonstrate its compliance with this clause5 and
            immediately inform the Client if, in the opinion of the Supplier, an instruction infringes the Data
            Protection Legislation and/or the Supplier’s privacy policy.
    </p>

    <p>
            5.8 Each party shall ensure that it has in place appropriate technical and organisational measures to
            protect against unauthorised or unlawful processing of personal data and against accidental loss or
            destruction of, or damage to, personal data, appropriate to the harm that might result from such processing
            or loss, destruction or damage and the nature of the data to be protected, having regard to the state of
            technological development and the cost of implementing any measures.{' '}
    </p>

    <p>6. Supplier's obligations</p>

    <p>
            6.1 The Supplier undertakes that the Services will be performed, in all material respects, in accordance
            with the Documentation and with reasonable skill and care.
    </p>

    <p>
            6.2 e extent of any non-conformance which is caused by use of the Services contrary to the Supplier's
            instructions, or by modification or alteration of the Services by any party other than the Supplier or the
            Supplier's duly authorised contractors or agents, or any other act by any third party (including hacking or
            the introduction of any virus or malicious code). Subject as aforesaid, if the Services do not conform with
            the undertaking at clause 6.1, the Supplier will, at its expense, use reasonable commercial endeavours to
            correct any non-conformance promptly, or provide the Client with an alternative means of accomplishing the
            desired performance. Such correction or substitution constitutes the Client's sole and exclusive remedy for
            any breach of the undertaking set out in clause 6.1.
    </p>

    <p>6.3 The Supplier:</p>

    <p>(a) does not warrant that:</p>

    <p>(i) the Client's use of the Services will be uninterrupted or error-free; or</p>

    <p>
            (ii) the Services, Documentation and/or the information obtained by the Client through the Services will
            meet the Client's requirements;
    </p>

    <p>
            (iii) the use of the Subscribed Services will achieve any particular outcome (including, without limitation,
            mitigating the consequences of any incident or ensuring the safety, health or wellbeing of any person or
            property).
    </p>

    <p>
            (b) is not responsible for any delays, delivery failures, or any other loss or damage resulting from the
            transfer of data over communications networks and facilities, including the internet, and the Client
            acknowledges that the Services and Documentation may be subject to limitations, delays and other problems
            inherent in the use of such communications facilities;
    </p>

    <p>
            (c) is not responsible for the actions of (or work undertaken by) any third party to whom it may introduce
            the Client (including any consultants which the Client may engage) and that the relationship between the
            Client and any consultant or other third party engaged by them shall be governed by the terms agreed between
            the Client and such third party.
    </p>

    <p>
            6.4 For the avoidance of doubt, this agreement shall not prevent the Supplier from entering into similar
            agreements with third parties, or from independently developing, using, selling or licensing documentation,
            products and/or services which are the same as, or similar to, any of the Services.
    </p>

    <p>
            6.5 The Supplier warrants that it has and will maintain all necessary licences, consents, and permissions
            necessary for the performance of its obligations under this agreement.
    </p>

    <p>7. Client's obligations</p>

    <p>7.1 The Client shall:</p>

    <p>(a) provide the Supplier with:</p>

    <p>(i) all necessary co-operation in relation to this agreement; and</p>

    <p>(ii) all necessary access to such information as may be reasonably required by the Supplier;</p>

    <p>
            in order to provide the Services, including but not limited to Client Data, security access information and
            configuration services;
    </p>

    <p>
            (b) during any Trial Period, provide feedback to the Supplier at regular intervals on the various features
            of the Subscribed Services, seek feedback from its Authorised Users in respect of the Subscribed Services
            (and make the same available to the Supplier) and timeously respond to any surveys or information requests
            by the Supplier with regard to the features of the Subscribed Services and the Client’s views or preferences
            thereon;
    </p>

    <p>(c) comply with all applicable laws and regulations regarding its activities under this agreement;</p>

    <p>
            (d) comply (and procure that its Authorised Users comply) with the rules of the App Store or of any other
            provider via which the Application is made available;
    </p>

    <p>
            (e) comply (and procure that its Authorised Users comply) with the Supplier’s Acceptable Use Policy in
            respect of the Application, available at{' '}
      <a target="_blank" href="https://engage4.vidatec.com">
                https://engage4.vidatec.com
      </a>
            , (as amended from time to time by the Supplier, in its discretion);
    </p>

    <p>
            (f) carry out all other Client responsibilities set out in this agreement in a timely and efficient manner
            (and in the event of any delays in the Client's performance of its obligations, the Supplier may adjust any
            agreed timetable or delivery schedule as reasonably necessary);
    </p>

    <p>
            (g) ensure that the Authorised Users use the Services and the Documentation in accordance with the terms and
            conditions of this agreement (and shall be responsible for any Authorised User's breach of this agreement or
            any document referred to herein);
    </p>

    <p>
            (h) obtain and shall maintain all necessary licences, consents, and permissions (if any) necessary for the
            Supplier, its contractors and agents to perform their obligations under this agreement, including without
            limitation the Services;
    </p>

    <p>
            (i) ensure that its network and systems meet the minimum operating system required for the use of the
            Services from time to time; and
    </p>

    <p>
            (j) be, to the extent permitted by law and except as otherwise expressly provided in this agreement, solely
            responsible for procuring, maintaining and securing (and for paying all costs and expenses of) its network
            connections and telecommunications links and for any loss or damage arising from or relating to thes network
            connections or telecommunications links or caused by the internet.
    </p>

    <p>8. Charges and payment</p>

    <p>
            8.1 The Client shall pay the Subscription Fees to the Supplier in accordance with this clause 8 and Part 1A
            of the Schedule, and any fees payable for additional support or other services, in accordance with Parts 1C
            and/or 1D of the Schedule.   The Subscription Fees will be charged by reference to the number of User
            Subscriptions purchased (which may be a flat fee for a number of User Subscriptions within a certain band,
            or a fee per User Subscription), as set out in Part 1A of the Schedule.  The Client acknowledges that
            Subscription Fees will be charged by reference to the number of User Subscriptions in place at a given point
            in each month.  For the avoidance of doubt, no Subscription Fees shall be payable by the Client during any
            Trial Period.
    </p>

    <p>
            8.2 Unless otherwise stated in Part 1 of the Schedule, the Supplier shall invoice the Client on a monthly
            basis, in arrears, and the Client shall pay each invoice within 30 days after the date of the relevant
            invoice. {' '}
    </p>

    <p>
            8.3 If the Supplier has not received payment within 14 days after the due date, and without prejudice to any
            other rights and remedies of the Supplier:
    </p>

    <p>
            (a) the Supplier may, without liability to the Client, disable the Client's (and all Authorised Users’) user
            authentications, passwords, accounts and access to all or part of the Services and the Supplier shall be
            under no obligation to provide any or all of the Services while the invoice(s) concerned remain unpaid; and
    </p>

    <p>
            (b) interest shall accrue on a daily basis on such overdue amounts at an annual rate equal to 3% over the
            base lending rate of HSBC UK Bank plc from time to time, commencing on the due date and continuing until
            fully paid, whether before or after judgment.
    </p>

    <p>8.4 All amounts and fees stated or referred to in this agreement:</p>

    <p>(a) shall be payable in pounds sterling;</p>

    <p>(b) are non-cancellable and non-refundable;</p>

    <p>
            (c) are exclusive of value added tax, which shall be added to the Supplier's invoice(s) at the appropriate
            rate.
    </p>

    <p>
            8.5 The Supplier shall be entitled to increase the fees payable in terms of this agreement (including,
            without limitation, the Subscription Fees) upon notifying the Client in writing, at least 90 days prior to
            the end of the Initial Subscription Term or of any Renewal Period (and Part 1 of the Schedule shall be
            deemed to have been amended accordingly) provided always that such increase shall not take effect until
            commencement of the next Renewal Period.
    </p>

    <p>9. Proprietary rights</p>

    <p>
            9.1 The Client acknowledges and agrees that the Supplier and/or its licensors own all intellectual property
            rights in the Services (including, without limitation, in the Application, the Software and in any content
            provided or made available by the Supplier) and the Documentation. Except as expressly stated herein, this
            agreement does not grant the Client any rights to, under or in, any patents, copyright, database right,
            trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licences
            in respect of the Services, the Application, the Software, any such content and/or the Documentation.
    </p>

    <p>
            9.2 The Supplier confirms that it has all such rights in the Application, the Software and the Documentation
            as are necessary to grant the rights it purports to grant under and in terms of this agreement.
    </p>

    <p>10. Confidentiality and Announcements </p>

    <p>
            10.1 Save as set out below, each party undertakes to the other that they will keep any Confidential
            Information of that other party strictly confidential, and will not disclose the same to any third party,
            provided that each party may disclose such Confidential Information to its legal, financial and business
            advisors (insofar as they need to know the same and subject to them being bound by appropriate
            confidentiality obligations) or as may require to be disclosed by law. {' '}
    </p>

    <p>
            10.2 Clause 10.1 shall not apply to Confidential Information which the receiving party can demonstrate was:
            -
    </p>

    <p>(a) already in its possession prior to its receipt from the disclosing party;</p>

    <p>
            (b) subsequently lawfully disclosed to it by a third party who did not obtain the Confidential Information
            (directly or indirectly) from the disclosing party, or otherwise under any obligation of confidence; or
    </p>

    <p>
            (c) in the public domain at the time of receipt by the receiving party or has subsequently entered the
            public domain other than as a result of a breach of clause 10.1 by the receiving party.
    </p>

    <p>
            10.3 Subject to clause 10.4, neither party shall make (or permit any person to make) any public announcement
            concerning the existence, subject matter or terms of this agreement without the prior written consent of the
            other party (not to be unreasonably withheld or delayed) except as required by law, any governmental or
            regulatory authority or court of competent jurisdiction.
    </p>

    <p>
            10.4 The Supplier shall be entitled to reference the fact that it is providing the Services to the Client on
            its website and other marketing and promotional materials.
    </p>

    <p>11. Indemnity</p>

    <p>
            11.1 The Client shall indemnify and keep indemnified the Supplier from and against any claims, actions,
            proceedings, losses, damages, expenses and costs (including legal and other professional fees) incurred by
            it as a result of the Client’s breach of this agreement.
    </p>

    <p>
            11.2 The Supplier shall defend the Client against any claim that the Client's use of the Services or
            Documentation in accordance with this agreement infringes any UK patent effective as of the Effective Date,
            or any other intellectual property right of any third party, and shall indemnify the Client for any amounts
            awarded against the Client in judgment or settlement of such claims, provided that:
    </p>

    <p>(a) the Supplier is given prompt notice of any such claim;</p>

    <p>
            (b) the Client provides reasonable co-operation to the Supplier in the defence and settlement of such claim,
            at the Supplier's expense; and
    </p>

    <p>(c) the Supplier is given sole authority to defend or settle the claim.</p>

    <p>
            11.3 In the defence or settlement of any claim, the Supplier may procure the right for the Client to
            continue using the Services, replace or modify the Services so that they become non-infringing or, if such
            remedies are not reasonably available, terminate this agreement on 2 Business Days' notice to the Client
            without any additional liability or obligation to pay liquidated damages or other additional costs to the
            Client.
    </p>

    <p>
            11.4 In no event shall the Supplier, its employees, agents or sub-contractors be liable to the Client to the
            extent that the alleged infringement is based on:
    </p>

    <p>
            (a) any claim relating to the Client Data or any other content or material provided or uploaded by or on
            behalf of the Client (or any Authorised User); or
    </p>

    <p>(b) a modification of the Services or Documentation by anyone other than the Supplier; or</p>

    <p>
            (c) the Clients use of the Services or Documentation in a manner contrary to the instructions given to the
            Client by the Supplier; or
    </p>

    <p>
            (d) the Clients use of the Services or Documentation after notice of the alleged or actual infringement from
            the Supplier or any appropriate authority.
    </p>

    <p>
            11.5 The foregoing (and clause 12.3(b)) state the Client's sole and exclusive rights and remedies, and the
            Supplier's (including the Supplier's employees', agents' and sub-contractors') entire obligations and
            liability, for infringement of any patent or other intellectual property right.
    </p>

    <p>12. Limitation of liability</p>

    <p>12.1 Except as expressly and specifically provided in this agreement:</p>

    <p>
            (a) the Client assumes sole responsibility for results obtained, conclusions drawn, or decisions taken as a
            result of its use of the Services and the Documentation. The Supplier shall have no liability for any damage
            caused by errors or omissions in any information, content, instructions or scripts provided to the Supplier
            by the Client in connection with the Services, or any actions taken by the Supplier at the Client's
            direction;
    </p>

    <p>
            (b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by
            statute or common law are, to the fullest extent permitted by applicable law, excluded from this agreement;
    </p>

    <p>
            (c) the Supplier shall not be responsible if the Application does not achieve any anticipated outcome
            (including, without limitation, mitigating the consequences of any incident or ensuring the safety, health
            or wellbeing of any person or property);
    </p>

    <p>
            (d) none of the content within, or provided via, the Application constitutes medical advice and should not
            be construed or interpreted as providing medical advice; and
    </p>

    <p>(e) the Services and the Documentation are provided to the Client on an &quot;as is&quot; basis.</p>

    <p>12.2 Nothing in this agreement excludes the liability of the Supplier:</p>

    <p>(a) for death or personal injury caused by the Supplier's negligence; or</p>

    <p>(b) for fraud or fraudulent misrepresentation.</p>

    <p>12.3 Subject to clause 12.1 and clause 12.2:</p>

    <p>
            (a) the Supplier shall not be liable whether in delict (including for negligence or breach of statutory
            duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business,
            depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic
            loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however
            arising under this agreement; and
    </p>

    <p>
            (b) the Supplier's total aggregate liability in contract, delict (including negligence or breach of
            statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or
            contemplated performance of this agreement (including any indemnity granted hereunder) shall be limited to
            the total Subscription Fees paid during the 12 months immediately preceding the date on which the claim
            arose (or, in the event of a claim arising during the first 12 months, the amount of the Subscription Fees
            which would have been payable during such 12 month period, based on the number of User Subscriptions
            purchased as at the Effective Date).
    </p>

    <p>13. Term and termination</p>

    <p>
            13.1 This agreement shall, subject to this clause 13, commence on the Effective Date and shall continue for
            the Trial Period (if applicable) or (if there is no Trial Period) for the Initial Subscription Term.   If,
            at any time prior to the expiry of the Trial Period (if applicable), the Supplier and the Client enter into
            a Continuation Letter, then this agreement shall automatically continue and shall remain in force for the
            Initial Subscription Term.   If they do not enter into a Continuation Letter, then this agreement shall
            terminate upon the expiry of the Trial Period.
    </p>

    <p>
            13.2 Upon expiry of the Initial Subscription Term, this agreement shall be automatically renewed for
            successive Renewal Periods (each a Renewal Period), unless:
    </p>

    <p>
            (a) either party gives the other written notice of termination, before the next payment date or of any
            Renewal Period, in which case this agreement shall terminate upon the expiry of the applicable Initial
            Subscription Term or Renewal Period; or
    </p>

    <p>(b) otherwise terminated in accordance with the provisions of this agreement;</p>

    <p>
            and the Trial Period (if any), the Initial Subscription Term and any subsequent Renewal Periods shall
            together constitute the Subscription Term.
    </p>

    <p>
            13.3 For the avoidance of doubt, the terms agreed between the Client and the Supplier and documented in any
            Continuation Letter shall form part of this agreement. In the event of any conflict between the terms of any
            Continuation Letter and the terms of this agreement, the terms of the Continuation Letter shall prevail.
    </p>

    <p>
            13.4 Without affecting any other right or remedy available to it, either party may terminate this agreement
            with immediate effect by giving written notice to the other party if:
    </p>

    <p>
            (a) the other party fails to pay any amount due under this agreement on the due date for payment and remains
            in default not less than 30 days after being notified in writing to make such payment.
    </p>

    <p>
            (b) the other party commits a material breach of any other term of this agreement which breach is
            irremediable or (if such breach is remediable) fails to remedy that breach within 30 days after being
            notified in writing to do so;
    </p>

    <p>
            (c) is unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986) or becomes
            insolvent or an order is made or a resolution passed for the administration, winding-up or dissolution of
            the other (otherwise than for the purposes of a solvent amalgamation or reconstruction) or an administrative
            or other receiver, manager, liquidator, administrator, trustee or similar officer is appointed over all or
            any substantial part of the assets of the other or the other enters into or proposes any composition or
            arrangement with its creditors generally or any similar event occurs in any applicable jurisdiction; or
    </p>

    <p>
            (d) the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial
            part of its business.
    </p>

    <p>13.5 On termination of this agreement for any reason:</p>

    <p>
            (a) all licences granted under this agreement shall immediately terminate and the Client shall immediately
            cease all use of the Services and/or the Documentation;
    </p>

    <p>
            (b) each party shall return and make no further use of any equipment, property, Documentation and other
            items (and all copies of them) belonging to the other party;
    </p>

    <p>
            (c) the Supplier may destroy or otherwise dispose of any Client Data in its possession in accordance with
            clause 5.7(e), unless the Supplier receives, within ten days of the termination date, a written request for
            delivery to the Client of the then most recent back-up of the Client Data. The Supplier shall use reasonable
            commercial endeavours to deliver the back-up to the Client within 30 days of receipt of such a request,
            provided that the Client has, at that time, paid all fees and charges outstanding at and resulting from
            termination. The Client shall pay all reasonable expenses incurred by the Supplier in returning or disposing
            of Client Data;
    </p>

    <p>
            (d) any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of
            termination (including the right to claim damages in respect of any antecedent breach of this agreement)
            shall not be affected or prejudiced;
    </p>

    <p>
            (e) the provisions of clauses 2.2(f), 2.2(h), 2.5, 5.4, 9.1, 10, 11, 12, 13, 15 and 17 shall remain in full
            force and effect, notwithstanding such termination.
    </p>

    <p>14. Force majeure</p>

    <p>
            The Supplier shall have no liability to the Client under this agreement if it is prevented from or delayed
            in performing its obligations under this agreement, or from carrying on its business, by acts, events,
            omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or
            other industrial disputes (whether involving the workforce of the Supplier or any other party), failure of a
            utility service, transport or telecommunications network, act of God, war, riot, civil commotion, malicious
            damage, compliance with any law or governmental order, rule, or regulation, accident, breakdown of
            plant/machinery, fire, flood, storm or default of suppliers/sub-contractors, pandemic or epidemic, provided
            that the Client is notified of such event and (if known to the Supplier) it’s likely duration.
    </p>

    <p>15. General</p>

    <p>
            15.1 If there is an inconsistency between any of the provisions in the main body of this agreement and the
            Schedule, then the provisions in the Schedule shall prevail.
    </p>

    <p>
            15.2 No variation of this agreement shall be effective unless it is in writing and signed by the parties (or
            their authorised representatives).
    </p>

    <p>
            15.3 No failure or delay by a party to exercise any right or remedy provided under this agreement or by law
            shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further
            exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall
            prevent or restrict the further exercise of that or any other right or remedy.
    </p>

    <p>
            15.4 If any provision or part-provision of this agreement is or becomes invalid, illegal or unenforceable,
            it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this
            agreement (and if any such provision or part-provision is deemed deleted then the parties shall negotiate in
            good faith to agree a replacement provision that, to the greatest extent possible, achieves the intended
            commercial result of the original provision).
    </p>

    <p>
            15.5 This agreement contains the whole agreement between the parties relating to its subject matter and
            supersedes any prior agreements, representations or understandings between them unless expressly
            incorporated by reference in this agreement. Each party acknowledges that it has not relied on, and shall
            have no remedy in respect of, any warranty or representation (whether innocent or negligent) made but not
            expressly embodied in this agreement. Nothing in this clause limits or excludes any liability for fraud.
    </p>

    <p>
            15.6 The Client shall not, without the prior written consent of the Supplier, assign, transfer, charge,
            sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement.
               The Supplier may at any time assign, transfer, charge, sub-contract or deal in any other manner with all
            or any of its rights or obligations under this agreement.
    </p>

    <p>
            15.7 This agreement does not confer on any person (other than the parties) any right to enforce or otherwise
            invoke any term of this agreement under the Contracts (Third Party Rights) (Scotland) Act 2017 provided that
            this does not affect any right or remedy of the third party which exists or is available apart from that
            Act.
    </p>

    <p>normal>16.Notices</p>

    <p>
            Any notice required to be given under this agreement shall be in writing and shall be delivered by hand or
            sent by pre-paid first-class post or recorded delivery post to the other party at its address set out in
            this agreement, or such other address as may have been notified by that party for such purposes.   A notice
            delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business
            hours, at 9 am on the first Business Day following delivery). A correctly addressed notice sent by pre-paid
            first-class post or recorded delivery post shall be deemed to have been received at the time at which it
            would have been delivered in the normal course of post.
    </p>

    <p>17. Governing Law </p>

    <p>
            This agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in
            connection with it or its subject matter or formation shall be governed by and construed in accordance with
            Scots law and the parties hereby submit to the exclusive jurisdiction of the Scottish Courts.
    </p>

    <p>THIS IS THE SCHEDULE REFERRED TO IN THE FOREGOING AGREEMENT </p>

    <p>SCHEDULE PART 1</p>

    <p>FEE AND SERVICES SCHEDULE</p>

    <p>
            The standard price of £2.00 per user per month will be applied unless a valid promotion code is applied
            during the sign-up process.{' '}
    </p>

    <p>
            The client will be billed 30 days after signing up and every 30 days thereafter for the month ahead based on
            the number of users in the system on the billing date.
    </p>

    <p>
            Should the client wish to cancel they may do so at any point and will not be invoiced after doing so. Access
            will be revoked automatically on the next scheduled payment date.
    </p>

    <p>PART 1B</p>

    <p>SUPPORT SERVICES PROVIDED WITHIN THE COST OF THE USER SUBSCRIPTIONS</p>

    <p>There is no charge for this support.</p>

    <p>a. Support Hours</p>

    <p>Monday to Friday 09:00 – 17:00 (GMT + British Summer time)</p>

    <p>b. Contact Methods</p>

    <p>Freshdesk is Vidatec’s support Service Desk tool.</p>

    <p>
            Freshdesk is a cloud-based system and not location dependent; thus, de-risking service provision being
            affected by disruption to a physical location
    </p>

    <p>
            Freshdesk is available 24/7/365. The Vidatec Service Desk support staff are available between the hours of
            09:00 and 17:00 Monday – Friday to respond to incidents raised via Freshdesk.
    </p>

    <p>c. Call Logging</p>

    <p>
            All incidents are logged via Freshdesk and will have a unique reference number. Freshdesk Tickets should be
            raised by the customer through the URL supplied at the start of the contract.
    </p>

    <p>
            Customers willat all timeshave real-time visibility of Freshdesk through authorised staff who will be able
            to enter new calls, new information and interact directly with Freshdesk via a web browser.
    </p>

    <p>
            Customers will be able to raise new tickets at any time. However, the customer acknowledges that the
            progression of calls outside of the agreed Cover Hours will be at the discretion of Vidatec.
    </p>

    <p>Freshdesk is the tool that will be used for managing tickets until final resolution.</p>

    <p>Tickets raised via any other method will be transferred to a Freshdesk ticket.</p>

    <p>d. Severity Levels and SLA’s</p>

    <p>
            The severity level is allocated when the call is logged by the client as per the definitions below. This is
            based on the impact of the incident on the use of engage4
    </p>

    <p>
            Vidatec will respond to all support tickets raised within 24 hours within the support hours as described in
            part1.b
    </p>

    <p>
            As fixes are dependent on a submission and review by the app stores Vidatec cannot provide a standard SLA
            against issues. Vidatec will endeavour to provide an estimated date of the fix being available through
            Freshdesk as soon as a date is determined.
    </p>

    <table>
      <tr>
        <td>
          <p>Incident Type</p>
        </td>
        <td>
          <p>Description</p>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <p>Critical</p>
        </td>
        <td>
          <p>The incident affects critical functionality or critical data. It does not have a workaround.</p>
        </td>
        <td>
          <p>A fix will be deployed as soon as possible</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Major</p>
        </td>
        <td>
          <p>
                        The incident affects major functionality or major data. It has a workaround but is not obvious
                        and is difficult.
          </p>
        </td>
        <td>
          <p>A fix will be deployed as soon as possible</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Minor</p>
        </td>
        <td>
          <p>The incident affects minor functionality or non-critical data. It has an easy workaround.</p>
        </td>
        <td>
          <p>
                        A fix will be deployed as part of the next planned release and an estimated date will be
                        provided at the time of raising the incident
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Feature Limitation</p>
        </td>
        <td>
          <p>This does not affect functionality or data but is a limitation of a feature.</p>
        </td>
        <td>
          <p>All feedback will be logged and considered for future development.</p>
        </td>
      </tr>
    </table>

    <p>SCHEDULE PART 2</p>

    <p>SUBSCRIPTION TERM</p>

    <p>Trial Period =  30 days </p>

    <p>Renewal Period = Subscription will renew every 30 days unless cancelled</p>

    <p>SCHEDULE PART 3</p>

    <p>DATA PROCESSING SCHEDULE</p>

    <p>
            Unless otherwise agreed between the parties in writing, any processing of any personal data by the Supplier
            under this agreement shall be for the subject-matter, duration, nature and purposes and involve the types of
            personal data and categories ofdata subjects set out in this Part 3 of the Schedule.
    </p>

    <p>Subject-matter of processing:</p>

    <p>The Supplier will be providing the Services as described in this agreement.</p>

    <p>Duration of the processing:</p>

    <p>The Subscription Term.</p>

    <p>Nature and purpose of the processing:</p>

    <p>
            It is anticipated that limited personal data will be shared as part of the Services.  Any personal data
            transferred may be subject to one or more of the following processing activities, (1) holding/storing, (2)
            referencing, (3) disposing/destroying, (4) analysing, (5) altering/updating, (6) using/applying, (7)
            transferring to third parties, and such other processing activities as may be agreed with the Client from
            time to time. The purpose of the processing will be for the purpose of providing the Services as described
            in this agreement.
    </p>

    <p> Type of Personal Data:</p>

    <p>
            The personal data may concern Authorised Users and/or employees or contractors or clients of the
            Client. This may include names, email addresses, job titles, departments and other contact details, together
            with browsing behaviour and the expression of any preferences or likes.
    </p>

    <p>Categories of Data Subjects:</p>

    <p>Data subjects are likely to include Authorised Users, employees, contractors and clients of the Client.</p>

    <p>Special Category Data</p>

    <p>
      {' '}
            It is not anticipated that special category data will be provided in the course of the Services, save to the
            extent that the expression of preferences with regard to health and wellbeing content may be regarded as
            special category data (in that it relates to health).
    </p>

    <p>Specific processing instructions:</p>

    <p>
            Personal data provided will be hosted and stored by the Supplier and/or its third-party hosting provider(s).
    </p>

    <p>Usernames will be stored by the Supplier to enable the control of access to the Application.</p>

    <p>Security Requirements</p>

    <p>
            In accordance with the Data Protection Laws, taking into account the state of the art, the costs of
            implementation and the nature, scope, context and purposes of the processing of the personal data to be
            carried out under or in connection with this agreement, as well as the risks of varying likelihood and
            severity for the rights and freedoms of natural persons and the risks that are presented by the processing,
            especially from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access
            to any personal data transmitted, stored or otherwise processed, the Supplier shall implement appropriate
            technical and organisational security measures appropriate to the risk, including as appropriate those
            matters mentioned in Articles 32(1)(a) to 32(1)(d) (inclusive) of the GDPR.
    </p>
  </>
);

export default TermsAndConditions;
