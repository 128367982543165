import React from 'react';
import { ENTITY } from '../../CampaignData';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import '../../Campaign.css';
import ReportDetails from './ReportDetails';

const ReportItem = ({ item, index, onClickExpandRow }) => {
  const { entity, type, title, expand, start_date, sequence_no } = item || {}
  const contentDate = moment(start_date).add(sequence_no - 1, 'days')
  const isContentPublished =
    new Date(contentDate).getTime() <= new Date().getTime()
  return (
    <div
      className="d-flex flex-column"
      style={isContentPublished ? {} : { backgroundColor: 'rgba(151, 151, 151, 0.2)' }}
    >
      <div
        className="row p-3"
        style={index === 0 ? { borderTopWidth: 0 } : {}}
        onClick={isContentPublished ? () => onClickExpandRow(index) : null}
      >
        <div className="column">
          <p className="content-title" style={{ fontWeight: 600 }}>
                        Type:
          </p>
          <p className="content-title" style={{ color: '#0274a7', fontWeight: 600 }}>
            {ENTITY[type]}
          </p>
        </div>
        <div className="column" style={{ flex: 3 }}>
          <p className="content-title" style={{ fontWeight: 600 }}>
                        Content:
          </p>
          <p className="pr-2 content-title">{title}</p>
          <p className="mt-1 content-title" style={{ fontWeight: 600 }}>
            {type === 'notices' ? 'Body: ' : type === 'polls' ? 'Question: ' : 'Title: '}
          </p>
          <p className="content-title">{entity.title}</p>
          {expand ? <ReportDetails item={item} /> : false}
        </div>
        <div className="column">
          <p className="content-title" style={{ fontWeight: 600 }}>
                        Date:
          </p>
          <p className="content-title">{contentDate.format('DD/MM/YYYY')}</p>
        </div>
        <div className="column" style={{ alignItems: 'flex-end' }}>
          <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} color="#505a62">
                        faArrowDown
          </FontAwesomeIcon>
        </div>
      </div>
    </div>
  );
};

export default ReportItem;
