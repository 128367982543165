import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import Loader from 'components/Loader/Loader';
import { EditText, EditDropDown, EditTextArea } from 'components/EditText/EditText';
import { get, post, patch, dodelete } from 'lib/comms';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import { EditImage } from 'components/EditImage/EditImage';
import SearchRow from 'components/SearchRow/SearchRow';
import './NoticePage.css';
import Table from 'components/Table/Table';
import { Dot } from 'components/SVG/SVG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

import NoticesService from 'services/NoticesService';
import PinnedPostLimitError from '../../../../models/errors/PinnedPostLimitError';
import { PinnedPostLimitDialog } from '../../../../components/Dialog/Dialog';

const categories = [
  // prob want these from the db...
  { id: 1, name: 'athletic' },
  { id: 2, name: 'learning' },
  { id: 3, name: 'charity' },
  { id: 4, name: 'social' },
  { id: 5, name: 'work' }
];

export default class NoticePage extends React.Component {
    columns = [
      {
        name: 'Name',
        grow: 2,
        selector: 'last_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''} ${row.deleted_at ? ' (deleted)' : ''}`
      },
      { name: 'Email', grow: 2, selector: 'email_address', sortable: true },
      {
        name: 'Member Type',
        selector: 'member_type',
        sortable: true,
        cell: (row) => (
          <button className="notice-toggle-member-btn" onClick={() => this.toggleMemberType(row)}>
            <Dot className="mt-1" color={row.member_type === 'admin' ? '#FBB811' : '#68C4D1'} />{' '}
            {row.member_type === 'admin' ? 'Admin' : 'Standard'}
          </button>
        )
      },
      {
        name: '',
        selector: 'id',
        sortable: false,
        right: true,
        cell: (row) => (
          <button className="notice-remove-member-btn" onClick={() => this.deleteMember(row)}>
            <FontAwesomeIcon icon={faMinusCircle} /> Remove Member
          </button>
        )
      }
    ];
    columns2 = [
      {
        name: 'Name',
        grow: 2,
        selector: 'last_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''}`
      },
      { name: 'Email', grow: 2, selector: 'email_address', sortable: true },
      {
        name: '',
        selector: 'id',
        sortable: false,
        right: true,
        cell: (row) => (
          <button className="notice-remove-member-btn notice-add-member-btn" onClick={() => this.addMember(row)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Add Member
          </button>
        )
      }
    ];

    constructor (props) {
      super(props);

      const isNew = props.notice && props.notice.id === 'new';

      let notice = {
        id: 'new',
        notice: '',
        created_at: new Date().toUTCString(),
        is_pinned: false
      };

      if (props.notice) {
        notice = {
          ...notice,
          ...props.notice
        };
      }

      this.state = {
        group: {},
        isNew,
        notice,
        status: 'LOADING_INITIAL', // LOADING_INITIAL, LOADING, READY
        editing_notice: isNew,
        saving_notice: false,
        showDeleteDialog: false,
        showMessage: null
      };
    }

    setEditing = (value) => async (e) => {
      let newState = {};

      if (`editing_${value}` in this.state) {
        newState[`editing_${value}`] = !this.state[`editing_${value}`];
      }

      // if going from true to false, save
      if (newState[`editing_${value}`] === false) {
        this.onSave();
      }

      this.setState(newState);
    };

    togglePinned = () => {
      this.setState({
        notice: {
          ...this.state.notice,
          is_pinned: !this.state.notice.is_pinned
        }
      });
    };

    componentDidMount = async () => {
      // if not a new notice, get the full notice from the API
      if (this.state.isNew) {
        this.setState({
          status: 'READY'
        });
      } else {
        try {
          const notice = await NoticesService.getFullNotice(this.state.notice.id);
          this.setState({
            notice,
            status: 'READY'
          });
        } catch (error) {
          // throw error here and return to list
          // TODO: add global modal code
          this.props.onBack();
        }
      }
    };

    onSave = async () => {
      this.setState({
        status: 'LOADING',
        saving_notice: true
      });

      try {
        if (this.state.isNew) {
          // save new notice
          await NoticesService.createNewNotice(this.state.notice);
        } else {
          // update existing notice
          await NoticesService.updateExistingNotice(this.state.notice);
        }

        this.setState({
          isNew: false
        });
      } catch (error) {
        if (error instanceof PinnedPostLimitError) {
          this.setState({
            errorStatus: 'PINNED_POST_LIMIT',
            pinnedPosts: error.pinnedPosts
          });
        } else {
          this.setState({
            showMessage: { message: error.message, title: 'Error' }
          });
        }
      }

      this.setState({
        editing: [false],
        status: 'READY',
        saving_notice: false
      });
    };

    doDelete = async () => {
      const resp = await dodelete('cms/notices', { id: this.state.notice.id }, true);
      await this.setState({ showDeleteDialog: false });
      if (resp && resp.success && this.props.onBack) this.props.onBack();
      else if (resp.message) {
        setTimeout(
          () =>
            this.setState({
              showMessage: { message: resp.message, title: 'Delete notice' }
            }),
          500
        );
      }
    };

    onClosePinnedPostLimitDialog = () => {
      this.setState({
        errorStatus: null,
        editing_notice: true
      });
    };

    onSelectExistingPostToUnpin = async (postToUnpin) => {
      console.log(postToUnpin);

      try {
        await NoticesService.updateExistingNotice({
          id: postToUnpin,
          is_pinned: false
        });

        this.setState({
          errorStatus: null,
          pinnedPosts: []
        });

        this.onSave();
      } catch (error) {
        this.setState({
          errorStatus: null,
          showMessage: {
            message: error.message,
            title: 'Error'
          },
          pinnedPosts: []
        });
      }
    };

    render () {
      let { notice, editing_notice, saving_notice, status, isNew } = this.state;

      return (
        <div className="maincontainer">
          <BackRow
            onBack={this.props.onBack}
            buttonTitle={status === 'LOADING' || isNew ? null : 'DELETE NOTICE'}
            onDelete={() => this.setState({ showDeleteDialog: true })}
          />

          {status === 'LOADING' && <Loader />}

          {status !== 'LOADING_INITIAL' && (
            <Card
              title="Notice information"
              editing={editing_notice ? '1' : undefined}
              saving={saving_notice}
              onToggle={this.setEditing('notice')}
            >
              <div className="notice-edit-outer">
                <div className="notice-edit-text-outer">
                  <EditTextArea
                    label="Content"
                    editing={editing_notice ? '1' : undefined}
                    onChange={(text) => this.setState({ notice: { ...notice, notice: text } })}
                    value={notice.notice}
                  />
                  <div
                    className={`notice-edit-pinned ${
                      editing_notice ? 'notice-edit-pinned--active' : null
                    }`}
                    onClick={this.togglePinned}
                  >
                    <FontAwesomeIcon
                      icon={notice.is_pinned ? faCheckSquare : faSquare}
                      className="notice-edit-pinned__checkbox"
                      style={{ color: editing_notice && notice.is_pinned ? '#1874A7' : '#888' }}
                    />
                                    Pin this notice to the top of the feed
                  </div>
                </div>
                <div className="notice-edit-image-outer ml-4">
                  <EditImage
                    editing={editing_notice ? '1' : undefined}
                    imageid={notice.image_id}
                    onChange={(imageId) => this.setState({ notice: { ...notice, image_id: imageId } })}
                  />
                </div>
              </div>
            </Card>
          )}

          {this.state.showDeleteDialog ? (
            <DeleteDialog
              item="Notice"
              onSuccess={this.doDelete}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
          {this.state.errorStatus === 'PINNED_POST_LIMIT' ? (
            <PinnedPostLimitDialog
              message={
                'You can only have a max of 3 pinned notice posts. Select one to unpin when this notice is posted:'
              }
              title={'Unpin post'}
              pinnedPosts={this.state.pinnedPosts}
              onClose={this.onClosePinnedPostLimitDialog}
              onSelect={this.onSelectExistingPostToUnpin}
            />
          ) : null}
        </div>
      );
    }
}
