import React from 'react';
import * as S from './KnowledgebaseItem.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faDotCircle } from '@fortawesome/free-solid-svg-icons';

const ItemType = ({ name, onChange, id, selectedTypeId, isEditing, description }) => {
  const isSelected = id === selectedTypeId;

  return (
    <div className={`knowledgebaseItem-type-container-outer ${name}`}>
      <S.KnowledgebaseTypeOuterButton
        onClick={() => {
          if (isEditing) onChange(id);
        }}
        disabled={!isEditing}
      >
        <div>
          <S.KnowledgebaseTop>
            <FontAwesomeIcon
              icon={isSelected ? faDotCircle : faCircle}
              className="knowledgebaseItem-type-checkbox"
              style={{
                color: isEditing ? '#1774A7' : isSelected ? '#505A62' : '#888'
              }}
            />

            <S.HorizontalSpacer size={5} />

            <div className="knowledgebaseItem-type-title">{name}</div>
          </S.KnowledgebaseTop>

          <S.KnowledgebaseBottom>
            <div className="knowledgebaseItem-type-desc">{description}</div>
          </S.KnowledgebaseBottom>
        </div>
      </S.KnowledgebaseTypeOuterButton>
    </div>
  );
};

export default ItemType;
