import React from 'react';
import { get } from 'lib/comms';
import { FlexibleXYPlot, AreaSeries, LineSeries, MarkSeries, HorizontalGridLines, XAxis, YAxis, Hint } from 'react-vis'; // https://uber.github.io/react-vis/documentation/api-reference/xy-plot
import 'react-vis/dist/style.css';
import moment from 'moment';
import { createDashboardWidgetSettings } from '../../../../../lib/dashboard';
import SmallLoader from '../../../../../components/SmallLoader/SmallLoader';

export default class extends React.Component {
    state = { data: [], status: 'LOADING', error: null };

    componentDidMount = async () => this.reload();

    reload = async () => {
      const { settings } = this.props;

      this.setState({
        status: 'LOADING'
      });

      const response = await get('cms/dashboard_data/pulse', createDashboardWidgetSettings(settings), true);
      const data = response.data;

      if (response.success === false && response.message.length > 0) {
        this.setState({
          status: 'READY_WITH_ERROR',
          error: response.message
        });
      }

      if (Array.isArray(data)) {
        this.makeXLSPage(data);
        this.setState({
          data,
          status: 'READY'
        });
      }
    };

    makeXLSPage = (data) => {
      // console.log(data);
      // let out = [['First name', 'Surname', 'Email', 'Points']];
      // data.forEach(record => out.push([record.first_name, record.last_name, record.email_address, record.points]));
      // if (this.props.onXLSData) this.props.onXLSData(out);
    };

    render () {
      const { hintItem, data, status, error } = this.state;
      const { width, settings = {} } = this.props;
      const { time = 'thismonth' } = settings;

      if (status === 'LOADING') {
        return (
          <div style={localStyle.outer}>
            <SmallLoader />
          </div>
        );
      }

      if (status === 'READY_WITH_ERROR') {
        return <div style={localStyle.outer}>{error}</div>;
      }

      // let data = [];
      let labels = [];
      let xlsData = [['Date', 'Avg Pulse']];

      const formattedData = data.map((dayScore, index) => ({
        x: index,
        y: Math.min(dayScore.score, 100),
        color: getColor(dayScore.score)
      }));

      data.forEach((row, index) => {
        xlsData.push([row.dateString.slice(0, 10), row.score]);
      });

      if (this.props.onXLSData) this.props.onXLSData(xlsData);

      return (
        <div style={localStyle.outer}>
          <div style={{ flex: 1 }}>
            <FlexibleXYPlot xType="linear" yDomain={[0, 100]}>
              <HorizontalGridLines />
              <XAxis
                tickValues={data.map((item, index) => index)}
                tickFormat={(d, i) =>
                  i % Math.max(Math.floor(data.length / 10), 1) === 0
                    ? moment(data[i].dateString).format('DD/MM')
                    : ''
                }
                tickTotal={5}
              />
              <YAxis />
              <AreaSeries data={formattedData} curve={'curveMonotoneX'} fill="#CCC" color="#CCC" />
              <LineSeries data={formattedData} curve={'curveMonotoneX'} color="#333" />
              <MarkSeries
                data={formattedData}
                size={4}
                colorRange={['#ff0000', '#00ff00']}
                colorType="literal"
                onValueMouseOver={(item) => this.setState({ hintItem: item })}
                onValueMouseOut={() => this.setState({ hintItem: null })}
              />
              {hintItem ? (
                <Hint value={hintItem} style={{ fontSize: 14 }}>
                  <div
                    style={{
                      border: '1px solid ' + hintItem.color,
                      borderWidth: 2,
                      borderRadius: '4px',
                      backgroundColor: '#FFF',
                      padding: '2px 6px',
                      color: '#000',
                      margin: '1px'
                    }}
                  >
                    {hintItem.y.toFixed(0)}
                  </div>
                </Hint>
              ) : null}
            </FlexibleXYPlot>
          </div>
        </div>
      );
    }
}

const localStyle = {
  outer: { display: 'flex', flexDirection: 'row', padding: 16, flex: 1 }
};

const getColor = (score) => {
  const g = Math.min(score * 5, 255);
  const r = Math.min((100 - score) * 5, 255);
  return `rgb(${r},${g},0)`;
};
