import React from 'react';
import { Link } from 'react-router-dom';

import './RegistrationHeader.css';

const RegistrationHeader = () => {
  return (
    <div className="header-section">
      <div className="header-row">
        <div className="login-section">
          <span>Already have an account?</span>
          <Link className="login-button" to="/login">
                        Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegistrationHeader;
