import React from 'react';
import Card from 'components/Card/Card';
import '../BillingPage.css';

const InvoiceHistory = ({ invoiceHistory }) => {
  return (
    <Card outerClass="invoice-history card-medium ml-3" title="Invoice History">
      {invoiceHistory.map(({ invoice_date, amount_paid, total, currency, invoice_pdf }) => (
        <div className="invoice-item">
          <span>{invoice_date}</span>
          <div>
            <span>{`${currency === 'gbp' ? '£' : ''}${total}`}</span>
          </div>
          <a target="_blank" href={invoice_pdf} className="pdf-link">
                        PDF
          </a>
        </div>
      ))}
    </Card>
  );
};

export default InvoiceHistory;
