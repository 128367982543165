import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lib/comms';
import Table from 'components/Table/Table';
import MicroLearningPage from './MicroLearningPage';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import ToolTip from 'components/ToolTip/TooltTip';

const LEARNING_TOOLTIPS = [
  {
    target: '.searchrow-btn',
    title: 'Add New Micro Learning',
    content:
            'Micro learning is a great way to share learning modules with your users in a quick user friendly format. To create your micro learning select add new material.',
    placement: 'left'
  },
  {
    target: '.tabrow-tab.active',
    title: 'Adding information about your micro learning',
    content:
            'Complete the form below with information about this learning module, you can attach an image, title, description and allocate a estimated time to complete as a guide to users as well as award points for completing this learning module. When you are done select save to progress to the next step to create the questions.',
    placement: 'right'
  },
  {
    target: '.tabrow-tab.active',
    title: 'Creating your micro learning',
    content:
            'You can build your learning from here by selecting either a question or a statement. If you select a question you will have free text to create a question and a list of answers, simply select the correct answer or multiple answers. Statements are great to share information in-between the questions. Each question or statement will be displayed on its own screen in the app.',
    placement: 'right'
  },
  {
    target: '.text-button',
    title: 'Saving and Publishing your micro learning',
    content:
            'When you are done creating your micro learning select save and then return to the about section and select "Post Learning" this will make the learning available in the app for users to participate in. When users start to complete the micro learning you will see a third tab for "result" where you can view the average results for each question. You can archive your micro learning at anytime. This will mean it is no longer available in the app for users to participate in but you will still have access to the micro learning module and the results from the CMS',
    placement: 'left'
  }
];
const columns = [
  { name: 'Micro-Learning Material', selector: 'name', sortable: true, grow: 3, wrap: true },
  {
    name: 'Date Posted',
    selector: 'posted_date',
    sortable: true,
    format: (row) => (row.posted_date ? moment(row.posted_date).format('DD MMM YYYY') : '')
  },
  { name: 'Completed by', selector: 'user_count', center: true, sortable: true },
  {
    name: 'Average Score',
    selector: 'score',
    center: true,
    sortable: true,
    format: (row) => (row.score ? `${((100 * row.score) / (row.user_count * (row.out_of || 1))).toFixed(0)}%` : '')
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: true,
    center: true,
    format: (row) => (row.posted_date ? (row.active ? 'Active' : 'Archived') : 'Not posted')
  },
  {
    name: '',
    selector: 'id',
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
  }
];

export default class MicroLearningsPage extends React.Component {
  constructor (props) {
    super(props);
    this.navigationState = this.props.location.state;
    this.previousPage = this.navigationState?.from;
    this.fromAnotherPage =
            this.navigationState?.from === 'onboarding' || this.navigationState?.from === 'campaigns';
    this.state = {
      data: [],
      search: '',
      activeTab: null,
      editing: this.fromAnotherPage ? { id: 'new' } : null,
      stepIndex: 0
    };
  }

    updateItemCreated = () => {
      this.setState({ createdNew: true });
    };

    doneEditing = () => {
      if (this.fromAnotherPage) {
        this.setState({ fromAnotherPage: true });
      } else {
        this.setState({ editing: null });
        this.reload();
      }
    };

    componentDidMount = () => this.reload();

    reload = async () => {
      let resp = await get('cms/learnings', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.learnings) this.setState({ data: resp.learnings });
      else console.log(resp);
    };

    render () {
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      if (this.state.fromAnotherPage) {
        return (
          <Redirect
            to={{
              pathname: `/${this.previousPage}`,
              state: { ...this.navigationState, from: 'learning', createdNew: this.state.createdNew }
            }}
          />
        );
      }
      let { data, search, editing, stepIndex } = this.state;
      if (search) {
        data = data.filter(
          (item) => `${item.name} ${item.category}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <>
          {this.state.editing ? (
            <MicroLearningPage
              learning={editing}
              onBack={this.doneEditing}
              updateItemCreated={this.updateItemCreated}
              activeTab={this.state.activeTab}
            />
          ) : (
            <div className="maincontainer">
              <SubscriptionBanner />
              <h4>Micro-Learning</h4>
              <SearchRow
                value={search}
                searchTitle="Search by name of Micro-Learning material"
                onSearch={(search) => this.setState({ search })}
                buttonTitle="ADD NEW MATERIAL"
                onBtnClick={(item) => this.setState({ editing: { id: 'new' } })}
              />
              <Table
                columns={columns}
                data={data}
                onRowClicked={(item) => this.setState({ editing: item, activeTab: null })}
                tableID="microlearning"
                defaultSortField={columns[1].selector}
              />
            </div>
          )}
          <ToolTip
            style={{ width: 480 }}
            screenId={'learning'}
            stepIndex={this.state.stepIndex}
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                const activeTab =
                                stepIndex === 3 || stepIndex === 1 || (stepIndex === 2 && tooltipData.action === 'next')
                                  ? 'Questions'
                                  : 'About';
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  activeTab,
                  editing:
                                    ((stepIndex === 0 || stepIndex === 1 || stepIndex === 2) &&
                                        tooltipData.action === 'next') ||
                                    ((stepIndex === 2 || stepIndex === 3) && tooltipData.action === 'prev')
                                      ? { id: 'new' }
                                      : null
                });
              }
            }}
            steps={LEARNING_TOOLTIPS}
          />
        </>
      );
    }
}
