import React, { useState } from 'react';
import TextField, { Input } from '@material/react-text-field';
import _ from 'lodash';
import { useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import Loader from '../Loader/Loader';
import TermsAndConditions from '../../pages/register/TermsAndConditions/TermsAndConditions';

import './CardAndBillingAddress.css';

const CardAndBillingAddress = ({
  saveLabel,
  onSave,
  processingMessage,
  isProcessing,
  paymentErrorMessage,
  onDismissError,
  showTerms,
  paymentDetails = {},
  submitButtonId
}) => {
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [expiryDateComplete, setExpiryDateComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);

  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState(undefined);
  const [expiryDateErrorMessage, setExpiryDateErrorMessage] = useState(undefined);
  const [cvcErrorMessage, setCvcErrorMessage] = useState(undefined);

  const [cardNumberElementId] = useState(_.uniqueId());
  const [cardExpiryElementId] = useState(_.uniqueId());
  const [cardCvcElementId] = useState(_.uniqueId());

  const [cardNumberFocused, setCardNumberFocused] = useState(false);
  const [cardExpiryFocused, setCardExpiryFocused] = useState(false);
  const [cardCVCFocused, setCardCVCFocused] = useState(false);

  const [addressLineOne, setAddressLineOne] = useState(paymentDetails.addressLineOne || '');
  const [postcode, setPostcode] = useState(paymentDetails.postCode || '');
  const [cardName, setCardName] = useState(paymentDetails.cardName || '');

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(!showTerms);

  const elements = useElements();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!cardNumberComplete && !cardNumberErrorMessage) {
      setCardNumberErrorMessage('This field is required');
    }

    if (!expiryDateComplete && !expiryDateErrorMessage) {
      setExpiryDateErrorMessage('Expiry date is required');
    }

    if (!cvcComplete && !cvcErrorMessage) {
      setCvcErrorMessage('CVC is required');
    }

    if (!cardNumberComplete || !expiryDateComplete || !cvcComplete) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    onSave(cardElement, {
      billingPostcode: postcode,
      billingAddressLineOne: addressLineOne,
      nameOnCard: cardName
    });
  };

  const handleCardNumberElementChange = (event) => {
    if (event.error) {
      setCardNumberErrorMessage(event.error.message);
    } else {
      setCardNumberErrorMessage(undefined);
    }

    setCardNumberComplete(event.complete);
  };

  const handleCardExpiryElementChange = (event) => {
    if (event.error) {
      setExpiryDateErrorMessage(event.error.message);
    } else {
      setExpiryDateErrorMessage(undefined);
    }

    setExpiryDateComplete(event.complete);
  };

  const handleCardCvcElementChange = (event) => {
    if (event.error) {
      setCvcErrorMessage(event.error.message);
    } else {
      setCvcErrorMessage(undefined);
    }

    setCvcComplete(event.complete);
  };

  return (
    <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <div>
          <TextField style={{ width: '100%' }} className="mt-3" label="Name on card" outlined={true}>
            <Input
              id="cardName"
              className="poppins"
              name="cardName"
              value={cardName}
              onChange={(e) => setCardName(e.currentTarget.value)}
              required
            />
          </TextField>
        </div>

        <div className="payment-entry-form-row mt-4">
          <div
            className={`stripe-input-offset-wrapper form-control ${
              cardNumberErrorMessage ? '--error' : ''
            } ${cardNumberFocused ? '--focused' : ''}`}
          >
            <CardNumberElement
              id={cardNumberElementId}
              onChange={handleCardNumberElementChange}
              onFocus={() => setCardNumberFocused(true)}
              onBlur={() => setCardNumberFocused(false)}
            />
          </div>
          <div className="form__error">{cardNumberErrorMessage}</div>
        </div>

        <div className="card-wrapper-horizontal-row payment-entry-form-row mt-3">
          <div className="expiry-date-wrapper">
            <div className="horizontal-layout">
              <div
                className={`stripe-input-offset-wrapper form-control ${
                  expiryDateErrorMessage ? '--error' : ''
                } ${cardExpiryFocused ? '--focused' : ''}`}
              >
                <CardExpiryElement
                  id={cardExpiryElementId}
                  onChange={handleCardExpiryElementChange}
                  className="card-element"
                  onFocus={() => setCardExpiryFocused(true)}
                  onBlur={() => setCardExpiryFocused(false)}
                />
              </div>
            </div>
          </div>
          <div className="cvc-wrapper">
            <div className="horizontal-layout">
              <div
                className={`stripe-input-offset-wrapper form-control ${
                  cvcErrorMessage ? '--error' : ''
                } ${cardCVCFocused ? '--focused' : ''}`}
              >
                <CardCvcElement
                  id={cardCvcElementId}
                  className="card-element"
                  onChange={handleCardCvcElementChange}
                  onFocus={() => setCardCVCFocused(true)}
                  onBlur={() => setCardCVCFocused(false)}
                />
              </div>
            </div>
          </div>
        </div>

        {(expiryDateErrorMessage || cvcErrorMessage) && (
          <div className="expiry-cvc-form-errors">
            <div>{expiryDateErrorMessage}</div>
            <div>{cvcErrorMessage}</div>
          </div>
        )}

        <div>
          <TextField
            style={{ width: '100%' }}
            className="mt-3"
            label="Billing Address Line 1"
            outlined={true}
          >
            <Input
              id="address_line_one"
              className="poppins"
              name="address_line_one"
              value={addressLineOne}
              onChange={(e) => setAddressLineOne(e.currentTarget.value)}
              required
            />
          </TextField>
          <TextField style={{ width: '100%' }} className="mt-3" label="Billing Postcode" outlined={true}>
            <Input
              id="postcode"
              className="poppins"
              name="postcode"
              value={postcode}
              onChange={(e) => setPostcode(e.currentTarget.value)}
              required
            />
          </TextField>
          {showTerms && (
            <label className="mt-2 d-flex align-items-center">
              <input
                className="terms-checkbox"
                type="checkbox"
                name="termsAgreed"
                checked={termsAgreed}
                onChange={(e) => setTermsAgreed(e.target.checked)}
              />
              <p className="mb-0">
                                I have read and agree to the{' '}
                <span onClick={() => setShowTermsModal(true)} className="terms-and-conditions-link">
                                    Terms & Conditions
                </span>
              </p>
            </label>
          )}
          <input
            style={{ width: '100%' }}
            className="mt-3 btn btn-primary"
            type="submit"
            disabled={
              isProcessing ||
                            !cardName ||
                            !addressLineOne ||
                            !postcode ||
                            !cardNumberComplete ||
                            !cvcComplete ||
                            !expiryDateComplete ||
                            !termsAgreed ||
                            cardNumberErrorMessage ||
                            cvcErrorMessage ||
                            expiryDateErrorMessage
            }
            onSubmit={onSubmit}
            value={saveLabel}
            id={submitButtonId}
          />
        </div>
        {isProcessing && <Loader text={processingMessage} />}
        {paymentErrorMessage && (
          <div className="modal-outer">
            <div className="modal-inner">
              <p className="mb-0">{paymentErrorMessage}</p>
              <input
                style={{ width: '100%' }}
                className="mt-3 terms-close-button btn btn-primary"
                onClick={() => onDismissError()}
                value="CLOSE"
              />
            </div>
          </div>
        )}
        {showTerms && showTermsModal && (
          <div className="terms-modal-outer">
            <div className="terms-modal-inner">
              <div className="terms-modal-text-container">
                <h3>Terms & Conditions</h3>
                <TermsAndConditions />
              </div>
              <div className="terms-modal-button-container">
                <input
                  style={{ width: '300px' }}
                  className="btn btn-primary terms-close-button"
                  onClick={() => setShowTermsModal(false)}
                  value="CLOSE TERMS & CONDITIONS"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default CardAndBillingAddress;
