import React from 'react';
import Table from 'components/Table/Table';
import { Dialog, MessageDialog } from 'components/Dialog/Dialog';
import CampaignsCalendar from './components/CampaignsCalendar/Calendar';
import CampaignsService from 'services/CampaignsService';

import { TemplateColumns, CAMPAIGN_TOOLTIP_DATA } from './CampaignData';
import './Campaign.css';
import CampaignFilters from './components/CampaignsList/CampaignFilters';
import CalendarListView from './components/CampaignsList/CalendarListView';
import AddCampaignView from './components/CampaignsList/AddCampaignView';
import AddCampaignDialog from './components/AddOrEditCampaign';

import CampaignPage from './CampaignPage';
import CampaignsReport from './CampaignsReport';

import ToolTip from 'components/ToolTip/TooltTip';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class CampaingsPage extends React.Component {
  constructor (props) {
    super(props);
    this.navigationState = this.props.location.state;
    this.CampaignColumns = [
      { name: 'Id', selector: 'id', grow: 0, sortable: false, hide: 4096 },
      {
        name: 'Campaign Name',
        selector: 'name',
        sortable: true,
        grow: 2,
        cell: (row, index, column, id) => {
          return (
            <div
              // onClick={() => this.setState({ editing: row })}
              style={{
                height: '100%',
                marginLeft: -16,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  backgroundColor: row.color,
                  width: 8,
                  height: '100%'
                }}
              ></div>
              <p
                className="m-2"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 170
                }}
              >
                {row.name}
              </p>
            </div>
          );
        }
      },
      {
        name: 'Start Date',
        selector: 'formattedStartDate',
        sortable: true,
        grow: 1,
        wrap: true
      },
      {
        name: 'Length',
        selector: 'length',
        sortable: true,
        grow: 1,
        wrap: true
      },
      {
        name: 'Description',
        selector: 'description',
        sortable: true,
        grow: 3,
        wrap: true,
        cell: (row, index, column, id) => {
          return (
            <p
              className="m-2"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 275
              }}
            >
              {row.description}
            </p>
          );
        }
      },
      {
        name: 'Status',
        selector: 'statusLabel',
        sortable: true,
        grow: 1,
        wrap: true
      },
      {
        name: '',
        selector: '',
        sortable: false,
        grow: 1 / 2,
        cell: (row, index, column, id) => (
          <UncontrolledDropdown>
            <DropdownToggle color="white">
              <FontAwesomeIcon color="#505a62" icon={faEllipsisV} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setState({ editing: row })}>View/Edit</DropdownItem>
              {row.status === 'active' || row.status === 'ended' ? (
                <DropdownItem onClick={() => this.setState({ editing: row, showReports: true })}>
                                    Reporting
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ];
    this.state = {
      editing: this.navigationState?.params?.campaign || null,
      campaigns: [],
      templates: [],
      search: '',
      addCampaignFromTemplateId: '',
      addCampaignFromTemplate: false,
      addNewCampaign: false,
      viewType: 'list',
      slctdFilter: {
        value: 'all',
        label: 'All'
      },
      addingContent: this.navigationState?.params?.content || false,
      showMessage: this.navigationState?.createdNew
        ? {
          title: 'Message',
          message: 'Newly created item available for selection'
        }
        : null,
      showReports: false
    };
  }
  componentDidMount () {
    !this.navigationState?.params && this.reload();
  }

    onCloseAddCampaignDialog = () => {
      this.setState({});
    };
    reload = async () => {
      const campaigns = await CampaignsService.getCampaigns();
      const templates = await CampaignsService.getTemplates();
      this.setState({
        campaigns,
        templates,
        editing: null,
        addingContent: null,
        showReports: false
      });
    };

    renderListView = (campaignsList) => {
      return (
        <>
          <Table
            columns={this.CampaignColumns}
            data={campaignsList}
            // onRowClicked={item => this.setState({ editing: item })}
            tableID="campaigns"
            defaultSortField={this.CampaignColumns[1].selector}
          />
          <h4 className="mt-3 pt-3">Templates</h4>
          <Table
            className="templates"
            noSelect={true}
            columns={TemplateColumns}
            data={this.state.templates}
            tableID="templates"
            defaultSortField={TemplateColumns[1].selector}
            paginationPerPage={5}
          />
        </>
      );
    };

    renderTemplateDialog = () => {
      let { templates } = this.state;
      return (
        <Dialog
          title="Select Campaign Template"
          btnTitle="CREATE"
          onClose={() => {
            this.setState({
              addCampaignFromTemplate: false
            });
          }}
          onConfirm={() => {
            this.setState({
              addNewCampaign: true,
              addCampaignFromTemplate: false
            });
          }}
        >
          <Table
            className="w-100"
            columns={TemplateColumns}
            onRowClicked={(row) => {
              this.setState({
                addCampaignFromTemplateId: row.id,
                addNewCampaign: true,
                addCampaignFromTemplate: false
              });
            }}
            data={templates}
            tableID="templates"
            defaultSortField={TemplateColumns[1].selector}
            paginationPerPage={5}
          />
        </Dialog>
      );
    };

    updateState = (key, val) => {
      this.setState({
        [key]: val
      });
    };

    render () {
      let {
        campaigns,
        viewType,
        slctdFilter,
        addNewCampaign,
        addCampaignFromTemplate,
        templates,
        addCampaignFromTemplateId,
        editing,
        search,
        addingContent,
        showReports
      } = this.state;
      const runningCampaigns = campaigns.filter((item) => item.status === 'active');
      if (slctdFilter.value !== 'all') {
        campaigns = campaigns.filter((item) => item.status === slctdFilter.value);
      }
      if (search) campaigns = campaigns.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);

      const [slctdTemplate] = templates.filter((item) => item.id === addCampaignFromTemplateId);
      return (
        <>
          {showReports ? (
            <CampaignsReport
              campaign={editing}
              onBack={() => {
                this.setState({
                  editing: null,
                  showReports: false
                });
              }}
            />
          ) : editing ? (
            <CampaignPage
              campaign={editing}
              addingContent={addingContent}
              onBack={() => {
                this.reload();
              }}
            />
          ) : (
            <div className="maincontainer">
              <h4>Campaigns</h4>
              <p>
                {'Currently running campaigns: '}
                <span className="bold">{runningCampaigns.length}</span>
              </p>

              <div className="searchrow-outer">
                <input
                  className="searchrow-input"
                  value={this.state.search}
                  placeholder={'Search by campaign name'}
                  onChange={(e) => this.updateState('search', e.target.value)}
                />
                <div className="searchrow-btn-outer">
                  <AddCampaignView updateState={this.updateState} />
                </div>
              </div>

              <div className="d-flex mt-3">
                <CampaignFilters slctdFilter={slctdFilter} updateState={this.updateState} />
                <CalendarListView viewType={viewType} updateState={this.updateState} />
              </div>

              {viewType === 'list' ? (
                this.renderListView(campaigns)
              ) : (
                <CampaignsCalendar
                  campaigns={campaigns}
                  onSelectCampaign={(item) => this.setState({ editing: item })}
                />
              )}
              {addNewCampaign ? (
                <AddCampaignDialog
                  updateState={this.updateState}
                  campaign={{ ...slctdTemplate, id: null }}
                  addCampaignFromTemplateId={addCampaignFromTemplateId}
                />
              ) : null}
              {addCampaignFromTemplate ? this.renderTemplateDialog() : null}
            </div>
          )}
          <ToolTip screenId={'campaigns'} steps={CAMPAIGN_TOOLTIP_DATA} stepIndex={this.state.stepIndex} />
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </>
      );
    }
}
