import React, { useRef, useEffect, useState } from 'react';
import './EditText.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCopy } from '@fortawesome/free-regular-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TextField, { Input } from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const EditText = (props) => {
  const className = `${props.className || ''} edittext-outer ${props.editing ? 'editing' : 'notediting'}`;
  let textRef = useRef(null);
  let { helpText, maxLength, name, value, onChange, editing, enableCopy, type = 'text' } = props;
  if (maxLength) {
    const lengthLeft = Math.max(maxLength - (value || '').length, 0);
    helpText = `Character${lengthLeft === 1 ? '' : 's'} left: ${lengthLeft}`;
  }
  return (
    <div className={props.outerClassName || 'mt-3 mb-3 d-flex'}>
      {!enableCopy ? (
        <TextField
          className={className}
          label={props.label}
          outlined
          style={{ marginLeft: props.marginLeft || '0' }}
        >
          <Input
            ref={textRef}
            maxLength={maxLength}
            className="poppins"
            type={type}
            name={name}
            value={value || ''}
            onChange={onChange && editing ? (e) => onChange(e.currentTarget.value) : null}
            readOnly={editing ? null : 'On'}
            disabled={editing ? null : 'disabled'}
          />
        </TextField>
      ) : (
        <div className={`${className} d-flex align-items-center`}>
          <input
            ref={textRef}
            maxLength={maxLength}
            className={'className poppins w-100'}
            style={{ height: 54, border: '2px solid #0274a7', borderRadius: 5, padding: 10 }}
            type={type}
            name={name}
            value={value || ''}
            onChange={onChange && editing ? (e) => onChange(e.currentTarget.value) : null}
            readOnly={editing ? null : 'On'}
            disabled={editing ? null : 'disabled'}
          />
          <CopyToClipboard text={value}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                right: 30,
                top: 280
              }}
              onClick={() => {
                textRef.current && textRef.current.select();
              }}
            >
              <button className="card-edit text-button" style={{ fontSize: 14, marginRight: 5 }}>
                                Copy
              </button>
              <FontAwesomeIcon icon={faCopy} color="#0274a7" size={'lg'} />
            </div>
          </CopyToClipboard>
        </div>
      )}
      {helpText ? <p className="edittext-help">{helpText}</p> : null}
    </div>
  );
};

export const EditTextArea = (props) => {
  let { helpText, maxLength, value, onChange, editing, label } = props;
  if (maxLength) {
    const lengthLeft = Math.max(maxLength - (value || '').length, 0);
    helpText = `Character${lengthLeft === 1 ? '' : 's'} left: ${lengthLeft}`;
  }
  const className = `mdc-text-field edittext-outer mdc-text-field--outlined ${editing ? 'editing' : 'notediting'} ${
    props.className || ''
  } `;
  return (
    <div className={`mt-3 mb-3 ${props.outerClassName || ''}`}>
      <div className={className} style={{ height: props.height || '180px' }}>
        <textarea
          maxLength={maxLength}
          className="mdc-text-field__input poppins"
          rows="8"
          type="text"
          style={{ height: props.height || '180px' }}
          value={value}
          onChange={onChange && editing ? (e) => onChange(e.currentTarget.value) : null}
          readOnly={editing ? null : 'On'}
          disabled={editing ? null : 'disabled'}
        />
        <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
          <div className="mdc-notched-outline__leading"></div>
          <div className="mdc-notched-outline__notch" style={{ width: 20 + label.length * 6 + 'px' }}>
            <label className="mdc-floating-label mdc-floating-label--float-above">{label}</label>
          </div>
          <div className="mdc-notched-outline__trailing"></div>
        </div>
      </div>
      {helpText ? <p className="edittext-help">{helpText}</p> : null}
    </div>
  );
};

export const EditRichTextArea = (props) => {
  let { helpText, maxLength, value, onChange, editing, label } = props;

  const quill = useRef(null);

  useEffect(() => {
    if (!editing && quill.current) {
      quill.current.blur();
    }
  }, [editing]);

  if (maxLength) {
    const lengthLeft = Math.max(maxLength - (value || '').length, 0);
    helpText = `${lengthLeft} Character${lengthLeft === 1 ? '' : 's'} left`;
  }
  const className = `mdc-text-field edittext-outer mdc-text-field--outlined ${editing ? 'editing' : 'notediting'} ${
    props.className || ''
  } `;

  return (
    <div className={`mt-3 mb-3 ${props.outerClassName || ''}`}>
      <div className={className} style={{ height: '224px', pointerEvents: editing ? 'all' : 'none' }}>
        <ReactQuill
          ref={quill}
          className="quill-rich"
          style={{ opacity: editing ? 1 : 0.8 }}
          value={value}
          onChange={onChange && editing ? (content) => onChange(content) : null}
          readOnly={editing ? null : 'On'}
          disabled={editing ? null : 'disabled'}
        />
        {/* <textarea maxLength={maxLength} className="mdc-text-field__input poppins" rows="8" type="text" /> */}
        <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
          <div className="mdc-notched-outline__leading"></div>
          <div className="mdc-notched-outline__notch" style={{ width: 20 + label.length * 6 + 'px' }}>
            <label className="mdc-floating-label mdc-floating-label--float-above">{label}</label>
          </div>
          <div className="mdc-notched-outline__trailing"></div>
        </div>
      </div>
      {helpText ? <p className="edittext-help">{helpText}</p> : null}
    </div>
  );
};

export const EditDropDown = (props) => {
  let className = `mdc-text-field edittext-outer mt-3 mb-3 mdc-text-field--outlined ${
    props.editing ? 'editing' : 'notediting'
  } ${props.className || ''}`;
  if (props.className && props.className.indexOf('mb-') >= 0) className = className.replace('mb-3', '');
  if (props.className && props.className.indexOf('mt-') >= 0) className = className.replace('mt-3', '');
  return (
    <div className={className} style={props.style || {}}>
      <select
        className="mdc-text-field__input poppins"
        value={props.value}
        onChange={props.onChange && props.editing ? (e) => props.onChange(e.currentTarget.value) : null}
        disabled={props.editing ? null : 'On'}
      >
        {props.options.map((option) => (
          <option key={option.id || option} value={option.id || option}>
            {option.name || option}
          </option>
        ))}
      </select>
      <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
        <div className="mdc-notched-outline__leading"></div>
        <div className="mdc-notched-outline__notch" style={{ width: props.label.length * 7 + 'px' }}>
          <label className="mdc-floating-label mdc-floating-label--float-above">{props.label}</label>
        </div>
        <div className="mdc-notched-outline__trailing"></div>
      </div>
    </div>
  );
};

const dangerColour = '#af365e';

const notchedClassNames = {
  normal: {
    leading: 'mdc-notched-outline__leading',
    notch: 'mdc-notched-outline__notch',
    trailing: 'mdc-notched-outline__trailing'
  },
  noValue: {
    leading: 'mdc-notched-outline__leading--has-no-value',
    notch: 'mdc-notched-outline__notch--has-no-value',
    trailing: 'mdc-notched-outline__trailing--has-no-value'
  },
  notFocused: {
    leading: 'mdc-notched-outline__leading--not-focused',
    notch: 'mdc-notched-outline__notch--not-focused',
    trailing: 'mdc-notched-outline__trailing--not-focused'
  },
  focused: {
    leading: 'mdc-notched-outline__leading--focused',
    notch: 'mdc-notched-outline__notch--focused',
    trailing: 'mdc-notched-outline__trailing--focused'
  },
  error: {
    leading: 'mdc-notched-outline__leading--error',
    notch: 'mdc-notched-outline__notch--error',
    trailing: 'mdc-notched-outline__trailing--error'
  }
};

export const EditDate = (props) => {
  const [meta, setMeta] = useState({
    focused: false,
    touched: false
  });

  const isDateOfBirth = props.isDateOfBirth;
  const className = `${props.className || ''} mdc-text-field edittext-outer mt-3 mb-3 mdc-text-field--outlined ${props.editing ? 'editing' : 'notediting'}`;

  const handleOnBlur = () => {
    setMeta({ focused: false, touched: true });
  };

  const handleOnFocus = () => {
    setMeta({ ...meta, focused: true });
  };

  const handleOnChange = (date) => {
    if (props.onChange && props.editing) {
      props.onChange(moment.utc(date).format('YYYY-MM-DD'));
    }

    setMeta({ ...meta, focused: false });
  };

  const hasError = !props.value && props.required && meta.touched;

  const leadingClassName = `
    ${notchedClassNames.normal.leading}
    ${!props.value ? notchedClassNames.noValue.leading : ''}
    ${meta.focused ? notchedClassNames.focused.leading : notchedClassNames.notFocused.leading}
    ${hasError ? notchedClassNames.error.leading : ''}
    `;

  const notchClassName = `
    ${notchedClassNames.normal.notch}
    ${!props.value ? notchedClassNames.noValue.notch : ''}
    ${meta.focused ? notchedClassNames.focused.notch : notchedClassNames.notFocused.notch}
    ${hasError ? notchedClassNames.error.notch : ''}
  `;

  const trailingClassName = `
    ${notchedClassNames.normal.trailing}
    ${!props.value ? notchedClassNames.noValue.trailing : ''}
    ${meta.focused ? notchedClassNames.focused.trailing : notchedClassNames.notFocused.trailing}
    ${hasError ? notchedClassNames.error.trailing : ''}
  `;

  return (
    <div className={className}>
      <div onFocus={handleOnFocus} onBlur={handleOnBlur} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '150px' }}>
        <FontAwesomeIcon icon={faCalendar} className={`ml-3 mb-1 ${isDateOfBirth ? 'editdate-dateofbirth' : ''}`} color={hasError ? '#af365e' : '#999'} size={props.iconSize || 'lg'} />
        <DatePicker
          calendarClassName="rasta-stripes"
          showYearDropdown={!!isDateOfBirth}
          minDate={props.minDate}
          maxDate={isDateOfBirth ? new Date() : undefined}
          selected={props.value ? moment.utc(props.value).toDate() : ''}
          dateFormat="dd/MM/yyyy"
          className="mdc-text-field__input poppins"
          onChange={handleOnChange}
          popperPlacement={props.popperPlacement}
        />
        {/* <input className="mdc-text-field__input poppins" style={{padding: '10px', width: '180px'}} type="date" value={props.value} onChange={props.onChange && props.editing ? e => props.onChange(e.currentTarget.value) : null} disabled={props.editing ? null : 'On'}/> */}
      </div>
      <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
        <div className={`${leadingClassName}`} />
        <div className={`${notchClassName}`} style={{ width: props.label.length * 8 + 'px' }}>
          <label className="mdc-floating-label mdc-floating-label--float-above">{props.label}</label>
        </div>
        <div className={`${trailingClassName}`}/>
      </div>
    </div>
  );
};
