import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { EditText, EditTextArea } from 'components/EditText/EditText';
import { MessageDialog } from 'components/Dialog/Dialog';
import { get, post } from 'lib/comms';
import moment from 'moment';
import { setData, getData } from '../../../../lib/data';
import OrgFlags from './OrgFlags';
import OrgTheme from './OrgTheme';

export default class GroupPage extends React.Component {
  constructor (props) {
    super(props);
    let { organisation = {} } = props;
    const isNew = organisation.id === 'new';
    const supportActive =
            organisation.support_window_expires_at && new Date(organisation.support_window_expires_at) > new Date();
    this.state = {
      organisation,
      isNew,
      editing: [isNew],
      showMessage: null,
      supportActive,
      orgFlags: [],
      fileContent: '',
      theme: []
    };
  }

  componentDidMount () {
    this.getOrganisationData();
  }

  async getOrganisationData () {
    let orgID = this.state.organisation.id;

    const featureFlagsResp = await get(`cms/feature_flags/${orgID}`, {}, true);
    const orgFlags = featureFlagsResp && featureFlagsResp.feature_flags ? featureFlagsResp.feature_flags : [];

    const themeResp = await get('cms/org_theme/', { organisation_id: orgID }, true);
    const theme = themeResp && themeResp.theme ? themeResp.theme : [];

    this.setState({ orgFlags: [orgFlags], theme });
  }

    setEditing = async (index) => {
      let { editing, organisation } = this.state;
      if (editing[index]) {
        // save to db
        if (!organisation.company_name) return;
        const resp = await post('cms/organisations', { organisation }, true);
        if (resp && resp.success) {
          editing[index] = false;
          if (resp.id) this.setState({ organisation: { ...organisation, id: resp.id }, isNew: false });
        } else console.log(resp);
        if (resp.message) {
          setTimeout(() => this.setState({ showMessage: { message: resp.message, title: 'Save error' } }), 500);
        }
      } else editing[index] = true;
      this.setState({ editing });
    };

    setFileContent = async (index) => {
      let { editing, organisation, fileContent } = this.state;
      if (editing[index]) {
        // save to db
        if (!organisation.id) return;
        const resp = await post(
          `cms/organisation_config/${organisation.id}/firebase_service`,
          { value: fileContent },
          true
        );
        if (resp && resp.success) {
          editing[index] = false;
        } else console.log(resp);
        if (resp.message) {
          setTimeout(() => this.setState({ showMessage: { message: resp.message, title: 'Save error' } }), 500);
        }
      } else editing[index] = true;
      this.setState({ editing });
    };

    setFlag = (flagId, flagValue) => {
      let { orgFlags } = this.state;
      orgFlags[0].forEach((flag) => {
        if (flag.id === flagId) {
          flag.flag_value = flagValue;
        }
      });
      this.setState({ orgFlags: orgFlags });
    };

    setFeatureFlags = async (index) => {
      let { orgFlags, organisation, editing } = this.state;
      if (editing[index]) {
        // save to db
        if (!organisation.id) return;
        orgFlags[0].forEach(async (flag) => {
          const resp = await post(
            `cms/feature_flags/${flag.id}/?organisation_id=${organisation.id}`,
            { flag_value: flag.flag_value ? 'true' : false },
            true
          );
          if (resp.message) {
            setTimeout(
              () => this.setState({ showMessage: { message: resp.message, title: 'Save error' } }),
              500
            );
          }
        });
        editing[index] = false;
      } else editing[index] = true;
      this.setState({ editing });
    };

    setThemeVal = (themeId, themeVal) => {
      let theme = this.state.theme.map((item) => {
        if (item.id === themeId) {
          return {
            ...item,
            value: themeVal
          };
        }
        return item;
      });
      this.setState({ theme });
    };

    setOrgTheme = async (index) => {
      let { theme, organisation, editing } = this.state;
      if (editing[index]) {
        // save to db
        if (!organisation.id) return;
        theme.forEach(async ({ id, value }) => {
          const resp = await post(
            `cms/org_theme/${id}/?organisation_id=${organisation.id}`,
            { theme_value: value },
            true
          );
          if (resp.message) {
            setTimeout(
              () => this.setState({ showMessage: { message: resp.message, title: 'Save error' } }),
              500
            );
          }
        });
        editing[index] = false;
      } else editing[index] = true;
      this.setState({ editing });
    };

    requestSupportToken = async () => {
      const tokenRequest = await post(`cms/support/join/${this.state.organisation.id}`, {}, true);

      if (tokenRequest && tokenRequest.token) {
        // save existing token in another cookie as a backup so we can return from support mode
        setData('token_admin', getData('token', null), true);
        setData('token', tokenRequest.token, true);
        window.location = '/';
      } else {
        this.setState({
          showMessage: {
            message: `Couldn't active remote support for ${this.state.organisation.company_name}.`
          }
        });
      }
    };

    render () {
      let { orgFlags, organisation, editing, isNew, supportActive, fileContent, theme } = this.state;

      return (
        <div className="maincontainer">
          <BackRow onBack={this.props.onBack} />

          <Card
            title="Organisation information"
            editing={editing[0] ? '1' : undefined}
            onToggle={() => this.setEditing(0)}
          >
            {organisation.member_count ? (
              <p className="mt-2 mb-2" style={{ color: '#717171' }}>
                            Members: {organisation.member_count}
              </p>
            ) : null}
            <EditText
              label="Organisation Name"
              editing={editing[0] ? '1' : undefined}
              onChange={(text) => this.setState({ organisation: { ...organisation, company_name: text } })}
              value={organisation.company_name}
            />
            <EditText
              className="mt-3"
              label="Points Cap"
              editing={editing[0] ? '1' : undefined}
              onChange={(text) =>
                !isNaN(text) &&
                            this.setState({ organisation: { ...organisation, points_cap: Math.round(text) } })
              }
              value={organisation.points_cap}
            />
            {isNew ? (
              <EditText
                className="mt-3"
                label="Admin login (email)"
                editing={editing[0] ? '1' : undefined}
                helpText="Add a username so that they can log in"
                onChange={(text) => this.setState({ organisation: { ...organisation, email: text } })}
                value={organisation.email}
              />
            ) : null}
            {isNew ? (
              <EditText
                className="mt-1"
                label="Admin password"
                editing={editing[0] ? '1' : undefined}
                helpText="Leave blank for magic link login"
                type="password"
                onChange={(text) => this.setState({ organisation: { ...organisation, password: text } })}
                value={organisation.password}
              />
            ) : null}
          </Card>
          <OrgTheme
            setTheme={this.setThemeVal}
            theme={theme}
            isEditing={editing[1] ? '1' : undefined}
            onToggle={() => this.setOrgTheme(1)}
            organisation={organisation.id}
          />
          <OrgFlags
            setFlag={this.setFlag}
            flags={orgFlags}
            isEditing={editing[2] ? '1' : undefined}
            onToggle={() => this.setFeatureFlags(2)}
            organisation={organisation.id}
          />

          <Card
            title="File Content"
            editing={editing[2] ? '1' : undefined}
            onToggle={() => this.setFileContent(2)}
          >
            <div className="mt-2">
              <EditTextArea
                label="Firebase Service JSON Value"
                editing={editing[2] ? '1' : undefined}
                onChange={(text) => this.setState({ fileContent: text })}
                value={fileContent}
              />
            </div>
          </Card>

          {!isNew && (
            <Card title="Support">
              <div className="mt-2">
                {supportActive ? (
                  <div>
                    <button className="btn btn-primary" onClick={this.requestSupportToken}>
                                        REMOTE SUPPORT
                    </button>
                    <br />
                    <div className="mt-2">
                                        Remote support expires{' '}
                      <b>{moment(organisation.support_window_expires_at).fromNow()}</b> (
                      {moment(organisation.support_window_expires_at).format(
                        'dddd, MMMM Do YYYY, h:mm:ssa'
                      )}
                                        )
                    </div>
                  </div>
                ) : (
                  <p>Organisation has not activated remote support.</p>
                )}
              </div>
            </Card>
          )}

          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
