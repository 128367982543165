import React, { useState } from 'react';
import { get, post, dodelete, put } from 'lib/comms';
import { EditDropDown, EditText } from 'components/EditText/EditText';
import { InputDialog, DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import '../OnboardingPage.css';

export default class OnboardingWorkflow extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      onboardings: [],
      showMessage: null,
      addingOnboarding: false,
      editingOnboarding: false,
      editingOnboardingName: '',
      deleting: false
    };
    this.onSelectOnboarding = this.props.onSelectOnboarding;
  }

    componentDidMount = () => this.reload(this.props.onboardingId);

    setErrorMessage = (title = 'Error', message) => {
      this.setState({
        showMessage: {
          title,
          message
        }
      });
    };

    reload = async (slctdOnboardingId) => {
      let resp = await get('cms/onboarding', null, true);
      if (resp && resp.onboardings) {
        this.setState({ onboardings: resp.onboardings });
        if (slctdOnboardingId) {
          this.onSelectOnboarding(slctdOnboardingId);
        } else {
          const [defaultOnboarding] = resp.onboardings.filter((item) => item.is_default);
          this.onSelectOnboarding(defaultOnboarding ? defaultOnboarding.id : '');
        }
      }
    };

    deleteOnboarding = async () => {
      const deleting = this.state.onboardings.find((item) => item.id === this.props.onboardingId);
      if (!deleting) return this.setErrorMessage("Something went wrong, can't find the onboarding to delete.");

      if (deleting.is_default) return this.setErrorMessage('Delete', "You can't delete the default onboarding.");

      const resp = await dodelete(`cms/onboarding/${deleting.id}`, {}, true);
      if (resp && resp.success) {
        await this.reload();
      } else {
        this.setErrorMessage('Delete', resp.message);
      }
    };

    updateOnboarding = async (name, isDefault) => {
      const { onboardingId } = this.props;
      const resp = await put(`cms/onboarding/${onboardingId}`, { name, is_default: isDefault }, true);
      if (resp && resp.success) {
        await this.reload(onboardingId);
      } else {
        this.setErrorMessage('Error', resp.message);
      }
    };

    addNewOnboarding = async (name) => {
      if (name) {
        const resp = await post('cms/onboarding', { name }, true);
        if (resp && resp.success) {
          const onboardingId = resp.onboarding_id || '';
          this.setState({
            onboardings: [
              ...this.state.onboardings,
              {
                id: onboardingId,
                name
              }
            ],
            addingOnboarding: false
          });
          this.onSelectOnboarding(onboardingId);
        } else {
          this.setState({
            addingOnboarding: false,
            showMessage: {
              title: 'Unable to Add Onboarding',
              message: resp.message
            }
          });
        }
      }
    };
    render () {
      const { onboardings, showMessage, addingOnboarding, editingOnboarding, editingOnboardingName } = this.state;
      const { onboardingId } = this.props;
      const options = onboardings.map((item) => ({
        id: item.name,
        name: item.name + (item.is_default ? ' (default)' : '')
      }));
      const thisOnboarding = onboardings.find((item) => item.id === onboardingId) || {};
      const deleteDialog = this.state.deleting ? (
        <DeleteDialog
          item="Onboarding"
          onSuccess={() => {
            this.setState({
              deleting: false
            });
            this.deleteOnboarding();
          }}
          onClose={() =>
            this.setState({
              deleting: false
            })
          }
        />
      ) : null;

      return (
        <div>
          <div className="onboarding-selection">
            <div className="onboard-select">
              <div style={{ display: 'inline' }}>
                <EditDropDown
                  label="Select Onboarding"
                  options={options}
                  editing={true}
                  onChange={(slctdName) => {
                    const slctdItem = this.state.onboardings.find((item) => item.name === slctdName);
                    this.onSelectOnboarding(slctdItem.id);
                  }}
                  value={thisOnboarding.name}
                  style={{
                    width: 400,
                    backgroundColor: '#FFF',
                    marginRight: 10,
                    height: 50
                  }}
                />
              </div>
              <button
                onClick={() => this.setState({ deleting: true })}
                className="card-edit text-button"
                style={{ color: thisOnboarding.default ? '#999' : null }}
              >
                            Delete
              </button>{' '}
                        |{' '}
              <button
                onClick={() => this.updateOnboarding(thisOnboarding.name, true)}
                className="card-edit text-button"
                style={{ color: thisOnboarding.is_default ? '#999' : null }}
              >
                            Set Default
              </button>
            </div>
            <div>
              <button
                onClick={() => this.setState({ addingOnboarding: true })}
                className="card-edit text-button onboarding-create"
              >
                            Create New Onboarding
              </button>
              {addingOnboarding ? (
                <InputDialog
                  outerClassName="mt-3 mb-4 w-100 d-flex flex-column"
                  title="Create New Onboarding"
                  hint="Onboarding Name"
                  buttonText="CREATE"
                  maxLength={40}
                  onClose={() =>
                    this.setState({
                      addingOnboarding: false
                    })
                  }
                  onEnterEmpty={() => {
                    this.setState({
                      showMessage: {
                        title: 'Unable to Add',
                        message: 'Please enter a name for this onboarding'
                      },
                      addingOnboarding: false
                    });
                  }}
                  onSuccess={this.addNewOnboarding}
                />
              ) : null}
            </div>
            {showMessage ? (
              <MessageDialog
                message={showMessage.message}
                title={showMessage.title}
                onClose={() =>
                  this.setState({
                    showMessage: null
                  })
                }
              />
            ) : null}
          </div>
          <hr />
          <div className="d-flex"></div>
          <div className="d-flex align-items-center">
            {editingOnboarding ? (
              <EditText
                outerClassName="w-50 d-flex flex-column"
                label="Onboarding name"
                maxLength={40}
                onChange={(txt) =>
                  this.setState({
                    editingOnboardingName: txt
                  })
                }
                editing={true}
                value={editingOnboardingName}
              />
            ) : (
              <h3>{`${thisOnboarding.name}`}</h3>
            )}
            <button
              onClick={() => {
                if (!editingOnboarding) {
                  this.setState({
                    editingOnboarding: true,
                    editingOnboardingName: thisOnboarding.name
                  });
                } else {
                  if (editingOnboardingName && editingOnboardingName !== thisOnboarding.name) {
                    this.updateOnboarding(editingOnboardingName, thisOnboarding.is_default);
                  }
                  if (!editingOnboardingName) {
                    return this.setErrorMessage('Error', 'Please enter onboarding name');
                  }
                  this.setState({
                    editingOnboarding: false,
                    editingOnboardingName: ''
                  });
                }
              }}
              className="ml-2 card-edit text-button"
            >
              {editingOnboarding ? 'Ok' : 'Rename'}
            </button>
          </div>
          {deleteDialog}
        </div>
      );
    }
}
