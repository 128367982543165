import React from 'react'
import BackRow from 'components/BackRow/BackRow'
import './Campaign.css'
import CampaignsService from 'services/CampaignsService'
import moment from 'moment'
import Card from 'components/Card/Card'
import ReportItem from './components/CampaignReports/ReportItem'
import CampaignInfo from './components/CampaignInfo'
import { exportCampaignReport } from './components/CampaignReports/ExportReport'
import SmallLoader from 'components/SmallLoader/SmallLoader'

export default class CampaignReports extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: [],
      loading: false
    }
  }

  componentDidMount () {
    this.setState({
      loading: true
    })
    this.reload()
  }

  reload = async () => {
    let content = await CampaignsService.getContent(
      this.props.campaign.id,
      true
    )
    content = content.map(item => ({ ...item, expand: false }))
    this.setState({ content, loading: false })
  }

  onClickExpandRow = slctdIndex => {
    let content = this.state.content
    content = content.map((item, index) => ({
      ...item,
      expand: index === slctdIndex ? !item.expand : false
    }))
    this.setState({ content })
  }

  onClickExportReport = () => {
    exportCampaignReport(this.props.campaign, this.state.content)
  }

  updateState = (key, val) => {
    this.setState({
      [key]: val
    })
  }

  render () {
    const { content, loading } = this.state

    return (
      <div className='maincontainer'>
        <BackRow
          backTitle='Back to Campaign Dashboard'
          onBack={this.props.onBack}
        />
        {loading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              marginTop: 100,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <SmallLoader />
          </div>
        ) : (
          <>
            <CampaignInfo
              campaign={this.props.campaign}
              onClickExportReport={this.onClickExportReport}
            />

            {content ? (
              <Card title='Campaign Content' bodyStyle={{ padding: 0 }}>
                {content.map((item, index) => {
                  return (
                    <ReportItem
                      item={item}
                      index={index}
                      onClickExpandRow={this.onClickExpandRow}
                    />
                  )
                })}
              </Card>
            ) : null}
          </>
        )}
      </div>
    )
  }
}
