class FeatureFlagService {
    convertFeatureFlagsToObject = (featureFlags) => {
      const flagObject = {};
      featureFlags.forEach((item) => {
        flagObject[item.id] = item.flag_value;
      });
      return flagObject;
    };
}

export default new FeatureFlagService();
