import React from 'react';

export const Colors = [
  { id: '#0274a7', name: '-' },
  { id: '#855FF6', name: 'Purple' },
  { id: '#69B9F0', name: 'Blue' },
  { id: '#FAAA65', name: 'Orange' },
  { id: '#67DBAA', name: 'Green' },
  { id: '#F6D45F', name: 'Yellow' },
  { id: '#FF8BFA', name: 'Pink' }
];

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const ENTITY = {
  learning: 'Micro-Learning',
  challenges: 'Challenge',
  notices: 'Notice',
  polls: 'Poll',
  knowledgebase: 'Knowledgebase'
};

export const Filters = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'draft',
    label: 'Draft'
  },
  {
    value: 'ended',
    label: 'Ended'
  },
  {
    value: 'upcoming',
    label: 'Upcoming'
  },
  {
    value: 'active',
    label: 'Active'
  }
];

export const CAMPAIGN_TOOLTIP_DATA = [
  {
    target: '.searchrow-btn',
    title: 'Create a campaign',
    content:
            'Campaigns are great to schedule content to be published automatically over a set period of time for a specific group within Engage4. To create a new campaign select the Add new campaign button.'
  },
  {
    target: '.table-outer',
    title: 'Your Campaigns',
    content:
            'You will be able to view all your campaigns from this table view or you can switch to calendar view to view when campaigns are running over the month. You can select a campaign from this list to view detailed information about the campaign and to edit information.'
  },
  {
    target: '.templates',
    title: 'Creating and accessing templates',
    content:
            'When you create campaigns you can save them as a template to reuse in future.  All your templates will be accessible through this table or when you select add new campaign you can choose to create from template.'
  }
];

export const getTimeToPushData = () => {
  let timeToPush = [];
  for (let index = 0; index < 24; index++) {
    timeToPush.push(`${index > 9 ? '' : 0}${index}:00`);
  }
  return timeToPush;
};

export const TemplateColumns = [
  { name: 'Id', selector: 'id', grow: 0, sortable: false, hide: 4096 },
  {
    name: 'Campaign Name',
    selector: 'name',
    sortable: true,
    grow: 2,
    cell: (row, index, column, id) => {
      return (
        <p
          className="m-2"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 170
          }}
        >
          {row.name}
        </p>
      );
    }
  },
  { name: 'Length', selector: 'length', sortable: true, grow: 1, wrap: true },
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    grow: 3,
    cell: (row, index, column, id) => {
      return (
        <p
          className="m-2"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 380
          }}
        >
          {row.description}
        </p>
      );
    }
  }
];
