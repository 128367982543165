import React from 'react';
import Card from 'components/Card/Card';
import '../BillingPage.css';

const PlanDetails = ({ onClickCancelPlan, onReactivatePlan, scheduledToCancel, cancelled }) => {
  return (
    <Card outerClass="plan-detail-cont card-small ml-3" title="Your Plan">
      <p className="plan-details" style={{ fontWeight: 600 }}>
                Professional Plan
      </p>
      <p className="plan-details">£2.00 per user per month</p>
      {!cancelled ? (
        <div
          className={`cancel-plan ${scheduledToCancel ? 'reactivate-plan' : ''}`}
          onClick={scheduledToCancel ? onReactivatePlan : onClickCancelPlan}
        >
          {scheduledToCancel ? 'Reactivate' : 'Cancel'}
        </div>
      ) : null}
    </Card>
  );
};

export default PlanDetails;
