import React, { useEffect, useState } from 'react';
import { Dialog } from 'components/Dialog/Dialog';
import 'components/Dialog/Dialog.css';
import '../Campaign.css';
import CampaignsService from 'services/CampaignsService';
import { EditText, EditDropDown, EditTextArea, EditDate } from 'components/EditText/EditText';
import EditCheckBox from 'components/EditCheckBox/EditCheckBox';
import moment from 'moment';
import { Colors } from '../CampaignData';

const CampaignDialog = (props) => {
  const { updateState, isEdit, campaign = {}, addCampaignFromTemplateId } = props;
  const minStartDate = new Date();
  minStartDate.setDate(minStartDate.getDate() + 1);
  const [groups, setGroups] = useState([]);
  const [slctdGroup, setSlctdGroup] = useState(campaign.group_id);
  const [name, setName] = useState(campaign.name || '');
  const [description, setDescription] = useState(campaign.description || '');
  const [color, setColor] = useState(campaign.color || '#0274a7');
  const [isTemplate, setIsTemplate] = useState(campaign.is_template || false);
  const [autoStart, setAutoStart] = useState(campaign.auto_start || false);
  const [startDate, setStartDate] = useState(campaign.start_date || null);

  const closeDialog = () => {
    updateState(isEdit ? 'editCampaign' : 'addNewCampaign', false);
    updateState('addCampaignFromTemplateId', false);
  };
  useEffect(() => {
    const fetchData = async () => {
      let groups = await CampaignsService.getGroupsList();
      setGroups(groups);
      if (campaign && campaign.group_id) setSlctdGroup(campaign.group_id);
      else if (groups[0]) setSlctdGroup(groups[0].id);
    };
    fetchData();
  }, []);

  const onAddOrEditCampaign = async () => {
    const campaignParams = {
      id: campaign.id,
      color,
      auto_start: autoStart,
      description,
      name,
      group_id: slctdGroup,
      is_template: isTemplate,
      no_of_days: campaign.no_of_days || 7,
      status: autoStart ? 'upcoming' : 'draft',
      start_date: autoStart
        ? startDate
          ? moment(startDate).format('YYYY-MM-DD')
          : moment(minStartDate).format('YYYY-MM-DD')
        : null,
      create_from_template_id: addCampaignFromTemplateId,
      template_id: campaign.template_id
    };
    isEdit && updateState('campaign', campaignParams);
    const resp = await CampaignsService.createOrEditCampaign(campaignParams);
    if (resp.id) {
      updateState('editing', { ...campaignParams, id: resp.id });
    }
    if (resp && resp.success) {
        const content = await CampaignsService.getContent(campaign.id,false);
        content.map((item) => {
          CampaignsService.addOrEditContent(campaignParams, item);
        });
    }
  };

  return (
    <Dialog
      title={`${isEdit ? 'Edit Campaign' : 'Create New Campaign'}`}
      btnTitle={`${isEdit ? 'SAVE' : 'ADD CAMPAIGN'}`}
      onClose={closeDialog}
      onConfirm={() => {
        closeDialog();
        onAddOrEditCampaign();
      }}
      disabled={!name || !description || !color || !slctdGroup}
      style={{ marginTop: 80 }}
    >
      <EditDropDown
        className="mt-3"
        label="Group  "
        options={groups}
        editing={true}
        value={slctdGroup}
        onChange={(id) => setSlctdGroup(id)}
      />
      <EditText
        outerClassName="w-100"
        label="Title  "
        editing={true}
        onChange={(text) => setName(text)}
        value={name}
        maxLength={50}
      />
      <EditTextArea
        height="100px"
        outerClassName="w-100"
        label="Description"
        editing={true}
        onChange={(text) => setDescription(text)}
        value={description}
        maxLength={300}
      />
      <div className="d-flex w-100 flex-1">
        <EditCheckBox
          className="mt-1"
          editing={true}
          title={'Save as template'}
          onClick={() => setIsTemplate(!isTemplate)}
          isChecked={isTemplate}
          desc={''}
          iconSize="sm"
        />
        <EditCheckBox
          className="mt-1"
          style={{ marginLeft: 120 }}
          editing={true}
          title={'Start campaign automatically'}
          onClick={() => setAutoStart(!autoStart)}
          isChecked={autoStart}
          desc={''}
          iconSize="sm"
        />
      </div>
      <div className="mt-2 d-flex flex-1" style={{ alignSelf: 'flex-start', width: '70%' }}>
        <EditDropDown
          style={{ marginRight: 80 }}
          label="Color  "
          options={Colors}
          editing={true}
          value={color}
          onChange={(item) => setColor(item)}
        />

        {autoStart ? (
          <EditDate
            editing={true}
            iconSize="xs"
            dateFormat="dd/MM/yyyy"
            label="Start Date"
            onChange={(date) => setStartDate(date)}
            minDate={minStartDate}
            value={startDate || minStartDate}
          />
        ) : (
          <div style={{ flex: 1 }} />
        )}
      </div>
    </Dialog>
  );
};

export default CampaignDialog;
