import { get, post } from 'lib/comms';

class ToolTipService {
    getToolTipStatusForScreen = async (screenId) => {
      const resp = await get(`cms/tooltip_status/${screenId}`, null, true);
      if (resp) {
        return resp.show_tooltip;
      }
      return false;
    };

    updateToolTipStatusForScreen = async (screenId) => {
      await post(`cms/tooltip_status/${screenId}`, {}, true);
    };
}

export default new ToolTipService();
