import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { get, post } from '../../../../lib/comms';
import Loader from '../../../../components/Loader/Loader';
import SmallLoader from '../../../../components/SmallLoader/SmallLoader';
import Card from '../../../../components/Card/Card';
import Table from 'components/Table/Table';
import { Button } from 'reactstrap';
import ShowMoreText from 'react-show-more-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import ToolTip from 'components/ToolTip/TooltTip';

const columns = [
  { name: 'Id', selector: 'id', grow: 0, sortable: false, hide: 4096 },
  { name: 'Reported By', selector: 'reported_by', sortable: true, grow: 1, wrap: true },
  { name: 'Reason', selector: 'reason', sortable: true, wrap: true, grow: 1 },
  { name: 'Details', selector: 'details', sortable: false, grow: 1, wrap: true },
  { name: 'Reported Post', selector: 'reported_post', sortable: true, grow: 1, wrap: true },
  { name: 'Ttl Reports', selector: 'ttl_reports', sortable: true, grow: 1, wrap: true, width: '80px', center: true },
  { name: '', selector: 'action', sortable: false, grow: 1, wrap: true, width: '200px', center: true }
];

const TabsViewer = ({ tabs, activeTabId, onTabClick }) => {
  return (
    <div>
      <div style={styles.tabsContainer}>
        {tabs.map((tab) => (
          <div style={styles.tabContainer}>
            <div
              className={activeTabId === tab.id ? 'text-tab-active' : 'text-tab'}
              onClick={() => {
                onTabClick(tab.id);
              }}
            >
              {tab.value}
            </div>
          </div>
        ))}
      </div>
      <div style={styles.tabsBottomContainer} />
    </div>
  );
};

const ReportsPage = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [status, setStatus] = useState('READY'); // LOADING, READY, ERROR
  const [currentTabId, setCurrentTabId] = useState('ACTIVE'); // ACTIVE, ARCHIVED
  const [reports, setReports] = useState([]);
  const [singlePostIdFilter, setSinglePostIdFilter] = useState(null);

  const getReports = async () => {
    const reportReasons = await getReportReasons();

    const response = await get('cms/reports', {}, true);
    setReports(
      response.reports.map((item) => {
        const reportReasonIndex = reportReasons.findIndex(
          (reportReason) => reportReason.id === item.reason_type
        );
        const details = reportReasons[reportReasonIndex].report_type_text;

        let status;
        let action;
        let ttl_reports = (
          <div onClick={() => setSinglePostIdFilter(item.post_id)} style={styles.ttlReportsContainer}>
            {item.total > 1 ? (
              <div style={styles.ttlMultipleReports}>{item.total}</div>
            ) : (
              <div style={styles.ttlSingleReports}>{item.total}</div>
            )}
          </div>
        );

        if (item.is_deleted === 0 || item.is_deleted === 1) {
          status = 'ARCHIVED';
          action = <div></div>;
        }

        if (item.is_deleted === null) {
          status = 'ACTIVE';
          action = (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Button
                color="link"
                onClick={async () => {
                  await actionReport(item.id);
                }}
                style={styles.removePostButton}
              >
                <FontAwesomeIcon icon={faTimesCircle} /> Remove Post
              </Button>
              <Button
                color="link"
                onClick={async () => {
                  await archiveReport(item.id);
                }}
                style={styles.archivePostButton}
              >
                                Archive Report
              </Button>
            </div>
          );
        }

        return {
          id: item.id,
          reason: <b>{details}</b>,
          reported_by: (
            <div style={{ flexDirection: 'column' }}>
              <Button color="link" style={styles.userButton}>
                <Link
                  to={`/users?user_id=${item.user_id}`}
                >{`${item.user_first_name} ${item.user_last_name}`}</Link>
              </Button>
              <div style={styles.reportDatetime}>
                {moment(item.created_at).format('DD/MM/YYYY kk:mm')}
              </div>
            </div>
          ),
          details: (
            <div>
              <ShowMoreText lines={3} more="View More" less="View Less" expanded={false} width={0}>
                {item.reason || ''}
              </ShowMoreText>
            </div>
          ),
          post_id: item.post_id,
          reported_post: (
            <div>
              <ShowMoreText lines={3} more="View More" less="View Less" expanded={false} width={0}>
                {item.post_contents}
              </ShowMoreText>
            </div>
          ),
          ttl_reports,
          action,
          status
        };
      })
    );
    return response;
  };

  const actionReport = async (reportId) => {
    const response = await post(`cms/reports/${reportId}/action`, {}, true);
    getReports();
    return response.success;
  };

  const archiveReport = async (reportId) => {
    const response = await post(`cms/reports/${reportId}/archive`, {}, true);
    getReports();
    return response.status;
  };

  const getReportReasons = async () => {
    const response = await get('/content/report_reasons', {}, true);
    if (response.success) {
      return response.reasons;
    }
    return [];
  };

  useEffect(() => {
    (async () => {
      getReports();
    })();
  }, []);

  return (
    <div className="maincontainer">
      <ToolTip
        callback={(tooltipData) => {
          if (tooltipData.lifecycle === 'complete') {
            setStepIndex(tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1);

            stepIndex === 0 && tooltipData.action === 'next'
              ? setStepIndex(stepIndex + 1) && setCurrentTabId('ARCHIVED')
              : setCurrentTabId('ACTIVE');

            stepIndex === 1 && tooltipData.action === 'prev'
              ? setStepIndex(stepIndex - 1) && setCurrentTabId('ACTIVE')
              : setCurrentTabId('ARCHIVED');
          }
        }}
        screenId={'reports'}
        steps={[
          {
            target: '.maincontainer',
            title: 'Reported Posts',
            content:
                            'If a post is reported by another user you will receive an email notification for you to review and action the report. From this screen you can see the details of the report such as the user who reported the post, the date and time, the reason and details of the report, the post and the total number of times this post was reported. You then have two options. The first will be to remove the post. This will permanently delete the post and any comments. The user who reported the post and who created the post will receive a notification to let them know the post has been removed. The second option is to archive the report which will leave not make any changes to the post itself and the user who reported the post will be notified that an administrator has reviewed the report.',
            placement: 'auto'
          },
          {
            target: '.table-outer',
            title: 'Viewing archived reported posts',
            content: 'You can also view a record of all archived reported posts.',
            placement: 'auto'
          }
        ]}
        stepIndex={stepIndex}
      />
      <SubscriptionBanner />
      <h4>Reported Posts</h4>

      {status === 'LOADING' && <SmallLoader color="#0F2B41" />}
      {status === 'READY' && (
        <div>
          {singlePostIdFilter === null ? (
            <div style={styles.tabsViewerContainer}>
              <TabsViewer
                tabs={[
                  { value: 'Active', id: 'ACTIVE' },
                  { value: 'Archived', id: 'ARCHIVED' }
                ]}
                activeTabId={currentTabId}
                onTabClick={(currentTabId) => {
                  setCurrentTabId(currentTabId);
                  getReports(); // to keep it fresh
                }}
              />
            </div>
          ) : (
            <div style={styles.tabsViewerContainer}>
              <Button color="primary" outline onClick={() => setSinglePostIdFilter(null)}>
                                Back
              </Button>
            </div>
          )}
          <div style={styles.separator} />
          <Table
            columns={columns}
            data={reports
              .filter((item, index) => {
                if (singlePostIdFilter) {
                  // if a post has more reports, when we expand the report we see only the related posts
                  return item.post_id === singlePostIdFilter;
                } else {
                  // if all the reports should be shows, even when a post has more reports, only one report per post will be shown
                  return index === reports.findIndex((i) => i.post_id === item.post_id);
                }
              })
              .map((item) => {
                if (singlePostIdFilter) {
                  // if a single post is expanded we want to remove the TTL button (it would be useless)
                  return {
                    ...item,
                    ttl_reports: null
                  };
                } else {
                  return item;
                }
              })
              .filter((item) => item.status === currentTabId) // filter between ACTIVE and ARCHIVED
              .sort((a, b) => a.timestamp - b.timestamp)}
            tableID="reports"
            defaultSortField={null}
          />
        </div>
      )}
      {status === 'ERROR' && <div>Error</div>}
    </div>
  );
};

const styles = {
  removePostButton: {
    fontWeight: 'normal',
    color: 'red',
    fontSize: 16
  },
  archivePostButton: {
    fontWeight: 'normal',
    fontSize: 16
  },
  userButton: {
    fontWeight: 'normal',
    fontSize: 16,
    paddingLeft: 20,
    paddingBottom: 0
  },
  reportDatetime: {
    paddingLeft: 20
  },
  tabsContainer: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex'
  },
  tabContainer: {
    paddignRight: 16,
    paddingLeft: 16
  },
  tabsBottomContainer: {
    width: '100%',
    backgroundColor: 'rgb(205, 205, 205)',
    height: 1,
    marginTop: 5
  },
  tabsViewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(203, 203, 203)'
  },
  ttlReportsContainer: {
    width: 40,
    height: 40,
    backgroundColor: 'rgb(20, 97, 152)',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  ttlMultipleReports: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'white'
  },
  ttlSingleReports: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'white'
  }
};

export default ReportsPage;
