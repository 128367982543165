import React from 'react';
import { MessageDialog } from 'components/Dialog/Dialog';

export default class EditBrowse extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showMessage: null
    };
  }

    fileChange = (e) => {
      const acceptedFile = e.target.files;
      if (acceptedFile.length > 0) {
        const [{ size }] = acceptedFile;
        if (size > 2000000) {
          return this.setState({
            showMessage: {
              title: 'Message',
              message: 'you can upload maximum of 2MB file'
            }
          });
        }
        this.props.onFileChange(acceptedFile[0]);
      }
    };

    render () {
      const classNameOuter = `${
        this.props.className || ''
      } mdc-text-field edittext-outer mt-3 mb-3 mdc-text-field--outlined ${
        this.props.editing ? 'editing' : 'notediting'
      }`;

      const classNameButton = `editBrowse-button ${this.props.editing ? 'editing' : 'notediting'}`;
      const { fileName } = this.props;
      return (
        <div className={classNameOuter} style={{ marginLeft: this.props.marginLeft || '0' }}>
          <div className="editBrowse-inner">
            <label className={classNameButton}>
                        Browse
              {this.props.editing ? (
                <input type="file" onChange={this.fileChange} accept="application/pdf" />
              ) : null}
            </label>
            <div className="editBrowse-fileName-container">
              <input
                className="editBrowse-fileName-title"
                style={{ opacity: this.props.editing ? 1 : 0.37 }}
                type="text"
                value={fileName}
                readonly
              ></input>
            </div>
          </div>
          <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
            <div className="mdc-notched-outline__leading"></div>
            <div className="mdc-notched-outline__trailing"></div>
          </div>
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
