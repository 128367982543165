import React from 'react';
import { getBlob } from 'lib/comms';
import placeholder from 'assets/placeholder.png';
import loadingImage from 'assets/loading_image.png';

export default class extends React.Component {
    state = { src: '', status: 'LOADING' };

    componentDidMount = () => this.loadImage(this.props.url);

    loadImage = async (url) => {
      try {
        const data = await getBlob(url);
        this.setState({
          src: URL.createObjectURL(data),
          status: 'READY'
        });
      } catch (error) {
        this.setState({
          status: 'ERROR'
        });
      }
    };

    shouldComponentUpdate = async (nextProps) => {
      if (nextProps.url !== this.props.url) this.loadImage(nextProps.url);
      return true;
    };

    render = () => {
      const { url, alt = '', ...otherProps } = this.props;

      let image = this.state.src || placeholder;

      if (this.state.status === 'LOADING') {
        image = loadingImage;
      } else if (this.state.status === 'ERROR') {
        image = placeholder;
      }

      return <img src={image} {...otherProps} alt={alt} />;
      // return this.state.src ? <img src={this.state.src} {...otherProps} alt={alt}/> : <div {...otherProps}/>;
    };
}
