import React from 'react';
import Card from 'components/Card/Card';

const UpcomingBill = ({ upcomingBill }) => {
  const { amount_due, currency, next_payment_attempt } = upcomingBill || {};

  return (
    <Card outerClass="upcoming-bill card-small" title="Next Bill Estimate">
      <p className="billing-amount">{`${currency === 'gbp' ? '£' : ''}${amount_due || '£0'}`}</p>
      {next_payment_attempt && (
        <span className="billing-due-date">
          {'Due: '}
          <span style={{ fontWeight: 600 }}>{next_payment_attempt}</span>
        </span>
      )}
    </Card>
  );
};

export default UpcomingBill;
