import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { EditText } from 'components/EditText/EditText';
import { get, post, dodelete, put, upload } from 'lib/comms';
import { DeleteDialog, MessageDialog, VideoUploadDialog } from 'components/Dialog/Dialog';
import SwitchBar from 'components/SwitchBar/SwitchBar';
import './KnowledgebasePage.css';
import KnowldgebaseDetailsItem from './KnowledgebaseItem/KnowledgebaseDetailsItem';
import KnowldgebaseFileItem from './KnowledgebaseItem/KnowledgebaseFileItem';
import KnowldgebaseInfoItem, { VideoOrImage } from './KnowledgebaseItem/KnowledgebaseInfoItem';
import KnowldgebaseItemType from './KnowledgebaseItem/KnowledgebaseItemType';

import isEmail from 'validator/lib/isEmail';

import * as S from './KnowledgebaseItem/KnowledgebaseItem.styled';
import KnowldgebaseCategory from './KnowledgebaseItem/KnowldgebaseItemCategory';
import 'react-quill/dist/quill.snow.css';

const TYPES = [
  {
    id: 'INFO',
    name: 'Information',
    description: 'Long form content with optional images and files'
  },
  {
    id: 'FILE',
    name: 'File',
    description: 'A single file to be highlighted e.g. handbooks, policies'
  },
  {
    id: 'DETAILS',
    name: 'Details',
    description: 'Key info like important contact info and external websites'
  }
];

export default class KnowldgebaseItem extends React.Component {
  constructor (props) {
    super(props);

    this.fileInputRef = React.createRef();
    const isNew = props.knowledgebaseItem && props.knowledgebaseItem.id === 'new';
    this.state = {
      title: '',
      description: '',
      type: 'INFO',
      active: 1,
      details: [],
      files: [],
      videoUrls: {},
      isNew,
      isEditing: isNew,
      ...props.knowledgebaseItem,
      categories: [],
      video_id: '',
      isImageSet: false,
      isVideoSet: false,
      mediaFile: null,
      imageBlobUrl: '',
      videoName: '',
      isTextareaFocused: false,
      mediaType: '',
      videoBlobUrl: '',
      uploadingVideo: false
    };
  }

    // refresh the item
    reload = async () => {
      if (!this.state.isNew) {
        let resp = await get(`cms/knowledgebase_item_detail/${this.state.id}`, null, true);
        if (resp && resp.status === 401) this.setState({ logout: true });
        if (resp && resp.item) {
          const itemCategories = resp.item.categories ? resp.item.categories.map((category) => category.id) : [];
          const videoUrls = resp.item.video_urls;
          this.setState({
            ...resp.item,
            categories: itemCategories,
            isVideoSet: !!resp.item.video_id,
            videoUrls: videoUrls
          });
        } else {
          console.log('error retreiving knowledgebase item');
        }
      }
    };

    // update item on change of any item params
    updateKnowledgebaseItem = (item) => {
      this.setState({
        ...item
      });
    };

    componentDidMount = () => this.reload();

    // update state on change title
    setTitle = (title) => {
      this.setState({ title });
    };

    _onManageAttachmentClick = () => {
      this.fileInputRef.current.click();
    };

    // update item view on change of knowledgebase type
    selectType = (type) =>
      this.setState({
        type,
        files: [],
        details: []
      });

    // on toggle edit/save
    toggleEditingStatus = () => {
      const { isEditing, videoBlobUrl } = this.state;
      if (isEditing) {
        const isValid = this.validateKnowledgebaseItem();
        if (isValid) {
          if (videoBlobUrl) this.uploadVideoToCloudflare();
          else {
            this.setState({ isEditing: !isEditing });
            this.saveKnowledgebaseItem();
          }
        }
      } else {
        this.setState({ isEditing: !isEditing });
      }
    };

    // on Select category for item
    onSelectCategory = (slctdCategory) => {
      let itemCategories = this.state.categories;
      const catIndex = itemCategories.indexOf(slctdCategory);
      if (catIndex !== -1) {
        itemCategories.splice(catIndex, 1);
      } else {
        itemCategories.push(slctdCategory);
      }
      this.setState({ categories: itemCategories });
    };

    // on add video to knowledgebsae item
    onAddVideo = () => {
      this.setState({
        isImageSet: false,
        isVideoSet: true
      });
    };

    // on add image to item
    onAddImage = () => {
      this.setState({
        isImageSet: true,
        isVideoSet: false
      });
    };

    removeVideo = () => {
      this.setState({
        mediaType: '',
        mediaFile: null,
        imageBlobUrl: '',
        videoName: '',
        videoBlobUrl: '',
        isVideoSet: false
      });
    };

    removeImage = () => {
      this.setState({
        mediaType: '',
        mediaFile: null,
        imageBlobUrl: '',
        videoName: '',
        videoBlobUrl: '',
        isImageSet: false
      });
    };

    // upload files item to backend before update/create of  knowledgebase item
    uploadFiles = async () => {
      const { files } = this.state;
      let fileIds = [];
      const fileCount = files.length;
      for (let index = 0; index < fileCount; index++) {
        const fileItem = files[index];
        if (!fileItem.id) {
          const fileResp = await upload(
            `cms/upload_file/${fileItem.title}/knowledgebase`,
            'file',
            fileItem.file,
            true
          );
          if (fileResp && fileResp['file_id']) {
            fileIds.push(fileResp['file_id']);
          }
        } else {
          fileIds.push(fileItem.id);
        }
      }
      return fileIds;
    };

    // create or update knowledgebase item to backend

    uploadVideoToCloudflare = async () => {
      let video_cloudflare_id;
      let { videoBlobUrl, mediaFile, isEditing } = this.state;
      if (videoBlobUrl) {
        const abortController = new AbortController();
        this.setState({
          uploadingVideo: true,
          abortController
        });
        const videoResp = await post('cms/video', {}, true);
        if (videoResp && videoResp.success) {
          try {
            const uploadURL = videoResp.data.uploadURL;
            video_cloudflare_id = videoResp.data.uid;
            const formData = new FormData();
            formData.append('file', mediaFile);
            const uploadResp = await fetch(uploadURL, {
              method: 'POST',
              body: formData,
              signal: abortController.signal
            });
            if (uploadResp && uploadResp.ok) {
              // this.timerId = setTimeout(() => {
              this.setState({ isEditing: !isEditing, uploadingVideo: false });
              this.saveKnowledgebaseItem(video_cloudflare_id);
              // }, 10000)
            } else {
              alert('error uploading video');
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
      return video_cloudflare_id;
    };
    saveKnowledgebaseItem = async (videoCloudflareId) => {
      let knowledgebaseItemId = this.state.id;
      let resp = null;
      const { title, description, active, type, categories, details, image_id, video_id, videoBlobUrl, mediaFile } =
            this.state;
      const knowledgebaseItem = {
        title,
        description,
        active,
        type,
        categories,
        details,
        videoBlobUrl,
        video_id,
        image_id,
        mediaFile
      };
      const fileIds = await this.uploadFiles();
      knowledgebaseItem['file_ids'] = fileIds;
      knowledgebaseItem['video_cloudflare_id'] = videoCloudflareId;
      if (this.state.isNew) {
        resp = await post('cms/knowledgebase_items', knowledgebaseItem, true);
      } else {
        resp = await put(`cms/knowledgebase_items/${this.state.id}`, knowledgebaseItem, true);
      }
      if (resp && resp.success) {
        this.props.updateItemCreated();
        this.setState(
          {
            isNew: false,
            isEditing: false,
            id: resp.item ? resp.item.id : knowledgebaseItemId
          },
          this.reload
        );
      } else {
        resp.message && this.showErrorDialog(resp.message);
      }
    };

    validateKnowledgebaseItem = () => {
      let { title, details, files, type } = this.state;
      const messageObj = {
        title: 'Save Knowledge Base',
        message: 'Please include a valid title'
      };
      let isValid = true;
      // validating title
      if (!title) {
        this.setState({
          showMessage: messageObj
        });
        isValid = false;
        return isValid;
      }

      if (files && files.length === 0 && type === 'FILE') {
        messageObj.message = 'Please upload file';
        this.setState({
          showMessage: messageObj
        });
        isValid = false;
        return isValid;
      }
      if (files) {
        const invalidFiles = files.filter((item) => !item.original_name);
        const invalidFilesNames = files.filter((item) => !item.title);
        if (invalidFiles.length > 0 || invalidFilesNames.length > 0) {
          if (invalidFiles.length > 0) {
            messageObj.message = 'Please upload valid file';
            this.setState({
              showMessage: messageObj
            });
          }
          if (invalidFilesNames.length > 0) {
            messageObj.message = 'Please enter valid filename';
            this.setState({
              showMessage: messageObj
            });
          }
          isValid = false;
          return isValid;
        }
      }
      if (details) {
        for (let index = 0; index < details.length; index++) {
          const type = details[index].type;
          const value = details[index].value;
          if (type === 'PHONE_NUMBER' && !value) {
            messageObj.message = 'Please enter valid Phone no';
            this.setState({
              showMessage: messageObj
            });
            isValid = false;
            break;
          }
          if (type === 'EMAIL_ADDRESS' && !isEmail(value)) {
            messageObj.message = 'Please enter valid email address';
            this.setState({
              showMessage: messageObj
            });
            isValid = false;
            break;
          }
          if (type === 'WEB_ADDRESS') {
            try {
              // eslint-disable-next-line no-new
              new URL(value);
            } catch (err) {
              messageObj.message =
                            'Please copy and paste the full URL, including https or http, into the web address field';
              this.setState({
                showMessage: messageObj
              });
              return false;
            }
          }
        }
        return isValid;
      }
      return isValid;
    };

    doDelete = async () => {
      const resp = await dodelete(`cms/knowledgebase_items/${this.state.id}`, {}, true);
      if (resp && resp.success) {
        this.props.onBack();
      } else if (resp.message) {
        setTimeout(
          () =>
            this.setState({
              showMessage: {
                message: resp.message,
                title: 'Delete Knowledgebase'
              }
            }),
          500
        );
      }
    };

    renderCategoryAndActiveSection = () => {
      let { isEditing, categories, active } = this.state;
      return (
        <div style={{ width: '100%' }}>
          <KnowldgebaseCategory
            itemCategories={categories}
            isEditing={isEditing}
            onSelectCategory={this.onSelectCategory}
          />
          <S.VerticalSpacer size={10} />
          <SwitchBar
            style={{ height: 24 }}
            editing={isEditing}
            options={['Active', 'Archived']}
            values={[1, 0]}
            value={active}
            onChange={(active) => this.setState({ active })}
          />
        </div>
      );
    };

    renderTitle = () => {
      let { isEditing, title, type } = this.state;
      const titleLabel = type === 'DETAILS' ? 'Details' : type === 'INFO' ? 'Info' : 'File';
      return (
        <EditText
          outerClassName="mt-3 mb-0"
          label={`Knowledgebase ${titleLabel} Title`}
          editing={isEditing}
          onChange={this.setTitle}
          value={title}
          maxLength={100}
        />
      );
    };

    // update state with error message on error
    showErrorDialog = (message) => {
      setTimeout(
        () =>
          this.setState({
            showMessage: {
              message: message,
              title: 'Save error',
              close: () => this.setState({ showMessage: null })
            }
          }),
        500
      );
    };

    render () {
      let {
        isEditing,
        isNew,
        type,
        details,
        files,
        description,
        image_id,
        video_id,
        videoUrls,
        isVideoSet,
        isImageSet
      } = this.state;

      return (
        <div className="maincontainer">
          <BackRow
            onBack={this.props.onBack}
            buttonTitle={this.state.isNew ? null : 'DELETE ITEM'}
            onDelete={() => this.setState({ showDeleteDialog: true })}
            accept="image/jpg, image/png"
          />
          <Card title="Knowledgebase Item" editing={isEditing} onToggle={this.toggleEditingStatus}>
            <div>
              <div style={localStyle.subtitle}>Select Type</div>
              <S.KnowledgebaseType>
                {TYPES.map((itemType) => (
                  <KnowldgebaseItemType
                    isEditing={isEditing && isNew}
                    name={itemType.name}
                    description={itemType.description}
                    id={itemType.id}
                    selectedTypeId={type}
                    onChange={this.selectType}
                  />
                ))}
              </S.KnowledgebaseType>
            </div>
            <div>
              {type === 'INFO' && (
                <S.MainBodyInformationType>
                  <S.MinWidthColumn>
                    <VideoOrImage
                      isEditing={isEditing}
                      videoId={video_id}
                      videoName={this.state.videoName}
                      imageId={image_id}
                      isVideoSet={isVideoSet}
                      videoUrls={videoUrls}
                      isImageSet={isImageSet}
                      updateKnowledgebaseItem={this.updateKnowledgebaseItem}
                      onAddVideo={this.onAddVideo}
                      onAddImage={this.onAddImage}
                      onFileSelect={this.onFileSelect}
                      removeVideo={this.removeVideo}
                      removeImage={this.removeImage}
                      videoBlobUrl={this.state.videoBlobUrl}
                    />
                    <S.VerticalSpacer size={20} />
                    {this.renderCategoryAndActiveSection()}
                  </S.MinWidthColumn>
                  <S.MaxWidthColumn>
                    <div style={{ width: '100%' }}>
                      {this.renderTitle()}
                      <S.VerticalSpacer size={30} />
                      <KnowldgebaseInfoItem
                        isNew={isNew}
                        isEditing={isEditing}
                        files={files}
                        updateKnowledgebaseItem={this.updateKnowledgebaseItem}
                        description={description}
                      />
                    </div>
                  </S.MaxWidthColumn>
                </S.MainBodyInformationType>
              )}

              {(type === 'DETAILS' || type === 'FILE') && (
                <S.MainBodyInformationType>
                  <S.MaxWidthColumn>
                    <div style={{ width: '100%' }}>
                      {this.renderTitle()}
                      <S.VerticalSpacer size={10} />
                      {type === 'FILE' ? (
                        <KnowldgebaseFileItem
                          isEditing={isEditing}
                          files={files}
                          updateKnowledgebaseItem={this.updateKnowledgebaseItem}
                          description={description}
                        />
                      ) : (
                        <KnowldgebaseDetailsItem
                          details={details}
                          isEditing={isEditing}
                          updateKnowledgebaseItem={this.updateKnowledgebaseItem}
                        />
                      )}
                    </div>
                  </S.MaxWidthColumn>
                  <S.MinWidthColumn>{this.renderCategoryAndActiveSection()}</S.MinWidthColumn>
                </S.MainBodyInformationType>
              )}
            </div>
          </Card>
          {this.state.showDeleteDialog ? (
            <DeleteDialog
              title="Delete Knowledgebse Item"
              item="item"
              onSuccess={this.doDelete}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
          {this.state.uploadingVideo ? (
            <VideoUploadDialog
              message={'Uploading Video'}
              title={'Message'}
              onClose={() => {
                // this.timerId && clearTimeout(this.timerId)
                this.state.abortController.abort();
                this.setState({ uploadingVideo: false });
              }}
            />
          ) : null}
        </div>
      );
    }
}

const localStyle = {
  subtitle: {
    fontSize: '15px',
    fontWeight: '400',
    letterSpacing: '-0.15px',
    color: '#000'
  },
  typeTitle: {
    fontSize: '15px',
    fontWeight: '400',
    letterSpacing: '-0.15px',
    color: '#000',
    marginLeft: 8
  },
  TypeSubtitle: {
    fontSize: '15px',
    fontWeight: '400',
    letterSpacing: '-0.15px',
    color: '#505A62',
    textAlign: 'left',
    marginLeft: 8,
    marginTop: 3
  }
};
