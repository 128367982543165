import React from 'react';
import * as dateFns from 'date-fns';
import './Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CalendarItem from './CalendarItem';

class Calendar extends React.Component {
    state = {
      currentMonth: new Date(),
      selectedDate: new Date()
    };
    renderHeader () {
      const dateFormat = 'MMMM yyyy';

      return (
        <div className="header">
          <div className="arrow" onClick={this.prevMonth}>
            <FontAwesomeIcon icon={faChevronLeft} color="#969CA1" />
          </div>
          <span className="header-month">{dateFns.format(this.state.currentMonth, dateFormat)}</span>
          <div className="arrow" onClick={this.nextMonth}>
            <FontAwesomeIcon icon={faChevronRight} color="#969CA1" />
          </div>
        </div>
      );
    }

    renderDays () {
      const dateFormat = 'EEE';
      const days = [];

      let startDate = dateFns.startOfWeek(this.state.currentMonth);

      for (let i = 0; i < 7; i++) {
        days.push(
          <div className="col days" key={i}>
            {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
          </div>
        );
      }

      return <div className="days-row row">{days}</div>;
    }

    renderCells () {
      const { currentMonth } = this.state;
      const monthStart = dateFns.startOfMonth(currentMonth);
      const monthEnd = dateFns.endOfMonth(monthStart);
      const startDate = dateFns.startOfWeek(monthStart);
      const endDate = dateFns.endOfWeek(monthEnd);

      const rows = [];

      let days = [];
      let day = startDate;
      while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
          days.push(CalendarItem(this.props.campaigns, day, monthStart, this.props.onSelectCampaign));
          day = dateFns.addDays(day, 1);
        }
        rows.push(
          <div className="row" key={day}>
            {days}
          </div>
        );
        days = [];
      }
      return <div className="body">{rows}</div>;
    }

    onDateClick = (day) => {
      this.setState({
        selectedDate: day
      });
    };

    nextMonth = () => {
      this.setState({
        currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
      });
    };

    prevMonth = () => {
      this.setState({
        currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
      });
    };

    render () {
      return (
        <div className="container">
          {this.renderHeader()}
          <div className="calendar">
            {this.renderDays()}
            {this.renderCells()}
          </div>
        </div>
      );
    }
}

export default Calendar;
