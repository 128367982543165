import React, { useEffect, useState } from 'react';
import './CreateContent.css';
import { get, post } from 'lib/comms';
import { MessageDialog, VideoUploadDialog } from 'components/Dialog/Dialog';
import { EditText } from 'components/EditText/EditText';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import NoticeForm from './NoticeForm';
import PollForm from './PollForm';
import VideoService from 'services/VideoServices';
import ContentService from 'services/ContentService';
import FeatureFlagService from 'services/FeatureFlagService';

function CreateContent (props) {
  let ContentTypes = [{ name: 'Knowledgebase', id: 'knowledgebase' }];
  const { onClose, onSave, introTitle, editingItem, isCampaignContent } = props;

  let [items, setItems] = useState([]);
  let [featureFlags, setFeatureFlags] = useState({});
  const [slctdItem, setSlctdItem] = useState({
    intro: (editingItem && (editingItem.title || editingItem.intro)) || '',
    type: (editingItem && editingItem.type) || 'knowledgebase',
    id: (editingItem && editingItem.entity_id) || ''
  });
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [entity, setEntity] = useState(editingItem ? editingItem.entity : null);
  const [showMessage, setShowMessage] = useState(null);
  const [searchTxt, setSearchTxt] = useState('');
  if (featureFlags['LEARNING']) {
    ContentTypes.splice(1, 1, { name: 'Micro-Learning', id: 'learning' });
  }
  if (featureFlags['CHALLENGES']) {
    ContentTypes.splice(2, 1, { name: 'Challenges', id: 'challenges' });
  }
  if (isCampaignContent) {
    ContentTypes.splice(3, 2, { name: 'Notice', id: 'notices' }, { name: 'Poll', id: 'polls' });
  }
  const fetchData = async () => {
    const data = await ContentService.fetchContent(slctdItem.type);
    setItems(data);
  };

  const onChangeEntity = (entityParams) => {
    setEntity({
      ...entity,
      ...entityParams
    });
  };

  const uploadVideo = async () => {
    let video_cloudflare_id;
    let { mediaFile } = entity;
    if (mediaFile) {
      const abortController = new AbortController();
      setAbortController(abortController);
      setUploadingVideo(true);
      video_cloudflare_id = await VideoService.uploadVideoToCloudflare(mediaFile, abortController);
      return video_cloudflare_id;
    }
  };

  const onClickSave = async () => {
    const { isError, errMsg } = ContentService.validateContent(slctdItem, entity);
    if (!isError) {
      let video_cloudflare_id;
      if (entity && entity.mediaType === 'video') {
        video_cloudflare_id = await uploadVideo();
      }
      onSave && onSave({ ...slctdItem, entity: { ...entity, video_cloudflare_id } });
      onClose();
    } else {
      setShowMessage({
        title: 'Error',
        message: errMsg
      });
    }
  };

  const fetchFeatureFlags = async (orgId) => {
    let featureFlags = {};
    const featureFlagResp = await get(`cms/feature_flags/${orgId}`, null, true);
    if (featureFlagResp && featureFlagResp.feature_flags) {
      featureFlags = FeatureFlagService.convertFeatureFlagsToObject(featureFlagResp.feature_flags);
    }
    setFeatureFlags(featureFlags);
  };

  useEffect(() => {
    fetchData();
  }, [slctdItem.type]);

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  const renderContent = () => {
    if (searchTxt) {
      items = items.filter(
        (item) => (item.title || item.name).toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1
      );
    }
    return (
      <div className="content">
        <div className="content-left">
          {ContentTypes.map((item) => (
            <div
              className={`p-3 content-item-type ${
                slctdItem.type === item.id ? 'content-item-type-selected' : ''
              }`}
              onClick={() =>
                setSlctdItem({
                  ...slctdItem,
                  type: item.id
                })
              }
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="content-right">
          {slctdItem.type === 'notices' ? (
            <NoticeForm entity={entity} onChangeEntity={onChangeEntity} />
          ) : slctdItem.type === 'polls' ? (
            <PollForm entity={entity} onChangeEntity={onChangeEntity} />
          ) : (
            <div className="mt-3 mb-3 d-flex flex-column">
              <div className="ml-3 search-cont">
                <FontAwesomeIcon icon={faSearch} color={'#505a6275'} size="md" />
                <input
                  className="search-input"
                  placeholder="Search"
                  value={searchTxt}
                  onChange={(event) => setSearchTxt(event.target.value)}
                />
              </div>
              {items.length ? (
                items.map((item, index) => {
                  const typeLabel =
                                        item.type === 'INFO' ? 'Info' : item.type === 'DETAILS' ? 'Details' : 'File';
                  return (
                    <div
                      key={item.id}
                      // style={{index === items.length -1 ? {b}}}
                      className={`content-item ${
                        item.id == slctdItem.id ? 'content-item-selected' : ''
                      }`}
                      onClick={() => {
                        setSlctdItem({
                          ...slctdItem,
                          id: item.id
                        });
                      }}
                    >
                      {slctdItem.type === 'knowledgebase' ? (
                        <>
                          <p className="content-item-name">{item.title}</p>
                          <p className="m-0 p-0">{typeLabel}</p>
                        </>
                      ) : (
                        item.name
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="m-2">There are no records to display</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="maincontainer" onClick={(e) => e.target.className === 'overlay-outer' && onClose()}>
      <BackRow onBack={onClose} />
      <Card
        title="Add an introduction and select content"
        bodyStyle={{ padding: 0 }}
        editing={1}
        onToggle={onClickSave}
      >
        <EditText
          outerClassName="m-3 mt-4"
          label={introTitle || 'Introduction'}
          onChange={(txt) =>
            setSlctdItem({
              ...slctdItem,
              intro: txt
            })
          }
          value={slctdItem.intro}
          maxLength={150}
          editing={1}
        />
        {/* <p className='m-3 mt-5 mb-1 p-0 header-title'>Select Item</p> */}
        {renderContent()}
        {slctdItem.type !== 'notices' && slctdItem.type !== 'polls' ? (
          <div className={'add-item-container'}>
            <button
              onClick={() => props.addContentItem(slctdItem)}
              className={'btn btn-primary add-category-button'}
            >
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
              {'CREATE NEW'}
            </button>
          </div>
        ) : null}
      </Card>

      {showMessage ? (
        <MessageDialog
          message={showMessage.message}
          title={showMessage.title}
          onClose={() => setShowMessage(null)}
        />
      ) : null}
      {uploadingVideo ? (
        <VideoUploadDialog
          message={'Uploading Video'}
          title={'Message'}
          onClose={() => {
            abortController.abort();
            setUploadingVideo(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default CreateContent;
