import React from 'react';
import './Table.css';
import DataTable, { createTheme } from 'react-data-table-component';
import { SyncLoader } from 'react-spinners';
import customStyles from './styles';

var pages = {}; // save 'state' of each table, eg pages['users']=2 etc
var rowsPerPage = {};
var sorts = {};

// https://www.npmjs.com/package/react-data-table-component#basic-table

export default (props) => {
  const sort = sorts[props.tableID] || {};

  return (
    <div className={`mt-3 table-outer ${props.className} `}>
      {props.loading ? (
        <div className="table__loader">
          <SyncLoader color={'#1874A7'} />
        </div>
      ) : (
        <DataTable
          className="table-table"
          columns={props.columns}
          data={props.data}
          customStyles={customStyles}
          highlightOnHover={!props.noSelect}
          pointerOnHover={!props.noSelect}
          selectableRowsHighlight={true}
          pagination
          noHeader
          defaultSortField={sort.field || props.defaultSortField}
          defaultSortAsc={!sort.desc}
          paginationDefaultPage={pages[props.tableID] || 1}
          paginationPerPage={props.paginationPerPage || 10}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          onRowClicked={props.onRowClicked}
          onChangePage={props.tableID ? (page) => (pages[props.tableID] = page) : null}
          onChangeRowsPerPage={(rows, page) => {
            if (props.tableID) {
              pages[props.tableID] = page;
              rowsPerPage[props.tableID] = rows;
            }
          }}
          onSort={
            props.tableID
              ? (column, direction) =>
                (sorts[props.tableID] = {
                  field: column.selector,
                  desc: direction === 'desc'
                })
              : null
          }
        />
      )}
    </div>
  );
};
