import React, { useContext } from 'react';
import Card from 'components/Card/Card';
import { EditImage } from 'components/EditImage/EditImage';
import { EditText, EditTextArea, EditDropDown } from 'components/EditText/EditText';
import SwitchBar from 'components/SwitchBar/SwitchBar';
import moment from 'moment';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { UserContext } from '../../../../contexts/UserContext';

export default ({ editing, learning, onEdit, onToggle, onPost }) => {
  const { user } = useContext(UserContext);

  let postedLine = null;
  if (learning.posted_date) {
    postedLine = (
      <p className="mt-4">
                Date Posted: <b>{moment(learning.posted_date).format('DD/MM/YYYY')}</b>
      </p>
    );
  } else if (learning.content && !editing) {
    postedLine = (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <p>If you have completed editing this learning:</p>
        <button onClick={onPost} className="btn btn-primary mb-4" style={{ minWidth: '180px' }}>
                    POST LEARNING
        </button>
      </div>
    );
  } else if (!learning.content) postedLine = <p className="mt-4">Post status: please add some questions first</p>;

  return (
    <Card title="Micro-Learning Material Information" editing={editing ? '1' : undefined} onToggle={onToggle}>
      <div className="group-edit-outer">
        <div className="group-edit-image-outer mr-4">
          <EditImage
            editing={editing ? '1' : undefined}
            imageid={learning.image_id}
            onChange={(imageId) => onEdit({ image_id: imageId })}
          />
        </div>
        <div className="group-edit-text-outer">
          {postedLine}
          <SwitchBar
            className="mb-4"
            editing={editing ? '1' : undefined}
            options={['Active', 'Archived']}
            values={[1, 0]}
            value={learning.active}
            onChange={(value) => onEdit({ active: value })}
          />
          <EditText
            label="Micro-Learning Title"
            editing={editing ? '1' : undefined}
            onChange={(text) => onEdit({ name: text })}
            value={learning.name}
          />
          <EditTextArea
            label="Micro-Learning Description"
            editing={editing ? '1' : undefined}
            onChange={(text) => onEdit({ description: text })}
            value={learning.description}
          />
          <EditDropDown
            className="mb-3"
            label="Time to Complete"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            editing={editing ? '1' : undefined}
            onChange={(id) => onEdit({ time_to_complete: id })}
            value={learning.time_to_complete}
          />
          <EditDropDown
            className="mb-0"
            label="Effort Level"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            editing={editing ? '1' : undefined}
            onChange={(id) => onEdit({ effort_level: id })}
            value={learning.effort_level}
          />
          <p className="edittext-help">
                        The effort level is measured as a range between 1 and 10. 1 being the lowest level of effort and
                        10 being the highest.
          </p>
          <p className="mt-4">
                        Points Value: <b>{(learning.effort_level || 1) * 50}</b>
          </p>
          {user && user.is_global ? (
            <div
              className={`learning-edit-global ${editing ? 'learning-edit-global--active' : null}`}
              onClick={() => onEdit({ is_global: !learning.is_global })}
            >
              <FontAwesomeIcon
                icon={learning.is_global ? faCheckSquare : faSquare}
                className="notice-edit-pinned__checkbox"
                style={{ color: editing && learning.is_global ? '#1874A7' : '#888' }}
              />
                            Make available to all Engage4 organisations
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  );
};
