import { get, post } from 'lib/comms'
import moment from 'moment';

class BillingService {
  getSubscriptionDetails = async () => {
    const paymentResp = await get(
      'cms/billing_subscription_details',
      null,
      true
    )
    let paymentElapsed = false
    if (paymentResp && paymentResp.subscription) {
      const { subscription } = paymentResp
      const periodEndDate = moment(subscription.period_end_date)
      const trialEndDate = subscription.status === 'trialing' && periodEndDate.diff(moment(),'days') <=7  ? periodEndDate.format('DD/MM/YYYY')  : ''
      return {
        paymentElapsed: subscription.status === 'past_due',
        endsOn: subscription.period_end_date,
        status: subscription.status,
        trialEndingOn: trialEndDate,
        cancelled:
          subscription.status === 'canceled' || subscription.scheduled_to_cancel
      }
    }
    return { paymentElapsed }
  }
  getBillingDetails = async () => {
    const resp = await get('cms/billing_details', null, true)
    if (resp && resp.success) {
      return resp.billing_details
    }
  }

  getCancellationReasons = async () => {
    const resp = await get('cms/billing_cancellation_reasons', null, true)
    if (resp && resp.cancellation_reasons) {
      return resp.cancellation_reasons
    }
  }

  cancelSubscription = async params => {
    const resp = await post(
      'cms/billing_cancel_subscription',
      {
        subscription_cancellation_reason: params.reason,
        suggested_improvement: params.toBeImprovded,
        password: params.password
      },
      true
    )
    return resp
  }

  reActivateSubscription = async params => {
    const resp = await post(
      'cms/billing_reactivate_subscription',
      {
        password: params.password
      },
      true
    )
    return resp
  }

  updatePaymentMethod = async (
    paymentMethodId,
    addressPostCode,
    addressLine1
  ) => {
    const resp = await post(
      'cms/billing_payment_method',
      {
        paymentMethodId,
        address_postcode: addressPostCode,
        address_line_one: addressLine1
      },
      true
    )
    return resp
  }
}

export default new BillingService()
