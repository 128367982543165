import React from 'react';
import styled from 'styled-components';

export const TaskLine = ({ isFirst, isLast }) => (
  <SimpleLineContainer style={isLast ? {} : { alignItems: 'center' }}>
    <VerticalLine style={isLast ? { height: '50%' } : { height: '100%' }} />
    <HorizontalLine style={isLast ? { alignSelf: 'center' } : {}} />
  </SimpleLineContainer>
);

const SimpleLine = styled.div`
    background-color: #d3d8dc;
    display: flex;
    height: 1px;
    width: 1px;
`;

export const VerticalLine = styled(SimpleLine)`
    height: 100%;
`;

const HorizontalLine = styled(SimpleLine)`
    width: 24px;
`;

const SimpleLineContainer = styled.div`
    flex-direction: row;
    display: flex;
    width: 40px;
    justify-content: center;
`;
