import React, { useState, useEffect } from 'react';
import './PlanModification.css';
import { EditDropDown, EditTextArea, EditText } from 'components/EditText/EditText';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';

const PlanCancellation = ({ showLoading, onClose, onModifyAccount, updateMessage }) => {
  const [cancellationDetails, setCancellationDetails] = useState({});
  const { reason, toBeImprovded, password } = cancellationDetails;
  const [cancellationReasons, setCancellationReasons] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const reasons = await BillingService.getCancellationReasons();
      if (reasons) setCancellationReasons(reasons);
    };
    fetchData();
  }, []);
  const onClickCloseAccount = async () => {
    showLoading();
    const resp = await BillingService.cancelSubscription({
      reason: cancellationDetails.reason
        ? cancellationDetails.reason
        : cancellationReasons && cancellationReasons[0].id,
      ...cancellationDetails
    });
    if (resp.message) {
      updateMessage({
        title: 'Cancellation Error',
        message: resp.message
      });
    } else {
      onModifyAccount();
    }
  };
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && onClose()}
    >
      <div className="plan-cancellation-cont" style={{ fontSize: 17 }}>
        <p className="plan-cancellation-title">We're sorry to see you go</p>
        <p className="plan-cancellation-desc">
                    Thank you for being a member of the Engage4 community. We are sorry to see you go. We would like to
                    understand the reason for cancelling so we can improve Engage4 and the service we offer to our
                    customers.
        </p>
        <EditDropDown
          editing={true}
          value={reason}
          options={cancellationReasons}
          label="Reason for closing your account"
          onChange={(val) =>
            setCancellationDetails({
              ...cancellationDetails,
              reason: val
            })
          }
        />
        <EditTextArea
          style={{ height: '100px' }}
          editing={true}
          value={toBeImprovded}
          label="What could we improve"
          onChange={(val) =>
            setCancellationDetails({
              ...cancellationDetails,
              toBeImprovded: val
            })
          }
        />
        <SubscriptionBanner isCancellation={true} />
        <EditText
          editing={true}
          value={password}
          label="Your Password"
          onChange={(val) =>
            setCancellationDetails({
              ...cancellationDetails,
              password: val
            })
          }
          type="password"
        />
        <button
          className="btn btn-primary w-50 align-self-center mt-3 mb-3"
          onClick={onClickCloseAccount}
          disabled={!password}
        >
                    CLOSE ACCOUNT
        </button>
      </div>
    </div>
  );
};

export default PlanCancellation;
