import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

const EditCheckBox = (props) => {
  const { className, style, editing, onClick, isChecked, title, desc, iconSize = 'lg' } = props;
  const outerClassName = `${className || ''} ${editing ? 'editing' : 'notediting'}`;
  return (
    <div className={outerClassName} style={style}>
      <div
        className={`d-flex ${desc ? '' : 'align-items-center'}`}
        onClick={onClick}
        style={{
          minWidth: '150px'
        }}
      >
        <FontAwesomeIcon
          icon={isChecked ? faCheckSquare : faSquare}
          className={'mr-3'}
          color={editing ? '#0274A7' : '#717171'}
          size={iconSize}
        />
        <div className="d-flex flex-column">
          <p
            className="m-0 p-0 pt-0"
            style={
              desc
                ? {
                  lineHeight: '18px',
                  color: `${editing ? '#0274A7' : '#717171'}`,
                  fontWeight: 600
                }
                : {
                  fontSize: 16
                }
            }
          >
            {title}
          </p>
          {desc && (
            <p className="card-text mt-2" style={{ fontSize: 14, color: '#0F2B41' }}>
              {desc}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCheckBox;
