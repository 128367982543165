const CONTENT_TYPE = {
  polls: 'POLLS',
  notices: 'NOTICES',
  challenges: 'CHALLENGES',
  learning: 'LEARNING',
  knowledgebase: 'KNOWLEDGEBASE'
}

const CORRECT = `<svg xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
<g id="🎓-6-Micro-learning" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="6-Training-Results" transform="translate(-16.000000, -460.000000)">
        <rect x="0" y="0" width="375" height="1956"></rect>
        <g id="Correct" transform="translate(16.000000, 460.000000)">
            <g id="right">
                <circle id="Oval" fill="#4AB793" cx="12" cy="12" r="12"></circle>
                <path d="M9.52352089,16.8763756 C9.44223378,16.9554335 9.33515357,16.9996005 9.22380112,17 C9.10600864,16.999492 8.99331283,16.9500263 8.91085841,16.8626396 L6.12963521,13.9780706 C6.01783154,13.8619284 5.97416723,13.6926476 6.01509021,13.5339944 C6.05601319,13.3753412 6.17530628,13.251419 6.32803292,13.208908 C6.48075957,13.1663971 6.64371696,13.2117558 6.75552062,13.3278979 L9.2326164,15.9056953 L17.2589357,8.12193775 C17.4378548,7.94998374 17.7170866,7.96125845 17.8826172,8.14712051 C18.0481479,8.33298257 18.0372944,8.62304981 17.8583752,8.79500384 L9.52352089,16.8763756 Z" id="Path" stroke="#FFFFFF" stroke-width="2" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</g>
</svg>`

const WRONG = `<svg xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
<g id="🎓-6-Micro-learning" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="6-Training-Results" transform="translate(-16.000000, -665.000000)">
        <rect x="0" y="0" width="375" height="1956"></rect>
        <g id="Wrong" transform="translate(16.000000, 665.000000)">
            <g id="wrong">
                <g id="Wrong" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " fill="#AF365F">
                    <g id="Right" transform="translate(0.000000, 0.000000)">
                        <circle id="Oval" cx="12" cy="12" r="12"></circle>
                    </g>
                </g>
                <g id="Group" transform="translate(6.000000, 7.000000)" stroke="#FFFFFF" stroke-linecap="round" stroke-width="2.25">
                    <path d="M6,-1.09090909 L6,12" id="Line-2" transform="translate(6.000000, 5.454545) rotate(-315.000000) translate(-6.000000, -5.454545) "></path>
                    <path d="M6,-1.09090909 L6,12" id="Line-2" transform="translate(6.000000, 5.454545) rotate(-225.000000) translate(-6.000000, -5.454545) "></path>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>`

const formatToColumn = (label, value, image) => {
  const columns = [
    {
      text: label,
      style: 'columnLbl',
      width: '70%'
    },
    {
      text: value,
      style: 'columnVal',
      width: '25%'
    }
  ]
  if (image)
    columns.splice(1, 0, {
      svg: image === 'correct' ? CORRECT : WRONG,
      width: 12,
      style: 'columnVal'
    })
  columns.splice(2, 0, {
    text: '',
    width: '3%'
  })

  return {
    columns
  }
}

export const getStatistics = item => {
  let statistcsData = []
  const {
    knowledgebase_unique_views = 0,
    knowledgebase_views = 0,
    learning_completed = 0,
    learning_score = 0,
    likes_counter = 0,
    comments_counter = 0,
    challenge_participants = 0,
    challenge_completed = 0,
    poll_votes = 0,
    poll_engagement = 0,
    poll_options = [],
    learning_content = [],
    learning_answers = []
  } = item ? item.statistics || {} : {}

  switch (CONTENT_TYPE[item.type]) {
    case 'KNOWLEDGEBASE':
      statistcsData = [
        formatToColumn(`Times Browsed:`, knowledgebase_views),
        formatToColumn(`Number of Unique Views:`, knowledgebase_unique_views)
      ]
      break
    case 'LEARNING':
      statistcsData = [
        formatToColumn(`Times Completed:`, learning_completed),
        formatToColumn(`Average Score:`, learning_score),
        { text: 'Questions', style: 'contentHeader' },
        ...learning_content.map(learningItem => {
          const answersCount = learning_answers.length
          const answers = learningItem.answers || []
          return {
            stack: [
              { text: `${learningItem.question}`, style: 'columnVal' },
              ...answers.map(answerItem =>
                formatToColumn(
                  answerItem.title,
                  `${(100 * answerItem.score) / (answersCount || 1)} %`,
                  answerItem.correct ? 'correct' : 'wrong'
                )
              )
            ]
          }
        })
      ]
      break
    case 'NOTICES':
      statistcsData = [
        formatToColumn(`Likes: `, likes_counter),
        formatToColumn(`Comments: `, comments_counter)
      ]
      break
    case 'CHALLENGES':
      statistcsData = [
        formatToColumn(`Participants: `, challenge_participants),
        formatToColumn(`Completed: `, challenge_completed)
      ]
      break
    case 'POLLS':
      statistcsData = [
        formatToColumn(`Total Votes: `, poll_votes),
        formatToColumn(`Avg Engagement: `, poll_engagement),
        formatToColumn(`Likes: `, likes_counter),
        formatToColumn(`Comments: `, comments_counter),
        { text: 'Results', style: 'contentHeader' },
        ...poll_options.map(item => {
          return formatToColumn(item.option_text, `${item.total_votes} Votes`)
        })
      ]
      break
  }

  return [{ text: 'Statistics', style: 'contentHeader' }, ...statistcsData]
}
