import React from 'react';
import { EditText, EditDropDown } from 'components/EditText/EditText';
import './CreateContent.css';
import { getPollOptions, OptionMap } from 'services/ContentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

const PollForm = (props) => {
  const { onChangeEntity, entity } = props;
  const {
    description,
    poll_duration,
    options = [
      { id: 1, option_text: '' },
      {
        id: 2,
        option_text: ''
      }
    ]
  } = entity || {};
  const optionsCount = options.length;
  const pollMaxLength = 350;
  const lengthLeft = Math.max(pollMaxLength - (description || '').length, 0);
  const helpText = `Character${lengthLeft === 1 ? '' : 's'} left: ${lengthLeft}`;
  return (
    <div className="poll-container">
      <div className="poll-content">
        <textarea
          maxLength={pollMaxLength}
          value={description}
          className="poll-input"
          placeholder="Poll description"
          onChange={(event) => onChangeEntity({ description: event.target.value })}
        />
        {/* {helpText ? <p className='edittext-help'>{helpText}</p> : null} */}
        <div className="poll-option-cont">
          {options.map((item, index) => (
            <EditText
              outerClassName="flex-column mb-3"
              editing={true}
              maxLength={40}
              value={item.option_text}
              label={`Option ${OptionMap[index]}`}
              onChange={(val) =>
                onChangeEntity({
                  options: options.map((option) => {
                    if (option.id === item.id) return { ...option, option_text: val };
                    return option;
                  })
                })
              }
            />
          ))}
          {optionsCount < 5 ? (
            <div
              className="add-option-cont"
              onClick={() =>
                onChangeEntity({
                  options: [...options, { id: optionsCount + 1, option_text: '' }]
                })
              }
            >
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
              <p className="add-option-text"> ADD ANOTHER OPTION</p>
            </div>
          ) : null}
        </div>
      </div>
      <EditDropDown
        className="poll-duration"
        label="Poll length"
        options={getPollOptions()}
        editing={1}
        onChange={(val) =>
          onChangeEntity({
            poll_duration: val
          })
        }
        value={poll_duration}
      />
    </div>
  );
};

export default PollForm;
