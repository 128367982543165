import React, { useContext, useState } from 'react';
import Card from 'components/Card/Card';
import OrgFlagsCheckBox from 'components/OrgFlagsCheckBox/OrgFlagsCheckBox';

const OrgFlags = ({ flags, isEditing, onToggle, setFlag }) => {
  const activityFlags = flags[0];

  const [loadingFlags, setLoadingFlags] = useState([]);

  const onClickCheckbox = (flagId) => (flagValue) => {
    if (!isEditing) return;
    setFlag(flagId, flagValue);
  };

  if (flags && !flags.length) {
    return null;
  }

  return (
    <Card title="Feature Flags" editing={isEditing} onToggle={onToggle}>
      <div
        style={{
          border: '1px solid #979797',
          borderRadius: '5px',
          maxHeight: 320,
          overflowY: 'scroll',
          margin: '16px 0px'
        }}
      >
        {activityFlags.map(({ title, description, id, flag_value }, index) => {
          return (
            <OrgFlagsCheckBox
              className="p-3"
              style={{
                backgroundColor: index % 2 === 0 ? 'white' : '#CBCBCB20'
              }}
              editing={isEditing}
              title={title}
              initialValue={flag_value}
              onToggle={onClickCheckbox(id)}
              desc={description}
              isLoading={loadingFlags.includes(id)}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default OrgFlags;
