import React from 'react';
import { get } from 'lib/comms';
import styled from 'styled-components';
import TaskStatusLine from './TaskStatusLine';
import BackRow from 'components/BackRow/BackRow';
import GreenTick from 'assets/green_tick.svg';
import GreyTick from 'assets/grey_tick.svg';

export default class UserTaskStatus extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      tasks: []
    };
  }

    componentDidMount = () => {
      this.reload();
    };

    reload = async () => {
      let resp = await get(`cms/onboarding_tasks/${this.props.onboardingId}/${this.props.userId}`, null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      const tasks = resp && resp.onboarding_tasks ? resp.onboarding_tasks : [];
      this.setState({
        tasks
      });
    };

    getEntityTitle = (type) => {
      return type === 'learning' ? 'Micro Learning' : type === 'challenges' ? 'Challenge' : 'Knowledgebase';
    };

    render () {
      const { tasks } = this.state;
      const taskCount = tasks.length;
      return (
        <div className="maincontainer onboarding-task-container">
          <BackRow onBack={this.props.onBack} />
          <div className="onboarding-task-status">
            <div className="d-flex">
              <div className="task-status-container">
                <div className="task-items">
                  {tasks.map(({ title, entity, type, is_completed }, index) => {
                    return (
                      <div className="task-status-item">
                        <TaskStatusLine
                          isCompleted={is_completed}
                          isLast={index == taskCount - 1}
                        />
                        <div className={'task-item'} style={{ position: 'relative' }}>
                          <img
                            className="task-status-icon"
                            src={is_completed ? GreenTick : GreyTick}
                          />
                          <span className={'task-item-title'}>{title}</span>
                          {entity ? (
                            <span className={'task-item-title'} style={{ fontWeight: 'bold' }}>
                              {`${this.getEntityTitle(type)}: `}
                              <span style={{ fontWeight: 'normal' }}>{entity.title}</span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
