import { get, post, patch, dodelete } from 'lib/comms';
import PinnedPostLimitError from '../models/errors/PinnedPostLimitError';

class NoticesService {
    getFullNotice = async (id) => {
      const notice = await get(`cms/notices/${id}`, {}, true);

      if (notice && notice.success && notice.notice) {
        return notice.notice;
      } else if (notice && notice.success === false && notice.message) {
        throw new Error(notice.message);
      } else {
        throw new Error("Couldn't get notice");
      }
    };

    createNewNotice = async (notice) => {
      const postRequest = await post('cms/notices', notice, true);

      if (postRequest && postRequest.success) {
        return true;
      } else if (postRequest && postRequest.success === false && postRequest.message) {
        if (postRequest.error === 'PINNED_POST_LIMIT') {
          throw new PinnedPostLimitError(postRequest.message, postRequest.pinnedPosts);
        } else {
          throw new Error(postRequest.message);
        }
      } else {
        throw new Error("Couldn't get notice");
      }
    };

    updateExistingNotice = async (notice) => {
      const patchRequest = await patch(
        `cms/notices/${notice.id}`,
        {
          ...notice,
          id: notice.id
        },
        true
      );

      if (patchRequest && patchRequest.success && patchRequest) {
        return true;
      } else if (patchRequest && patchRequest.success === false && patchRequest.message) {
        if (patchRequest.error === 'PINNED_POST_LIMIT') {
          throw new PinnedPostLimitError(patchRequest.message, patchRequest.pinnedPosts);
        } else {
          throw new Error(patchRequest.message);
        }
      } else {
        throw new Error("Couldn't get notice");
      }
    };
}

export default new NoticesService();
