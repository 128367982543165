import moment from 'moment'
import pdfmake from 'pdfmake'
import { ENTITY } from '../../CampaignData'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getStatistics } from './Statisitcs'
pdfmake.vfs = pdfFonts.pdfMake.vfs

export const exportCampaignReport = (campaign, campaignContent) => {
  const { name, description, start_date } = campaign
  const tableContent = campaignContent.map(item => {
    const { type, entity, title, start_date, sequence_no } = item
    let statistics = getStatistics(item)
    let content = [
      {
        stack: [
          { text: 'Type:', style: 'contentHeader' },
          { text: ENTITY[type], style: 'entityType' }
        ],
        borderColor: ['#d3d3d3', '#d3d3d3', '#d3d3d3', '#d3d3d3']
      },
      {
        stack: [
          { text: 'Content:', style: 'contentHeader' },
          {
            text: title,
            style: 'tableBody'
          },
          { text: 'Title:', style: 'contentHeader' },
          {
            text: entity.title,
            style: 'tableBody'
          },
          ...statistics
        ],
        borderColor: ['#d3d3d3', '#d3d3d3', '#d3d3d3', '#d3d3d3']
      },
      {
        stack: [
          { text: 'Date:', style: 'contentHeader' },
          {
            text: moment(start_date)
              .add(sequence_no - 1, 'days')
              .format('DD/MM/YYYY'),
            style: 'tableBody'
          }
        ],
        borderColor: ['#d3d3d3', '#d3d3d3', '#d3d3d3', '#d3d3d3']
      }
    ]
    return content
  })
  var docDefinition = {
    content: [
      {
        stack: [
          {
            text: campaign.name,
            style: 'header'
          },
          {
            text: campaign.description,
            style: 'subHeader'
          },
          {
            text: `Ran for ${campaign.no_of_days / 7} weeks from ${moment(
              campaign.start_date
            ).format('DD/MM/YYYY')}`,
            style: 'subHeader'
          }
        ]
      },
      {
        table: {
          headerRows: 1,
          style: 'tableBody',
          widths: [130, '*', 80],
          body: [TABLE_HEADER, ...tableContent]
        }
      }
    ],
    styles: pdfStyles
  }

  var pdf = pdfmake.createPdf(docDefinition)
  pdf.open()
}

const pdfStyles = {
  header: {
    bold: true,
    color: '#0274a7',
    fontSize: 22,
    margin: [0, 10, 0, 5]
  },
  subHeader: {
    bold: true,
    color: '#505a62',
    fontSize: 15,
    margin: [0, 5, 0, 15]
  },
  tableHeader: {
    bold: true,
    color: '#000008',
    fontSize: 15,
    margin: [0, 10, 0, 10]
  },
  contentHeader: {
    color: '#505a62',
    fontSize: 12,
    margin: [0, 10, 0, 2],
    bold: true
  },
  tableBody: {
    color: '#505a62',
    fontSize: 12,
    margin: [0, 5, 0, 6]
  },
  columnLbl: {
    color: '#505a62',
    fontSize: 12,
    margin: [0, 5, 0, 6]
  },
  columnVal: {
    color: '#505a62',
    fontSize: 12,
    margin: [0, 5, 0, 6],
    bold: true
  },
  entityType: {
    color: '#0274a7',
    fontSize: 12,
    margin: [0, 0, 0, 10],
    bold: true
  }
}

const TABLE_HEADER = ['Campaign Content', '', ''].map((item, index) => ({
  text: item,
  style: 'tableHeader',
  border: [index === 0 ? true : false, true, index === 2 ? true : false, true],
  borderColor: ['#d3d3d3', '#d3d3d3', '#d3d3d3', '#d3d3d3']
}))
