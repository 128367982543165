import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { EditText, EditDropDown, EditDate } from 'components/EditText/EditText';
import EditCheckBox from 'components/EditCheckBox/EditCheckBox';
import { getUserFromToken } from 'lib/userprocs';
import { getData } from 'lib/data';
import SwitchBar from 'components/SwitchBar/SwitchBar';
import { put, get, dodelete } from 'lib/comms';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import moment from 'moment';
import RedAlert from 'assets/red_alert.svg';
import GreenAlert from 'assets/green_alert.svg';
import GreyCheckMark from 'assets/grey_check.svg';
import isEmail from 'validator/lib/isEmail';
import UserRolesCard from './UserRoles';

export default class UserPage extends React.Component {
  constructor (props) {
    super(props);
    const myUser = getUserFromToken(getData('token', '', true));
    this.isMe = myUser.id === props.user.id;
    this.state = {
      user: props.user,
      editing: [false, false, false, false],
      departments: [],
      securityQuestions: [],
      showDeleteDialog: false,
      showMessage: null,
      tags: [],
      userTags: {},
      generatingPassword: false,
      generatedPassword: '',
      generatedPasswordAt: '',
      accountUnlocked: false,
      roles: [],
      userRoleIds: []
    };
  }

  componentDidMount () {
    this.getDropdownData();
  }

  async getDropdownData () {
    let departments = [];
    let securityQuestions = [];
    let tags = [];
    let userTags = {};
    let roles = [];
    let userRoleIds = [];

    const rolesResp = await get(`cms/user_roles/${this.state.user.id}`, null, true);
    if (rolesResp && rolesResp.user_roles) {
      roles = rolesResp.roles;
      userRoleIds = rolesResp.user_roles;
    }

    const deptResp = await get('cms/department', null, true);
    if (deptResp && deptResp.departments) {
      departments = deptResp.departments.map((item) => ({
        id: item.id,
        name: item.department
      }));
    }
    const securityQuestionsResp = await get('account/security_questions', null, true);
    if (securityQuestionsResp && securityQuestionsResp.security_questions) {
      securityQuestions = securityQuestionsResp.security_questions.map((item) => ({
        id: item.id,
        name: item.short_text
      }));
    }
    const tagsResp = await get(`cms/user_tags/${this.state.user.id}`, null, true);
    if (tagsResp && tagsResp.tags) {
      tags = tagsResp.tags.map((tagItem) => {
        const [slctdOption] = tagItem.options.filter((item) => item.is_selected);
        const slctdOptionId = slctdOption ? slctdOption.id : 'select';
        let options = tagItem.options.map((option) => ({
          id: option.id,
          name: option.label
        }));
        if (slctdOption) {
          // push selcted tags to user tags
          userTags[tagItem.id] = {
            tagId: tagItem.id,
            value: slctdOptionId
          };
        } else if (tagItem.type === 1) {
          // add select option if tag option is not selected
          options = [
            {
              id: 'select',
              name: 'Select'
            },
            ...options
          ];
        }
        return {
          ...tagItem,
          options
        };
      });
    }
    this.setState({
      roles,
      departments,
      securityQuestions,
      tags,
      userTags,
      userRoleIds
    });
  }

    validateUsername = () => {
      var re = /^[a-zA-Z0-9_]*$/;
      const username = String(this.state.user.username).toLowerCase();
      return isEmail(username) || re.test(username);
    };

    onChangeFieldValue = (fieldKey, fieldVal) => {
      let { user } = this.state;
      this.setState({
        user: { ...user, [fieldKey]: fieldVal }
      });
    };

    updateUserInfo = async () => {
      let userTags = [];
      let { user, userRoleIds } = this.state;
      let isEditing = true;
      for (const tagItem in this.state.userTags) {
        userTags.push(this.state.userTags[tagItem]);
      }
      const resp = await put('cms/users', { user: { ...user, tags: userTags, role_ids: userRoleIds } }, true);
      if (resp && resp.success) isEditing = false;
      else console.log(resp);
      if (resp.message) {
        setTimeout(
          () =>
            this.setState({
              showMessage: {
                message: resp.message,
                title: 'Save error',
                isError: true
              }
            }),
          500
        );
      }
      return isEditing;
    };

    setEditing = async (index) => {
      let { editing, user, generatedPassword, userRoleIds } = this.state;
      let emailAddress = user.email_to_report || '';
      let message =
            'Please reenter your username either in email format or using only letters, numbers or _ character and without spaces';
      if (!userRoleIds.length && index === 1) {
        message = 'Please assign at least one role to user';
      } else if (!isEmail(emailAddress)) {
        message = 'Please enter a valid email address';
      }

      if (
        (editing[1] && !userRoleIds.length && index === 1) ||
            (editing[0] && !this.validateUsername()) ||
            (editing[0] && emailAddress && !isEmail(emailAddress))
      ) {
        return setTimeout(
          () =>
            this.setState({
              showMessage: {
                message: message,
                title: 'Save error',
                isError: true
              }
            }),
          100
        );
      }

      if (editing[index]) {
        if (editing[2]) {
          if (generatedPassword) {
            user.password = generatedPassword;
          }
          this.setState({
            generatingPassword: false,
            generatedPassword: ''
          });
        }
        const isEditing = await this.updateUserInfo();
        editing[index] = isEditing;
      } else if (index === 1 && this.isMe) {
        this.setState({
          showMessage: {
            message: "You can't change your own user permissions!",
            title: 'Permissions'
          }
        });
      } else editing[index] = true;
      this.setState({ editing });
    };

    doDelete = async () => {
      const resp = await dodelete('cms/users', { id: this.state.user.id }, true);
      await this.setState({ showDeleteDialog: false });
      if (resp && resp.success && this.props.onBack) this.props.onBack();
      else {
        let errMsg = resp.message || 'Couldn’t delete user';
        setTimeout(
          () =>
            this.setState({
              showMessage: { message: errMsg, title: 'Delete user' }
            }),
          500
        );
      }
    };

    renderAuthentication = () => {
      let { user, generatedPassword } = this.state;
      const lastGenratedOTPat = user.last_otp_generated_at;
      const isEditing = this.state.editing[2] ? '1' : undefined;
      const generateRandomPassword = () => {
        let password = '';
        const passwordChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz';
        for (let i = 1; i <= 8; i++) {
          let char = 0;
          if (i === 4) {
            char += Math.floor(Math.random() * '0123456789'.length);
            password += '0123456789'.charAt(char);
          } else if (i == 6) {
            char += Math.floor(Math.random() * '@#$()!*&'.length);
            password += '@#$()!*&'.charAt(char);
          } else {
            char = Math.floor(Math.random() * passwordChars.length);
            password += passwordChars.charAt(char);
          }
        }
        return password;
      };

      const onClickGeneratePassword = () => {
        this.setState({
          generatingPassword: true,
          generatedPassword: generateRandomPassword(),
          user: { ...user, last_otp_generated_at: new Date() }
        });
      };
      return (
        <Card title="Authentication" editing={isEditing} onToggle={() => this.setEditing(2)}>
          <EditDropDown
            label="Security Question"
            options={this.state.securityQuestions}
            editing={isEditing}
            onChange={(id) => this.onChangeFieldValue('security_question_id', id)}
            value={user.security_question_id || ''}
          />

          <EditText
            outerClassName="mt-2 mb-3"
            label="Security Answer"
            editing={isEditing}
            onChange={(answer) => this.onChangeFieldValue('security_answer', answer)}
            value={user.security_answer}
            type="password"
          />
          <>
            <p className="card-text">One time password</p>
            <EditText
              label="Password"
              editing={!!generatedPassword}
              value={generatedPassword}
              enableCopy={!!generatedPassword}
            />
            {lastGenratedOTPat ? (
              <div className="d-flex align-items-center mt-2 mb-3">
                <img src={GreyCheckMark} width="14" height="11" />
                <p className="card-text ml-2  p-0">
                                OTP last generated at
                  <span style={{ fontWeight: 600, color: '#666' }}>{` ${moment(lastGenratedOTPat).format(
                    'hh:mm a'
                  )}, ${moment(lastGenratedOTPat).format('DD MMMM YYYY ')}`}</span>
                </p>
              </div>
            ) : (
              <p className="card-text mt-2 mb-3 p-0">You have not created a one time password yet</p>
            )}
            <button
              disabled={!isEditing}
              style={{ height: 40 }}
              className={'btn btn-primary w-100 mb-3 text-uppercase'}
              onClick={onClickGeneratePassword}
            >
              {`Generate ${lastGenratedOTPat ? 'new' : ''} password`}
            </button>
          </>
        </Card>
      );
    };

    renderPersonalInfo = () => {
      let { user } = this.state;
      const isEditing = this.state.editing[0] ? '1' : undefined;
      return (
        <Card title="Personal information" editing={isEditing} onToggle={() => this.setEditing(0)}>
          <p>Status: Registered</p>
          <div className="two-cols" style={{ marginBottom: '-10px' }}>
            <div style={{ flex: 1, marginRight: '6px', maxWidth: '200px' }}>
              <EditText
                label="First Name"
                editing={isEditing}
                onChange={(fname) => this.onChangeFieldValue('first_name', fname)}
                value={user.first_name}
              />
            </div>
            <div style={{ flex: 1, marginLeft: '6px' }}>
              <EditText
                label="Last Name"
                editing={isEditing}
                onChange={(lname) => this.onChangeFieldValue('last_name', lname)}
                value={user.last_name}
              />
            </div>
          </div>
          <EditText
            outerClassName="mb-3 mt-2"
            label="Username"
            editing={isEditing}
            onChange={(username) => this.onChangeFieldValue('username', username)}
            value={user.username}
          />
          <EditText
            label="Job Title"
            editing={isEditing}
            onChange={(jobTitle) => this.onChangeFieldValue('job_title', jobTitle)}
            value={user.job_title}
          />
          <EditText
            label="Email Address"
            editing={isEditing}
            onChange={(emailAddress) => this.onChangeFieldValue('email_to_report', emailAddress)}
            value={user.email_to_report}
          />
          <EditDropDown
            label="Department "
            options={this.state.departments}
            editing={isEditing}
            onChange={(id) => this.onChangeFieldValue('department_id', id)}
            value={user.department_id}
          />
          <EditDate
            isDateOfBirth={true}
            editing={isEditing}
            dateFormat="dd/MM/yyyy"
            label="Date of Birth"
            onChange={(date) =>
              this.onChangeFieldValue('date_of_birth', date ? moment(date).format('YYYY-MM-DD') : '')
            }
            value={user.date_of_birth || undefined}
          />
        </Card>
      );
    };

    renderUserTags = () => {
      let { user, editing } = this.state;
      let isEditing = editing[3] ? '1' : undefined;
      return (
        <Card title="User Tags" editing={isEditing} onToggle={() => this.setEditing(3)}>
          {this.state.tags.map((tagItem) => {
            let userTag = this.state.userTags[tagItem.id];
            let optionId = userTag ? userTag.value : 'select';
            let userTags = { ...this.state.userTags };
            return tagItem.type === 1 ? (
              <EditDropDown
                label={tagItem.title + ' '}
                options={tagItem.options}
                editing={isEditing}
                onChange={(slctdOptionId) => {
                  if (slctdOptionId === 'select') {
                    delete userTags[tagItem.id];
                  } else {
                    userTags[tagItem.id] = {
                      tagId: tagItem.id,
                      value: slctdOptionId
                    };
                  }
                  this.setState({
                    userTags
                  });
                }}
                value={optionId}
              />
            ) : tagItem.type === 2 ? (
              <EditCheckBox
                className="mt-2 mb-3"
                editing={isEditing}
                title={tagItem.options[0].name}
                onClick={(isChecked) => {
                  const userTags = { ...this.state.userTags };
                  userTags[tagItem.id] = {
                    tagId: tagItem.id,
                    value: isChecked
                  };
                  this.setState({
                    userTags
                  });
                }}
                isChecked={userTag ? userTag.value : false}
              />
            ) : null;
          })}
        </Card>
      );
    };

    renderLockedBanner = () => {
      let { user, accountUnlocked } = this.state;
      const onClickUnlockAccount = () => {
        const userRec = {
          ...user,
          locked_reason: null,
          failed_attempts: 0,
          auth_locked_until: null
        };
        this.setState(
          {
            user: userRec,
            accountUnlocked: true
          },
          this.updateUserInfo
        );
      };
      const isUserAuthLocked = !!(user.auth_locked_until && new Date(user.auth_locked_until) > new Date());
      const failureReason =
            user.locked_reason === 'dob'
              ? 'date of birth'
              : user.locked_reason === 'security'
                ? 'security'
                : 'password';
      if (!isUserAuthLocked && !accountUnlocked) return null;
      return (
        <div
          className={`mt-2 mb-2 d-flex flex-fill align-items-center justify-content-between ${
            isUserAuthLocked ? 'p-2' : 'pl-2 pr-2 '
          }`}
          style={{
            borderWidth: 2,
            borderColor: isUserAuthLocked ? '#AF365E' : 'rgb(106,181,150)',
            borderStyle: 'solid',
            borderRadius: 5
          }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <img src={isUserAuthLocked ? RedAlert : GreenAlert} width="20" height="20" />
              <p
                className="card-text p-0 ml-2"
                style={{
                  fontWeight: 600,
                  color: isUserAuthLocked ? '#AF365E' : 'rgb(106,181,150)'
                }}
              >{`User Account ${isUserAuthLocked ? 'Locked' : 'Unlocked'}`}</p>
            </div>
            {isUserAuthLocked && (
              <p className="card-text p-0" style={{ fontSize: 14 }}>
                            Reason:
                <span
                  style={{ fontWeight: 600, color: '#666' }}
                >{` Too many incorrect ${failureReason} attempts`}</span>
              </p>
            )}
          </div>
          {isUserAuthLocked ? (
            <button
              style={{ height: 40 }}
              className={'btn btn-primary text-uppercase'}
              onClick={onClickUnlockAccount}
            >
              {'Unlock Account'}
            </button>
          ) : (
            <button
              style={{ fontSize: 14, color: '#666' }}
              className={'text-button'}
              onClick={() => this.setState({ accountUnlocked: false })}
            >
                        Dismiss
            </button>
          )}
        </div>
      );
    };

    renderUserRoles = () => {
      let { roles, editing } = this.state;
      const isEditing = editing[1] ? '1' : undefined;
      const onClickCheckbox = (roleId) => {
        if (!isEditing) return;
        let roleIds = [...this.state.userRoleIds];
        const index = roleIds.indexOf(roleId);
        if (index !== -1) roleIds.splice(0, 1);
        // if role id present remove it
        else roleIds.push(roleId); // if role id not present insert it
        this.setState({
          userRoleIds: roleIds
        });
      };
      if (roles && !roles.length) {
        return null;
      }
      return (
        <Card title="User Roles" editing={isEditing} onToggle={() => this.setEditing(1)}>
          <div
            style={{
              border: '1px solid #979797',
              borderRadius: '5px',
              maxHeight: 320,
              overflowY: 'scroll',
              margin: '16px 0px'
            }}
          >
            {roles.map(({ title, description, id }, index) => {
              return (
                <EditCheckBox
                  className="p-3"
                  style={{
                    backgroundColor: index % 2 === 0 ? 'white' : '#CBCBCB20'
                  }}
                  editing={isEditing}
                  title={title}
                  onClick={() => onClickCheckbox(id)}
                  isChecked={this.state.userRoleIds.indexOf(id) !== -1}
                  desc={description}
                />
              );
            })}
          </div>
        </Card>
      );
    };

    render () {
      let { roles, editing, userRoleIds, user } = this.state;
      return (
        <div className="maincontainer">
          <BackRow
            onBack={this.props.onBack}
            buttonTitle="DELETE USER"
            onDelete={() => this.setState({ showDeleteDialog: true })}
          />
          {this.renderLockedBanner()}
          <div className="row">
            <div className="col">
              {this.renderPersonalInfo()}
              <UserRolesCard
                roles={roles}
                emailToReport={user.email_to_report}
                userRoleIds={userRoleIds}
                isEditing={editing[1] ? '1' : undefined}
                onSelectReportsRole={() =>
                  setTimeout(
                    () =>
                      this.setState({
                        showMessage: {
                          message:
                                                    'Please enter email address in Personal Information section before assigning this role',
                          title: 'Error',
                          isError: true
                        }
                      }),
                    500,
                    50
                  )
                }
                onToggle={() => this.setEditing(1)}
                onUpdateUserRoles={(roleIds) =>
                  this.setState({
                    userRoleIds: roleIds
                  })
                }
              />
            </div>
            <div className="col">
              {this.renderAuthentication()}
              {this.state.tags && this.renderUserTags()}
            </div>
          </div>
          {this.state.showDeleteDialog ? (
            <DeleteDialog
              item="User"
              onSuccess={this.doDelete}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              isError={this.state.showMessage.isError}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
