import React from 'react';
import Card from 'components/Card/Card';
import { CrossBox, TickBox } from 'components/SVG/SVG';
import { get } from 'lib/comms';

export default class Answers extends React.Component {
    state = { answers: [] };

    componentDidMount = async () => {
      let resp = await get('cms/learnings', { id: this.props.learning.id }, true);
      if (resp && resp.answers) this.setState({ answers: resp.answers });
    };

    render () {
      let content = JSON.parse(this.props.learning.content || '[]');
      let { answers } = this.state;
      answers = answers.map((answer) => (answer ? JSON.parse(answer) : []));
      content = content.map((item, qNo) => {
        item.answers = item.answers ? item.answers.map((answer) => ({ ...answer, score: 0 })) : null;
        return item;
      });

      // unpick answers and add one to each answer chosen
      answers.forEach((personAnswers) => {
        personAnswers.forEach((answer, index) => {
          const answered = answer.split('.');
          for (var answerIndex of answered) {
            if (content[index] && content[index].answers && content[index].answers[answerIndex]) {
              content[index].answers[answerIndex].score += 1;
            }
          }
        });
      });

      return (
        <Card title="Company Results">
          <div className="learning-questions-inner">
            {content.map((item, index) => {
              return <ResultQuestion key={index} item={item} index={index} outof={answers.length} />;
            })}
          </div>
        </Card>
      );
    }
}

export const ResultQuestion = ({ item, index, outof }) => {
  const isQuestion = !!item.answers;
  let answers = isQuestion ? [...item.answers] : [];
  // const answeredTotal = answers.reduce((total, answer) => total + answer.results || 0, 0);
  // answers = answers.map(answer => ({...answer, score: 100 * (answer.score || 0) / (answeredTotal || 1)}));
  return (
    <div className="learning-question mt-3">
      <div className="learning-border" style={{ backgroundColor: isQuestion ? '#68C4D1' : '#FBB811' }} />
      <div className="learning-title">
        <div className="mr-2">{index + 1}:</div>
        <div style={{ flex: 1 }}>{item.question}</div>
      </div>
      {isQuestion
        ? answers.map((answer, index) => (
          <ItemAnswer key={index} item={answer} score={(100 * answer.score) / (outof || 1)} />
        ))
        : null}
    </div>
  );
};

const ItemAnswer = ({ item, score }) => (
  <div className="learning-answer-container mt-3">
    <div
      className="learning-question-title"
      style={{ margin: 0, position: 'relative', padding: 0, height: '40px' }}
    >
      <div
        style={{
          position: 'absolute',
          width: `${score}%`,
          height: '100%',
          backgroundColor: item.correct ? '#D2EDE4' : '#F3CDDA',
          zIndex: 1
        }}
      />
      <div
        className=""
        style={{ backgroundColor: 'transparent', zIndex: 2, position: 'absolute', padding: '8px' }}
      >
        {item.title}
      </div>
    </div>
    <div
      style={{
        width: '120px',
        marginLeft: '20px',
        marginRight: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {item.correct ? <TickBox /> : <CrossBox />}
      {score.toFixed(0) || 0} %
    </div>
  </div>
);
