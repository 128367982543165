import React from 'react';
import './SearchRow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  const btnClass = props.className || 'primary';
  return (
    <div className="searchrow-outer">
      {props.title ? <h5 className="mr-4 mt-1">{props.title}</h5> : null}
      <input
        className="searchrow-input"
        value={props.value || ''}
        placeholder={props.searchTitle || 'Search'}
        onChange={(e) => (props.onSearch ? props.onSearch(e.target.value) : null)}
      />
      <div className="searchrow-btn-outer">
        {props.buttonTitle ? (
          <button className={`btn btn-${btnClass} searchrow-btn`} onClick={props.onBtnClick}>
            <FontAwesomeIcon
              icon={btnClass === 'primary' ? faPlusCircle : faCheckCircle}
              style={{ marginRight: '10px' }}
            />{' '}
            {props.buttonTitle}
          </button>
        ) : null}
      </div>
    </div>
  );
};
