import React, { useState } from 'react';
import './CreateContent.css';
import { EditTextArea } from '../EditText/EditText';
import placeholder from 'assets/placeholder.png';
import AuthImage from 'components/AuthImage/AuthImage';
import { upload } from 'lib/comms';
import { Stream } from '@cloudflare/stream-react';

const NoticeForm = (props) => {
  const { onChangeEntity, entity } = props;
  const { image_id, video_id, description, videoBlobUrl, video_url: videoUrl } = entity || {};
  const videoToken = videoUrl ? videoUrl.token : '';
  const onRemoveMedia = () =>
    onChangeEntity({
      image_id: null,
      videoBlobUrl: null,
      mediaFile: null,
      video_id: null,
      mediaType: '',
      video_url: null
    });
  const onMediaSelect = async (event) => {
    try {
      const mediaFile = event.target.files[0];
      const fileBlobUrl = URL.createObjectURL(mediaFile);
      const mediaType = mediaFile.type.indexOf('video') !== -1 ? 'video' : 'image';
      if (mediaType === 'video') {
        if (mediaFile.size > 200 * 1000 * 1000) {
          // Cloudflare limit is 200 MB for direct user uploads
          // TO-DO ERROR DIALOG FOR VIDEO FILE THAT IS TOO LARGE
          return alert('Video File is too large, please upload a video file that is less than 200MB');
        }
        onChangeEntity({
          mediaType,
          videoBlobUrl: fileBlobUrl,
          mediaFile,
          image_id: null
        });
      } else {
        if (mediaFile.size > 4000000) return alert('Please upload a smaller image!');
        const resp = await upload('cms/upload', 'image', mediaFile, true);
        if (resp && resp.image_id) onChangeEntity({ image_id: resp.image_id, videoBlobUrl: '' });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="m-3 notice-cont">
      <div className="notice-img-cont">
        {image_id || videoBlobUrl || videoToken ? (
          image_id ? (
            <AuthImage url={`content/image/${image_id}`} className="editimage-img" />
          ) : videoBlobUrl ? (
            <video
              key={videoBlobUrl}
              className="editimage-img"
              controls
              // onDurationChange={this.checkForVideoFileDuration}
              src={videoBlobUrl}
            >
              <source src={videoBlobUrl} />
                            Your browser does not support HTML video.
            </video>
          ) : (
            <Stream
              src={videoToken}
              controls
              autoplay={false}
              preload
              height="200px"
              width="300px"
            ></Stream>
          )
        ) : (
          <img src={placeholder} className="editimage-img" />
        )}
        <div className="d-flex flex-column w-25">
          <label className={'notice-add-img'}>
            {!image_id && !videoBlobUrl && !video_id ? 'ADD MEDIA' : 'REPLACE MEDIA'}
            <input type="file" onChange={onMediaSelect} accept="video/*, image/*" />
          </label>

          <button
            disabled={!image_id && !video_id && !videoBlobUrl}
            className={'mt-3 btn btn-primary w-100'}
            onClick={onRemoveMedia}
          >
                        DELETE
          </button>
        </div>
      </div>
      <EditTextArea
        style={{ height: '100px' }}
        editing={true}
        maxLength={2500}
        value={description}
        label="Body text"
        onChange={(val) =>
          onChangeEntity({
            description: val
          })
        }
      />
    </div>
  );
};

export default NoticeForm;
