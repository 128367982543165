import React from 'react';
import SwitchBar from 'components/SwitchBar/SwitchBar';
import { get, post, dodelete } from 'lib/comms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { EditText } from 'components/EditText/EditText';
import { MessageDialog } from 'components/Dialog/Dialog';

export default class KnowldgebaseItemCategoryDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      categories: [],
      isAddingCategory: false,
      newCategoryName: '',
      showMessage: false
    };
  }
    getKnowledgebaseCategories = async () => {
      // TODO - Categories are meant to be taken by a separate table in the db
      let resp = await get('cms/knowledgebase_categories', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      const categories = resp && resp.categories ? resp.categories : [];
      return categories;
    };

    selectCategory = (index) => {
      this.props.onSelectCategory(this.state.categories[index].id);
    };

    onChangeNewCategoryName = (newCategoryName) => {
      this.setState({ newCategoryName });
    };

    onAddNewCategory = () => {
      this.setState({
        isAddingCategory: true,
        newCategoryName: ''
      });
    };
    showErrorDialog = (message) => {
      setTimeout(
        () =>
          this.setState({
            showMessage: {
              message: message,
              title: 'Save error',
              close: () => this.setState({ showMessage: null })
            }
          }),
        500
      );
    };
    saveCategory = async () => {
      let { newCategoryName } = this.state;
      const existingCat = this.state.categories.filter((category) => category.name === newCategoryName);
      if (existingCat.length > 0) {
        this.showErrorDialog('Category already exists');
        return;
      }
      const resp = await post('cms/knowledgebase_categories', { name: newCategoryName }, true);
      if (resp && resp.success) {
        const categories = await this.getKnowledgebaseCategories();
        this.setState({
          newCategoryName: null,
          addingCategory: false,
          categories
        });
      } else {
        resp.message && this.showErrorDialog(resp.message);
      }
      this.setState({
        isAddingCategory: false
      });
    };

    componentDidMount () {
      this.reload();
    }

    reload = async () => {
      let categories = await this.getKnowledgebaseCategories();
      this.setState({
        categories
      });
    };

    render () {
      let { categories, active, isAddingCategory, newCategoryName } = this.state;
      const { isEditing } = this.props;
      categories = categories.map((category) => {
        return {
          ...category,
          isSelected: this.props.itemCategories.indexOf(category.id) !== -1
        };
      });
      return (
        <div className="categories-section">
          <div style={localStyle.subtitle}>Select Categories</div>
          {categories?.map((category, index) => (
            <ItemCategory
              isEditing={isEditing}
              key={category.id}
              name={category.name}
              id={index}
              onChange={this.selectCategory}
              isSelected={category.isSelected}
            />
          ))}

          {isAddingCategory && isEditing ? (
            <>
              <EditText
                label="New Category Name"
                editing={isEditing}
                onChange={(name) => this.onChangeNewCategoryName(name)}
                value={newCategoryName}
                maxLength={40}
              />
              <button className="btn btn-primary knowledgebase-btn" onClick={this.saveCategory}>
                            Add
              </button>
            </>
          ) : null}

          {isEditing && !isAddingCategory && (
            <button className="knowledgebaseItem-add-category-text btn" onClick={this.onAddNewCategory}>
                        + Add Category
            </button>
          )}
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}

const ItemCategory = ({ onChange, id, isEditing, isSelected, name }) => (
  <button
    className="knowledgebaseItem-category-checkbox-button"
    onClick={isEditing ? () => onChange(id) : null}
    disabled={!isEditing}
  >
    <FontAwesomeIcon
      icon={isSelected ? faCheckSquare : faSquare}
      className="knowledgebaseItem-category-checkbox"
      style={{ color: isEditing ? '#1774A7' : isSelected ? '#505A62' : '#888' }}
    />
    {name}
  </button>
);

const localStyle = {
  subtitle: {
    fontSize: '15px',
    fontWeight: '400',
    letterSpacing: '-0.15px',
    color: '#000'
  },
  typeTitle: {
    fontSize: '15px',
    fontWeight: '400',
    letterSpacing: '-0.15px',
    color: '#000',
    marginLeft: 8
  },
  TypeSubtitle: {
    fontSize: '15px',
    fontWeight: '400',
    letterSpacing: '-0.15px',
    color: '#505A62',
    textAlign: 'left',
    marginLeft: 8,
    marginTop: 3
  }
};
