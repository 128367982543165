import React, {useState, useEffect} from 'react';
import TextField, {Input} from '@material/react-text-field';
import ReCAPTCHA from 'react-google-recaptcha';

import './RegistrationUserEntry.css';
import RegistrationService from '../../../services/RegistrationService';

import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import Loader from '../../../components/Loader/Loader';

import CheckBoxRoundGreen from '../../../components/Icons/CheckBoxRoundGreen';
import CheckBoxRound from '../../../components/Icons/CheckBoxRound';
import {EditDate} from '../../../components/EditText/EditText';
import moment from 'moment';

const RegistrationUserEntry = ({ onRegistrationComplete }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [reCaptcha, setsReCaptcha] = useState(false);
  const [dob, setDob] = useState(undefined);

  const checkOrgName = async (nameToCheck) => {
    const result = await RegistrationService.validateOrgName(nameToCheck);

    if (result.status === 200) {
      return true;
    }

    if (result.status === 422) {
      return false;
    }
  };

  const checkEmailAddress = async (emailAddress) => {
    const result = await RegistrationService.validateEmailAddress(emailAddress);

    if (result.status === 200) {
      return true;
    }

    if (result.status === 422) {
      return false;
    }
  };

  const onSubmit = async (e) => {
    setIsProcessing(true);
    e.preventDefault();
    setError(null);
    let regResponse;

    const isEmailUnique = await checkEmailAddress(email);

    if (!isEmailUnique) {
      setError('This email address is already in use.');
      setIsProcessing(false);
      return;
    }

    const isOrgNameUnique = await checkOrgName(orgName);

    if (!isOrgNameUnique) {
      setError('This organisation name is already in use.');
      setIsProcessing(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Your passwords do not match.');
      setIsProcessing(false);
      return;
    }

    if (!reCaptcha) {
      setError('Please confirm that you are not a robot');
      setIsProcessing(false);
      return;
    }

    regResponse = await RegistrationService.selfRegister({
      firstName,
      email,
      password,
      orgName,
      dob
    });

    if (regResponse.fullyRegistered) {
      onRegistrationComplete();
    } else {
      setError(regResponse.error.message);
      setIsProcessing(false);
    }
  };

  const onChange = (value) => {
    setsReCaptcha(value);
  };

  const passwordCheckList = [
    {
      isPassed: false,
      title: 'At least 8 characters'
    },
    {
      isPassed: false,
      title: 'Contains a number'
    },
    {
      isPassed: false,
      title: 'Contains a special character'
    }
  ];
  const [passwordChecks, setPasswordChecks] = useState(passwordCheckList);

  useEffect(() => {
    let specialCharsFormat = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    let checkList = [...passwordCheckList];
    checkList[0].isPassed = password && password.length >= 8;
    checkList[1].isPassed = !!/\d/.test(password);
    checkList[2].isPassed = !!specialCharsFormat.test(password);
    const isPasswordValid = checkList.filter((item) => item.isPassed).length === 3;
    setPasswordChecks(checkList);
    setIsPasswordValid(isPasswordValid);
  }, [password]);

  return (
    <div className="registration-user-entry-wrapper">
      <h3>Start Your 30-Day Free Trial</h3>
      <div>Please provide some details to start your trial.</div>

      <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField className="mt-4" label="First Name" outlined={true}>
          <Input
            id="firstName"
            className="poppins"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            required
          />
        </TextField>
        <TextField className="mt-4" label="Email Address" outlined={true}>
          <Input
            id="email"
            className="poppins"
            name="email"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
          />
        </TextField>
        <TextField className="mt-4" label="Company Name" outlined={true}>
          <Input
            id="orgName"
            className="poppins"
            name="orgName"
            value={orgName}
            type="text"
            onChange={(e) => setOrgName(e.currentTarget.value)}
            required
          />
        </TextField>
        <TextField className="mt-4" label="Password" outlined={true}>
          <Input
            id="password"
            className="poppins"
            name="password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.currentTarget.value)}
            required
          />
        </TextField>
        <TextField className="mt-4" label="Confirm Password" outlined={true}>
          <Input
            id="confirmPassword"
            className="poppins"
            name="confirmPassword"
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            required
          />
        </TextField>
        <div className="mt-2">
          <EditDate
            editing={true}
            value={dob}
            isDateOfBirth
            dateFormat="dd/MM/yyyy"
            label="Date of Birth*"
            onChange={setDob}
            required
          />
        </div>
        {error ? <p className="mt-3 mb-3 login-error">{error}</p> : null}
        <div className="mt-3">
          <div>
                        Your password should be at least 8 characters long and contain a number and a special character
            <ul className="password-check-list pl-0 mt-3">
              {passwordChecks.map(({ isPassed, title }) => (
                <li key={title} className="password-check-item mb-3">
                  {isPassed ? <CheckBoxRoundGreen /> : <CheckBoxRound />}
                  <p className="mb-0 ml-2">{title}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <label className="mt-2 d-flex align-items-center">
          <input
            className="terms-checkbox"
            type="checkbox"
            name="termsAgreed"
            checked={termsAgreed}
            onChange={(e) => setTermsAgreed(e.target.checked)}
          />
          <p className="mb-0">
                        I have read and agree to the{' '}
            <span onClick={() => setShowTermsModal(true)} className="terms-and-conditions-link">
                            Terms & Conditions
            </span>
          </p>
        </label>

        <ReCAPTCHA
          style={{display: 'flex', justifyContent: 'center', paddingTop: 25}}
          sitekey={'6LcNUlodAAAAAN5njgq7l-2AiN0L8QbA-idcNFpf'}
          onChange={onChange}
        />

        <div className="mt-4 mb-4">
          <h3>What happens next?</h3>
          <div>
                        Once you submit the form, we will send you a confirmation email which includes the link to your
                        Free Trial account. You will then be able to enjoy the 30-Day Free Trial.
          </div>
        </div>

        <input
          disabled={
            !firstName ||
            !email ||
            !password ||
            !confirmPassword ||
            !isPasswordValid ||
            !termsAgreed ||
            !dob ||
            !reCaptcha
          }
          type="submit"
          value="SIGN UP"
          className="btn btn-primary"
        />

        {isProcessing && <Loader text="Loading..." />}

        {showTermsModal && (
          <div className="terms-modal-outer">
            <div className="terms-modal-inner">
              <div className="terms-modal-text-container">
                <h3>Terms & Conditions</h3>
                <TermsAndConditions />
              </div>
              <div className="terms-modal-button-container">
                <input
                  style={{ width: '300px' }}
                  className="btn btn-primary terms-close-button"
                  onClick={() => setShowTermsModal(false)}
                  value="CLOSE TERMS & CONDITIONS"
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default RegistrationUserEntry;
