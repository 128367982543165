import React from 'react';
import * as S from './KnowledgebaseItem.styled';
import { EditText, EditDropDown } from 'components/EditText/EditText';
import { MessageDialog } from 'components/Dialog/Dialog';

export default class KnowledgebaseDetailsItem extends React.Component {
  constructor (props) {
    super(props);
    this.state = { showMessage: null };
    this.detailTypes = [
      {
        id: 'PHONE_NUMBER',
        name: 'Phone Number'
      },
      {
        id: 'EMAIL_ADDRESS',
        name: 'Email Address'
      },
      {
        id: 'WEB_ADDRESS',
        name: 'Web Address'
      }
    ];
  }

    selectDetailType = (id, index) => {
      const details = [...this.props.details];
      details[index].type = id;
      details[index].value = '';
      this.props.updateKnowledgebaseItem({ details });
    };

    setDetailValue = (value, type, index) => {
      const details = [...this.props.details];
      details[index].value = value;
      this.props.updateKnowledgebaseItem({ details });
    };

    addDetails = () => {
      if (this.props.details.length > 4) {
        this.setState({
          showMessage: {
            message: 'You can set 5 details at maximum',
            title: 'Add Details'
          }
        });
        return;
      }
      const details = [...this.props.details, { type: 'PHONE_NUMBER', value: '' }];
      this.props.updateKnowledgebaseItem({ details });
    };

    removeDetail = (index) => {
      let details = [...this.props.details];
      details.splice(index, 1);
      this.props.updateKnowledgebaseItem({ details });
    };

    render () {
      let { isEditing, details } = this.props;
      return (
        <div style={{ marginTop: 20 }}>
          {details.map((detail, index) => (
            <S.DetailContainer key={index}>
              <div style={{ flex: 1 }}>
                <EditDropDown
                  label="Details Type"
                  options={this.detailTypes}
                  editing={isEditing}
                  onChange={(type) => this.selectDetailType(type, index)}
                  value={detail.type}
                />
              </div>
              <S.HorizontalSpacer size={20} />
              <div style={{ flex: 1 }}>
                <EditText
                  type={detail.type === 'PHONE_NUMBER' ? 'number' : 'text'}
                  editing={isEditing}
                  onChange={(value) => this.setDetailValue(value, detail.type, index)}
                  value={detail.value || ''}
                />
              </div>
              <S.HorizontalSpacer size={20} />
              <button
                className="btn btn-remove"
                onClick={() => this.removeDetail(index)}
                disabled={!isEditing}
                isEditing={isEditing}
              >
                            Remove
              </button>
            </S.DetailContainer>
          ))}

          <S.BottomDetailsContainer>
            <button
              className="btn btn-primary knowledgebase-btn"
              disabled={!isEditing}
              onClick={() => {
                this.addDetails();
              }}
            >
                        Add Details
            </button>
          </S.BottomDetailsContainer>
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
