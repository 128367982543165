import React, { useContext } from 'react';
import Card from 'components/Card/Card';
import EditCheckBox from 'components/EditCheckBox/EditCheckBox';
import { USER_ROLES } from '../../../../App';
import { UserContext } from '../../../../contexts/UserContext';

const UserRoles = ({
  roles,
  emailToReport,
  userRoleIds,
  isEditing,
  onUpdateUserRoles,
  onSelectReportsRole,
  onToggle
}) => {
  const LoggedInUserContext = useContext(UserContext);
  const isSuperAdmin = !!(
    LoggedInUserContext.userRoleIds && LoggedInUserContext.userRoleIds.indexOf(USER_ROLES.ADMIN) !== -1
  );
  if (!isSuperAdmin) {
    return null;
  }
  const onClickCheckbox = (roleId) => {
    if (!isEditing) return;
    if (roleId === USER_ROLES.REPORTS && !emailToReport) {
      return onSelectReportsRole();
    }
    let roleIds = [...userRoleIds];
    const index = roleIds.indexOf(roleId);
    if (index !== -1) roleIds.splice(index, 1);
    // if role id present remove it
    else roleIds.push(roleId); // if role id not present insert it
    onUpdateUserRoles(roleIds);
  };
  if (roles && !roles.length) {
    return null;
  }
  return (
    <Card title="User Roles" editing={isEditing} onToggle={onToggle}>
      <div
        style={{
          border: '1px solid #979797',
          borderRadius: '5px',
          maxHeight: 320,
          overflowY: 'scroll',
          margin: '16px 0px'
        }}
      >
        {roles.map(({ title, description, id }, index) => {
          return (
            <EditCheckBox
              className="p-3"
              style={{
                backgroundColor: index % 2 === 0 ? 'white' : '#CBCBCB20'
              }}
              editing={isEditing}
              title={title}
              onClick={() => onClickCheckbox(id)}
              isChecked={userRoleIds.indexOf(id) !== -1}
              desc={description}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default UserRoles;
