import React from 'react';
import Card from 'components/Card/Card';
import Question from './Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export default class Questions extends React.Component {
  constructor (props) {
    super(props);
    this.state = { content: JSON.parse(props.learning.content || '[]'), editing: !!props.editing };
  }

    toggle = () => {
      const editing = !this.state.editing;
      this.setState({ editing });
      if (!editing) {
        let content = [...this.state.content].filter((item) => item.question !== undefined && item.question !== '');
        content.forEach(
          (question) =>
            (question.answers = question.answers
              ? question.answers.filter((answer) => answer.title !== '')
              : null)
        );
        this.setState({ content });
        const outOf = content.reduce((total, question) => (question.answers ? total + 1 : total), 0);
        this.props.onEdit({ content: JSON.stringify(content), outOf });
      }
    };

    updateQuestion = (index, question) => {
      const { content } = this.state;
      content[index] = question;
      this.setState({ content });
    };

    addQuestion = (questionType) => {
      const { content } = this.state;
      let newContent = questionType === 'question' ? { answers: [] } : {};
      content.push(newContent);
      this.setState({ content });
    };

    render () {
      const { content, editing } = this.state;
      const { allowEditAnswers } = this.props;
      console.log(allowEditAnswers);
      return (
        <Card title="Questions" editing={editing ? '1' : undefined} onToggle={this.toggle}>
          <div className="learning-questions-inner">
            {content.map((item, index) => (
              <Question
                editing={editing}
                key={index + (editing ? 'a' : 'b')}
                item={item}
                index={index}
                onEdit={(question) => this.updateQuestion(index, question)}
                allowEditAnswers={allowEditAnswers}
              />
            ))}
            {editing && allowEditAnswers ? <AddNewSection onAdd={this.addQuestion} /> : null}
          </div>
        </Card>
      );
    }
}

const AddNewSection = (props) => (
  <div className="mt-4">
    <button className="btn learning-add-new-button " onClick={() => props.onAdd('question')}>
      <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} /> ADD NEW QUESTION
    </button>
    <button
      className="btn learning-add-new-button ml-3"
      style={{ backgroundColor: '#FBB811' }}
      onClick={() => props.onAdd('statement')}
    >
      <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
            ADD NEW STATEMENT
    </button>
  </div>
);
