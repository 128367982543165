import React from 'react';
import { get } from 'lib/comms';
import './BillingPage.css';
import InvoiceHistory from './components/InvoiceHistory';
import PlanDetails from './components/PlanDetails';
import UpcomingBill from './components/UpcomingBill';
import PaymentDetails from './components/PaymentDetails';
import WantMore from './components/WantMore';
import BillingService from 'services/BillingService';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import PlanCancellation from './components/PlanModification/PlanCancellation';
import { MessageDialog } from 'components/Dialog/Dialog';
import Loader from 'components/Loader/Loader';
import PlanReactivation from './components/PlanModification/PlanReActiviation';
import ToolTip from 'components/ToolTip/TooltTip';

const BILLING_TOOLTIPS = [
  {
    target: '.upcoming-bill',
    title: 'Your next estimated bill',
    content:
            'You are charged monthly based on the number of users you have in the system on your billing date. From here you can see based on your current number of users what you can anticipate your next bill to be.',
    placement: 'right'
  },
  {
    target: '.plan-detail-cont',
    title: 'Your Plan',
    content:
            'From here you can see the details of your current plan. You also have the option to cancel at anytime by selecting the cancel option.',
    placement: 'right'
  },
  {
    target: '.dialog-overlay-outer',
    title: 'Cancelling your subscription',
    content:
            'To cancel your subscription fill in the short form below and enter your password to confirm your cancellation. You will not be billed again after cancelling and you will have access up until your next payment date when access will be revoked for all users and all the data will be permanently deleted. If you change your mind you can reactive before the end of the cancellation date.',
    placement: 'bottom'
  },
  {
    target: '.invoice-history',
    title: 'Invoice History',
    content: 'you can download and view your invoices from here by selecting the PDF button.',
    placement: 'bottom'
  },
  {
    target: '.payment-details',
    title: 'Your payment details',
    content:
            'You can view and update your payment details from this screen, select edit to update the card details or billing address.',
    placement: 'right'
  }
];

export default class BillingPage extends React.Component {
    state = {
      billing_details: {},
      loading: false,
      cancelPlan: false,
      reactivatePlan: false,
      stepIndex: 0
    };
    componentDidMount () {
      this.showLoading();
      this.getData();
    }

    getData = async () => {
      const billingDetails = await BillingService.getBillingDetails();
      this.setState({
        billing_details: { ...billingDetails, payment_details: {}, invoice_history: [] } || {},
        loading: false,
        cancelPlan: false,
        reactivatePlan: false
      });
    };

    onClickCancelPlan = () =>
      this.setState({
        cancelPlan: true
      });

    updateMessage = (respMessage) => {
      this.setState({ message: respMessage, loading: false });
    };

    showLoading = () => {
      this.setState({
        loading: true
      });
    };

    render () {
      const { message, cancelPlan, reactivatePlan, loading, stepIndex } = this.state;
      const { upcoming_bill, invoice_history, payment_details, subscription } = this.state.billing_details;

      return (
        <>
          <div className="maincontainer billing-container">
            {subscription && (subscription.scheduled_to_cancel || subscription.status === 'canceled') ? (
              <SubscriptionBanner />
            ) : null}
            <p className="billing-header">Billing</p>
            <div className="d-flex">
              {loading ? <Loader /> : null}
              {payment_details ? (
                <>
                  <UpcomingBill upcomingBill={upcoming_bill || {}} />
                  <PlanDetails
                    scheduledToCancel={subscription && subscription.scheduled_to_cancel}
                    cancelled={subscription && subscription.status === 'canceled'}
                    onClickCancelPlan={this.onClickCancelPlan}
                    onReactivatePlan={() =>
                      this.setState({
                        reactivatePlan: true
                      })
                    }
                  />
                  <ToolTip
                    screenId="billing"
                    stepIndex={this.state.stepIndex}
                    callback={(tooltipData) => {
                      if (tooltipData.lifecycle === 'complete') {
                        this.setState({
                          stepIndex:
                                                    tooltipData.action === 'prev'
                                                      ? tooltipData.index - 1
                                                      : tooltipData.index + 1,
                          editingLayout:
                                                    (stepIndex === 1 && tooltipData.action === 'next') ||
                                                    (stepIndex === 3 && tooltipData.action === 'prev')
                                                      ? this.setState({ cancelPlan: true })
                                                      : this.setState({ cancelPlan: false })
                        });
                      }
                    }}
                    style={{ width: 480 }}
                    steps={BILLING_TOOLTIPS}
                  />
                </>
              ) : !loading ? (
                <p className="mt-3">Billing section coming soon</p>
              ) : null}
              {invoice_history ? <InvoiceHistory invoiceHistory={invoice_history} /> : null}
            </div>
            <div className="d-flex">
              {payment_details ? (
                <>
                  <PaymentDetails paymentDetails={payment_details} />
                  <WantMore />
                </>
              ) : null}
            </div>
          </div>
          {cancelPlan ? (
            <PlanCancellation
              updateMessage={this.updateMessage}
              showLoading={this.showLoading}
              onModifyAccount={() => {
                this.setState({
                  cancelPlan: false
                });
                this.getData();
              }}
              onClose={() =>
                this.setState({
                  cancelPlan: false
                })
              }
            />
          ) : null}
          {reactivatePlan ? (
            <PlanReactivation
              updateMessage={this.updateMessage}
              showLoading={this.showLoading}
              onModifyAccount={() => {
                this.setState({
                  reactivatePlan: false
                });
                this.getData();
              }}
              onClose={() =>
                this.setState({
                  reactivatePlan: false
                })
              }
            />
          ) : null}
          {message ? (
            <MessageDialog
              title={message.title}
              message={message.message}
              onClose={() => this.updateMessage(null)}
            />
          ) : null}
        </>
      );
    }
}
