import React from 'react';
import { EditText, EditTextArea } from 'components/EditText/EditText';
import EditBrowse from 'components/EditBrowse/EditBrowse';
import * as S from './KnowledgebaseItem.styled';

export default class KnowledgebaseFileItem extends React.Component {
    setFileName = (fileName) => {
      const fileObj = this.getFileObj();
      this.props.updateKnowledgebaseItem({
        files: [{ ...fileObj, title: fileName }]
      });
    };

    onFileChange = (file) => {
      const fileObj = this.getFileObj();
      this.props.updateKnowledgebaseItem({
        files: [{ ...fileObj, file, original_name: file.name }]
      });
    };

    removeFile = () => {
      this.props.updateKnowledgebaseItem({ files: [] });
    };

    getFileObj = () => {
      return this.props.files && this.props.files.length > 0 ? this.props.files[0] : {};
    };
    render () {
      let { isEditing, description } = this.props;
      const fileObj = this.getFileObj();
      return (
        <div>
          <div style={{ width: '100%' }}>
            <EditTextArea
              label="Knowledgebase File Description"
              editing={isEditing}
              onChange={(description) => this.props.updateKnowledgebaseItem({ description })}
              value={description}
              maxLength={256}
            />
          </div>

          <S.VerticalSpacer size={10} />

          <S.FileContainer>
            <EditText
              outerClassName="mt-3 mb-3 w-50"
              label="File Name"
              editing={isEditing && !fileObj.id}
              onChange={(fileName) => this.setFileName(fileName)}
              value={fileObj.title || ''}
            />
            <S.HorizontalSpacer size={20} />
            <EditBrowse
              editing={isEditing && !fileObj.id}
              onFileChange={this.onFileChange}
              fileName={fileObj['original_name'] || ''}
            />
            {fileObj.id && <S.HorizontalSpacer size={20} />}
            {fileObj.id && (
              <button
                className="btn btn-remove"
                onClick={this.removeFile}
                disabled={!isEditing && fileObj.id}
                isEditing={isEditing && !fileObj.id}
              >
                            Remove
              </button>
            )}
          </S.FileContainer>
        </div>
      );
    }
}
