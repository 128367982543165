import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import Table from 'components/Table/Table';
import ChallengePage from './ChallengePage';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lib/comms';
import { Redirect, Router } from 'react-router-dom';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import ToolTip from 'components/ToolTip/TooltTip';

const columns = [
  { name: 'Name of the Challenge', grow: 7, selector: 'name', sortable: true, wrap: true },
  {
    name: 'Start date',
    grow: 2,
    selector: 'start_date',
    sortable: true,
    format: (row) => moment(row.start_date).format('DD MMM YYYY')
  },
  { name: 'Participants', selector: 'member_count', sortable: true, center: true },
  {
    name: 'Points value',
    grow: 2,
    selector: 'effort_level',
    sortable: true,
    center: true,
    format: (row) => row.effort_level * 50
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: true,
    center: true,
    format: (row) => (row.active ? 'Active' : 'Archived')
  },
  {
    name: '',
    selector: 'id',
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
  }
];

export default class ChallengesPage extends React.Component {
  constructor (props) {
    super(props);
    this.navigationState = this.props.location.state;
    this.previousPage = this.navigationState?.from;
    this.fromAnotherPage =
            this.navigationState?.from === 'onboarding' || this.navigationState?.from === 'campaigns';
    this.state = {
      data: [],
      createdNew: false,
      stepIndex: 0,
      fromAnotherPage: false,
      editing: this.fromAnotherPage ? { id: 'new' } : null
    };
  }

    componentDidMount = () => this.reload();

    reload = async () => {
      let resp = await get('cms/challenges', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.challenges) this.setState({ data: resp.challenges });
      else console.log(resp);
    };

    updateItemCreated = () => {
      this.setState({ createdNew: true });
    };

    doneEditing = () => {
      if (this.fromAnotherPage) {
        this.setState({ fromAnotherPage: true });
      } else {
        this.setState({ editing: null });
        this.reload();
      }
    };

    render () {
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      if (this.state.fromAnotherPage) {
        return (
          <Redirect
            to={{
              pathname: `/${this.previousPage}`,
              state: { ...this.navigationState, from: 'challenges', createdNew: this.state.createdNew }
            }}
          />
        );
      }
      let { data, search, editing } = this.state;
      if (search) {
        data = data.filter(
          (item) => `${item.name} ${item.category}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <>
          {this.state.editing ? (
            <ChallengePage
              challenge={editing}
              onBack={this.doneEditing}
              updateItemCreated={this.updateItemCreated}
            />
          ) : (
            <div className="maincontainer">
              <SubscriptionBanner />
              <h4>Challenges</h4>
              <SearchRow
                value={search}
                searchTitle="Search by challenge name"
                buttonTitle="ADD NEW CHALLENGE"
                onBtnClick={(item) => this.setState({ editing: { id: 'new' } })}
                onSearch={(search) => this.setState({ search })}
              />
              <Table
                columns={columns}
                data={data}
                onRowClicked={(item) => this.setState({ editing: item })}
                tableID="challenges"
                defaultSortField={columns[1].selector}
              />
            </div>
          )}
          <ToolTip
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  editing:
                                    (this.state.stepIndex === 0 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 1 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 2 && tooltipData.action === 'prev')
                                      ? { id: 'new' }
                                      : null
                });
              }
            }}
            screenId={'challenges'}
            steps={[
              {
                target: '.searchrow-btn',
                title: 'Add New Challenge',
                content:
                                'Challenges are a great way to get your users working towards a common goal without the need to all be in the same place at the same time. Select Add new challenge to get started.'
              },
              {
                target: '.card',
                title: 'Creating your challenge',
                content:
                                'Give your challenge a title and add a description about this challenge. You can then choose the time period for this challenge to run, the start date will be the date the challenge becomes available in the engage tab in the app for users to find and join. You can then allocate the effort level which will also award points for when this challenge is complete. The last field is target this number which allows you to set how many times an activity must be done to complete this challenge.'
              },
              {
                target: '.card-edit',
                title: 'Saving and managing your challenge',
                content:
                                'Once you select save you will see a list of any user who have joined the challenge or you can add users to this challenge. You will also see the option to delete the challenge which if selected will delete the challenge and any progress and can not be undone.'
              }
            ]}
            stepIndex={this.state.stepIndex}
          />
        </>
      );
    }
}
