import React from 'react';
import Card from 'components/Card/Card';
import BackRow from 'components/BackRow/BackRow';
import { EditText } from 'components/EditText/EditText';
import { MessageDialog } from 'components/Dialog/Dialog';
import './DepartmentPage.css';

export default function NewDepartmentPage (props) {
  return (
    <div className="maincontainer">
      <BackRow onBack={props.back} />
      <Card title="Department" editing={'1'} onToggle={props.submit}>
        <div className="">
          <div className="">
            <EditText label="Department Name" editing={'1'} onChange={props.onEdit} value={props.value} />
          </div>
        </div>
      </Card>
      {props.errorMessage ? (
        <MessageDialog
          message={props.errorMessage.message}
          title={props.errorMessage.title}
          onClose={() => props.errorMessage.close()}
        />
      ) : null}
    </div>
  );
}
