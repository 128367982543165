import { get, post } from 'lib/comms';

export const OptionMap = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E'
};
export const getPollOptions = () => {
  const options = [];
  for (let index = 0; index < 30; index++) {
    options.push(`${index + 1} ${index === 0 ? 'day' : 'days'}`);
  }
  return options;
};

class ContentService {
    fetchContent = async (contentType) => {
      const requestUrlPath =
            contentType === 'challenges'
              ? 'challenges'
              : contentType === 'learning'
                ? 'learnings'
                : contentType === 'knowledgebase'
                  ? 'knowledgebase_items'
                  : '';
      if (requestUrlPath) {
        let resp = await get(`cms/${requestUrlPath}`, null, true);
        if (resp) {
          let data = resp.challenges || resp.learnings || resp.items || [];
          if (resp.learnings) {
            data = data.filter((item) => item.posted_date !== null);
          }
          data = data.filter((item) => item.active);
          return data;
        }
      }
      return [];
    };

    validateContent = (slctdItem = {}, entity = {}) => {
      let errMsg = '';
      let isError = false;
      const { id, intro, type } = slctdItem || {};
      const { description, options = [] } = entity || {};
      if (!intro) {
        isError = true;
        errMsg = 'Please add introduction';
      } else if (!id && (type === 'learning' || type === 'challenges')) {
        isError = true;
        errMsg = 'Please select content';
      } else if (type === 'notices' && !description) {
        isError = true;
        errMsg = 'Please add body text';
      } else if (type === 'polls') {
        if (!description) {
          isError = true;
          errMsg = 'Please add poll description';
        } else {
          const isOptionEmpty = options.filter((item) => !item.option_text);
          if (options.length === 0 || isOptionEmpty.length > 0) {
            isError = true;
            errMsg = 'Please enter option text';
          }
        }
      }
      return {
        isError,
        errMsg
      };
    };
}
export default new ContentService();
