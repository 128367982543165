import React, { useState, useEffect } from 'react'
import CampaignsService from 'services/CampaignsService'
import StatisticsItem from './StatisticsItem'
import '../../Campaign.css'
import { ResultQuestion } from '../../../MicroLearning/Results'

const ReportDetails = ({ item }) => {
  const { type, statistics } = item

  const {
    challenge_participants,
    challenge_completed,
    poll_votes,
    poll_engagement,
    likes_counter,
    comments_counter,
    poll_options = [],
    learning_completed = 0,
    learning_score = 0,
    learning_content,
    learning_answers,
    knowledgebase_views = 0,
    knowledgebase_unique_views = 0
  } = statistics || {}

  return (
    <div className='d-flex flex-column mt-3'>
      {type === 'learning' ? (
        <div className='d-flex flex-column'>
          <p className='content-title' style={{ fontWeight: 600 }}>
            Questions:
          </p>
          {learning_content
            ? learning_content.map((item, index) => (
                <ResultQuestion
                  key={index}
                  item={item}
                  index={index}
                  outof={learning_answers.length}
                />
              ))
            : null}
        </div>
      ) : null}
      {type === 'polls' ? (
        <div className='d-flex flex-column'>
          <p className='content-title' style={{ fontWeight: 600 }}>
            Results:
          </p>
          {poll_options.map(({ total_votes, option_text }) => (
            <div className='poll-option-cont'>
              <div
                style={{ display: 'flex', position: 'relative', width: 400 }}
              >
                <div
                  style={{
                    width:
                      total_votes > 0
                        ? `${(total_votes / poll_votes) * 100}%`
                        : '0%'
                  }}
                  className={total_votes > 0 ? 'poll-option-text' : ''}
                />
                <p className='pl-2 m-0'>{option_text}</p>
              </div>
              <span style={{ fontWeight: 600 }}>{`${total_votes} Votes`}</span>
            </div>
          ))}
        </div>
      ) : null}
      <p className='mt-3 content-title' style={{ fontWeight: 600 }}>
        Statistics:
      </p>
      {type === 'challenges' ? (
        <div className='d-flex'>
          <StatisticsItem
            name={'Participants'}
            value={challenge_participants}
          />
          <StatisticsItem name={'Completed'} value={challenge_completed} />
        </div>
      ) : null}
      {type === 'knowledgebase' ? (
        <div className='d-flex'>
          <StatisticsItem name={'Times Browsed'} value={knowledgebase_views} />
          <StatisticsItem
            name={'Number of Unique Views'}
            value={knowledgebase_unique_views}
          />
        </div>
      ) : null}
      {type === 'notices' || type === 'polls' ? (
        <div className='d-flex'>
          {type === 'polls' && (
            <>
              <StatisticsItem name={'Total Votes'} value={poll_votes} />
              <StatisticsItem
                name={'Avg Engagement'}
                value={`${poll_engagement || 0}%`}
              />
            </>
          )}
          <StatisticsItem name={'Likes'} value={likes_counter} />
          <StatisticsItem name={'Comments'} value={comments_counter} />
        </div>
      ) : null}

      {type === 'learning' ? (
        <div className='d-flex'>
          <StatisticsItem name={'Times Completed'} value={learning_completed} />
          <StatisticsItem name={'Average Score'} value={learning_score} />
        </div>
      ) : null}
    </div>
  )
}

export default ReportDetails
