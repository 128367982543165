import React from 'react';
import moment from 'moment';
import { Dialog } from 'components/Dialog/Dialog';

const RunOrStopCampaign = (props) => {
  const { status, template_id, start_date, updateCampaign, OnClickRunOrStopCampaign } = props;
  const RUN_STOP_DIALOG_TITLE = status === 'active' ? 'STOP' : 'RUN';
  const RUN_STOP_DIALOG_MESSAGE =
        status === 'active'
          ? `Are you sure want to stop this campaign? Doing so will stop the campaign immediately and no further content will be posted. If you would like to keep the content from this campaign for future use, select “Save as a template”. You cannot restart a campaign once it has been stopped.
        `
          : `Are you sure you want to run this campaign? If yes, this campaign will begin on ${moment()
            .add(1, 'days')
            .format(
              'DD/MM/YYYY'
            )} at the time selected. Once a campaign is running you can not edit any content that has already been published or the campaign title and description, you can edit future content. You can stop running this campaign at any time from selecting "Stop campaign"`;

  const onClickSaveAsTemplate = () => {
    let params = {
      status: 'ended',
      end_date: moment().format('YYYY-MM-DD'),
      is_template: true
    };
    updateCampaign(params);
  };

  const onRunOrStopCampaign = () => {
    let params;
    if (status === 'draft') {
      // set to run campaign
      params = {
        status: 'upcoming',
        start_date: start_date
          ? moment(start_date).format('YYYY-MM-DD')
          : moment().add(1, 'day').format('YYYY-MM-DD'),
        auto_start: 1
      };
    } else {
      // set to stop campaign
      params = {
        status: 'ended',
        end_date: moment().format('YYYY-MM-DD')
      };
    }
    updateCampaign(params, true);
  };

  return (
    <Dialog
      title={`${RUN_STOP_DIALOG_TITLE} CAMPAIGN`}
      btnTitle={`YES, ${RUN_STOP_DIALOG_TITLE} THIS CAMPAIGN `}
      btnNo={`NO, DON'T ${RUN_STOP_DIALOG_TITLE} THIS CAMPAIGN`}
      btnNoWithSave={status === 'active' && !template_id ? 'STOP AND SAVE AS TEMPLATE' : ''}
      OnClickBtnNoWithSave={onClickSaveAsTemplate}
      onConfirm={onRunOrStopCampaign}
      onClose={() => OnClickRunOrStopCampaign(false)}
    >
      <p className="dialog-text">{RUN_STOP_DIALOG_MESSAGE}</p>
    </Dialog>
  );
};

export default RunOrStopCampaign;
