import React from 'react';

import './SettingsRow.css';
import SwitchBar from '../../../../components/SwitchBar/SwitchBar';
import SmallLoader from '../../../../components/SmallLoader/SmallLoader';

export const SettingsRow = ({ title, subtitle, options, values, value, updating, onChange }) => {
  return (
    <div className="settings-row">
      <div className="settings-row__text-container">
        <div className="settings-row__title">{title}</div>
        {subtitle}
      </div>
      <div className="settings-row__switch-container">
        {updating ? (
          <SmallLoader color="#0F2B41" />
        ) : (
          <SwitchBar editing={true} options={options} values={values} value={value} onChange={onChange} />
        )}
      </div>
    </div>
  );
};
