import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CloseIcon from '@material-ui/icons/Close';

import Card from 'components/Card/Card';
import UpdateCard from './UpdateCard/UpdateCard';
import '../BillingPage.css';

const PaymentDetails = ({ paymentDetails }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(paymentDetails || {});

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  let { name, address = {}, card } = paymentInfo;
  const convertToCamelCase = (input) => {
    if (input) {
      let part1 = input.substring(0, 1).toUpperCase();
      let part2 = input.substring(1).toLowerCase();
      return part1 + part2;
    }
    return '';
  };

  const onEdit = () => setShowEditModal(true);
  const card_type = card?.brand;
  const card_no = card?.last4 ? '**** **** **** ' + card?.last4 : '';
  return (
    <>
      <Card onToggle={onEdit} outerClass="payment-details card-medium" title="Your Payment Details">
        <div className="d-flex w-100">
          <div className="d-flex flex-column w-50">
            <p className="plan-details" style={{ fontWeight: 600 }}>
                            Card Details:
            </p>
            <p className="plan-details">{card_no}</p>
            <p className="plan-details">{name}</p>
            <p className="plan-details">{convertToCamelCase(card_type)}</p>
          </div>
          <div className="d-flex flex-column w-50">
            <p className="plan-details" style={{ fontWeight: 600 }}>
                            Address:
            </p>
            <p className="plan-details">{address.line1}</p>
            <p className="plan-details">{address.postal_code}</p>
          </div>
        </div>
      </Card>
      {showEditModal && (
        <div className="payment-details-modal-outer">
          <div className="payment-details-modal-inner">
            <div className="payment-details-modal-close" onClick={() => setShowEditModal(false)}>
              <CloseIcon fontSize="large" />
            </div>
            <h3>Update Payment Information</h3>
            <p>Please enter your new payment and billing information below.</p>
            <Elements stripe={stripePromise}>
              <UpdateCard
                onComplete={(updatedDetails) => {
                  setPaymentInfo(updatedDetails);
                  setShowEditModal(false);
                }}
                paymentDetails={{
                  cardName: name || '',
                  postCode: address.postal_code || '',
                  addressLineOne: address.line1 || ''
                }}
              />
            </Elements>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
