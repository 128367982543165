import { get, post, put, dodelete } from 'lib/comms';
import moment from 'moment';
import { convertToCamelCase } from 'lib/utils';

const getNoOfWeeks = (days) => {
  const weeks = days / 7;
  return `${weeks} ${weeks > 1 ? 'Weeks' : 'Week'}`;
};

class CampaignsService {
    getCampaigns = async () => {
      const campaignsResp = await get('cms/campaigns', null, true);
      if (campaignsResp && campaignsResp.campaigns) {
        let campaigns = campaignsResp.campaigns;
        campaigns = campaigns.map((item) => {
          const { start_date, no_of_days, status } = item;
          return {
            ...item,
            formattedStartDate: start_date ? moment(start_date).format('DD MMM YYYY') : '-',
            length: getNoOfWeeks(no_of_days),
            statusLabel: convertToCamelCase(status)
          };
        });
        return campaigns;
      }
      return [];
    };

    getTemplates = async () => {
      const templateResp = await get('cms/campaign_templates', null, true);
      if (templateResp && templateResp.campaign_templates) {
        let templates = templateResp.campaign_templates;
        templates = templates.map((item) => {
          return {
            ...item,
            length: getNoOfWeeks(item.no_of_days)
          };
        });
        return templates;
      }
      return [];
    };

    createOrEditCampaign = async (reqBody) => {
      let resp;
      if (reqBody.id) {
        resp = await put(`cms/campaigns/${reqBody.id}`, reqBody, true);
      } else {
        resp = await post('cms/campaigns', reqBody, true);
      }
      if (resp && resp.success) {
        return resp;
      }
      return {};
    };

    deleteCampaign = async (campaignId) => {
      const resp = await dodelete(`cms/campaigns/${campaignId}`, {}, true);
      if (resp.message) {
        return resp.message;
      }
    };

  getContent = async (campaignId, withStatistics) => {
    const resp = await get(
      `cms/campaign_content/${campaignId}`,
      {
        with_statistics: withStatistics
      },
      true
    )
    if (resp && resp.campaign_content) {
      let content = resp.campaign_content
      if (withStatistics) {
        content = content.map(contentItem => {
          if (contentItem.type === 'learning') {
            const statistics = contentItem.statistics
            if (statistics && statistics.learning_content) {
              let answers = statistics.learning_answers
              let content = JSON.parse(statistics.learning_content || '[]')
              if (answers) {
                answers = answers.map(answer =>
                  answer ? JSON.parse(answer) : []
                )
                content = content.map((item, qNo) => {
                  item.answers = item.answers
                    ? item.answers.map(answer => ({ ...answer, score: 0 }))
                    : null
                  return item
                })
                answers.forEach(personAnswers => {
                  personAnswers.forEach((answer, index) => {
                    const answered = answer.split('.')
                    for (var answerIndex of answered) {
                      if (
                        content[index] &&
                        content[index].answers &&
                        content[index].answers[answerIndex]
                      )
                        content[index].answers[answerIndex].score += 1
                    }
                  })
                })
              }
              console.log({
                ...contentItem,
                statistics: {
                  ...statistics,
                  learning_content: content,
                  learning_answers: answers
                }
              })
              return {
                ...contentItem,
                statistics: {
                  ...statistics,
                  learning_content: content,
                  learning_answers: answers
                }
              }
            }
          }
          return contentItem
        })
      }
      return content
    }
    return []
  }

    getContentStatistics = async (contentId) => {
      const resp = await get(`cms/campaign_statistics/${contentId}`, null, true);
      if (resp && resp.campaign_statistics) {
        let statistics = resp.campaign_statistics;
        return statistics;
      }
      return {};
    };
    deleteContent = async (contentId) => {
      const resp = await dodelete(`cms/campaign_content/${contentId}`, {}, true);
      if (resp.message) {
        return resp.message;
      }
    };

  addOrEditContent = async (campaign, contentItem) => {
    let resp
    if (campaign.start_date) {
      const { sequence_no, time_to_push } = contentItem
      const pushTimeArr = time_to_push ? time_to_push.split(':') : ''
      const hours = pushTimeArr && pushTimeArr[0] ? pushTimeArr[0] : 0
      const minutes = pushTimeArr && pushTimeArr[1] ? pushTimeArr[1] : 0
      let pushDateTime = new Date(campaign.start_date)
      pushDateTime.setDate(pushDateTime.getDate() + sequence_no - 1)
      pushDateTime.setHours(hours)
      pushDateTime.setMinutes(minutes)
      contentItem.push_on = moment(pushDateTime)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    } else {
      contentItem.push_on = null;
    }

    if (campaign.status == 'ended') {
      contentItem.push_on = null;
    }
      if (contentItem.id) {
        resp = await put(
          `cms/campaign_content/${contentItem.id}`,
          { campaign_id: campaign.id, ...contentItem },
          true
        );
      } else {
        resp = await post(`cms/campaign_content/${campaign.id}`, contentItem, true);
      }

      return resp || {};
    };

    getGroupsList = async () => {
      let resp = await get('cms/groups', null, true);
      if (resp && resp.groups) {
        let groups = resp.groups.sort((item1, item2) => {
          if (item1.name > item2.name) {
            return 1;
          } else if (item1.name < item2.name) {
            return -1;
          } else {
            return 0;
          }
        });
        const [orgGroup] = groups.filter((item) => item.is_org_level);

        if (orgGroup) {
          const orgGroupIndex = groups.findIndex((item) => item.is_org_level);
          groups.splice(orgGroupIndex, 1);
          groups.splice(0, 0, orgGroup || {});
        }

        return groups;
      }
      return [];
    };
}

export default new CampaignsService();
