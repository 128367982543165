import React from 'react';

import './RegistrationComplete.css';

const RegistrationComplete = () => {
  return (
    <div className="registration-complete-wrapper">
      <h3>You&apos;re all set!</h3>

      <div>
                Please check your inbox for an email from hello@engage4.vidatec.com with the link to your Engage4
                Dashboard. Make sure to whitelist our email address so you don’t miss any of our product guides or
                updates.
      </div>

      <div className="mt-3">
                It’s easy to get started with Engage4 but if you have any questions please check out our user guide
                here: <a href="https://bit.ly/3nHO3RU">https://bit.ly/3nHO3RU</a> or contact us for help by calling{' '}
        <a href="tel:447501652253">+44 (0) 7501 653 253</a> or send us an email to{' '}
        <a href="mailto:hello@engage4.vidatec.com">hello@engage4.vidatec.com</a> and we will be happy to assist.
      </div>
    </div>
  );
};

export default RegistrationComplete;
