import React from 'react';
import { get, post, dodelete } from 'lib/comms';
import './TagsPage.css';
import Tag from './Tag';
import { Redirect } from 'react-router-dom';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewTag from './NewTag';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';

export default class TagsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = { data: [], search: '', newTagActive: false };
  }

    componentDidMount = () => this.reload();

    reload = async () => {
      let resp = await get('content/tag', null, true);

      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.tags) this.setState({ data: resp.tags, newTagActive: false });
    };

    handleTagCreationButton = async () => {
      const { data } = this.state;
      data.push({
        id: null,
        type: 1,
        title: '',
        options: [
          { id: -1, label: '' },
          { id: -2, label: '' }
        ]
      });
      this.setState({ data: data, newTagActive: true });
    };

    handleCancelNewButton = async (e, tag) => {
      const { data } = this.state;
      data.pop();
      this.setState({ data: data, newTagActive: false });
    };

    handleCreateNewTagNewButton = async (e, tag) => {
      const options =
            tag.type === 1
              ? tag.options.reduce((a, c) => {
                a.push({ label: c.label });
                return a;
              }, [])
              : [{ label: tag.options[0].label }];
      const newTag = { title: tag.title, type: tag.type, options };
      const resp = await post('cms/tag', newTag, true);
      if (resp.success) await this.reload();
    };

    handleDeleteTag = async (tagId) => {
      const resp = await dodelete(`cms/tag/${tagId}`, [], true);
      if (resp.success) {
        const { data } = this.state;
        const dataN = data.filter((tag) => tag.id !== tagId);
        this.setState({ data: dataN });
      } else {
        alert(resp);
      }
    };

    render () {
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      let { data } = this.state;
      const newTagActiveStatus = data.length > 3 ? true : this.state.newTagActive;
      return (
        <div className="maincontainer">
          <SubscriptionBanner />
          <div style={{ display: 'flex', flexDirection: 'row', margin: '15px 0', padding: '0' }}>
            <h4>User Tags</h4>
            <button
              className={'btn btn-primary'}
              disabled={newTagActiveStatus}
              style={{ float: 'right', right: '10px' }}
              onClick={this.handleTagCreationButton}
            >
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
                        ADD NEW TAG TYPE
            </button>
          </div>
          <Tags
            tags={data}
            handleCreateNewTagNewButton={this.handleCreateNewTagNewButton}
            handleDeleteTag={this.handleDeleteTag}
            handleCancelNewButton={this.handleCancelNewButton}
          />
        </div>
      );
    }
}

function Tags (props) {
  const tags = props.tags;
  return (
    <div className={'tags-board'}>
      {tags.map((tag) =>
        tag.id === null ? (
          <NewTag
            key={'new'}
            tag={tag}
            handleCreateNewTagNewButton={props.handleCreateNewTagNewButton}
            handleCancelNewButton={props.handleCancelNewButton}
          />
        ) : (
          <Tag key={tag.id} tag={tag} handleDeleteTag={props.handleDeleteTag} />
        )
      )}
    </div>
  );
}
