import React from 'react';

export default (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'stretch',
      alignSelf: 'stretch',
      margin: '20px 0'
    }}
  >
    <div style={{ height: '1px', backgroundColor: '#999', flex: 1 }} />
    <div style={{ fontSize: '16px', color: '#505A62', padding: '10px' }}>{props.text}</div>
    <div style={{ height: '1px', backgroundColor: '#999', width: '100px', flex: 1 }} />
  </div>
);
