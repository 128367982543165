/* eslint no-unused-vars: 0 */
import cookie from 'react-cookies';

let db = {};
let watchers = [];

export function setData (key, val, isCookie, cookieOptions = {}) {
  if (val) {
    const oldStringified = JSON.stringify(db[key]);
    const stringified = JSON.stringify(val);
    db[key] = val;

    if (stringified !== oldStringified && watchers[key]) {
      for (const id in watchers[key]) watchers[key][id](val);
    }
    if (isCookie) cookie.save(key, val, { ...cookieOptions, sameSite: true, secure: false });
  }
}

export function getData (key, defaultVal) {
  if (db[key]) return db[key];
  const val = cookie.load(key);
  try {
    if (val) return JSON.parse(val);
  } catch (err) {
    return val;
  }

  return defaultVal;
}

export function deleteData (key, newVal = '', isCookie) {
  if (db[key]) delete db[key];
  if (watchers[key]) {
    for (const id in watchers[key]) watchers[key][id](newVal);
  }
  if (isCookie) cookie.remove(key, { path: '/' });
}

export function addDataWatcher (table, cb) {
  if (!watchers[table]) watchers[table] = {};
  const id = new Date().getTime() + Math.round(Math.random() * 1000000);
  watchers[table][id] = cb;
  return id;
}

export function removeDataWatcher (...ids) {
  for (const id of ids) {
    for (const table in watchers) {
      if (watchers[table][id]) delete watchers[table][id];
    }
  }
}
