import styled from 'styled-components';

export const HorizontalLine = styled.div`
    margin-top: 40px;
    height: 1px;
    background-color: rgb(224, 224, 224);
    width: 90%;
`;

export const FileContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    margin-bottom: 20px;
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
`;

export const BottomFileContainer = styled(FileContainer)`
    justify-content: left;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
`;

export const BottomDetailsContainer = styled(FileContainer)`
    justify-content: left;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    width: 100%;
`;

export const MainBodyInformationType = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MainBodyFileType = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MainBodyDetailsType = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MinWidthColumn = styled.div`
    display: flex;
    flex: 1;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const MaxWidthColumn = styled.div`
    display: flex;
    flex: 3;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const KnowledgebaseType = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const VerticalSpacer = styled.div`
    height: ${(props) => props.size}px;
`;

export const HorizontalSpacer = styled.div`
    width: ${(props) => props.size}px;
`;

export const KnowledgebaseBottom = styled.div`
    display: flex;
    padding-left: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
`;

export const KnowledgebaseTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 6px;
`;

export const KnowledgebaseTypeOuterButton = styled.button`
    background-color: transparent;
    border-color: transparent;
    color: #000;
    margin: 8px 0px;
    padding: 0;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
    width: 100%;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
