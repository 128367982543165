import React from 'react';
import Select from 'react-select';
import { Filters } from '../../CampaignData';

const CampaignFilters = (props) => {
  const { slctdFilter, updateState } = props;
  return (
    <div className="d-flex align-items-center">
      <p className="mr-3 filter-label">Filter by status</p>
      <Select
        options={Filters}
        value={[slctdFilter]}
        styles={{
          container: (styles) => ({
            ...styles,
            width: 200
          }),
          valueContainer: (styles) => ({
            ...styles,
            fontSize: 12
          })
        }}
        className="mt-2"
        onChange={(item) => updateState('slctdFilter', item)}
      />
    </div>
  );
};

export default CampaignFilters;
