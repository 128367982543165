import React, { useEffect, useState } from 'react';

import { deleteData, getData, setData } from '../lib/data';
import { getUserFromToken } from '../lib/userprocs';
import { get } from 'lib/comms';

const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(getData('token', null));
  const [user, setUser] = useState(null);
  const [userRoleIds, setUserRoleIds] = useState([]);

  const fetchuserRoleIds = async (userId) => {
    const rolesResp = await get(`cms/user_roles/${userId}`, null, true);
    if (rolesResp && rolesResp.success) {
      setUserRoleIds(rolesResp.user_roles);
    }
  };

  useEffect(() => {
    if (token) {
      setData('token', token, true);
      const userFromToken = getUserFromToken(token);
      fetchuserRoleIds(userFromToken.id);
      setUser(userFromToken);
    } else {
      deleteData('token', null, true);
      setUser(null);
    }
  }, [token]);
  return (
    <UserContext.Provider
      value={{
        // values
        token,
        user,
        userRoleIds,
        // functions
        setToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export { UserContextProvider, UserContextConsumer, UserContext };
