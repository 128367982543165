import ReactQuill from 'react-quill';

// Applies an inline blot to the text area that allows spellchecker to be used. see https://github.com/quilljs/quill/issues/2096#issuecomment-399576957 for more details - Joel

let Quill = ReactQuill.Quill;

const Inline = Quill.import('blots/inline');

class CustomColor extends Inline {
  constructor (domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this.remove();

    return span;
  }
}

CustomColor.blotName = 'customColor';
CustomColor.tagName = 'FONT';

export default CustomColor;
