import React, { useState, useEffect } from 'react';
import ReactJoyRide from 'react-joyride';
import ToolTipService from '../../services/ToolTipService';

const ToolTip = (props) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const { screenId, last, steps } = props;
  useEffect(() => {
    const fetchData = async () => {
      const showToolTip = await ToolTipService.getToolTipStatusForScreen(screenId);
      setShowToolTip(showToolTip);
    };
    fetchData();

    return () => {
      ToolTipService.updateToolTipStatusForScreen(screenId);
    };
  }, []);

  if (showToolTip) {
    return (
      <ReactJoyRide
        {...props}
        steps={steps.map((item) => ({
          ...item,
          disableBeacon: true,
          hideCloseButton: true,
          showSkipButton: true,
          continuous: true
        }))}
        locale={{
          last: last || 'Done'
        }}
        styles={{
          ...tooTipStyles,
          tooltip: {
            ...tooTipStyles.tooltip,
            ...props.style
          }
        }}
        showSkipButton={true}
        continuous={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
      />
    );
  }
  return null;
};

const tooTipStyles = {
  options: {
    arrowColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    overlayColor: 'rgba(0,0,0,0.5)',
    primaryColor: '#0274A7',
    textColor: '#505A62',
    zIndex: 999999
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    fontFamily: 'Poppins',
    color: '#505A62',
    padding: 20
  },
  tooltipContainer: {
    textAlign: 'left'
  },
  tooltipTitle: {
    fontSize: 18,
    color: '#242424',
    fontWeight: 600
  },
  tooltipContent: {
    padding: '0px',
    fontSize: 15
  },
  buttonNext: {
    borderRadius: 4,
    color: '#FFFFFF',
    backgroundColor: '#0274A7',
    borderWidth: 0,
    fontWeight: 600,
    outline: 'none'
  },
  buttonSkip: {
    color: '#505A62',
    fontSize: 18,
    fontWeight: 600,
    padding: 0
  },
  buttonBack: {
    fontSize: 18,
    fontWeight: 600,
    color: '#0274A7'
  },
  tooltipFooter: {
    marginTop: 20,
    padding: 0
  }
};
export default ToolTip;
