import React, { useState } from 'react';
import styled from 'styled-components';
import { TaskLine } from './TaskLine';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';

export function TaskItem (props) {
  const [deleting, setDeleting] = useState(false);
  const { id, isFirst, type, title, isLast, onEdit, entity = {} } = props;

  const deleteDialog = deleting ? (
    <DeleteDialog
      item="Task"
      onSuccess={() => {
        setDeleting(false);
        props.doDelete(id);
      }}
      onClose={() => setDeleting(false)}
    />
  ) : null;
  const entityTitle =
        type === 'learning'
          ? 'Micro Learning'
          : type === 'challenges'
            ? 'Challenge'
            : type === 'knowledgebase'
              ? 'Knowledgebase'
              : '';

  return (
    <div className="d-flex w-500">
      <TaskLine isFirst={isFirst} isLast={isLast} />
      <div className={'task-item'}>
        <span className={'task-item-title'}>{title}</span>
        {entity.id ? (
          <span className={'task-item-title'} style={{ fontWeight: 'bold' }}>
            {`${entityTitle}`}
            <span style={{ fontWeight: 'normal' }}>{`: ${entity.title}`}</span>
          </span>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 100
          }}
        >
          <EditButton onClick={onEdit}>Edit</EditButton>
          <span className={'vertical-divider'}>|</span>
          <DeleteButton onClick={() => setDeleting(true)}>Delete</DeleteButton>
        </div>
        {deleteDialog}
      </div>
    </div>
  );
}

const settingButtonStyle = `
  outline:0 !important;
  background: none;
  border: none;
  font-weight: 550;
  padding:0;
  margin:0;
`;
const settingButtonCancelColor = `
  color: #AF365F;
`;
const settingButtonSetColor = `
  color: #1874A7;
 `;
const EditButton = styled.button`
    ${settingButtonStyle}
    ${settingButtonSetColor}
`;
const DeleteButton = styled.button`
    ${settingButtonStyle}
    ${settingButtonCancelColor}
`;
