import React, { useState, useEffect } from 'react';
import './PlanModification.css';
import BillingService from 'services/BillingService';
import { EditText } from 'components/EditText/EditText';

const PlanReActiviation = ({ onClose, onModifyAccount, updateMessage, showLoading }) => {
  const [password, setPassword] = useState('');
  const onClickReactivateAccount = async () => {
    const resp = await BillingService.reActivateSubscription({
      password
    });
    showLoading();
    if (resp.message) {
      updateMessage({
        title: 'Reactivation Error',
        message: resp.message
      });
    } else {
      onModifyAccount();
    }
  };
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && onClose()}
    >
      <div className="plan-cancellation-cont h-50 " style={{ fontSize: 17, width: 600 }}>
        <p className="plan-cancellation-title">Reactivating your account</p>
        <p className="plan-cancellation-desc">
                    Reactivating your account will resume your monthly subscription payments with the initial terms and
                    conditions you accepted still in place. Once you reactivate your account the changes will take
                    effect immediately and there is no further action required.
        </p>
        <EditText
          editing={true}
          value={password}
          label="Your Password"
          onChange={(val) => setPassword(val)}
          type="password"
        />
        <button
          className="btn btn-primary w-50 align-self-center mt-3 mb-3"
          onClick={onClickReactivateAccount}
          disabled={!password}
        >
                    REACTIVATE ACCOUNT
        </button>
      </div>
    </div>
  );
};

export default PlanReActiviation;
