import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone'; // https://github.com/react-dropzone/react-dropzone
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { MessageDialog } from './Dialog';

import isEmail from 'validator/lib/isEmail';

export default (props) => {
  const [error, setError] = useState('');
  const [invalidRecords, setInvalidRecords] = useState([]);
  const onCloseCSVErrorDialog = () => setInvalidRecords([]);
  // on dropping csv file
  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length !== 1) return setError('Only one file please!');
    const [{ name, size }] = acceptedFiles;
    const ext = name.toLowerCase().split('.').pop();
    if (ext !== 'csv') return setError('Please upload a .csv file!');
    if (size > 2000000) return setError('Please upload a smaller list!');
    const reader = new FileReader();
    reader.onload = () => {
      const results = reader.result.split('\n');
      if (results.length > 1000) return setError('Please upload a smaller list, 1000 users is the maximum.');
      const errRecords = validateCSVItems(results);
      if (errRecords.length) {
        return setTimeout(() => setInvalidRecords(errRecords), 100);
      }
      const users = [];
      for (const csvItem of results) {
        if (!csvItem) {
          continue;
        }
        let [username, date_of_birth] = csvItem.split(',');
        const [trimmedUsername] = username.split(/[^A-Za-z0-9.@+!#$%&_-]/);
        const [trimmedDateofBirth] = date_of_birth.split(/[^A-Za-z0-9.@+!/#$%&_-]/);
        const user = {
          username: trimmedUsername.trim(),
          date_of_birth: moment(trimmedDateofBirth.trim()).format('YYYY-MM-DD')
        };
        users.push(user);
      }
      props.onSuccess(users);
    };
    reader.readAsText(acceptedFiles[0]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const text = isDragActive
    ? 'Drop the csv here!'
    : 'Attach a csv file containing a list of username in column A and date of birth in the format YYYY-MM-DD in column B';
  if (isDragActive && error) setError(''); // set error msg to empty on drag active
  return (
    <div className="editcsv-outer">
      <div {...getRootProps({ className: 'editcsv-dropzone' })}>
        <input {...getInputProps()} />
        <div className="editcsv-paperclip mb-2">
          <FontAwesomeIcon icon={faPaperclip} />
        </div>
        <p className="editcsv-text mb-0">{text}</p>
        {error ? <p style={{ color: '#800', margin: 0 }}>{error}</p> : null}
      </div>
      {invalidRecords && invalidRecords.length ? (
        <div
          className="dialog-overlay-outer"
          onClick={(e) => e.target.className === 'dialog-overlay-outer' && onCloseCSVErrorDialog()}
        >
          <div className="dialog-inner dialog-inner-csv">
            <p className="dialog-title">{'Bulk Upload Error'}</p>
            <p className={'dialog-text mb-2 mt-0 dialog-text-csverror dialog-text-error'}>
                            Users not added. Please update below errors and try again
            </p>
            {invalidRecords.map((item) => (
              <p className={'dialog-text dialog-text-csverror'}>{item}</p>
            ))}
            <button className="mt-3 btn btn-primary dialog-button" onClick={onCloseCSVErrorDialog}>
                            OK
            </button>
            <button className="dialog-close text-button" onClick={onCloseCSVErrorDialog}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function validateCSVItems (csvItems) {
  const dateFormatRegEx = /^\d{4}[-]\d{2}[-]\d{2}$/;
  let invalidRecords = [];
  let failureCount = 0;
  const itemsCount = csvItems.length;
  for (let index = 0; index < itemsCount; index++) {
    if (!csvItems[index]) {
      continue;
    }
    const [username, dateOfBirth] = csvItems[index].split(',');
    const [trimmedUsername] = username.split(/[^A-Za-z0-9.@+!#$%&_-]/);
    const [trimmedDateofBirth] = dateOfBirth.split(/[^A-Za-z0-9.@+!/#$%&_-]/);
    const userNameRegEx = /^[a-zA-Z0-9_]*$/;

    const isValidUsername =
            trimmedUsername &&
            (userNameRegEx.test(String(username).toLowerCase()) || isEmail(String(username).toLowerCase()));

    const isDateOfBirthValid =
            trimmedDateofBirth && dateFormatRegEx.test(trimmedDateofBirth) && moment(trimmedDateofBirth).isValid();

    if (!isValidUsername || !isDateOfBirthValid) {
      failureCount++;
      if (!isValidUsername && !isDateOfBirthValid) {
        invalidRecords.push(
          `${failureCount}. Username is not a valid email or contains spaces or special characters other than _ and Date of birth is not in YYYY-MM-DD format at record ${
            index + 1
          }`
        );
      } else if (!isValidUsername) {
        invalidRecords.push(
          `${failureCount}. Username is not a valid email or contains spaces or special characters other than _ at record ${
            index + 1
          }`
        );
      } else if (!isDateOfBirthValid) {
        invalidRecords.push(
          `${failureCount}. Date of birth is not in YYYY-MM-DD format at record ${index + 1}`
        );
      }
    }
  }
  return invalidRecords;
}
