import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import { get, post, dodelete } from 'lib/comms';
import { MessageDialog, DeleteDialog } from 'components/Dialog/Dialog';
import SearchRow from 'components/SearchRow/SearchRow';
import Table from 'components/Table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import './OnboardingUsers.css';

export default class OnboardingUsers extends React.Component {
    columns = [
      {
        name: 'Name',
        grow: 1,
        selector: 'first_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''} ${row.deleted_at ? ' (deleted)' : ''}`
      },
      { name: 'Email', grow: 1, selector: 'email_address', sortable: true },
      { name: 'Status', grow: 1, selector: 'task_status', sortable: true },
      {
        name: 'Last Updated',
        grow: 1,
        selector: 'last_updatedon',
        sortable: true
      },
      {
        name: '',
        grow: 1,
        selector: 'id',
        sortable: false,
        // right: true,
        cell: (row) => (
          <div className="onboarding-member-action">
            <button
              className="onboarding-remove-member"
              onClick={() =>
                this.setState({
                  showDeleteDialog: true,
                  slctdMember: row
                })
              }
            >
              <FontAwesomeIcon icon={faMinusCircle} /> Remove Member
            </button>
            <button
              className="onboarding-remove-member"
              style={{ color: '#0274a7' }}
              onClick={() => this.props.onClickViewProgress(row)}
            >
                        View Progress
            </button>
          </div>
        )
      }
    ];
    columns2 = [
      {
        name: 'Name',
        grow: 2,
        selector: 'last_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''}`
      },
      { name: 'Email', grow: 2, selector: 'email_address', sortable: true },
      {
        name: '',
        selector: 'id',
        sortable: false,
        right: true,
        cell: (row) => (
          <button className="group-remove-member-btn group-add-member-btn" onClick={() => this.addMember(row)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Add Member
          </button>
        )
      }
    ];

    constructor (props) {
      super(props);

      this.state = {
        showDeleteDialog: false,
        showMessage: null,
        onboardingMembers: [],
        nonOnboardingMembers: [],
        slctdMember: null
      };
    }

    componentDidMount = () => this.reload();

    componentDidUpdate (prevProps) {
      if (prevProps.onboardingId !== this.props.onboardingId) {
        this.reload();
      }
    }
    reload = async () => {
      const { onboardingId } = this.props;
      let resp = await get(`cms/onboarding_users/${onboardingId}`, null, true);
      if (resp && resp.members) this.setState({ onboardingMembers: resp.members });
      else console.log(resp);
      // now get the list of users that are not members so we can add them
      resp = await get(`cms/onboarding_users/${onboardingId}?non_members=1`, null, true);
      if (resp && resp.non_members) this.setState({ nonOnboardingMembers: resp.non_members });
      else console.log(resp);
    };

    deleteMember = async (member) => {
      const { onboardingId } = this.props;
      const resp = await dodelete(`cms/onboarding_users/${onboardingId}/${member.user_id}`, {}, true);
      if (resp.success) {
        const onboardingMembers = this.state.onboardingMembers.filter(
          (onBoardingMember) => onBoardingMember.user_id !== member.user_id
        );
        const nonOnboardingMembers = [...this.state.nonOnboardingMembers, member];
        this.setState({ onboardingMembers, nonOnboardingMembers });
      }
      if (resp.message) {
        this.setState({
          showMessage: { message: resp.message, title: 'Remove member' }
        });
      }
    };

    addMember = async (member) => {
      const { onboardingId } = this.props;
      const resp = await post(`cms/onboarding_users/${onboardingId}/${member.user_id}`, {}, true);
      if (resp.success) {
        const nonOnboardingMembers = this.state.nonOnboardingMembers.filter(
          (onBoardingMember) => onBoardingMember.user_id !== member.user_id
        );
        const onboardingMembers = [...this.state.onboardingMembers, member];
        this.setState({ onboardingMembers, nonOnboardingMembers });
      }
      if (resp.message) {
        this.setState({
          showMessage: { message: resp.message, title: 'Add memeber' }
        });
      }
    };

    render () {
      let { search, onboardingMembers, nonOnboardingMembers, addingMembers, slctdMember } = this.state;
      let tableData = addingMembers ? nonOnboardingMembers : onboardingMembers;
      if (search) {
        tableData = tableData.filter(
          (item) =>
            `${item.first_name} ${item.last_name} ${item.username}`
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <div className="onboard-members">
          {
            <div className="mt-5">
              <SearchRow
                title="Participants"
                value={search}
                searchTitle="Search by name or username"
                onSearch={(search) => this.setState({ search })}
                className={addingMembers ? 'success' : 'primary'}
                buttonTitle={addingMembers ? 'DONE' : 'ADD MEMBERS'}
                onBtnClick={() => this.setState({ addingMembers: !addingMembers, search: '' })}
              />
              <Table
                columns={addingMembers ? this.columns2 : this.columns}
                data={tableData}
                tableID="onboarding"
                defaultSortField={this.columns[1].selector}
                noSelect
              />
            </div>
          }
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
          {this.state.showDeleteDialog ? (
            <DeleteDialog
              item="Member"
              onSuccess={() => {
                this.setState({ showDeleteDialog: false });
                this.deleteMember(slctdMember);
              }}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
        </div>
      );
    }
}
