import React, { useContext, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logoImage from 'assets/logo_white.svg';
import poweredImage from 'assets/poweredby.png';
import './Menu.css';
import { UserContextConsumer, UserContext } from '../../../contexts/UserContext';
import { getData, setData } from '../../../lib/data';
import { put, get, dodelete } from 'lib/comms';
import { USER_ROLES } from '../../../App';
import { WelcomeDialog } from 'components/WelcomeDialog/WelcomeDialog';

const Menu = (props) => {
  const userContextVal = useContext(UserContext);
  const user = userContextVal.user || {};
  const userRoleIds = userContextVal.userRoleIds || [];
  const tokenAdmin = getData('token_admin', null);
  const [featureFlags, setFeatureFlags] = useState([]);
  const exitSupportMode = useCallback(() => {
    setData('token', tokenAdmin, true);
    window.location = '/';
  }, []);
  const [showSettings, setShowSettings] = useState(false); // LOADING, READY ERROR

  useEffect(() => {
    const fetchData = async () => {
      const featureFlagResp = await get(`cms/feature_flags/${user.organisation_id}`, null, true);
      if (featureFlagResp && featureFlagResp.feature_flags) setFeatureFlags(featureFlagResp.feature_flags);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const go = async () => {
      const tooltipRequest = await get('cms/tooltip_status', {}, true);
      if (tooltipRequest && tooltipRequest.success) {
        setShowSettings(tooltipRequest.tooltip_seen);
      }
    };

    go();
  }, []);

  const isAdmin = userRoleIds.indexOf(USER_ROLES.ADMIN) !== -1;
  const isFinance = userRoleIds.indexOf(USER_ROLES.FINANCE) !== -1;
  const isManagement = userRoleIds.indexOf(USER_ROLES.MANAGEMENT) !== -1;
  const isUserMngmnt = userRoleIds.indexOf(USER_ROLES.USER_MANAGEMENT) !== -1;
  const isContent = userRoleIds.indexOf(USER_ROLES.CONTENT) !== -1;
  const isReports = userRoleIds.indexOf(USER_ROLES.REPORTS) !== -1;
  const [onboardingFlag] = featureFlags.filter((item) => item.id === 'EMPLOYEE_ONBOARDING');
  const [microLearningFlag] = featureFlags.filter((item) => item.id === 'LEARNING');
  const [challengesFlag] = featureFlags.filter((item) => item.id === 'CHALLENGES');
  const [campaignsFlag] = featureFlags.filter((item) => item.id === 'CAMPAIGNS');

  return (
    <div className="menu-outer">
      {user.is_supporting && (
        <div className="support-box">
          <h4>Support mode</h4>
          {tokenAdmin ? (
            <button className="btn btn-danger" onClick={exitSupportMode}>
                            EXIT SUPPORT MODE
            </button>
          ) : (
            <div>Sign out to exit support mode</div>
          )}
        </div>
      )}
      <img src={logoImage} alt="Logo" className="menu-logo" />
      {<WelcomeDialog title="Welcome to Engage4" btnText="Get Started" />}
      {(isAdmin || isManagement) && (
        <NavLink className="menu-navlink" to="/dashboard">
                    Dashboard
        </NavLink>
      )}
      {(isAdmin || isUserMngmnt || isManagement) && (
        <NavLink className="menu-navlink" to="/users">
                    User Management
          <NavLink className="menu-subnavlink" exact to="/users">
                        - All Users
          </NavLink>
          {(isAdmin || isManagement) && (
            <NavLink className="menu-subnavlink" to="/users/tags">
                            - User Tags
            </NavLink>
          )}
        </NavLink>
      )}

      {(isAdmin || isManagement) && (
        <NavLink className="menu-navlink" to="/groups">
                    Group Management
        </NavLink>
      )}
      {(isAdmin || isUserMngmnt || isManagement) && (
        <NavLink className="menu-navlink" to="/departments">
                    Department
        </NavLink>
      )}
      {user.is_global && (
        <NavLink className="menu-navlink" to="/notices">
                    Notices
        </NavLink>
      )}
      {microLearningFlag && microLearningFlag.flag_value && (isAdmin || isContent) && (
        <NavLink className="menu-navlink" to="/micro">
                    Micro-Learning
        </NavLink>
      )}
      {challengesFlag && challengesFlag.flag_value && (isAdmin || isContent) && (
        <NavLink className="menu-navlink" to="/challenges">
                    Challenges
        </NavLink>
      )}
      {(isAdmin || isContent) && (
        <NavLink className="menu-navlink" to="/knowledgebase">
                    Knowledgebase
          <NavLink className="menu-subnavlink" exact to="/knowledgebase">
                        - Items
          </NavLink>
          <NavLink className="menu-subnavlink" to="/knowledgebase/manage-categories">
                        - Manage Categories
          </NavLink>
        </NavLink>
      )}

      {campaignsFlag && campaignsFlag.flag_value && (isAdmin || isContent) && (
        <NavLink className="menu-navlink" to="/campaigns">
                    Campaigns
        </NavLink>
      )}
      {(isAdmin || isManagement) && (
        <NavLink className="menu-navlink" to="/content">
                    App Content Editor
        </NavLink>
      )}

      {onboardingFlag && onboardingFlag.flag_value && (isAdmin || isContent) && (
        <NavLink className="menu-navlink" to="/onboarding">
                    Onboarding
        </NavLink>
      )}
      {(isAdmin || isReports) && (
        <NavLink className="menu-navlink" to="/reports">
                    Reported Posts
        </NavLink>
      )}
      {(isAdmin || isFinance) && (
        <NavLink className="menu-navlink" to="/billing">
                    Billing
        </NavLink>
      )}
      {(isAdmin || isManagement || showSettings) && (
        <NavLink className="menu-navlink" to="/settings">
                    Settings
        </NavLink>
      )}
      {user.master_admin && (
        <React.Fragment>
          <hr className="menu-line" />
          <NavLink className="menu-navlink" to="/orgs">
                        ⭐️ Organisations
          </NavLink>
        </React.Fragment>
      )}
      <hr className="menu-line" />
      <NavLink className="menu-navlink menu-signout" to="/logout">
        <div className="menu-reddot"></div>
                Sign out
      </NavLink>
      <img src={poweredImage} alt="Logo" className="menu-poweredby" />
    </div>
  );
};

export default Menu;
