import React, { useState, useEffect } from 'react';
import './SubscriptionBanner.css';
import BillingService from 'services/BillingService';

const Banner = ({ outerClass, isCancellation }) => {
  useEffect(() => {
    const fetchData = async () => {
      const resp = await BillingService.getSubscriptionDetails();
      setSubscription(resp);
    };
    fetchData();
  }, []);
  const [subscription, setSubscription] = useState({
    paymentElapsed: false
  })
  if (subscription.paymentElapsed || subscription.cancelled || isCancellation || subscription.trialEndingOn) {
    let title
    let desc
    if (isCancellation) {
      title = 'Are you sure?'
      desc = `Your account will be closed on ${subscription.endsOn}, you can continue to use Engage4 until this date, after this we will delete all of your data. There are no recovery options.`
    } else if(subscription.trialEndingOn){
      title = 'Your free trial is coming to an end?'
      desc = `Your free trial ends on ${subscription.trialEndingOn}, to continue using Engage4 please add credit card details in the billing section. If you would like to discuss your account contact us on hello@engage4.com`
    }
    else if (subscription.status === 'canceled') {
      title = 'Your account has been closed'
      desc = `Your account is closed, If you believe this has been done in error, please email hello@engage4.com`
    } else if (subscription.cancelled) {
      title = 'Your account has been cancelled';
      desc = `Your account will be closed on ${subscription.endsOn}, you can continue to use Engage4 until this date, after this we will delete all of your data. If you believe this has been done in error, please email hello@engage4.com`;
    } else if (subscription.paymentElapsed) {
      title = 'Your Account is currently in arrears';
      desc = `The last payment for your Engage4 subscription has failed, this indicates there is something wrong with your payment method. To prevent the suspension of all users, please update your billing information before ${subscription.endsOn} after which your subscription will be cancelled`;
    }
    return (
      <div className="banner-container outerClass">
        <span className="banner-title">{title || 'Your Account is currently in arrears'}</span>
        <span className="banner-desc">{desc}</span>
      </div>
    );
  }
  return null;
};

export default Banner;
