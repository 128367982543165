import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Helmet } from 'react-helmet';
import LoginPage from './pages/login/LoginPage'
import TokenPage from './pages/login/TokenPage'
import MainPage, { ReportedPostsPage } from './pages/main/MainPage'
import RegistrationPage from './pages/register/RegistrationRoutes'
import { deleteData } from './lib/data'
import {
  UserContextProvider,
  UserContextConsumer
} from './contexts/UserContext'

export const USER_ROLES = {
  NORMAL: 0,
  SUPER_USER: 1,
  ADMIN: 2,
  REPORTS: 3,
  FINANCE: 4,
  MANAGEMENT: 5,
  USER_MANAGEMENT: 6,
  CONTENT: 7
}

export default class App extends React.Component {
  logout = (renderProps) => {
    deleteData('token', '', true)
    return (
      <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
    )
  }

  render() {
    return (
      <UserContextProvider>
        {window.location.hostname.indexOf('dashboard.engage4.com') === -1 ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : null}
        <div className="outer">
          <div className="inner">
            <Router>
              <Switch>
                <Route path="/register" component={RegistrationPage} />
                <Route path="/login" component={LoginPage} />
                <Route
                  path="/logout"
                  render={() => (
                    <UserContextConsumer>
                      {({ setToken }) => {
                        setToken(null)
                        return (
                          <Redirect
                            to={{
                              pathname: '/',
                              state: { from: this.props.location }
                            }}
                          />
                        )
                      }}
                    </UserContextConsumer>
                  )}
                />
                <Route path="/token_auth" component={TokenPage} />
                <Route exact path="/reports" component={ReportedPostsPage} />
                <Route path="/" component={MainPage} />
              </Switch>
            </Router>
          </div>
        </div>
      </UserContextProvider>
    )
  }
}
