import { post } from 'lib/comms';

class RegistrationService {
    validateEmailAddress = async (email) => {
      const result = await post('self-registration/validate/user', { email });
      return result;
    };

    validateOrgName = async (name) => {
      const result = await post('self-registration/validate/organisation', {
        name
      });
      return result;
    };

  selfRegister = async (user) => {
    const result = await post('self-registration/self-register', {
      organisation: {
        name: user.orgName,
        tier_id: 1
      },
      user: {
        email: user.email,
        firstName: user.firstName,
        password: user.password,
        date_of_birth: user.dob
      }
    });
    return result;
  };
}

export default new RegistrationService();
