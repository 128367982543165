import React from 'react';
import Card from 'components/Card/Card';

const WantMore = () => {
  return (
    <Card outerClass="card-medium ml-3" title="Want more?">
      <p className="want-more">
                We offer a premium version of Engage4, allowing you to rebrand the platform to match your organisation,
                along with support for new features and integrations unique to you.
      </p>
      <p className="want-more">
        {'To find out more, '}
        <a className="contact" target="_blank" href="mailto:hello@engage4.com?subject=Engage4 Upgrade Enquiry">
                    contact the Engage4 team
        </a>
      </p>
    </Card>
  );
};

export default WantMore;
