import React, { useState } from 'react';
import { EditText } from 'components/EditText/EditText';
import { get, post, dodelete, put } from 'lib/comms';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './KnowledgebasePage.css';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

export default class KnowledgebaseCategories extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      categories: [],
      addingCategory: false,
      editCategoryName: '',
      newCategoryName: '',
      editingCategoryId: null,
      showMessage: ''
    };
  }

    componentDidMount = () => {
      this.reload();
    };

    reload = async () => {
      let resp = await get('cms/knowledgebase_categories', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      const categories = resp && resp.categories ? resp.categories : [];

      this.setState({
        categories: categories.sort((a, b) => (a.isDefault ? -1 : 1))
      });
    };

    resetEditingCategory = () => {
      this.setState({ editingCategoryId: null, editCategoryName: '' });
    };

    openAddingCategory = () => {
      this.setState({
        addingCategory: true,
        newCategoryName: '',
        editCategoryName: null,
        editingCategoryId: ''
      });
    };

    closeAddingCategory = () => {
      this.setState({
        addingCategory: false
      });
    };

    openEditingCategory = (editingCategoryId) => {
      this.setState({ editingCategoryId });
    };

    saveNewCategory = async () => {
      let { newCategoryName } = this.state;
      if (!newCategoryName || !newCategoryName.trim()) {
        this.setState({
          showMessage: {
            message: 'Please enter a valid name',
            title: 'Save Error',
            close: () => this.setState({ showMessage: null })
          }
        });
        return;
      }
      const existingCat = this.state.categories.filter((category) => category.name === newCategoryName);
      if (existingCat.length > 0) {
        this.showErrorDialog('Category already exists');
        return;
      }
      const resp = await post('cms/knowledgebase_categories', { name: newCategoryName }, true);
      if (resp && resp.success) {
        this.setState({ newCategoryName: null, addingCategory: false });
        this.reload();
      } else {
        resp.message && this.showErrorDialog(resp.message);
      }
    };

    deleteCategory = async (categoryId) => {
      const resp = await dodelete(`cms/knowledgebase_categories/${categoryId}`, {}, true);
      if (resp && resp.success) {
        this.reload();
      } else if (resp.message) {
        setTimeout(
          () =>
            this.setState({
              showMessage: { message: resp.message, title: 'Delete Category' }
            }),
          500
        );
      }
    };

    saveEditedCategory = async () => {
      if (this.state.editCategoryName) {
        const existingCat = this.state.categories.filter(
          (category) => category.name === this.state.editCategoryName
        );
        if (existingCat.length > 0) {
          this.showErrorDialog('Category already exists');
          return;
        }
        const resp = await put(
          `cms/knowledgebase_categories/${this.state.editingCategoryId}`,
          { name: this.state.editCategoryName },
          true
        );
        if (resp && resp.success) {
          this.setState({ editingCategoryId: null, editCategoryName: '' });
          this.reload();
        } else {
          resp.message && this.showErrorDialog(resp.message);
        }
      } else {
        this.setState({ editingCategoryId: null, editCategoryName: '' });
      }
    };

    setEditCategoryName = (editCategoryName) => this.setState({ editCategoryName });

    showErrorDialog = (message) => {
      setTimeout(
        () =>
          this.setState({
            showMessage: {
              message: message,
              title: 'Save error',
              close: () => this.setState({ showMessage: null })
            }
          }),
        500
      );
    };

    render () {
      return (
        <div className="maincontainer">
          <h4>Manage Knowledgebase Categories</h4>

          <CategoryItems>
            {this.state.categories.map((category) => {
              if (this.state.editingCategoryId !== null && this.state.editingCategoryId === category.id) {
                return (
                  <EditCategory
                    id={category.id}
                    initialCategoryName={category.name}
                    onSave={this.saveEditedCategory}
                    onCancel={this.resetEditingCategory}
                    editCategoryName={this.state.editCategoryName}
                    setEditCategoryName={this.setEditCategoryName}
                  />
                );
              }

              return (
                <CategoryItem
                  id={category.id}
                  categoryName={category.name}
                  isMultiple={this.state.categories.length > 1}
                  isEditable={!category.isDefault}
                  doDelete={this.deleteCategory}
                  onEdit={() => this.openEditingCategory(category.id)}
                />
              );
            })}
          </CategoryItems>

          <AddCategory
            addingCategory={this.state.addingCategory}
            onCancel={this.closeAddingCategory}
            onSave={this.saveNewCategory}
            newCategoryName={this.state.newCategoryName}
            setNewCategoryName={(newCategoryName) => this.setState({ newCategoryName })}
          />

          <div className={'add-category-container'}>
            <button onClick={this.openAddingCategory} className={'btn btn-primary add-category-button'}>
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
                        ADD CATEGORY
            </button>
          </div>
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}

function AddCategory (props) {
  if (!props.addingCategory) {
    return null;
  }

  return (
    <AddCategoryContainer>
      <StyledEditText
        label="New Category Name"
        editing={'1'}
        onChange={(e) => props.setNewCategoryName(e)}
        value={props.newCategoryName}
        maxLength={40}
      />

      <CategoryItemControlButtons>
        <SaveButton onClick={() => props.onSave()}>Save</SaveButton>
        <span className={'vertical-divider'}>|</span>
        <CancelButton onClick={props.onCancel}>Cancel</CancelButton>
      </CategoryItemControlButtons>
    </AddCategoryContainer>
  );
}

function EditCategory (props) {
  if (props.editingCategoryId === null) {
    return null;
  }

  return (
    <EditCategoryContainer>
      <StyledEditText
        label="Edit Category Name"
        editing={'1'}
        onChange={(e) => props.setEditCategoryName(e)}
        value={props.editCategoryName || props.initialCategoryName}
        maxLength={40}
      />

      <CategoryItemControlButtons>
        <SaveButton onClick={(e) => props.onSave(e, props.id)}>Save</SaveButton>
        <span className={'vertical-divider'}>|</span>
        <CancelButton onClick={props.onCancel}>Cancel</CancelButton>
      </CategoryItemControlButtons>
    </EditCategoryContainer>
  );
}

const CategoryItemControlButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

function CategoryItem (props) {
  const [deleting, setDeleting] = useState(false);

  const deleteDialog = deleting ? (
    <DeleteDialog
      item="Category"
      onSuccess={() => {
        setDeleting(false);
        props.doDelete(props.id);
      }}
      onClose={() => setDeleting(false)}
    />
  ) : null;

  return (
    <div className={'category-item'}>
      <CategoryLine />

      <span className={'category-item-title'}>{props.categoryName}</span>

      {props.isEditable ? (
        <div className={'category-item-control-buttons'}>
          <EditButton onClick={props.onEdit}>Edit</EditButton>
          <span className={'vertical-divider'}>|</span>
          <DeleteButton onClick={() => setDeleting(true)}>Delete</DeleteButton>
        </div>
      ) : null}

      {deleteDialog}
    </div>
  );
}

const AddCategoryContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const EditCategoryContainer = styled(AddCategoryContainer)``;

const StyledEditText = styled(EditText)`
    width: 300px;
    margin-right: 30px;
`;

const CategoryItems = styled.div`
    margin-top: 40px;
`;

const SimpleLine = styled.div`
    background-color: #d3d8dc;
    display: flex;
    height: 1px;
    width: 1px;
`;

const SimpleLineContainer = styled.div`
    flex-direction: row;
    display: flex;
    width: 40px;
    align-items: center;
    justify-content: center;
`;

const HorizontalLine = styled(SimpleLine)`
    width: 24px;
`;

const VerticalLine = styled(SimpleLine)`
    height: 40px;
    transform: translate(0px, -20px);
`;

const CategoryLine = (props) => (
  <SimpleLineContainer>
    <VerticalLine />
    <HorizontalLine />
  </SimpleLineContainer>
);

const settingButtonStyle = `
  outline:0 !important;
  background: none;
  border: none;
  font-weight: 550;
`;

const settingButtonCancelColor = `
  color: #AF365F;
`;

const settingButtonSetColor = `
  color: #1874A7;
`;

const CancelButton = styled.button`
    ${settingButtonStyle}
    ${settingButtonCancelColor}
`;

const SaveButton = styled.button`
    ${settingButtonStyle}
    ${settingButtonSetColor}
`;

const EditButton = styled.button`
    ${settingButtonStyle}
    ${settingButtonSetColor}
`;

const DeleteButton = styled.button`
    ${settingButtonStyle}
    ${settingButtonCancelColor}
`;
