import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

import BillingService from '../../../../../../services/BillingService';

import CardAndBillingAddress from 'components/CardAndBillingAddress/CardAndBillingAddress';

const UpdateCard = ({ onComplete, paymentDetails = {} }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const stripe = useStripe();

  const updatePaymentMethod = async (cardElement, values) => {
    setIsProcessing(true);
    const billingDetails = {
      name: values.nameOnCard,
      address: {
        line1: values.billingAddressLineOne,
        postal_code: values.billingPostcode
      }
    };
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails
    });
    if (result && result.paymentMethod) {
      const { id, card } = result.paymentMethod;

      await BillingService.updatePaymentMethod(id, values.billingPostcode, values.billingAddressLineOne);
      onComplete({
        ...billingDetails,
        card
      });
    }
    setIsProcessing(false);
  };

  return (
    <CardAndBillingAddress
      showTerms={false}
      saveLabel="SAVE PAYMENT DETAILS"
      processingMessage="Updating Card Details..."
      isProcessing={isProcessing}
      onSave={updatePaymentMethod}
      paymentDetails={paymentDetails}
      submitButtonId="update-payment-submit"
    />
  );
};

export default UpdateCard;
