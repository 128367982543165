import React, { useContext, useState } from 'react';
import Card from 'components/Card/Card';
import { EditText } from 'components/EditText/EditText';
import { ColorDialog, MessageDialog } from 'components/Dialog/Dialog';

const ColorPicker = (props) => {
  const [showPicker, setShowPicker] = useState(false);
  const { id, title, value, setTheme, isEditing } = props;
  return (
    <div>
      <div onClick={() => isEditing && setShowPicker(!showPicker)}>
        <EditText
          label={title}
          // onChange={val => setTheme(id, val)}
          value={value}
          editing={isEditing}
        />
      </div>
      {showPicker ? (
        <ColorDialog
          title="Select color"
          hexValOfColor={value}
          onSetColor={(hexVal) => {
            setShowPicker(false);
            setTheme(id, hexVal);
          }}
          onClose={() => {
            setShowPicker(false);
          }}
        />
      ) : null}
    </div>
  );
};

const OrgTheme = (props) => {
  const { theme, isEditing, onToggle, setTheme } = props;
  return (
    <Card title="Organisation Theme" editing={isEditing} onToggle={onToggle}>
      {theme.map((item, index) => {
        return <ColorPicker {...item} {...props} />;
      })}
    </Card>
  );
};

export default OrgTheme;
