import React from 'react';
import {Route, Redirect, Switch, useHistory} from 'react-router-dom';
import RegistrationUserEntry from './RegistrationUserEntry/RegistrationUserEntry';
import RegistrationComplete from './RegistrationComplete/RegistrationComplete';
import RegistrationHeader from './RegistrationHeader';
import avatarUnitedCapital from 'assets/avatar_united_capital_feedback.png';
import {Carousel} from 'react-responsive-carousel';

// Import Carousel styles
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './RegistrationRoutes.css';

const CAROUSEL_DATA = [{
  avatar: avatarUnitedCapital,
  name: 'Jo Eismont, Marketing Manager, United Capital',
  text: '“United Capital is a group that’s growing quickly, and we needed a platform to help us communicate with employees right across the country. Engage4 has been exactly what we needed – simple to launch, easy to use, and it really helps to know that the Vidatec team are always on hand to support us. Engage4 has helped us launch our group-wide values and Employee of the Year competition, so it’s really bringing people together and giving them reasons to visit the app regularly to get the latest company news. It’s been so easy to partner with Vidatec, as they just got what we needed from the first conversation.”'
}];

const RegistrationRoutes = () => {
  const history = useHistory();

  const onRegistrationComplete = () => {
    history.push('/register/complete');
  };

  // Need an if statement to cover when a page reloads, it punts the user
  // back to the user screen.
  return (
    <div className="registration-routes-container">
      <div className="main-body-container">
        <div className="left-images">
          <div className="image-text-container">
            <h1 className="image-text">Bring your workforce together.</h1>
            <p className="left-text">HR & Business Leaders Globally use Engage4 to:</p>
            <p className="left-text pt-4">• Centralise Company Communications</p>
            <p className="left-text">• Create an Exceptional Onboarding Experience</p>
            <p className="left-text">• Improve Employees Wellbeing</p>
            <p className="left-text">• Give Employees a Voice</p>
            <p className="left-text">• Enhance the Company Culture</p>
            <p className="left-text">• Recognise Employees Achievements</p>
            <p className="left-text">• Encourage on the job learning</p>
            <p className="left-text">• Retain Talent for longer</p>
            <p className="left-text pt-4">And much more…</p>
            <h5 className="left-subTitle pt-5">Here’s what some of our existing customers have to say about Engage4</h5>
            <Carousel showThumbs={false} showStatus={false} showArrows={false} autoPlay={false}>
              {CAROUSEL_DATA.map((item) => {
                return (
                  <div className="carousel-container">
                    <div className="avatar-container">
                      <img src={avatarUnitedCapital} alt="Avatar" className="carousel-avatar" />
                    </div>
                    <div className="pl-4">
                      <p className="carousel-text">“United Capital is a group that’s growing quickly, and we needed a platform to help us communicate with employees right across the country. Engage4 has been exactly what we needed – simple to launch, easy to use, and it really helps to know that the Vidatec team are always on hand to support us. Engage4 has helped us launch our group-wide values and Employee of the Year competition, so it’s really bringing people together and giving them reasons to visit the app regularly to get the latest company news. It’s been so easy to partner with Vidatec, as they just got what we needed from the first conversation.”</p>
                      <p className="carousel-text-bold pb-4">Jo Eismont, Marketing Manager, United Capital</p>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className="right-wrapper">
          <RegistrationHeader />
          <Switch>
            <Route exact path="/register/user">
              <RegistrationUserEntry onRegistrationComplete={onRegistrationComplete} />
            </Route>
            <Route exact path="/register/complete">
              <RegistrationComplete />
            </Route>
            <Redirect
              to={{
                pathname: '/register/user'
              }}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default RegistrationRoutes;
