import React from 'react';
import { get, post, dodelete, put } from 'lib/comms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TaskManage.css';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { VerticalLine } from './TaskLine';
import { TaskItem } from './TaskItem';
import ContentModal from 'components/CreateContent/CreateContent';

export default class OnboardingTasks extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      tasks: []
    };
  }

    componentDidMount = () => {
      this.reload();
    };

    componentDidUpdate (prevProps) {
      if (prevProps.onboardingId !== this.props.onboardingId) {
        this.reload();
      }
    }

    reload = async () => {
      const { onboardingId } = this.props;
      let resp = await get(`cms/onboarding_tasks/${onboardingId}`, null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      const tasks = resp && resp.onboarding_tasks ? resp.onboarding_tasks : [];

      this.setState({
        tasks
      });
    };

    onSaveTask = async (taskItem) => {
      let resp;
      const { onboardingId, editingTaskItem } = this.props;
      if (editingTaskItem) {
        const existingTask = this.state.tasks.filter(
          (task) => task.title === editingTaskItem.intro && task.entity_id === editingTaskItem.id
        );
        if (existingTask.length > 0) {
          this.showErrorDialog('Task already exists');
          return;
        }
      }
      const reqBody = {
        type: taskItem.id ? taskItem.type : '',
        title: taskItem.intro,
        entity_id: taskItem.id
      };
      if (editingTaskItem?.id) {
        resp = await put(`cms/onboarding_tasks/${editingTaskItem.id}`, reqBody, true);
      } else {
        resp = await post(`cms/onboarding_tasks/${onboardingId}`, reqBody, true);
      }

      if (resp && resp.success) {
        this.setState({ addingTask: false, editingTaskItem: null });
        this.reload();
      } else {
        resp.message && this.showErrorDialog(resp.message);
      }
    };

    deleteTask = async (taskId) => {
      const resp = await dodelete(`cms/onboarding_tasks/${taskId}`, {}, true);
      if (resp && resp.success) {
        this.reload();
      } else if (resp.message) {
        setTimeout(
          () =>
            this.setState({
              showMessage: { message: resp.message, title: 'Delete Task' }
            }),
          500
        );
      }
    };

    showErrorDialog = (message) => {
      setTimeout(
        () =>
          this.setState({
            showMessage: {
              message: message,
              title: 'Save error',
              close: () => this.setState({ showMessage: null })
            }
          }),
        500
      );
    };

    renderTasks () {
      const { tasks } = this.state;
      const taskCount = tasks.length;
      const { openAddingTask, openEditingTask } = this.props;
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
            {tasks.length ? <VerticalLine style={{ height: '40px', marginLeft: 8 }} /> : null}
            <div>
              {tasks.map((task, index) => {
                return (
                  <TaskItem
                    {...task}
                    isMultiple={tasks.length > 1}
                    doDelete={this.deleteTask}
                    onEdit={() => openEditingTask(task)}
                    isFirst={index === 0}
                    isLast={taskCount - 1 === index}
                  />
                );
              })}
            </div>
          </div>
          <div className={'add-task-container'}>
            <button onClick={openAddingTask} className={'btn btn-primary add-task-button'}>
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
                        ADD TASK
            </button>
          </div>
        </>
      );
    }
    render () {
      const { addingTask, addContentItem, editingTaskItem, onCloseContent, featureFlags } = this.props;
      if (addingTask || editingTaskItem) {
        return (
          <ContentModal
            onClose={onCloseContent}
            onSave={this.onSaveTask}
            editingItem={editingTaskItem}
            addContentItem={addContentItem}
            featureFlags={featureFlags}
          />
        );
      }
      return this.renderTasks();
    }
}
