import { post } from 'lib/comms';

class VideoService {
    uploadVideoToCloudflare = async (mediaFile, abortController) => {
      let video_cloudflare_id;
      const videoResp = await post('cms/video', {}, true);
      if (videoResp && videoResp.success) {
        try {
          const uploadURL = videoResp.data.uploadURL;
          video_cloudflare_id = videoResp.data.uid;
          const formData = new FormData();
          formData.append('file', mediaFile);
          const uploadResp = await fetch(uploadURL, {
            method: 'POST',
            body: formData,
            signal: abortController.signal
          });
          if (uploadResp && uploadResp.ok) {
            return video_cloudflare_id;
          } else {
            video_cloudflare_id = '';
            alert('error uploading video');
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
}

export default new VideoService();
