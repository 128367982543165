import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { EditText, EditDropDown, EditTextArea } from 'components/EditText/EditText';
import EditCheckBox from 'components/EditCheckBox/EditCheckBox';
import { get, post, dodelete } from 'lib/comms';
import { DeleteDialog, MessageDialog } from 'components/Dialog/Dialog';
import { EditImage } from 'components/EditImage/EditImage';
import SearchRow from 'components/SearchRow/SearchRow';
import './GroupPage.css';
import Table from 'components/Table/Table';
import { Dot } from 'components/SVG/SVG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Select from 'react-select';

const categories = [
  // prob want these from the db...
  { id: 1, name: 'athletic' },
  { id: 2, name: 'learning' },
  { id: 3, name: 'charity' },
  { id: 4, name: 'social' },
  { id: 5, name: 'work' }
];

export default class GroupPage extends React.Component {
    columns = [
      {
        name: 'Name',
        grow: 2,
        selector: 'last_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''} ${row.deleted_at ? ' (deleted)' : ''}`
      },
      { name: 'Username', grow: 2, selector: 'username', sortable: true },
      {
        name: 'Member Type',
        selector: 'member_type',
        sortable: false,
        wrap: true,
        allowOverflow: true,
        cell: (row) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle caret outline>
                {row.member_type === 'admin' ? 'Moderator' : 'Standard'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    if (row.member_type === 'admin') {
                      this.toggleMemberType(row);
                    }
                    this.setState({ openDropdownId: false });
                  }}
                >
                                Standard
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    if (row.member_type !== 'admin') {
                      this.toggleMemberType(row);
                    }
                    this.setState({ openDropdownId: false });
                  }}
                >
                                Moderator
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

          /*
                                                          <button
                                                            className="group-toggle-member-btn"
                                                            onClick={() => this.toggleMemberType(row)}
                                                          >
                                                            <Dot className="mt-1" color={row.member_type === 'admin' ? '#FBB811' : '#68C4D1'}
                                                            />
                                                            {row.member_type === 'admin' ? 'Moderator' : 'Standard'}
                                                          </button>
                                                          */
          );
        }
      },
      {
        name: '',
        selector: 'id',
        sortable: false,
        right: true,
        cell: (row) => (
          <button className="group-remove-member-btn" onClick={() => this.deleteMember(row)}>
            <FontAwesomeIcon icon={faMinusCircle} /> Remove Member
          </button>
        )
      }
    ];
    columns2 = [
      {
        name: 'Name',
        grow: 2,
        selector: 'last_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''}`
      },
      { name: 'Username', grow: 2, selector: 'username', sortable: true },
      {
        name: '',
        selector: 'id',
        sortable: false,
        right: true,
        cell: (row) => (
          <button className="group-remove-member-btn group-add-member-btn" onClick={() => this.addMember(row)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Add Member
          </button>
        )
      }
    ];

    constructor (props) {
      super(props);
      this.state = {
        group: props.group,
        editing: [false],
        showDeleteDialog: false,
        showMessage: null,
        groupmembers: [],
        nongroupmembers: [],
        addingMembers: false,
        privacyOptions: [],
        departments: [],
        featureFlags: []
      };
    }

    setEditing = async (index) => {
      let { editing, group } = this.state;
      if (editing[index]) {
        // save to db
        let departments;
        if (group.departments) departments = group.departments.map((item) => item.value);
        const resp = await post('cms/groups', { group: { ...group, departments } }, true);
        if (resp && resp.success) editing[index] = false;
        else console.log(resp);
        this.reload();
        if (resp.message) {
          setTimeout(
            () =>
              this.setState({
                showMessage: { message: resp.message, title: 'Save error' }
              }),
            500
          );
        }
      } else editing[index] = true;
      this.setState({ editing });
    };

    componentDidMount = () => {
      this.getPrivacyOptions();
      this.reload();
      this.fetchFeatureFlags();
    };

    getPrivacyOptions = async () => {
      let resp = await get('cms/group/privacy_types', null, true);
      if (resp && resp.privacy_types) this.setState({ privacyOptions: resp.privacy_types });
      else console.log(resp);
    };

    reload = async () => {
      let resp = await get(`cms/groupmembers/${this.props.group.id}`, null, true);
      if (resp && resp.groupmembers) this.setState({ groupmembers: resp.groupmembers });
      else console.log(resp);

      // now get the list of users that are not members so we can add them
      resp = await get(`cms/groupmembers/${this.props.group.id}?nonmembers=1`, null, true);
      if (resp && resp.nongroupmembers) this.setState({ nongroupmembers: resp.nongroupmembers });
      else console.log(resp);

      resp = await get('cms/department', null, true);
      if (resp && resp.departments) {
        const group = this.state.group;
        let groupDeps;
        let departments = resp.departments.map((item) => ({
          value: item.id,
          label: item.department
        }));
        let groupDeptResp = await get(`cms/group_departments/${group.id}`, null, true);
        if (groupDeptResp && groupDeptResp.group_departments) {
          groupDeps = groupDeptResp.group_departments;
        }
        if (groupDeps && groupDeps.length) {
          groupDeps = groupDeps.map((id) => {
            const [foundDep] = resp.departments.filter((item) => item.id === id);
            return { value: foundDep.id, label: foundDep.department };
          });
        }
        this.setState({
          departments,
          group: {
            ...group,
            departments: groupDeps
          }
        });
      } else console.log(resp.departments);
    };

    doDelete = async () => {
      const resp = await dodelete('cms/groups', { id: this.state.group.id }, true);
      await this.setState({ showDeleteDialog: false });
      if (resp && resp.success && this.props.onBack) this.props.onBack();
      else if (resp.message) {
        setTimeout(
          () =>
            this.setState({
              showMessage: { message: resp.message, title: 'Delete group' }
            }),
          500
        );
      }
    };

    deleteMember = async (member) => {
      const resp = await dodelete(`cms/groupmembers/${this.props.group.id}`, { id: member.user_id }, true);
      if (resp.success) {
        const groupmembers = this.state.groupmembers.filter(
          (groupmember) => groupmember.user_id !== member.user_id
        );
        const nongroupmembers = [...this.state.nongroupmembers, member];
        this.setState({ groupmembers, nongroupmembers });
      }
      if (resp.message) {
        this.setState({
          showMessage: { message: resp.message, title: 'Remove memeber' }
        });
      }
    };

    addMember = async (member) => {
      const resp = await post(`cms/groupmembers/${this.props.group.id}`, { id: member.user_id }, true);
      if (resp.success) {
        const nongroupmembers = this.state.nongroupmembers.filter(
          (groupmember) => groupmember.user_id !== member.user_id
        );
        const groupmembers = [...this.state.groupmembers, member];
        this.setState({ groupmembers, nongroupmembers });
      }
      if (resp.message) {
        this.setState({
          showMessage: { message: resp.message, title: 'Remove member' }
        });
      }
    };
    fetchFeatureFlags = async (orgId) => {
      const featureFlagResp = await get(`cms/feature_flags/${orgId}`, null, true);
      if (featureFlagResp && featureFlagResp.feature_flags) {
        this.setState({ featureFlags: featureFlagResp.feature_flags });
      }
    };
    toggleMemberType = async (member) => {
      const resp = await post(
        `/cms/groupmembers/${this.props.group.id}/toggle-user-permission/${member.user_id}`,
        {},
        true
      );
      if (resp.success) {
        const groupmembers = this.state.groupmembers.map((groupmember) => {
          if (groupmember.user_id === member.user_id) {
            groupmember.member_type = groupmember.member_type === 'admin' ? 'standard' : 'admin';
          }
          return groupmember;
        });
        this.columns = [...this.columns]; // forces rerender!
        this.setState({ groupmembers });
      }
      if (resp.message) {
        this.setState({
          showMessage: { message: resp.message, title: 'Change member type' }
        });
      }
    };

    onSelctDepartment = (items) => {
      const { group } = this.state;
      this.setState({
        group: { ...group, departments: items }
      });
    };
    render () {
      let {
        group,
        editing,
        groupmembers,
        departments,
        nongroupmembers,
        search,
        addingMembers,
        privacyOptions,
        featureFlags
      } = this.state;
      let tableData = addingMembers ? nongroupmembers : groupmembers;
      const [mandatoryGroupFlag] = featureFlags.filter((item) => item.id === 'MANDATORY_GROUPS');
      if (search) {
        tableData = tableData.filter(
          (item) =>
            `${item.first_name} ${item.last_name} ${item.username}`
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <div className="maincontainer">
          <BackRow
            onBack={this.props.onBack}
            buttonTitle={group.is_org_level ? null : 'DELETE GROUP'}
            onDelete={() => this.setState({ showDeleteDialog: true })}
          />
          <Card
            title="Group information"
            editing={editing[0] ? '1' : undefined}
            onToggle={() => this.setEditing(0)}
          >
            <div className="group-edit-outer">
              <div className="group-edit-image-outer mr-4">
                <EditImage
                  editing={editing[0] ? '1' : undefined}
                  imageid={group.image_id}
                  onChange={(imageId) => this.setState({ group: { ...group, image_id: imageId } })}
                />
              </div>
              <div className="group-edit-text-outer">
                <EditText
                  label="Group Name"
                  editing={editing[0] ? '1' : undefined}
                  onChange={(text) => this.setState({ group: { ...group, name: text } })}
                  value={group.name}
                />
                <EditTextArea
                  className="mt-3"
                  label="Description"
                  editing={editing[0] ? '1' : undefined}
                  onChange={(text) => this.setState({ group: { ...group, description: text } })}
                  value={group.description}
                />
                <div className="d-flex">
                  <EditDropDown
                    label="Category "
                    options={categories}
                    editing={editing[0]}
                    onChange={(id) => this.setState({ group: { ...group, category_id: id } })}
                    value={group.category_id}
                  />
                  <EditDropDown
                    className="ml-5"
                    label="Group Privacy"
                    options={privacyOptions}
                    editing={editing[0]}
                    onChange={(id) =>
                      this.setState({
                        group: { ...group, group_privacy_type_id: id }
                      })
                    }
                    value={group.group_privacy_type_id.toUpperCase()}
                  />
                </div>
                {mandatoryGroupFlag && mandatoryGroupFlag.flag_value ? (
                  <div className="group-info">
                    <EditCheckBox
                      className="mt-1 w-50"
                      editing={editing[0]}
                      title={'Mandatory Group'}
                      onClick={() =>
                        this.setState({
                          group: {
                            ...group,
                            is_mandatory: !group.is_mandatory
                          }
                        })
                      }
                      isChecked={group.is_mandatory}
                      desc={''}
                      iconSize="md"
                    />
                    {group.is_mandatory ? (
                      <div
                        className={`mdc-text-field mdc-text-field--outlined ${
                          editing[0] ? 'editing' : 'notediting'
                        }`}
                        style={{
                          alignItems: 'center',
                          width: '46%'
                        }}
                      >
                        <Select
                          isDisabled={!editing[0]}
                          isMulti
                          options={departments}
                          value={group.departments || []}
                          styles={{
                            container: (styles) => ({
                              ...styles,
                              border: 0,
                              width: '100%'
                            }),
                            control: (styles) => ({
                              ...styles,
                              borderWidth: 0,
                              borderColor: 'white',
                              backgroundColor: 'white',
                              boxShadow: null
                            }),
                            valueContainer: (styles) => ({
                              ...styles,
                              borderColor: 'white',
                              backgroundColor: 'white',
                              fontSize: 12,
                              height: 32,
                              maxHeight: 32,
                              width: '100%',
                              overflowX: 'scroll',
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              flexWrap: 'nowrap',
                              borderWidth: 0
                            }),
                            multiValueRemove: (base, state) =>
                              state.data.value === 0 ? { ...base, display: 'none' } : base,
                            multiValueLabel: (base, state) =>
                              state.data.value === 0
                                ? {
                                  ...base,
                                  fontSize: 12,
                                  backgroundColor: '#FFF'
                                }
                                : base
                          }}
                          // className='mt-2'
                          onChange={this.onSelctDepartment}
                        />
                        <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
                          <div className="mdc-notched-outline__leading"></div>
                          <div className="mdc-notched-outline__notch" style={{ width: 120 }}>
                            <label className="mdc-floating-label mdc-floating-label--float-above">
                              {'Add Department'}
                            </label>
                          </div>
                          <div className="mdc-notched-outline__trailing"></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </Card>

          <div className="members-table mt-5">
            <SearchRow
              title="Group Members"
              value={search}
              searchTitle="Search by name or username"
              onSearch={(search) => this.setState({ search })}
              className={addingMembers ? 'success' : 'primary'}
              buttonTitle={addingMembers ? 'DONE' : 'ADD MEMBERS'}
              onBtnClick={() => this.setState({ addingMembers: !addingMembers, search: '' })}
            />
            <Table
              columns={addingMembers ? this.columns2 : this.columns}
              data={tableData}
              noSelect
              tableID="group"
            />
          </div>
          {this.state.showDeleteDialog ? (
            <DeleteDialog
              item="Group"
              onSuccess={this.doDelete}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
          {this.state.showMessage ? (
            <MessageDialog
              message={this.state.showMessage.message}
              title={this.state.showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
        </div>
      );
    }
}
