import React, { useState, useCallback } from 'react';
import './Dialog.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TextField, { Input } from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import { EditDropDown, EditDate, EditText } from 'components/EditText/EditText';
import CSVDrop from './CSVDrop';
import LineText from './LineText';
import linkImage from 'assets/magic_link.png';
import moment from 'moment';
import { BlockPicker, SketchPicker } from 'react-color';
import { SyncLoader } from 'react-spinners';

export const Dialog = (props) => {
  const { title, btnTitle, btnNo, btnNoWithSave, OnClickBtnNoWithSave, onClose, onConfirm, disabled } = props;
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && onClose()}
    >
      <div className="dialog-inner" style={{ width: 800, ...props.style }}>
        <p className="mt-2 dialog-title align-self-center">{title}</p>
        {props.children}
        <button
          disabled={disabled}
          className="btn btn-primary dialog-button"
          style={{ marginTop: 20, width: '60%' }}
          onClick={() => {
            onConfirm();
          }}
        >
          {btnTitle}
        </button>
        {btnNoWithSave ? (
          <button
            className="btn btn-outline-primary dialog-button"
            style={{ width: '60%' }}
            onClick={OnClickBtnNoWithSave}
          >
            {btnNoWithSave}
          </button>
        ) : null}
        {btnNo ? (
          <button
            className="btn btn-outline-primary dialog-button"
            style={{ width: '60%' }}
            onClick={props.onClose}
          >
            {btnNo}
          </button>
        ) : null}
        <button className="mr-3 dialog-close text-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      </div>
    </div>
  );
};

export const DeleteDialog = (props) => {
  const { item = 'User', confirmMsg } = props;
  const title = props.title || `Delete ${item}`;
  const confirm =
        confirmMsg || `Are you sure you want to delete this ${item.toLowerCase()}? This action canot be undone.`;
  const btnYes = `YES, DELETE THIS ${item.toUpperCase()}`;
  const btnNo = `NO, DON'T DELETE THIS ${item.toUpperCase()}`;
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner" style={{ width: 450 }}>
        <p className="dialog-title">{title}</p>
        <p className="dialog-text">{confirm}</p>
        <button className="btn btn-primary dialog-button" onClick={props.onSuccess}>
          {btnYes}
        </button>
        <button className="btn btn-outline-primary dialog-button" onClick={props.onClose}>
          {btnNo}
        </button>
        <button className="dialog-close text-button" onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export const MessageDialog = (props) => {
  const { title, message, isError } = props;
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner">
        <p className="dialog-title">{title}</p>
        <p className={`dialog-text ${isError ? 'dialog-text-error' : ''}`}>{message}</p>
        <button className="btn btn-primary dialog-button" onClick={props.onClose}>
                    OK
        </button>
        <button className="dialog-close text-button" onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export const VideoUploadDialog = (props) => {
  const { title, message } = props;
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner">
        <p className="dialog-title">{title}</p>
        <p className={'dialog-text mb-3'}>{message}</p>
        <SyncLoader color={'#1874A7'} />
        <button className="btn btn-primary dialog-button mt-3 " onClick={props.onClose}>
                    Cancel
        </button>
      </div>
    </div>
  );
};

export const ColorDialog = (props) => {
  const { title, hexValOfColor } = props;
  const [color, setColor] = useState(hexValOfColor);
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner">
        <p className="dialog-title">{title}</p>
        <BlockPicker
          color={color}
          onChangeComplete={({ hex }) => {
            setColor(hex);
          }}
        />
        <button className="btn btn-primary dialog-button" onClick={() => props.onSetColor(color)}>
                    OK
        </button>
        <button className="dialog-close text-button" onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export const PinnedPostLimitDialog = (props) => {
  const { title, message, pinnedPosts, onClose, onSelect } = props;

  const posts = pinnedPosts.map((post) => ({
    id: post.id,
    name: `${post.notice.slice(0, 120)}${post.notice.length > 120 ? '...' : ''}`
  }));

  const [selectedPost, setSelectedPost] = useState(pinnedPosts[0]);
  const [status, setStatus] = useState('READY'); // READY, LOADING

  const onDropDownChange = useCallback((post) => {
    setSelectedPost(post);
  }, []);

  const onSelectPost = useCallback(() => {
    setStatus('LOADING');
    onSelect(selectedPost);
  }, [selectedPost]);

  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && onClose()}
    >
      <div
        className="dialog-inner"
        style={
          status === 'LOADING'
            ? {
              pointerEvents: 'none',
              opacity: 0.8
            }
            : {}
        }
      >
        <p className="dialog-title">{title}</p>
        <p className="dialog-text">{message}</p>
        <EditDropDown
          label="Pinned Notices"
          options={posts}
          editing={1}
          onChange={onDropDownChange}
          value={selectedPost.id}
        />
        <button className="btn btn-primary dialog-button" onClick={onSelectPost}>
                    Unpin and save
        </button>
        <button className="btn btn-outline-primary dialog-button" onClick={onClose}>
                    Cancel
        </button>
        <button className="dialog-close text-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export const InputDialog = (props) => {
  const [value, setValue] = useState('');
  const { hint, buttonText, maxLength, outerClassName } = props;
  return (
    <Outer {...props}>
      <EditText
        outerClassName={outerClassName}
        label={hint}
        onChange={(txt) => setValue(txt)}
        value={value}
        editing={1}
        maxLength={maxLength}
      />
      <button
        className="btn btn-primary dialog-button"
        onClick={() => {
          if ((hint === 'Dashboard Name' || hint === 'Onboarding Name') && !value) {
            props.onEnterEmpty();
          } else {
            props.onSuccess(value);
          }
        }}
      >
        {buttonText || 'OK'}
      </button>
      <button className="dialog-close text-button" onClick={props.onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </Outer>
  );
};

const Outer = (props) => {
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner">
        <p className="dialog-title">{props.title}</p>
        {props.children}
      </div>
    </div>
  );
};

export const MagicLinkDialog = (props) => {
  const { email } = props;
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner" style={{ width: '600px' }}>
        <img src={linkImage} alt="magic link" className="" />
        <h4>Magic Link Sent!</h4>
        <p className="dialog-link-text mt-2">We just sent a link to you at</p>
        <p className="dialog-link-text">
          <strong>{email}</strong>
        </p>
        <p className="dialog-link-text mb-3">It contains a link that'll sign you in</p>
        {/* <button className="btn btn-primary dialog-button" onClick={props.onClose} style={{maxWidth: '410px'}}>OK</button> */}
        {/* <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button> */}
      </div>
    </div>
  );
};

export class AddUserDialog extends React.Component {
    state = {
      username: '',
      validUsername: false,
      dateOfBirth: moment().format('YYYY-MM-DD')
    };

    updateEmail = (e) => {
      const username = e.currentTarget.value;
      const validUsername = validateUsername(username);
      this.setState({ username, validUsername });
    };

    render () {
      return (
        <div
          className="dialog-overlay-outer"
          onClick={(e) => e.target.className === 'dialog-overlay-outer' && this.props.onClose()}
        >
          <div className="dialog-inner">
            <p className="dialog-title">Add New User</p>
            <TextField className="mt-2" label="Username" outlined style={{ width: '100%' }}>
              <Input className="poppins" value={this.state.username} onChange={this.updateEmail} />
            </TextField>
            {this.state.username && !this.state.validUsername && (
              <p className="login-error">
                            Please reenter your username using only letters, numbers and @ or . no spaces
              </p>
            )}
            <EditDate
              isDateOfBirth={true}
              editing={true}
              dateFormat="dd/MM/yyyy"
              label="Date of Birth"
              onChange={(date) =>
                this.setState({
                  dateOfBirth: moment(date).format('YYYY-MM-DD')
                })
              }
              value={this.state.dateOfBirth}
            />
            <LineText text="Or" />
            <CSVDrop onSuccess={(users) => this.props.onSuccess(users)} />
            <button
              className="btn btn-primary dialog-button mt-4"
              disabled={this.state.validUsername ? null : 'on'}
              onClick={() =>
                this.props.onSuccess([
                  {
                    username: this.state.username,
                    date_of_birth: this.state.dateOfBirth
                  }
                ])
              }
            >
                        ADD
            </button>
            <button className="dialog-close text-button" onClick={this.props.onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      );
    }
}

export const ChangeDepartmentDialog = (props) => {
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner">
        <p className="dialog-title">Change department</p>
        <p className="dialog-text">
                    Select a new department for: <br />
          <b>{props.userName}</b>
        </p>
        <EditDropDown
          label="Departments"
          options={props.departments}
          editing={1}
          onChange={props.changeDepartment}
          value={props.currentDepartmentId}
        />
        <button className="btn btn-primary dialog-button" onClick={props.onSuccess}>
                    Confirm Change
        </button>
        <button className="btn btn-outline-primary dialog-button" onClick={props.onClose}>
                    Cancel
        </button>
        <button className="dialog-close text-button" onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export const DeleteDepartmentDialog = (props) => {
  return (
    <div
      className="dialog-overlay-outer"
      onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}
    >
      <div className="dialog-inner">
        <p className="dialog-title">Delete Department</p>
        {props.membersCount ? (
          <>
            <p className="dialog-text">
                            Select a new department for <br />{' '}
              <b>
                {props.membersCount} {props.membersCount > 1 ? 'users' : 'user'}
              </b>{' '}
                            to be moved to before deletion
            </p>
            <EditDropDown
              label="Departments"
              options={props.departments}
              editing={1}
              onChange={props.changeDepartment}
              value={props.currentDepartmentId}
            />
          </>
        ) : null}
        {props.departments.length === 0 && props.membersCount > 0 ? (
          <p>There are no other departments to transfer users to.</p>
        ) : (
          <button className="btn btn-primary dialog-button" onClick={props.onSuccess}>
                        CONFIRM DELETION
          </button>
        )}
        <button className="btn btn-outline-primary dialog-button" onClick={props.onClose}>
                    CANCEL
        </button>
        <button className="dialog-close text-button" onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

function validateUsername (username) {
  var re = /^[a-zA-Z0-9]([.@_-](?![.@_-])|[a-zA-Z0-9])*$/;
    // eslint-disable-line
  return re.test(String(username).toLowerCase());
}
