import React from 'react';
import './SmallLoader.css';

export default (props) => {
  let color = null;

  if (props.color) {
    color = {
      standard: props.color,
      faded: props.color + '11'
    };
  }
  return (
    <div
      className="small-loader"
      style={color ? { borderColor: color.faded, borderTopColor: color.standard } : null}
    ></div>
  );
};
