import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import { get } from 'lib/comms';
import Table from 'components/Table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ContentPage from './ContentPage';
import { Redirect } from 'react-router-dom';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import BillingService from 'services/BillingService';
import ToolTip from 'components/ToolTip/TooltTip';

const columns = [
  { name: 'Content Type', selector: 'content_type', sortable: true, grow: 3, wrap: true },
  {
    name: '',
    selector: 'id',
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
  }
];

export default class ContentsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = { data: [], search: '', editing: null, stepIndex: 0 };
  }

    componentDidMount = () => this.reload();

    reload = async () => {
      let resp = await get('cms/appcontent', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.appcontent) this.setState({ data: resp.appcontent });
      else console.log(resp);
    };

    doneEditing = () => {
      this.setState({ editing: null });
      this.reload();
    };

    render () {
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      let { data, search, editing } = this.state;
      if (search) {
        data = data.filter((item) => (item.content_type || '').toLowerCase().indexOf(search.toLowerCase()) >= 0);
      }

      return (
        <>
          {this.state.editing ? (
            <ContentPage item={editing} onBack={this.doneEditing} />
          ) : (
            <div className="maincontainer">
              <SubscriptionBanner />
              <h4>App Content</h4>
              <SearchRow
                value={search}
                searchTitle="Search by content type"
                onSearch={(search) => this.setState({ search })}
              />
              <Table
                columns={columns}
                data={data}
                onRowClicked={(item) => this.setState({ editing: item })}
                tableID="acppcontent"
              />
            </div>
          )}
          <ToolTip
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  editing:
                                    (this.state.stepIndex === 0 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 2 && tooltipData.action === 'prev')
                                      ? data[0]
                                      : this.state.stepIndex === 2 && tooltipData.action === 'next'
                                        ? data[1]
                                        : null
                });
              }
            }}
            screenId={'content'}
            steps={[
              {
                target: '.table-outer',
                title: 'App Content Editor',
                content:
                                'This area of the CMS allows you to customise some of the content your users will see when they log in to Engage4 for the first time'
              },
              {
                target: '.card',
                title: 'Adding your terms of use',
                content:
                                'When your users sign into Engage4 for the first time they will be asked to read and accept our Engage4 privacy policy before they can continue using the app. If you would like to add your own organisation specific terms of use which may be your social media policy for example you can do so by adding the content into this field. Your users will then be asked to read and accept these terms of use as part of the initial onboarding into the app.'
              },
              {
                target: '.table-outer',
                title: 'Welcome your users',
                content:
                                'When your users log in for the first time they will go through an app onboarding where we introduce them to some of the features in the app. This is a great time to add a personalised welcome message.'
              },
              {
                target: '.card',
                title: 'Add your own welcome message',
                content:
                                'To add a welcome message simply add a title and body text in the fields above. You can also attach an image that will display alongside your message such as your company logo. Your users will only see this message the first time they login to Engage4.'
              }
            ]}
            stepIndex={this.state.stepIndex}
          />
        </>
      );
    }
}
