import React from 'react'
import * as dateFns from 'date-fns'

const CalendarItem = (campaigns, day, monthStart, onSelectCampaign) => {
  let dateFormat = 'd'
  let formattedDate = ''

  let campaignsOnThisDay = []
  for (const item of campaigns) {
    for (let index = 0; index < item.no_of_days; index++) {
      if (
        dateFns.isSameDay(
          day,
          dateFns.addDays(new Date(item.start_date), index)
        )
      ) {
        campaignsOnThisDay.push({
          ...item,
          starts: index === 0,
          ends: index === item.no_of_days - 1,
          content_exists: item.content && item.content.indexOf(index + 1) !== -1
        })
        break
      }
    }
  }
  campaignsOnThisDay = campaignsOnThisDay.sort((item1, item2) => {
    if (item1.start_date > item2.start_date) return -1
    else if (item1.start_date < item2.start_date) return 1
    else return 0
  })

  if (!dateFns.isSameMonth(day, monthStart)) dateFormat = 'MMM dd'
  else dateFormat = 'd'
  formattedDate = dateFns.format(day, dateFormat)
  return (
    <div className={`col cell`} key={day}>
      <span
        className={`cell-date ${
          !dateFns.isSameMonth(day, monthStart) ? 'disabled' : ''
        }`}
      >
        {formattedDate}
      </span>
      <div className='d-flex flex-column flex-1'>
        {campaignsOnThisDay.map(item => {
          const { id, content_exists, status, color, name, starts, ends } = item
          return (
            <div
              key={id}
              onClick={() => onSelectCampaign(item)}
              className={`cell-with-campaign ${
                status === 'active' ? '' : 'stripe'
              }`}
              style={
                status === 'active'
                  ? {
                      backgroundColor: color + '40'
                    }
                  : {}
              }
            >
              {starts ? (
                <div className='d-flex h-100 align-items-center'>
                  <div
                    className='rectangle-bar-start'
                    style={{
                      backgroundColor: color
                    }}
                  ></div>
                  <span className='ml-1 campaign-title'>{name}</span>
                </div>
              ) : null}

              {content_exists ? (
                <div
                  className='dot'
                  style={{
                    backgroundColor: color,
                    right: ends ? 12 : 2
                  }}
                />
              ) : null}
              {ends ? (
                <div
                  className='rectangle-bar-end'
                  style={{
                    backgroundColor: color
                  }}
                />
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CalendarItem
