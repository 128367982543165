import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import { EditText } from 'components/EditText/EditText';
import { dodelete, get, post, put } from 'lib/comms';
import { ChangeDepartmentDialog, DeleteDepartmentDialog, MessageDialog } from 'components/Dialog/Dialog';
import SearchRow from 'components/SearchRow/SearchRow';
import './DepartmentPage.css';
import Table from 'components/Table/Table';
import { UserContext } from 'contexts/UserContext';
import { USER_ROLES } from '../../../../App';

export default class DepartmentPage extends React.Component {
    columns = [
      // add id of user?
      {
        name: 'Name',
        grow: 2,
        selector: 'last_name',
        sortable: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''} ${row.deleted_at ? ' (deleted)' : ''}`
      },
      { name: 'Username', grow: 2, selector: 'username', sortable: true },
      {
        name: '',
        selector: 'id',
        sortable: false,
        right: true,
        cell: (row) => (
          <button className="change-dep-member-btn" onClick={() => this.changeDepMember(row)}>
            {/* eslint-disable-next-line no-irregular-whitespace */}
                    Change Department
          </button>
        )
      }
    ];
    constructor (props) {
      super(props);
      this.state = {
        department: props.department,
        editing: [false],
        showDeleteDialog: false,
        showMessage: null,
        members: [],
        addingMembers: false,
        showChangeDepDialog: null,
        selectedDepartmentId: null
      };
    }
    static contextType = UserContext;
    setEditing = async (index) => {
      let { editing, department } = this.state;
      if (editing[index]) {
        // save to db
        const resp = await put(
          `cms/department/${department.id}`,
          { new_department_name: department.department },
          true
        );
        if (resp && resp.success) editing[index] = false;
        else console.log(resp);
        if (resp.message) {
          setTimeout(() => this.setState({ showMessage: { message: resp.message, title: 'Save error' } }), 500);
        }
      } else editing[index] = true;
      this.setState({ editing });
    };

    componentDidMount = () => {
      const options = this.getDepartmentsOptions();
      this.setState({ departmentsOptions: options });
      this.reload();
    };

    reload = async () => {
      let resp = await get(`cms/department/${this.props.department.id}/members`, null, true);
      if (resp && resp.members) this.setState({ members: resp.members });
      else console.log(resp);
    };

    doDelete = async () => {
      const { members, department, selectedDepartmentId } = this.state;
      const memberIds = members.map((member) => member.id);
      if (memberIds.length > 0) {
        const resp = await post(`cms/department/${selectedDepartmentId}/members`, { members: memberIds }, true);
        if (resp.success === false) {
          this.setState({ showDeleteDialog: false });
          setTimeout(
            () => this.setState({ showMessage: { message: resp.message, title: 'Delete Department' } }),
            500
          );
        }
      }
      const resp = await dodelete(`cms/department/${department.id}`, [], true);
      if (resp && resp.success && this.props.onBack) {
        this.setState({ showDeleteDialog: false });
        this.props.onBack();
      } else if (resp.message) {
        setTimeout(
          () => this.setState({ showMessage: { message: resp.message, title: 'Delete Department' } }),
          500
        );
      }
    };

    changeDepMember = async (member) => {
      const { departmentsOptions } = this.state;
      this.setState({
        showChangeDepDialog: { userId: member.id, userName: `${member.first_name} ${member.last_name}` },
        selectedDepartmentId: departmentsOptions.length > 0 ? departmentsOptions[0].id : 0
      });
    };

    onDepChange = async () => {
      const { department, showChangeDepDialog, selectedDepartmentId } = this.state;
      if (selectedDepartmentId !== department.id) {
        const resp = await post(
          `cms/department/${selectedDepartmentId}/members`,
          { members: [showChangeDepDialog.userId] },
          true
        );
        if (resp.success) {
          const members = this.state.members.filter((member) => member.id !== showChangeDepDialog.userId);
          this.setState({ members });
        }
        if (resp.message) this.setState({ showMessage: { message: resp.message, title: 'Change Department' } });
      }
      this.setState({ showChangeDepDialog: null });
    };

    handleDepartmentChange = (value) => {
      this.setState({ selectedDepartmentId: value });
    };

    getDepartmentsOptions = () => {
      const { department } = this.state;
      return this.props.departmentsData
        .map((dept) => (department.id !== dept.id ? { id: dept.id, name: dept.department } : null))
        .filter((dept) => dept);
    };

    render () {
      const { user, userRoleIds } = this.context;
      const isAdmin = userRoleIds.indexOf(USER_ROLES.ADMIN) !== -1;
      const isManagement = userRoleIds.indexOf(USER_ROLES.MANAGEMENT) !== -1;
      let {
        department,
        editing,
        members,
        search,
        departmentsOptions,
        showChangeDepDialog,
        showMessage,
        showDeleteDialog,
        selectedDepartmentId
      } = this.state;
      let tableData = members;
      if (search) {
        tableData = tableData.filter(
          (item) =>
            `${item.first_name} ${item.last_name} ${item.username}`
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <div className="maincontainer">
          <BackRow
            onBack={this.props.onBack}
            buttonTitle={isAdmin || isManagement ? 'DELETE DEPARTMENT' : ''}
            onDelete={() =>
              this.setState({
                showDeleteDialog: true,
                selectedDepartmentId: departmentsOptions.length > 0 ? departmentsOptions[0].id : 0
              })
            }
          />

          <Card
            title="Department"
            editing={editing[0] ? '1' : undefined}
            onToggle={isAdmin || isManagement ? () => this.setEditing(0) : undefined}
          >
            <div className="">
              <div className="">
                <EditText
                  label="Department Name"
                  editing={editing[0] ? '1' : undefined}
                  onChange={(text) => this.setState({ department: { ...department, department: text } })}
                  value={department.department}
                />
              </div>
            </div>
          </Card>

          <div className="members-table mt-5">
            <SearchRow
              title="Department Users"
              value={search}
              searchTitle="Search by name or username"
              onSearch={(search) => this.setState({ search })}
              className={'success'}
            />
            <Table columns={this.columns} data={tableData} noSelect tableID="group" />
          </div>
          {showDeleteDialog ? (
            <DeleteDepartmentDialog
              item="Department"
              membersCount={members.length}
              currentDepartmentId={selectedDepartmentId}
              departments={departmentsOptions}
              onSuccess={this.doDelete}
              changeDepartment={this.handleDepartmentChange}
              onClose={() => this.setState({ showDeleteDialog: false })}
            />
          ) : null}
          {showMessage ? (
            <MessageDialog
              message={showMessage.message}
              title={showMessage.title}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
          {showChangeDepDialog ? (
            <ChangeDepartmentDialog
              userName={showChangeDepDialog.userName}
              departments={departmentsOptions}
              currentDepartmentId={selectedDepartmentId}
              changeDepartment={this.handleDepartmentChange}
              onSuccess={this.onDepChange}
              onClose={() => this.setState({ showChangeDepDialog: null })}
            />
          ) : null}
        </div>
      );
    }
}
