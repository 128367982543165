import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare, faClock } from '@fortawesome/free-regular-svg-icons';

const OrgFlagsCheckBox = (props) => {
  const { className, style, editing, onToggle, title, initialValue, desc, isLoading = false } = props;
  const outerClassName = `${className || ''} ${editing ? 'editing' : 'notediting'}`;

  const [isChecked, setIsChecked] = useState(initialValue);

  const onToggleInternal = useCallback(() => {
    if (!editing) return null;
    const toggleValue = !isChecked;
    setIsChecked(toggleValue);

    if (onToggle) {
      onToggle(toggleValue);
    }
  }, [isChecked, onToggle]);

  return (
    <div className={outerClassName} style={style}>
      <div
        className={'d-flex align-items-center justify-content-between'}
        style={{
          minWidth: '150px'
        }}
      >
        <div className="d-flex flex-column">
          <p
            className="m-0 p-0 pt-0"
            style={
              desc
                ? {
                  lineHeight: '18px',
                  color: `${editing ? '#0274A7' : '#717171'}`,
                  fontWeight: 600
                }
                : {}
            }
          >
            {title}
          </p>

          {desc && (
            <p className="card-text mt-2" style={{ fontSize: 14 }}>
              {desc}
            </p>
          )}
        </div>
        {isLoading ? (
          <FontAwesomeIcon
            icon={faClock}
            className={'mr-3 d-flex justify-content-end'}
            color={editing ? '#0274A7' : '#717171'}
            size={'lg'}
          />
        ) : (
          <FontAwesomeIcon
            icon={isChecked ? faCheckSquare : faSquare}
            className={'mr-3 d-flex justify-content-end'}
            color={editing ? '#0274A7' : '#717171'}
            size={'lg'}
            onClick={onToggleInternal}
          />
        )}
      </div>
    </div>
  );
};

export default OrgFlagsCheckBox;
