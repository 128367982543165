import React from 'react';

const CalendarListView = (props) => {
  let { viewType, updateState } = props;
  return (
    <div className="list-calendar">
      <div
        onClick={() => updateState('viewType', 'list')}
        style={{ color: viewType === 'list' ? '#0274A7' : '#505A62' }}
      >
        {'List'}
      </div>
      <span className="vertical-bar pl-2 pr-2">{'|'}</span>
      <div
        onClick={() => updateState('viewType', 'calendar')}
        style={{ color: viewType === 'calendar' ? '#0274A7' : '#505A62' }}
      >
        {'Calendar'}
      </div>
    </div>
  );
};

export default CalendarListView;
