import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import { get } from 'lib/comms';
import Table from 'components/Table/Table';
import NoticePage from './NoticePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './NoticesPage.css';
import AuthImage from 'components/AuthImage/AuthImage';
import { Redirect } from 'react-router-dom';
import placeholder from 'assets/placeholder.png';

import moment from 'moment';

const columns = [
  { name: 'Content', selector: 'notice', sortable: false, grow: 3, wrap: true },
  {
    name: 'Posted',
    selector: 'created_at',
    sortable: true,
    format: (row) => moment(row.created_at).format('DD/MM/YYYY HH:mm')
  },
  {
    name: 'Pinned',
    selector: 'is_pinned',
    center: true,
    sortable: true,
    format: (row) => (row.is_pinned ? 'Yes' : 'No')
  },
  {
    name: '',
    selector: 'id',
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
  }
];
export default class NoticesPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: [],
      search: '',
      editing: null,
      status: 'LOADING'
    };
  }

    componentDidMount = () => this.reload();

    doneEditing = () => {
      this.setState({ editing: null });
      this.reload();
    };

    reload = async () => {
      let resp = await get('cms/notices', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.notices) {
        this.setState({
          data: resp.notices,
          status: 'READY'
        });
      } else console.log(resp);
    };

    render () {
      if (this.state.logout) {
        return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      }
      let { data, search, editing, status } = this.state;
      if (editing) {
        return <NoticePage notice={editing} onBack={this.doneEditing} />;
      }
      if (search) {
        data = data.filter(
          (item) => `${item.name} ${item.category_name}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <div className="maincontainer">
          <h4>Notices</h4>
          <SearchRow
            value={search}
            searchTitle="Search by group name or category"
            onSearch={(search) => this.setState({ search })}
            buttonTitle="ADD NEW NOTICE"
            onBtnClick={(item) => this.setState({ editing: { id: 'new' } })}
          />
          <Table
            loading={status === 'LOADING'}
            columns={columns}
            data={data}
            onRowClicked={(item) => this.setState({ editing: item })}
            tableID="groups"
            defaultSortField={columns[1].selector}
          />
        </div>
      );
    }
}
