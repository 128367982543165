import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import Table from 'components/Table/Table';
import UserPage from './UserPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { get, post } from 'lib/comms';
import { AddUserDialog, MessageDialog } from 'components/Dialog/Dialog';
import { Dot } from 'components/SVG/SVG';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import SubscriptionBanner from 'components/SubscriptionBanner/SubscriptionBanner';
import ToolTip from 'components/ToolTip/TooltTip';

const USER_TOOLTIPS = [
  {
    target: '.table-outer',
    title: 'Users Table',
    content: 'This is the primary screen for adding or updating users within your Engage4 organisation.',
    placement: 'bottom'
  },
  {
    target: '.searchrow-btn',
    title: 'Add User',
    content: 'To add a new user at any time, select add new user.',
    placement: 'left'
  },
  {
    target: '.dialog-inner',
    title: 'Adding Users',
    content:
            'You can add users manually by adding a unique username and their date of birth, or you can upload a .csv containing the relevant information to add multiple users at once.',
    placement: 'right'
  },
  {
    target: '.table-outer',
    title: 'Manage Users',
    content:
            'Once you have added users you can view and manage their information and permissions by selecting the user from this view.',
    placement: 'bottom'
  }
];

export default class UsersPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = { data: [], search: '', editing: null, addUser: false, showMessage: null, stepIndex: 0 };
  }

    columns = [
      {
        name: 'Name',
        selector: 'last_name',
        sortable: true,
        grow: 1,
        wrap: true,
        format: (row) => `${row.first_name || ''} ${row.last_name || ''}`
      },
      { name: 'Username', selector: 'email_address', sortable: true },
      {
        name: 'Status',
        selector: 'first_name',
        sortable: true,
        cell: (row) =>
          row.first_name ? (
            <div onClick={() => this.setState({ editing: row })}>
              <Dot color="#4AB793" /> Registered
            </div>
          ) : (
            <div onClick={() => this.setState({ editing: row })}>
              <Dot color="#AF365F" /> Not Registered
            </div>
          )
      },
      {
        name: 'Permissions',
        selector: 'user_level',
        sortable: true,
        cell: (row) =>
          row.user_level ? (
            <div onClick={() => this.setState({ editing: row })}>
              <Dot color="#FBB811" /> Advanced
            </div>
          ) : (
            <div onClick={() => this.setState({ editing: row })}>
              <Dot color="#68C4D1" /> Basic
            </div>
          )
      },
      {
        name: '',
        selector: 'id',
        sortable: false,
        grow: 0,
        right: true,
        cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />
      }
    ];

    componentDidMount = () => this.reload();

    reload = async () => {
      let resp = await get('cms/users', null, true);
      if (resp && resp.status === 401) this.setState({ logout: true });
      if (resp && resp.users) this.setState({ data: resp.users });
      else console.log(resp);
    };

    doneEditing = () => {
      this.setState({ editing: null });
      this.reload();
      this.props.history.push('/users');
    };

    addUser = async (users = []) => {
      this.setState({ addUser: false });
      let showMessage = { title: 'Add user' };
      let resp = await post('cms/users', { users }, true);
      if (resp.success) {
        showMessage.message =
                resp.inserted === 1
                  ? '1 user has been added to the system.'
                  : `${resp.inserted} users have been added to the system.`;
        if (resp.inserted === 1 && resp.failed === 0 && users.length === 1) {
          showMessage.message = `${users[0] && users[0].username} has been added to the system.`;
        }
        if (resp.inserted === 0 && resp.failed === 1 && users.length === 1) {
          showMessage = {
            title: 'Add user error',
            isError: true,
            message: `${users[0] && users[0].username} has not been added to the system, it already exists.`
          };
        }
        this.setState({ showMessage });
      }
      this.reload();
    };

    static getDerivedStateFromProps = (props, state) => {
      const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true
      });

      if (query.user_id) {
        const selectedUser = state.data.find((user) => user.id === query.user_id);
        return {
          ...state,
          editing: selectedUser
        };
      }

      return state;
    };

    render () {
      if (this.state.logout) return <Redirect to={{ pathname: '/logout', state: { from: this.props.location } }} />;
      let { data, search, editing, addUser, showMessage } = this.state;
      if (editing) return <UserPage user={editing} onBack={this.doneEditing} />;
      if (search) {
        data = data.filter(
          (item) =>
            `${item.first_name} ${item.last_name} ${item.email_address}`
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
        );
      }
      return (
        <div className="maincontainer">
          <SubscriptionBanner />
          <h4>User Management</h4>
          <SearchRow
            searchTitle="Search by name or username"
            value={search}
            buttonTitle="ADD NEW USER"
            onBtnClick={() => this.setState({ addUser: true })}
            onSearch={(search) => this.setState({ search })}
          />
          <Table
            columns={this.columns}
            data={data}
            onRowClicked={(item) => this.setState({ editing: item })}
            tableID="users"
            defaultSortField={this.columns[1].selector}
          />
          {addUser ? (
            <AddUserDialog
              className="adduser-dialog"
              onClose={() => this.setState({ addUser: false })}
              onSuccess={this.addUser}
            />
          ) : null}
          {showMessage ? (
            <MessageDialog
              message={showMessage.message}
              title={showMessage.title}
              isError={showMessage.isError}
              onClose={() => this.setState({ showMessage: null })}
            />
          ) : null}
          <ToolTip
            screenId={'users'}
            stepIndex={this.state.stepIndex}
            callback={(tooltipData) => {
              if (tooltipData.lifecycle === 'complete') {
                this.setState({
                  stepIndex:
                                    tooltipData.action === 'prev' ? tooltipData.index - 1 : tooltipData.index + 1,
                  addUser: !!(
                    (this.state.stepIndex === 1 && tooltipData.action === 'next') ||
                                    (this.state.stepIndex === 3 && tooltipData.action === 'prev')
                  )
                });
              }
            }}
            steps={USER_TOOLTIPS}
          />
        </div>
      );
    }
}
