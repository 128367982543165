import React from 'react';
import styled from 'styled-components';

const TaskStatusLine = ({ isCompleted, isLast }) => (
  <SimpleLineContainer>
    {isCompleted ? (
      <Circle>
        <Circle
          style={{
            width: 16,
            height: 16,
            backgroundColor: '#0274a7'
          }}
        />
      </Circle>
    ) : (
      <Circle style={{ width: 16, height: 16 }} />
    )}
    {!isLast && <SimpleLine />}
    {/* style={isCompleted ? { width: 2 } : {}} */}
  </SimpleLineContainer>
);

export default TaskStatusLine;

const SimpleLineContainer = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 40px;
`;

const SimpleLine = styled.div`
    background-color: #0274a7;
    display: flex;
    flex: 1;
    width: 1px;
`;

export const VerticalLine = styled(SimpleLine)``;

const Circle = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid #0274a7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
