import React, { useState } from 'react';
import './ContentPlanner.css';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditDropDown } from 'components/EditText/EditText';

const EditDeleteSection = (props) => {
  const { id, title, entity_id, type, sequence_no, onClickEditContent, onClickDeleteContentItem, entity } = props;
  return (
    <div className="d-flex mt-2" style={{ alignItems: 'flex-end' }}>
      <button
        className="pl-0 m-0 edit-btn"
        onClick={() =>
          onClickEditContent({
            id,
            entity_id,
            title,
            type,
            entity,
            sequence_no
          })
        }
      >
                Edit
      </button>
      <span className={'vertical-bar'}>|</span>
      <button
        className="edit-btn"
        style={{ color: '#AF365F' }}
        onClick={() => {
          onClickDeleteContentItem(id);
        }}
      >
                Remove
      </button>
    </div>
  );
};

const ContentItem = (props) => {
  const {
    position,
    emptyCol,
    weekName,
    date,
    row,
    column,
    title,
    typeDesc,
    entityTitle = '',
    id,
    time_to_push,
    canAddOrEdit,
    onClickAddContent,
    onChangeTimeToPush,
    timeToPushData,
    sequence_no
  } = props;
  const [timeToPush, setTimeToPush] = useState(time_to_push || '09:00');
  return (
    <div
      key={id}
      className="content-box"
      style={{
        borderLeftWidth: column === 0 ? 1 : 0,
        borderTopWidth: row === 0 ? 1 : 0
      }}
    >
      {emptyCol ? (
        <div className="add-week" onClick={props.onClickAddWeek}>
          <FontAwesomeIcon icon={faPlusCircle} color="#0274a7" />
          <p className="regular-text ml-2 blue bold">ADD WEEK</p>
        </div>
      ) : (
        <div className="vertical-align">
          <div className="weekday-cont">
            <p className="weekday bold">{weekName}</p>
            <p className="weekday">{date}</p>
          </div>
          {!title ? (
            canAddOrEdit ? (
              <div className="horizontal-align" onClick={() => onClickAddContent(position + 1)}>
                <FontAwesomeIcon icon={faPlus} color="#0274a7" size="sm" />
                <p className="ml-1 regular-text blue bold">Add Content</p>
              </div>
            ) : null
          ) : (
            <div className="d-flex flex-column flex-1">
              <p className="regular-text" style={{ color: !canAddOrEdit ? '#0F2B41' : '' }}>
                {title.length > 20 ? title.substring(0, 20) + '...' : title}
              </p>
              <p
                className="regular-text bold"
                style={!canAddOrEdit ? { color: '#0F2B41', opacity: 0.5 } : {}}
              >
                {`${typeDesc}: `}
                <span style={{ fontWeight: 500 }}>
                  {entityTitle.length > 20 ? entityTitle.substring(0, 20) + '...' : entityTitle}
                </span>
              </p>

              {canAddOrEdit ? (
                <>
                  <EditDeleteSection {...props} />
                </>
              ) : null}
              <EditDropDown
                label="Time to push "
                options={timeToPushData}
                editing={canAddOrEdit ? 1 : 0}
                onChange={(val) => {
                  setTimeToPush(val);
                  onChangeTimeToPush({
                    id,
                    time_to_push: val,
                    sequence_no
                  });
                }}
                value={timeToPush}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContentItem;
